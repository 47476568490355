import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { RenderTextField } from 'axeleratum-sgc-frontend-library';
import { Grid, Button, InputLabel } from '@material-ui/core';

class TemplateUsageForm extends Component {

  render() {
    const fields = this.props.fields;
    const {
      handleSubmit,
      submitAction,
      onClickPreview,
      onCancel
    } = this.props;

    return (
      <form className="p-3">
        <Grid container>
            {
              (fields && fields.length > 0) && fields.map((field, index) => {
                return (
                  <Grid key={`${field.label}${index}`} item xs={12}>
                    <InputLabel>{field.label}</InputLabel>
                    <Field
                      label={''}
                      name={field.label}
                      component={RenderTextField}
                    />
                  </Grid>
                )
              })
            }
          <Grid item xs={12} className="text-right pt-3">
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              onClick={ () => onCancel() }
            >
              Cancelar
            </Button>
            <Button
              className="ml-2"
              type="button"
              variant="contained"
              color="primary"
              onClick={handleSubmit(onClickPreview)}
            >
              Vista previa
            </Button>
          </Grid>
          <Grid item xs={12} className="text-right pt-3">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit(submitAction)}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default reduxForm({
  form: 'TemplateUsageForm'
})(TemplateUsageForm);