import React, { Component } from 'react';
import { IconButton } from '@material-ui/core';
import FormSync from '../forms/form-sync';
import { CalendarHttp } from '../../../../core/http/calendar.http';
import { Alert } from 'axeleratum-sgc-frontend-library';
import { tokenExpired } from '../../../../core/utils';
import { authActions } from '../../../../core/actions';
import { connect } from 'react-redux';

export class Header extends Component {

  constructor(props) {
    super(props);

    this.state = {
      openAuthentication: false,
      loadSync: false,
      loadOutlook: false,
      calendarSuccess: false,
      calendarCancel: false,
      calendarError: false
    }
  }

  getGoogelAccess() {
    const {isLoggedIn, userLoggedOut} = this.props;  

    if(isLoggedIn && tokenExpired()){
          userLoggedOut()
          return
        }
        this.setState({ loadSync: true }); 

    new CalendarHttp().getGoogelAccess().then(({ data }) => {
      const token = data;
      const {isLoggedIn, userLoggedOut} = this.props;  

 if(isLoggedIn && tokenExpired()){
      userLoggedOut()
      return
    }
      new CalendarHttp().hasGoogleCredentials().then(({ data }) => {
        if (!data) {
          window.location.href = token;
        } else {
          this.setState({ loadSync: false, calendarSuccess: true });
        }
      }).catch(() => {});
    }).catch(() => {
      this.setState({ loadSync: false, calendarError: true });
    });
  }

  getOutlookAccess() {
    this.setState({ loadOutlook: true });
    const {isLoggedIn, userLoggedOut} = this.props;  

      if(isLoggedIn && tokenExpired()){
            userLoggedOut()
            return
          }

    new CalendarHttp().getOutlookPermissions().then(({ data }) => {
      if (data) {
        window.location.href = data;
      } else {
        this.setState({ loadOutlook: false });
      }
    }).catch(() => {
      this.setState({ loadOutlook: false, calendarError: true });
    });
  }

  render() {
    const { onExit } = this.props;
    const { openAuthentication, loadSync, calendarSuccess, calendarError, loadOutlook } = this.state;

    return (
      <div className="row header">
        <Alert
          open={ calendarSuccess }
          title="Calendario sincronizado correctamente"
          onConfirm={() => this.setState({ calendarSuccess: false })}
          type="success"
        />

        <Alert
          open={ calendarError }
          title="Hubo un problema al sincronizar el calendario"
          onConfirm={() => this.setState({ calendarError: false })}
          type="error"
        />

        <FormSync
          open={ openAuthentication }
          submitActions={ (formData) => {} }
          onCancel={ () => this.setState({ openAuthentication: false }) }
          onGoogle={ () => this.getGoogelAccess() }
          onOutlook={ () => this.getOutlookAccess() }
          loadGoogle={ loadSync }
          loadOutlook={ loadOutlook }
        />

        <div className="col-md-6 col-xs-6">
          <IconButton
            id="calendarClose"
            className="button-border mini-button-size"
            variant="outlined"
            onClick={ () => onExit() }
          >
            <strong className="fas fa-times font-color"></strong>
          </IconButton>
        </div>

        {/* <div className="col-md-6 col-xs-6 text-right">
          <IconButton
            className="button-border mini-button-size mr-2"
            variant="outlined"
            onClick={ () => this.setState({ openAuthentication: true }) }
          >
            <strong className="fas fa-sync font-color"></strong>
          </IconButton>
        </div> */}
      </div>
    );
  }

}


const mapStateToProps = state => {
  return {
    isLoggedIn: state.authReducer.loggedIn,
  }
}

const mapDispatchToProps = {
  userLoggedOut: authActions.userLoggedOut,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);