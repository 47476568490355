import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export const ButtonMenu = ({ containerStyle, buttonStyle, handleClick, name, menuItems = [] }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleClickElement = (value) => {
    handleClick(value)
    handleClose();
  }

  return (
    <div style={containerStyle}>
      <Button onClick={(e) => setAnchorEl(e.currentTarget)} {...buttonStyle}>
        {name}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          menuItems.map(item => (
            <MenuItem
              key={item.value}
              disabled={item.disabled || false}
              onClick={() => handleClickElement(item.value)}
            >
              {item.label}
            </MenuItem>
          ))
        }
      </Menu>
    </div>
  )
}
