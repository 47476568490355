import React, { useEffect, useState } from 'react';
import { Backdrop, Breadcrumbs, Button, CircularProgress, Grid, IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { makeStyles } from '@material-ui/styles';
import EventIcon from '@material-ui/icons/Event';
import { Link } from "react-router-dom";
import Header from '../dashboard/header';
import { FileSystem } from './FileSystem';
import { useVault } from '../../hooks/useVault';
import DialogFormFolder from './dialog-form-folder';
import { OptionsMenu } from './components/OptionsMenu';
import { menuAdd } from '../../core/utils/menuOptions';
import DialogFormDocument from './DialogFormDocumentFN';
import { Alert } from 'axeleratum-sgc-frontend-library';
import { FoldersHttp } from '../../core/http/folders.http';
import { SearchBarVaults } from './components/SearchBarVaults';
import { DocumentsHttp } from '../../core/http/documents.http';
import './vault.scss';
import { TagFilter } from './components/TagFilter';
import { tokenExpired } from '../../core/utils';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../core/actions';


const invalidFolderNames = [
  "templates",
  "plantillas",
  "libros corporativos",
  "trash",
];

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));


const VaultFilesFn = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);

  const [options, setOptions] = useState([])
  const [isTrash, setIsTrash] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [createLoader, setCreateLoader] = useState(false)
  const [openDialogNewFolder, setOpenDialogNewFolder] = useState(false)
  const [openDialogNewDocument, setOpenDialogNewDocument] = useState(false);
  const [alert, setAlert] = useState({ open: false, title: '', type: 'error' });

  const {
    vault,
    addTag,
    loading,
    goToPath,
    arrayPath,
    removeTag,
    filterTags,
    currentUser,
    itemSelected,
    openBackDrop,
    currentFolderId,
    setItemSelected,
    enableDescription,
    requestFileSystem,
    searchElementLocal,
  } = useVault();

  const foldersHttp = new FoldersHttp();
  const documentsHttp = new DocumentsHttp();

  const handleClickBreadcrumb = (index) => {
    if (index === 0) {
      goToPath('')
    } else {
      const goTo = arrayPath.reduce((prev, curr, i) => {
        if (i <= index && i > 0) {
          return [...prev, curr]
        }
        return prev
      }, []).join('/');

      goToPath(goTo)
    }
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleSaveFolder = (formData) => {
    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }
    if (!formData.name || formData.name.trim().length === 0) {
      setAlert({
        open: true,
        title: "El nombre del folder es obligatorio",
        type: 'error'
      })

      return;
    }

    setOpenDialogNewFolder(false)
    setCreateLoader(true)
    const invalidName = invalidFolderNames.some(
      (name) => name === formData.name.toLowerCase()
    );

    const mensaje = "No se puede crear este forlder con la palabra reservada: " + formData.name;

    if (invalidName) {
      setCreateLoader(false)
      setAlert({
        open: true,
        title: mensaje,
        type: 'error'
      })
      return;
    }
    const path = arrayPath.reduce((prev, curr, i) => i === 0 ? prev : [...prev, curr], []).join('/')
    const objRequest = {
      folderId: !path ? vault.id : currentFolderId,
      isCompany: !path,
      name: formData.name,
    }
    if (path) {
      objRequest.path = path
    }

    foldersHttp.createFolder(
      vault.id,
      objRequest,
      (data) => {
        requestFileSystem()
        setCreateLoader(false)
        setAlert({
          open: true,
          title: "Se ha guardado correctamente",
          type: 'success'
        })
      },
      (error) => {
        setCreateLoader(false)
        setAlert({
          open: true,
          title: `${error.response.data
            ? error.response.data
            : "Ocurrió un error al guardar."
            }`,
          type: 'error'
        });
      }
    );
  };

  const handleClickMeuItem = (value) => {
    setAnchorEl(null);
    if (value === 'document') {
      setOpenDialogNewDocument(true)
    }
    if (value === 'directory') {
      setOpenDialogNewFolder(true);
    }
  }

  const handleConfirmAlert = () => {
    setAlert({ open: false, title: '', type: '' });
  }

  const handleDocumentSelect = (doc) => {
    setItemSelected(doc)
  }

  const createDocument = (formData) => {

    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }
    if (createLoader) {
      return
    }
    const formD = new FormData();
    setOpenDialogNewDocument(false)
    setCreateLoader(true)
    // const collaborators = formData.users ? formData.users : [];

    if (!formData.file) {
      setAlert({
        open: true,
        title: "Es necesario cargar un archivo.",
        type: 'error'
      });
      setCreateLoader(false)
      return
    }

    if (formData.file[0].name) {
      formD.append("document", formData.file[0]);
    }
    if (formData.file[0].storedName) {
      formD.append("storedName", formData.file[0].storedName);
    }
    if (formData.file[0].hash) {
      formD.append("hash", formData.file[0].hash);
    }

    formD.append("editable", formData.file[0].editable ?? false);

    if (formData.file[0].extension) {
      formD.append("extension", formData.file[0].extension);
    } else {
      formD.append("extension", /(?:\.([^.]+))?$/.exec(formData.file[0].name)[0])
    }
    formD.append("size", formData.file[0].size);


    formD.append("name", formData.name ? formData.name.trim() : "");
    formD.append(
      "description",
      formData.description ? formData.description : ""
    );

    const path = arrayPath.reduce((prev, curr, i) => i === 0 ? prev : [...prev, curr], []).join('/')
    formD.append("path", path);
    formD.append("companyId", vault.id);
    formD.append("owner", currentUser.userId);
    if (currentFolderId) {
      formD.append("folderId", currentFolderId);
    }

    if (formData.tags) {
      formData.tags.forEach((item, index) => {
        formD.append(`tags[${index}]`, item.value);
      });
    }

    documentsHttp.createDocument(
      vault.name, //no se usa parametro
      formD,
      (data) => {
        requestFileSystem()
        setCreateLoader(false)
        setAlert({
          open: true,
          title: "Se ha guardado correctamente",
          type: 'success'
        });
      },
      (error) => {
        setCreateLoader(false)
        const message = error.response?.data
          ? typeof error.response.data === "string"
            ? error.response.data
            : "Ocurrió un error al guardar."
          : "Ocurrió un error al guardar.";

        setAlert({
          open: true,
          title: message,
          type: 'error'
        })
      }
    );
  };

  useEffect(() => {
    setIsTrash(arrayPath[1] === "Trash" ?? false)
  }, [arrayPath])

  useEffect(() => {
    if (!isTrash) {
      setOptions(menuAdd)
    }
  }, [isTrash])

  return (
    <>
      <Header
        titleRight={'Bóveda'}
        actions={[
          <IconButton variant='outlined'>
            <NotificationsIcon color='primary' />
          </IconButton>,
          <IconButton variant='outlined'>
            <EventIcon color='primary' />
          </IconButton>,
        ]}
      />

      {
        !loading && (
          <>
            <Grid container alignItems='center' spacing={3}>
              <SearchBarVaults
                xs={12}
                md={10}
                searchlocal={searchElementLocal}
                includedescription={enableDescription}
                documentselect={handleDocumentSelect}
              />
              <Grid item container xs={6} md={2} alignContent='center' justifyContent='flex-end' spacing={3}>
                {!isTrash && (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(evt) => {
                        setAnchorEl(evt.currentTarget)
                      }}
                    >
                      +&nbsp;Nuevo
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid container className="TagsFilter" >
              {
                vault?.tags.sort((a, b)=>a.localeCompare(b)).map((tag, index) => (
                  <TagFilter
                    label={`${tag}`}
                    key={index}
                    onClick={() => {
                      addTag(tag)
                    }}
                    onClear={() => {
                      removeTag(tag)
                    }}
                    tagSelected={filterTags.includes(tag)}
                  />
                ))
              }
            </Grid>
          </>
        )
      }

      <Grid container className="Breadcrumbs">
        <Breadcrumbs

          separator={<ArrowForwardIosIcon />}
          aria-label="breadcrumb"
          style={{ fontSize: "1rem" }}
        >
          {
            vault ? (
              <Link color="inherit" to="/dashboard/vault">
                Bóveda
              </Link>

            ) : (
              <span>Bóvedas</span>
            )
          }
          {
            arrayPath.map((item, index) => (
              <span 
                key={index}
                style={{ cursor: "pointer"}}
                onClick={() => {
                  handleClickBreadcrumb(index);
                }}
              >
                {item}
              </span>
            ))
          }
        </Breadcrumbs>
      </Grid>

      <FileSystem />

      {openDialogNewFolder && (
        <DialogFormFolder
          openDialog={openDialogNewFolder}
          submitActions={(formData) => handleSaveFolder(formData)}
          onCancel={() => setOpenDialogNewFolder(false)}
          initialValues={{}}
          typeForm={'create'}
        />
      )}

      {openDialogNewDocument && (
        <DialogFormDocument
          openDialog={openDialogNewDocument}
          addCollaboratorsToDocumentIsEnabled={false}
          submitActions={(formData) => createDocument(formData)}
          onCancel={() => setOpenDialogNewDocument(false)}
          tagsSelect={vault.tags.map((tag) => ({ label: tag, value: tag }))}
          company={vault}
          documentSelected={itemSelected}
          typeForm={'create'}
          collaborators={[]}
          currentUser={currentUser}
        />
      )}
       

      <OptionsMenu
        options={options}
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
        handleClickMeuItem={handleClickMeuItem}
      />

      <Alert
        open={alert.open}
        title={alert.title}
        type={alert.type}
        onConfirm={handleConfirmAlert}
      />


      <Backdrop open={openBackDrop || createLoader} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>

     

    </>
  )
}

export default VaultFilesFn