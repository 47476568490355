import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText, Grid, makeStyles, ListItemSecondaryAction, IconButton, Tooltip } from '@material-ui/core';
import { validatePermissions, permissionScheme } from '../../../core/utils/can-i';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles({
  secondaryAction: {
    right: '-6px !important'
  },
  selectedItem: {
    color: 'white !important',
    borderRadius: '12px',
    fontFamily: 'Open Sans, sans-serif !important',
    fontStyle: 'normal !important',
    fontWeight: '600 !important',
    fontSize: '18px !important',
    lineHeight: '25px !important',
  }
})

export const SelectorRoles = (props) => {
  const [elementSelected, setElementSelected] = React.useState(null)
  const [modifyRolesIsEnabled, setModifyRolesIsEnabled] = React.useState(null)

  useEffect(() => {
    if (!props.elementSelected) {
      setElementSelected(null)
    }

    if (props.elementSelected) {
      setElementSelected(props.elementSelected)
    }
  }, [props.elementSelected])

  useEffect(()=> {
    validatePermissions((permissionScheme.roleManagement.AssignModifyRolesOfAllUsers)).then(enabled => {
      setModifyRolesIsEnabled(enabled)
    });

  }, [modifyRolesIsEnabled])

  const { elements, title, className, onAddRole } = props;

  const classes = useStyles(props)

  function selectElement(value) {
    const { onSelectElement } = props;
    setElementSelected( value );
    onSelectElement && onSelectElement(value);
  }

  return (
    <Grid item md={ 3 } xs={12} >
      <div className={`${className}`}>
        <List component="nav" aria-label="secondary mailbox folder">
          {title && (
            <ListItem
              className="selector-select"
              button
              disabled
            >
              <ListItemText primary={ title } />
              <ListItemSecondaryAction classes={{root: classes.secondaryAction}}>
                <Tooltip title={'Crear rol'}>
                  <IconButton
                    disabled={!modifyRolesIsEnabled}
                    onClick={ () => onAddRole()}
                  >
                    <AddCircleOutlineIcon/>
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          )}
          
          {
            (elements.length > 0) && elements.map((element, index) => (
              <ListItem
                disabled={!modifyRolesIsEnabled}
                className={`selector-select`}
                key={ index }
                classes={{selected: classes.selectedItem}}
                button
                selected={ (elementSelected && elementSelected.value === element.value) }
                onClick={ () => selectElement(element)}
                style={{background: (elementSelected && elementSelected.value === element.value) ? element.color : ''}}
              >
                <ListItemText
                  classeName={classes.rootItem}
                  primary={ element.label }
                  style={{color: (elementSelected && elementSelected.value === element.value) ? 'white' : element.color}}
                />
              </ListItem>
            ))
          }
          {
            elements.length === 0 && 
            <ListItem
              className="selector-select"
              button
              disabled
            >
              <ListItemText primary={ 'No hay roles registrados' } />
            </ListItem>
          }
        </List>
      </div>
    </Grid>
    
  );
}

SelectorRoles.propTypes = {
  elements: PropTypes.array,
  elementSelected: PropTypes.any,
  onSelectElement: PropTypes.func,
  title: PropTypes.string,
  sizeContainer: PropTypes.number,
  className: PropTypes.string
}
