import { authHeader, axiosCalendarRequest } from "../../configs/axios.config";


export class NotificationHttp {
	
	findAll() {
		return axiosCalendarRequest.get(
			'/calendars/notifications', 
			{ headers: authHeader() }
		);
	}

	findConfigurations() {
		return axiosCalendarRequest.get(
			'/calendars/notifications/notification-configurations', 
			{ headers: authHeader() }
		);
	}

	saveConfigurations(configurations) {
		return axiosCalendarRequest.put(
			'/calendars/notifications/notification-configurations', 
			configurations, 
			{ headers: authHeader() }
		);
	}

	viewNotification(id) {
		return axiosCalendarRequest.put(
			`/calendars/notifications/view-notification/${id}`,
			{ },
			{ headers: authHeader() }
		);		
	}

	signReminder(data) {
		return axiosCalendarRequest.post(
			`/calendars/notifications/sign-reminder`,
			data,
			{ headers: authHeader() }
		);	
	}
	
}