import { fileSystemConstants } from "../constants/fileSystem.constants";


export const fileSystemActions = {
  saveItems: (items) => ({ type: fileSystemConstants.SAVE_ITEMS_FILE_SYSTEM, payload: items }),
  selectItem: (itemId) => ({ type: fileSystemConstants.SELECT_ITEM, payload: itemId }),
  loadingItems: (isLoading) => ({ type: fileSystemConstants.LOADING_ITEMS, payload: isLoading }),
  openDocumentPdf: (open) => ({ type: fileSystemConstants.OPEN_DOCUMENT_PDF, payload: open }),
  saveArrayPath: (arrayPath) => ({ type: fileSystemConstants.SAVE_ARRAY_PATH, payload: arrayPath }),
  openBackDrop: (open) => ({ type: fileSystemConstants.OPEN_BACKDROP, payload: open }),
  searchElements: (name) => ({ type: fileSystemConstants.SEARCH_ELEMENTS, payload: name }),
  includeDescription: (payload) => ({ type: fileSystemConstants.SET_INCLUDE_DESCRIPTION, payload: payload }),
  addFilterTag: (tag) => ({ type: fileSystemConstants.ADD_FILTER_TAG, payload: tag }),
  removeFilterTag: (tag) => ({ type: fileSystemConstants.REMOVE_FILTER_TAG, payload: tag }),
}