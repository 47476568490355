import React, { useEffect, useState } from 'react';
import { Grid, List, Divider, ListItem, ListItemText, ListItemAvatar, Avatar, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import { DialogSignatureStatus } from '../../dialogs/activities/DialogSignatureStatus.jsx';
import { CorporateBooksHttp } from '../../../core/http/corporate-books.http.js';
import { DocumentsHttp } from '../../../core/http/documents.http.js';
import { CalendarHttp } from '../../../core/http/calendar.http.js';
import { BusinessHttp } from '../../../core/http/business.http.js';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Capital from '../../capital-management/inversores.jsx';
import { Alert, uuid } from 'axeleratum-sgc-frontend-library';
import DocumentReview from '../../vault/document-review.jsx';
import { DashboardCard } from '../dashboard-card.jsx';
import { tokenExpired } from '../../../core/utils/token.js';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../../core/actions/auth.actions.js';
import { getVaultUsers } from '../../../core/http/functionRequests/vault.http.js';

export const BusinessManagementFn = (props) => {
  const { folders, business, idCompany, currentUser } = props;

  const calendarHttp = new CalendarHttp();
  const companyHttp = new BusinessHttp();
  const corporateBookHttp = new CorporateBooksHttp();
  const documentsHttp = new DocumentsHttp();
  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);


  const [openActivity, setOpenActivity] = useState(false);
  const [activity, setActivity] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('');
  const [pendingActivities, setPendingActivities] = useState([]);
  const [pendingActivitiesLoad, setPendingActivitiesLoad] = useState(false);
  const [pendingDocuments, setPendingDocuments] = useState([]);
  const [pendingDocumentsLoad, setPendingDocumentsLoad] = useState(false);
  const [usersLoad, setUsersLoad] = useState(false);
  const [users, setUsers] = useState([]);
  const [upload, setUpload] = useState(false);

  const dispatch = useDispatch();

  const getPendingActivities = (companyId) => {


    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }

    setPendingActivitiesLoad(true);

    calendarHttp.getPendingActivities().then(({ data }) => {
      setPendingActivities(data.filter(el => el.companyId === companyId && el.show))
      setPendingActivitiesLoad(false)
    }).catch(() => {
      setPendingActivities([])
      setPendingActivitiesLoad(false)
    });
  }

  const getInvitations = (companyId) => {
    setPendingDocumentsLoad(true)
    calendarHttp.getSignatureActivitiesCurrentUser().then(({ data }) => {
      setPendingDocuments(data.filter(el => el.document.document.Company === companyId));
      setPendingDocumentsLoad(false)
    }).catch(() => {
      setPendingDocuments([]);
      setPendingDocumentsLoad(false)
    });
  }

  const getUsersByCompanyId = () => {
    setUsersLoad(true);
    getVaultUsers(window.location.href.split('home/')[1]).then(data => {
      setUsers(data)
      setUsersLoad(false);
    }).catch(() => {
      setUsers([])
      setUsersLoad(false);
    });
  }


  const handleClickPendingActivity = (id, item) => {
    calendarHttp.findActivityById(id,
      (data) => {
        if (data.type === "revision") {
          setActivity({ id: item.documentId, name: item.documentName, type: "revision" })
        } else {
          setActivity(data);
        }
        setOpenActivity(true)
      }, () => { });
  }

  const updateSignatureStatus = () => {
    const onError = (error) => {
    }

    const onSuccess = (data) => {
      if (data.type === "revision") {
        setActivity({ id: activity.documentId, name: activity.documentName, type: "revision" })
      } else {
        setActivity(data);
      }
      setOpenActivity(true)
    }

    calendarHttp.findActivityById(activity.id.toString(), onSuccess, onError)
  }


  const handleCancel = () => {
    setActivity(null);
    setOpenActivity(false);
  }

  useEffect(() => {
    getPendingActivities(props.idCompany.id)
    getInvitations(props.idCompany.id);
    getUsersByCompanyId();
  }, [])


  return (
    <div>

      <Grid container spacing={2}>
        <Alert
          open={openAlert}
          title={message}
          onConfirm={() => setOpenAlert(false)}
          type={typeMessage}
        />

        <DashboardCard
          number={pendingActivities ? pendingActivities.length : ''}
          text="Actividades pendientes"
          type="activities"
          style="detail"
          id={'activities'}
          loading={pendingActivitiesLoad}
          enabledExpand
          onExpand={() => getPendingActivities(idCompany.id)}
        >
          <List component="div" disablePadding>
            {
              pendingActivitiesLoad ?
                <ListItem style={{ paddingLeft: '0px' }}>
                  <ListItemText
                    className='DashboardCard-listItemText'
                    secondary={
                      <span>
                        <em className="fa fa-spinner fa-spin"></em>
                        &nbsp;&nbsp;Cargando actividades...
                      </span>
                    }
                  />
                </ListItem>
                :
                pendingActivities.map((item) => (
                  <div key={uuid()}>
                    <Divider />
                    <ListItem style={{ paddingLeft: '0px' }}>
                      <ListItemText
                        className='DashboardCard-listItemText'
                        secondary={item.name}
                      />

                      <ListItemSecondaryAction style={{ right: '-8px' }}>
                        <IconButton onClick={() => handleClickPendingActivity(item.id, item)}>
                          <ArrowForwardIcon color="primary" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </div>
                ))
            }
          </List>
        </DashboardCard>

        <DashboardCard
          number={pendingDocuments.length}
          text="Documentos enviados a firma"
          type="invitations"
          style="detail"
          id={'documents'}
          loading={pendingDocumentsLoad}
          enabledExpand
          onExpand={() => getInvitations(props.idCompany.id)}
        >
          <List component="div" disablePadding>
            {
              pendingDocumentsLoad ?
                <ListItem style={{ paddingLeft: '0px' }}>
                  <ListItemText
                    className='DashboardCard-listItemText'
                    secondary={
                      <span>
                        <em className="fa fa-spinner fa-spin"></em>
                        &nbsp;&nbsp;Cargando Documentos...
                      </span>
                    }
                  />
                </ListItem>
                :
                pendingDocuments.map((item) => (
                  <div key={uuid()}>
                    <Divider />
                    <ListItem style={{ paddingLeft: '0px' }}>
                      <ListItemText
                        className='DashboardCard-listItemText'
                        secondary={item.document.documentName}
                      />

                      <ListItemSecondaryAction style={{ right: '-8px' }}>
                        <IconButton onClick={() => handleClickPendingActivity(item.activity.id, item)}>
                          <ArrowForwardIcon color="primary" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </div>
                ))
            }
          </List>
        </DashboardCard>

        <DashboardCard
          number={users.length}
          text="Miembros de equipo"
          type="members"
          style="detail"
          id={'members'}
          loading={usersLoad}
          enabledExpand
        >
          <List component="div" disablePadding>
            {
              users.map((item) => (
                <div key={uuid()}>
                  <Divider />
                  <ListItem style={{ paddingLeft: '0px' }}>
                    <ListItemAvatar>
                      <Avatar style={{ border: '2px solid white' }}>
                        {item.completeName.toUpperCase().charAt(0)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      className='DashboardCard-listItemText'
                      primary={item.completeName}
                    />
                  </ListItem>
                </div>
              ))
            }
          </List>
        </DashboardCard>
      </Grid>

      {/* <Grid container spacing={1}>
        <Grid className="mt-4 animate__animated animate__fadeIn" container item md={12}>
          <Grid item container>
            <Capital idCompany={idCompany} />
          </Grid>
        </Grid>
      </Grid> */}

      {
        (["firm", "endorse", "cancel"].includes(activity?.type) && openActivity) && (
          <DialogSignatureStatus
            open={openActivity}
            setOpen={setOpenActivity}
            item={activity}
            onExitDetail={handleCancel}
            onUpdate={updateSignatureStatus}
          />
        )
      }

      {
        (activity?.type === "revision" && openActivity) && (
          <DocumentReview
            immediatelyRevision={false}
            openDialog={openActivity}
            onClose={handleCancel}
            closeModal={handleCancel}
            document={activity}
          />
        )
      }
    </div>
  )
}
