import React, { Component } from "react";
import { Button, Grid, Box, IconButton } from "@material-ui/core";
import { uuid } from "axeleratum-sgc-frontend-library";
import { PopUpUserFn } from "../popup/PopUpUserFn";

export class ItemFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openUsers: false,
      onlyShow: false,
      sign: false,
      donwloadFile: this.props.donwloadFile,
    };
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      donwloadFile: props.donwloadFile ? props.donwloadFile : false,
    });
  }
  searchSign() {
    const { users, bpmStatus } = this.props;
    let out = true;

    if (bpmStatus === "Rechazo de Firma") {
      return true;
    }

    if (users) {
      users.forEach((item) => {
        if (item.signed) {
          out = false;
        }
      });
    }

    return out;
  }

  render() {
    const { openUsers, onlyShow, donwloadFile } = this.state;
    const { name, users, checked, onRemoveItem, onAddUsers, type, fileId, hideRemoveButton } =
      this.props;

    return (
      <Grid item xs={12} className="ItemFile">
        <Grid container spacing={1} justifyContent={"space-between"}>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center">
              <em
                style={{ paddingLeft: 10 }}
                className="fa fa-file primary-text fa-2x"
              />
              <span style={{ paddingLeft: 10 }}>
                {name}
              </span>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              item
              display="flex"
              alignItems="center"
              justifyContent={"end"}
            >
              <>
                {users &&
                  users.map((user, index) => (
                    <div
                      className={`${index >= 4 ? "" : "col-md-1 col-xs-1"}`}
                      key={uuid()}
                    >
                      {index >= 4 ? undefined : index === 3 ? (
                        <div
                          style={{
                            marginLeft: `-${8 * index}px`,
                            background: user.color
                              ? user.color
                              : user.colorUser,
                          }}
                          className="text-center circle-user"
                          onClick={() =>
                            this.setState({ openUsers: true, onlyShow: true })
                          }
                        >
                          {users.length + 1 - 4}+
                        </div>
                      ) : (
                        <React.Fragment>
                          {users.length === 1 ? (
                            <div
                              style={{
                                marginLeft: "-25px",
                                background: user.color
                                  ? user.color
                                  : user.colorUser,
                              }}
                              className="text-center circle-user"
                              title={user.name}
                              onClick={() =>
                                this.setState({
                                  openUsers: true,
                                  onlyShow: true,
                                })
                              }
                            >
                              {user.name ? user.name.substring(0, 1) : ""}
                            </div>
                          ) : (
                            <div
                              style={{
                                marginLeft: `-${8 * index}px`,
                                background: user.color
                                  ? user.color
                                  : user.colorUser,
                              }}
                              className="text-center circle-user"
                              title={user.name}
                              onClick={() =>
                                this.setState({
                                  openUsers: true,
                                  onlyShow: true,
                                })
                              }
                            >
                              {user.name ? user.name.substring(0, 1) : ""}
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  ))}
                <span
                  style={{ position: "absolute" }}
                  title={
                    !this.searchSign()
                      ? "No se pueden añadir más firmantes porque el documento ya fué firmado."
                      : ""
                  }
                ></span>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    this.setState({ openUsers: true, onlyShow: false })
                  }
                  disabled={!this.searchSign()}
                >
                  {type === "revision"
                    ? "Agregar Revisores"
                    : "Firmantes"}
                </Button>
                {
                  openUsers && <PopUpUserFn
                    open={openUsers}
                    checkedOrder={checked === undefined ? false : checked}
                    labelType={type}
                    onlyShow={onlyShow}
                    initialValues={users}
                    documentId={fileId}
                    onSubmit={(users, check) => onAddUsers(users, check)}
                    onCancel={() => this.setState({ openUsers: false })}
                  />
                }
              </>
              {/**Quitar ellimar doc */}
              {donwloadFile === true ? (
                ""
              ) : hideRemoveButton ? <></>
                : (
                  <IconButton onClick={() => onRemoveItem(name)}>
                    <em className="fa fa-times primary-text fa-1x" />
                  </IconButton>
                )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
