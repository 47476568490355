import { Button, Dialog, Grid } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import RenderTextField from '../../core/redux-form-fields/render-text-field';

class ValidateCredentials extends Component {
  render() {
    const { open, handleSubmit, onSubmit, onCancel } = this.props;

    return (
      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth={true}
      >
        <form>
          <Grid container item xs={12} className="p-5">
            <h3>Validar Credenciales</h3>
            <Field
              label="Correo"
              name="username"
              required
              component={RenderTextField}
            />
            <Field
              label="Contraseña"
              name="password"
              required
              passwordAction
              icon={
                <Visibility />
              }
              component={RenderTextField}
            />

            <Grid container item xs={12} className="pt-3" direction="row" justifyContent="flex-end">
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                Aceptar
              </Button>
              <Button
                className="ml-2"
                color="primary"
                variant="outlined"
                onClick={() => onCancel()}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Dialog>
    );
  }
}

const validate = (values) => {
  const errors = {
    username: '',
    password: ''
  }

  if (!values.username) {
    errors.username = 'El correo electrónico es requerido';
  }

  if (!values.password) {
    errors.password = 'La contraseña es requerida';
  }

  if (values.username) {
    if (values.username.length > 50) {
      errors.username = 'El correo electrónico no puede ser mayor a 50 caracteres';
    }
  }

  if (values.password) {
    if (values.password.length > 20) {
      errors.password = 'La contraseña no puede ser mayor a 20 caracteres';
    }
  }

  return errors;
}

export default reduxForm({
  form: 'ValidateCredentialsForm',
  validate
})(ValidateCredentials);