import React, { Component } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';

export class CheckboxField extends Component {
 
  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.checked ? this.props.checked : false
    }
  }
 
  render() {
    const { label, name } = this.props;
    const { checked } = this.state;

    return (
      <React.Fragment>
        <FormControlLabel
          control={
            <Checkbox
              onChange={ (evt) => this.setState({ checked: evt.target.checked }) }
              name={ name }
              checked={ checked }
            />
          }
          label={ label }
        />
      </React.Fragment>
    );
  }
}

CheckboxField.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string
}