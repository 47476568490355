import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { Grid, makeStyles, styled } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { isExpired } from "../../core/utils";
import { authActions } from "../../core/actions";
import { Redirect } from "react-router";
import Control from "../control/control";
import "./header.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 500,
  },
  actions: {
    display: "flex",
    marginBottom: "10px"
  },
  actionButton: {
    marginLeft: "20px",
    "& .MuiIconButton-root": {
      color: "white !important",
      width: "30px !important",
      height: "30px !important",
      minWidth: "0 !important",
      borderRadius: "8px",
      border: "1px solid " + theme.palette.primary.main,
    },
    "& .MuiButton-root": {
      color: theme.palette.primary.main,
    },
  },
  title1: {
    fontSize: "2em",
    fontFamily: `'Rubik', sans-serif`,
    color: '#393D3F !important',
    marginTop: '-1px',
  },
  title2: {
    fontSize: "2em",
    fontWeight: "bold",
    fontFamily: `'Rubik', sans-serif`,
    color: '#393D3F !important',
    marginTop: '-1px',
  }
}));

const Header = (props) => {
  const { titleLeft, titleRight } = props;
  const classes = useStyles();
  const keep_active_session = useSelector(
    (state) => state.authReducer.keep_active_session
  );
  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const isExpiredToken = isExpired(sessionStorage.getItem("access_token"));

  //   if (keep_active_session && isExpiredToken) {
  //     dispatch(authActions.refreshToken());
  //   } else if (!keep_active_session && isExpiredToken) {
  //     dispatch(authActions.logout());
  //   }
  // }, [keep_active_session]);

  return (
    <Grid container className={"header"}>
      <Grid item xs={8}>
        {titleLeft && (
          <span className={classes.title1}>{`${titleLeft} `}</span>
        )}<span className={classes.title2}>{titleRight}</span>
      </Grid>
      <Grid item container xs={4} className={classes.actions} justifyContent="flex-end">
        <Control />
      </Grid>
      {/* {!isLoggedIn && <Redirect to={"/"} />} */}
    </Grid>
  );
};

Header.propTypes = {
  titleRight: PropTypes.string.isRequired,
  actions: PropTypes.array,
};

export default Header;
