import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, CardActionArea } from '@material-ui/core';
import { VerticalCenterDiv } from '../vertical-center-div/vertical-center-div';
import LockIcon from "@material-ui/icons/Lock";
import colorsJSON from '../../../assets/material-colors';
import { getRandomColor } from '../functions/colors';
import './template-business-card.scss';

export const TemplateBusinessCard = (props) => {
  const {
    label,
    value,
    icon,
    color,
    onClick,
    colorIndex,
  } = props;

  const getColor = () => {
    const color = colorIndex !== undefined ?
      colorsJSON.basicColors[colorIndex] : getRandomColor();

    return color;
  }

  return (
    <Grid className="template-business-card" item>
      <Card className="card pointer" onClick={() => { onClick(value) }}>
        <CardActionArea className="action-area" style={{ height: '100%' }}>
          <VerticalCenterDiv>
            <Grid container spacing={2}>
              <Grid item xs={4} md={3}>
                <VerticalCenterDiv
                  className="icon text-center ml-3"
                  style={{ background: color ? color : getColor() }}
                >
                  {icon ? icon : <LockIcon />}
                </VerticalCenterDiv>
              </Grid>

              <Grid item xs={8} md={9}>
                <VerticalCenterDiv className="text">
                  {label}
                </VerticalCenterDiv>
              </Grid>
            </Grid>
          </VerticalCenterDiv>
        </CardActionArea>
      </Card>
    </Grid>
  );
}

TemplateBusinessCard.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onClick: PropTypes.func,
  color: PropTypes.string,
  colorIndex: PropTypes.number,
  icon: PropTypes.any
}