import { Modal, Box, Grid } from '@material-ui/core';
import { BlockChainConfirmIcon } from 'axeleratum-sgc-frontend-library';
import { DocFileSelected } from 'axeleratum-sgc-frontend-library/components/icons/doc-file-selected';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { getDocumentInfo } from '../../core/http/functionRequests/documents.http';
import { TabPanelApp } from '../tabPanel/TabPanelApp';
import { useStyles } from './details';
import DetailsActivity from './details/details-activity';
import DetailsContent from './details/details-content';

export const DetailsModal = ({ openModal, handleCloseModal, documentId, documentName }) => {
  const classes = useStyles();
  const [historyInfo, setHistoryInfo] = useState(null);
  const [collaborators, setCollaborators] = useState([]);
  const [itemInfo, setItemInfo] = useState(null);



  const detailsTab = (
    <Grid
      container
      spacing={2}
      className={classes.detailsContainer}>
      <DetailsContent
        classes={classes}
        dataElement={itemInfo}
        accessElements={collaborators}
      />
    </Grid>
  );

  const activityTab = (
    <Grid
      container
      spacing={2}
      className={classes.detailsContainer}>
      <DetailsActivity
        dataElement={historyInfo}
        classes={classes}
      />
    </Grid>
  );

  const fetchInfo = async () => {
    try {
      const docRequest = await getDocumentInfo(documentId);

      setItemInfo(docRequest.itemInfo);
      setHistoryInfo(docRequest.history);
      setCollaborators([]);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchInfo();
  }, [])


  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >

      <Box
        sx={{
          bgcolor: 'background.paper',
          border: '1px solid #000',
          maxWidth: 500,
          maxHeight: 550,
          boxShadow: 24,
          p: 4,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '22px',
            fontWeight: 'bold',
            justifyContent: 'space-around'
          }}
        >
          <div>
            <DocFileSelected
              color='primary'
              className={classes.iconFolder}
            />
            <span>{documentName}</span>
          </div>

          <BlockChainConfirmIcon />
        </div>
        <TabPanelApp
          classes={classes}
          tabs={['DETALLES', 'ACTIVIDAD']}
          content={[
            detailsTab,
            activityTab
          ]}
        />
      </Box>

    </Modal>
  )
}
