import { createTheme } from '@material-ui/core/styles';

export const normal = createTheme({
  palette: {
    primary: {
      main: '#5C7BF0',
    },
    secondary: {
      main: '#C3D0EB', //#f50057
    },
    success: {
      main: '#38B349',
    },
    error: {
      main: '#F90D38',
    }
  },
  itemMenu: {
    primary: {
      main: 'rgba(247, 250, 255, 0.4)'
    }
  }
});
