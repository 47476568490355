import { Button, TableCell } from '@material-ui/core';
import React, { Component } from 'react';
import { ReviewersHttp } from '../../../../core/http/reviewers.http';
import { tokenExpired } from '../../../../core/utils';
import { authActions } from '../../../../core/actions';
import { connect } from 'react-redux';

export class ReviewButton extends Component {
  
  reviewersHttp = new ReviewersHttp();
  
  constructor(props) {
    super(props);

    this.state = {
      isReview: false
    }
  }

  componentDidMount() {
    const {isLoggedIn, userLoggedOut} = this.props;  

    if(isLoggedIn && tokenExpired()){
          userLoggedOut()
          return
        }
    this.reviewersHttp.isReviewed(this.props.item.documentId, (resp) => {
      this.setState({ isReview: resp });
    });
  }

  render() {
    const { onClick } = this.props;
    const { isReview } = this.state;

    return (
      <React.Fragment>
        <TableCell className="no-td">
          <Button
            color="primary"
            variant="contained"
            onClick={() => onClick() }
          >
            Revisiones
          </Button>
        </TableCell>
        
        {
          isReview &&
            <TableCell className="no-td">
              <div className="circle-check">
                <em className="fa fa-check"></em>
              </div>
            </TableCell>  
        }
      </React.Fragment>
    );
  }
} 

const mapStateToProps = state => {
  return {
    isLoggedIn: state.authReducer.loggedIn,
  }
}

const mapDispatchToProps = {
  userLoggedOut: authActions.userLoggedOut,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewButton);
