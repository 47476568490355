import { axiosRequestBlockChain, axiosTemplateRequest, axiosBusinessRequest, authHeader } from '../../configs/axios.config';

export class TemplatesHttp {

  getAllTemplates(companyId, success, error) {
    axiosRequestBlockChain.get(`/folder/company-templates/${companyId}`, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp))
  }

  createTemplate(data, success, error) {
    axiosRequestBlockChain.post(`/document/insert`, data, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp));
  }

  updateTemplate(data, success, error) {
    axiosRequestBlockChain.put(`/document/update`, data, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp));
  }

  deleteTemplate(data, success, error) {
    axiosRequestBlockChain.delete(`/document/delete`, {headers: authHeader(), data: data})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp));
  }

  getTemplateMarkers(params, success, error) {
    axiosTemplateRequest.get('/templates/bookmarks', {headers: authHeader(), params: params})
    .then(({data}) => success(data))
    .catch(err => error(err))
  }

  replaceBookmarks(fileName, bookmarks, success, error) {
    axiosTemplateRequest.post(`/templates/replace-bookmarks?document=${fileName}`, bookmarks, {headers: authHeader(), responseType: 'arraybuffer'})
    .then(({data}) => success(data))
    .catch(err => error(err))
  }

  deleteAuxTemplateFile(fileName, success, error) {
    axiosTemplateRequest.delete(`/templates/template-file?document=${fileName}`, {headers: authHeader()})
    .then(({data}) => success(data))
    .catch(err => error(err))
  }

  getBookmarkValuesByDocumentId(documentId, success, error) {
    axiosTemplateRequest.get(`/templates-bookmarks/${documentId}`, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp));
  }

  saveBookmarkValues(data, success, error) {
    axiosTemplateRequest.post(`/templates-bookmarks`, data, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp));
  }

  updateBookmarkValues(data, success, error) {
    axiosTemplateRequest.put(`/templates-bookmarks`, data, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp));
  }
}