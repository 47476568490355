import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import LockIcon from "@material-ui/icons/Lock"
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { Grid, ListItemIcon } from "@material-ui/core";
import {
	DocumentUndefined,
	ExcelIcon,
	PdfIcon,
	WordIcon,
} from "../../../icons";
import { FolderFileSystemIcon } from "../../../icons/FolderFileSystemIcon";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		maxWidth: "36ch",
		backgroundColor: theme.palette.background.paper,
	},
	inline: {
		display: "inline",
		fontSize: "13px !important",
	},
	sizeIcon: {
		width: "2em", height: "2em"
	},
	fileName: {
		fontSize: "16px !important",
		fontWeight: "bold !important"
	},
	path: {
		fontSize: "13px !important",
	}
}));

export default function CardSearched({
	id,
	path,
	name,
	extension,
	vaultName,
	vaultId,
	isVault,
	author,
	updatedAt,
}) {
	const classes = useStyles();

	return (
		<Grid container className={classes.cardContainer} alignItems="center">
			<Grid item xs={1}>
				<ListItemIcon>
					{isVault ? (
						<LockIcon
							style={{ width: "1.3em", height: "1.3em" }}
							color="primary"
						/>
					) : !extension ? (
						<FolderFileSystemIcon className={classes.sizeIcon} />
					) : extension === ".docx" ? (
						<WordIcon className={classes.sizeIcon} />
					) : extension === ".pdf" ? (
						<PdfIcon className={classes.sizeIcon} />
					) : extension === ".xlsx" ? (
						<ExcelIcon className={classes.sizeIcon} />
					) : (
						<DocumentUndefined
							className={classes.sizeIcon}
							style={{ color: "#5C7BF0" }}
						/>
					)}
				</ListItemIcon>
			</Grid>

			<Grid item xs={10} style={{ marginLeft: "10px" }}>
				<ListItemText
					style={{
						marginTop: '0px',
						marginBottom: '0px'
					}}
					primary={
						<span className={classes.fileName}>
							{extension ? `${name}${extension}` : `${name}`}
						</span>
					}
					secondary={
						<>
							<Typography
								component="span"
								variant="body2"
								className={classes.inline}
								color="textPrimary">
								{author} -{" "}
								{new Date(updatedAt).toLocaleDateString("es-Mexico", {
									year: "numeric",
									month: "short",
									day: "numeric",
								})}
							</Typography>
							<br></br>
							<span className={classes.path}>
								{!isVault && `/${vaultName}${path ? "/" + path + "/" : ""}`}
							</span>
						</>
					}
				/>
			</Grid>
		</Grid>
	);
}
