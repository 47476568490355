import React from 'react'
import { Dialog, Slide } from '@material-ui/core';
import PDFViewer from '../../../../../from-library/pdf-viewer-v2/PDFViewer';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DocumentSignatures = ({ open, onClose, signers, url, documentName, onSaveSigns, stickersValue }) => {
  return (
    <Dialog
      maxWidth={false}
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <PDFViewer
        users={signers}
        url={url}
        documentName={documentName}
        onClose={onClose}
        onSaveSigns={onSaveSigns}
        signatures={stickersValue ?? []}
      />
    </Dialog>
  )
}
