export default {
  "userManagement": {
    "editUser": {
      "permissionName": "Editar Usuarios",
      "groupName": "Gestion de usuarios"
    },
    "blockPlatformUser": {
      "permissionName": "Bloquear usuario de la plataforma",
      "groupName": "Gestion de usuarios"
    },
    "reactiveUserAccounts": {
      "permissionName": "Reactivar Cuentas de Usuario",
      "groupName": "Gestion de usuarios"
    },
    "deletePlatformUsers": {
      "permissionName": "Borrar Usuarios de la Plataforma",
      "groupName": "Gestion de usuarios"
    },
    "registerPlatformUser":{
      "permissionName": "Alta de Usuarios",
      "groupName": "Gestion de usuarios"
    },
    "externalUsersManager":{
      "permissionName": "Mantenimiento de usuarios Externos",
      "groupName": "Gestion de usuarios"
    },
  },
  "roleManagement": {
    "AssignModifyRolesOfAllUsers": {
      "permissionName": "Asignar/Modificar Roles de todos los Usuarios",
      "groupName": "Gestion de roles"
    },
    "AssignUserAsBusinessAdmin": {
      "permissionName": "Asignar un usuario como Admin de Bóveda",
      "groupName": "Gestion de roles"
    },
    "DesignateAssistantAdmin": {
      "permissionName": "Designar Asistente Admin",
      "groupName": "Gestion de roles"
    }
  },
  "businessManagement": {
    "createCompany": {
      "permissionName": "Crear una Bóveda",
      "groupName": "Gestión de Bóvedas"
    },
    "editCompany": {
      "permissionName": "Editar una Bóveda",
      "groupName": "Gestión de Bóvedas"
    },
    "blockCompany": {
      "permissionName": "Bloquear/Reactivar Bóveda",
      "groupName": "Gestión de Bóvedas"
    },
    "reactivateCompany": {
      "permissionName": "Reactivar Bóveda",
      "groupName": "Gestión de Bóvedas"
    },
    "enrollUsersInCompany": {
      "permissionName": "Inscribir usuarios dentro de una bóveda",
      "groupName": "Gestión de Bóvedas"
    },
    "removeUserfromCompany": {
      "permissionName": "Retirar Usuario de una Bóveda",
      "groupName": "Gestión de Bóvedas"
    }
  },
  "documentManagement": {
    "createFolder": {
      "permissionName": "Crear Folder",
      "groupName": "Gestion de Documentos"
    },
    "uploadDocument": {
      "permissionName": "Subir Documento",
      "groupName": "Gestion de Documentos"
    },
    "accessDocuments": {
      "permissionName": "Acceder a los Documentos",
      "groupName": "Gestion de Documentos"
    },
    "reviewDocument": {
      "permissionName": "Revisar Documento",
      "groupName": "Gestion de Documentos"
    },
    "restoreDocument": {
      "permissionName": "Restaurar Documentos",
      "groupName": "Gestion de Documentos"
    },
    "addCollaboratorsToDocument": {
      "permissionName": "Agregar Colaboradores a un Documento",
      "groupName": "Gestion de Documentos"
    },
    "modifyDocument": {
      "permissionName": "Modificar Documento",
      "groupName": "Gestion de Documentos"
    },
    "deleteDocument": {
      "permissionName": "Eliminar Documento",
      "groupName": "Gestion de Documentos"
    },
    "createReviewActivity":{
      "permissionName" : "Crear Actividad de Revision",
      "groupName" : "Gestion de Documentos"
    },
    "createSignatureActivity":{
      "permissionName": "Crear Actividad de Firma",
      "groupName": "Gestion de Documentos"
    },
    "inviteToSign": {
      "permissionName": "Invitar a Firmar",
      "groupName": "Gestion de Documentos"
    },
    "prepareSignatures": {
      "permissionName": "Preparar Firmas",
      "groupName": "Gestion de Documentos"
    },
    "signDocuments": {
      "permissionName": "Firmar documentos",
      "groupName": "Gestion de Documentos"
    },
    "signBlock": {
      "permissionName": "Firmar en Bloque",
      "groupName": "Gestion de Documentos"
    },
    "deleteActivity": {
      "permissionName": "Eliminar Actividad",
      "groupName": "Gestion de Documentos"
    },
    "moveDocumentsAndFolders": {
      "permissionName": "Mover documentos o folders",
      "groupName": "Gestion de Documentos"
    },
    "moveDocumentsAndFoldersFromCompany": {
      "permissionName": "Mover documentos o folders a otra bóveda",
      "groupName": "Gestion de Documentos"
    }
  },
  "templateManagement": {
    "useTemplate":{
      "permissionName": "Utilizar Plantilla",
      "groupName": "Gestión de Plantillas"
    },
    "addTemplate": {
      "permissionName": "Agregar Plantilla",
      "groupName": "Gestión de Plantillas"
    },
    "editTemplate": {
      "permissionName": "Editar Plantilla",
      "groupName": "Gestión de Plantillas"
    },
    "deleteTemplate": {
      "permissionName": "Eliminar Plantilla",
      "groupName": "Gestión de Plantillas"
    }
  },
  "systemAdministration": {
    "all": {
      "permissionName": "Todos",
      "groupName": "Administración del Sistema"
    }
  }
}