import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import { CheckboxField } from './checkboxField';
import { MenuItem } from '@material-ui/core';
import uid from 'uid';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';

export class UserMenu extends Component {

  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null
    }
  }

  onSubmit(evt) {
    evt.preventDefault();
    this.setState({ anchorEl: null });
    const { onSubmit } = this.props;
    const elements = evt.target.elements;
    const out = []; 

    for(let i = 0; i < elements.length; i++) {
      const element = elements[i];

      if (element.type === 'checkbox') {
        if (element.checked) {
          out.push(element.name);
        }
      }
    }

    onSubmit(out);
  }

  render() {
    const { anchorEl } = this.state;
    const { users, businessUsers, disabled } = this.props;

    return (
      <div>
        <Button
          className='card-business-user-add'
          variant='contained'
          color='primary'
          onClick={ (evt) => this.setState({ anchorEl: evt.currentTarget }) }
          disabled={ disabled }
        >
          <AddIcon />
        </Button>

        <Menu
          id='simple-menu'
          anchorEl={ anchorEl }
          keepMounted 
          open={ Boolean(anchorEl) }
          onClose={ () => this.setState({ anchorEl: null }) }
        >
          <form className='p-3' onSubmit={ (evt) => this.onSubmit(evt) }>
            {
              users && users.map(user =>
                <MenuItem key={ uid() }>
                  <CheckboxField 
                    label={ user.label } 
                    checked={ businessUsers?.find(busUser => busUser.value === user.value) ? true : false }
                    name={ user.value }
                  />
                </MenuItem>
              )
            }
            
            <div className='text-center mt-3'>
              <Button 
                type='button'
                variant='outlined'
                color='primary'
                onClick={ () => this.setState({ anchorEl: null }) }
              >
                Cancelar
              </Button>

              <Button
                className='ml-2'
                type='submit'
                variant='contained'
                color='primary'
              >
                Aceptar
              </Button>
            </div>
          </form>
        </Menu>
      </div>
    );
  }

}

UserMenu.propTypes = {
  usersList: PropTypes.any,
  onSubmit: PropTypes.func,
  users: PropTypes.any,
  businessUsers: PropTypes.any,
  disabled: PropTypes.bool
}