import { activityConstants } from "../constants";
import { endorseConstants } from "../constants";
import { decodeToken } from "../utils/token";

const initialState = {
  usersToSign: [],
  temporalUsersToSign: [],
  usersNotToSign: [],
  temporalUsersNotToSign: [],
  signers: [],
  noSigners: [],
  temporalSigners: [],
  temporalNoSigners: [],
  allSigners: [],
  allNoSigners: [],
  userExistError: null,
  userNameError: null,
  userRFCError: null,
  endorser: [],
  endorsee: [],
  temporalEndorser: [],
  temporalEndorsee: [],
}

export const activityReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === activityConstants.SAVE_INTERAL_USERS_TO_SIGN) {

    const availableUsers = payload.filter(el => !state.noSigners.find(noS => noS.email === el.email));
    return {
      ...state,
      usersToSign: availableUsers
    };

  } else if (type === activityConstants.SAVE_INTERAL_USERS_NOT_SIGN) {
    const availableUsersToNotSign = payload.filter(el => !state.signers.find(noS => noS.email === el.email));
    const { email } = decodeToken(sessionStorage.getItem('access_token'));
    return {
      ...state,
      usersNotToSign: availableUsersToNotSign.filter(el => el.email !== email)
    };
  } else if (type === activityConstants.SAVE_SIGNERS) {
    return {
      ...state,
      signers: payload,
      allSigners: [...payload, ...state.temporalSigners]
    }

  } else if (type === activityConstants.SAVE_NO_SIGNERS) {
    return {
      ...state,
      noSigners: payload,
      allNoSigners: [...payload, ...state.temporalNoSigners]
    }

  } else if (type === activityConstants.SAVE_TEMPORAL_USERS_TO_SIGN) {
    return {
      ...state,
      temporalUsersToSign: payload
    }

  } else if (type === activityConstants.SAVE_TEMPORAL_SIGNERS) {
    return {
      ...state,
      temporalSigners: payload,
      allSigners: [...state.signers, ...payload]
    }

  } else if (type === activityConstants.SAVE_TEMPORAL_NOT_TO_SIGN) {
    return {
      ...state,
      temporalUsersNotToSign: payload
    }

  } else if (type === activityConstants.SAVE_TEMPORAL_NO_SIGNERS) {
    return {
      ...state,
      temporalNoSigners: payload,
      allNoSigners: [...state.noSigners, ...payload]
    }

  } else if (type === activityConstants.SAVE_ENDORSER) {
    const endorser = state.signers.find(el => el.endorser)
    let processSigners = []
    if (!endorser) {
      processSigners = [...state.signers, ...payload]
    } else {
      processSigners = state.signers.map(el => el.endorser ? payload[0] : el)
    }

    return {
      ...state,
      endorser: payload,
      signers: processSigners
    }
  } else if (type === activityConstants.SAVE_ENDORSEE) {
    const endorsee = state.signers.find(el => !el.endorser)
    let processSigners = []
    if (!endorsee) {
      processSigners = [...state.signers, ...payload]
    } else {
      processSigners = state.signers.map(el => !el.endorser ? payload[0] : el)
    }

    return {
      ...state,
      endorsee: payload,
      signers: processSigners
    }
  } else if (type === endorseConstants.SET_ENDORSE_TYPE) {

    const newArray = item => {
      if (item.userId === payload.userId) {
        item.type = payload.type;
        item.stroke = payload.stroke;
      }
      return item;
    }

    return {
      ...state,
      endorser: state.endorser.map(newArray),
      temporalEndorser: state.temporalEndorser.map(newArray),
      endorsee: state.endorsee.map(newArray),
      temporalEndorsee: state.temporalEndorsee.map(newArray),
    }
  } else if (type === activityConstants.SAVE_TEMPORAL_ENDORSER) {
    return {
      ...state,
      temporalEndorser: [payload]
    }
  } else if (type === activityConstants.SAVE_TEMPORAL_ENDORSEE) {
    return {
      ...state,
      temporalEndorsee: [payload]
    }
  } else if (type === activityConstants.MARK_ENDORSER) {
    return {
      ...state,
      markEndorser: payload
    }
  } else if (type === activityConstants.MARK_ENDORSEE) {
    return {
      ...state,
      markEndorsee: payload
    }
  } else if (type === activityConstants.VALIDATE_TEMPORAL_USER) {
    const query = el => el.email.toLowerCase() === payload.toLowerCase();
    const newState = {
      ...state,
      userExistError: null
    }

    const emailReg = new RegExp(/\S+@\S+\.\S+/);
    const existsInUsers = state.usersToSign.find(query);
    const existsInUsersNotToSign = state.usersNotToSign.find(query);
    const existInSigners = state.signers.find(query);
    const existInNoSigners = state.noSigners.find(query);
    const existInTemportalUsersToSign = state.temporalUsersToSign.find(query);
    const existInTemportalUsersNotToSign = state.temporalUsersNotToSign.find(query);
    const existInTemporalSigners = state.temporalSigners.find(query);
    const existInTemporalNoSigners = state.temporalNoSigners.find(query);

    if (!payload) {
      newState.userExistError = ""
    } else if (!emailReg.test(payload)) {
      newState.userExistError = "Email inválido"
    } else if (!!existInSigners) {
      newState.userExistError = "Este correo ya está registrado en un usuario catalogado en la plataforma y participa como firmante."
    } else if (!!existInNoSigners) {
      newState.userExistError = "Este correo ya está registrado en un usuario catalogado en la plataforma y participa como no firmante."
    } else if (!!existInTemporalSigners) {
      newState.userExistError = "Este correo ya está registrado en un usuario como firmante."
    } else if (!!existInTemporalNoSigners) {
      newState.userExistError = "Este correo ya está registrado en un usuario como no firmante."
    } else if (!!existInTemportalUsersNotToSign) {
      newState.userExistError = "Este correo ya está registrado en un usuario como no firmante."
    } else if (!!existInTemportalUsersToSign) {
      newState.userExistError = "Este correo ya está registrado en un usuario como firmante."
    } else if (!!existsInUsers) {
      newState.userExistError = "Este correo ya está registrado en un usuario catalogado en la plataforma."
    } else if (!!existsInUsersNotToSign) {
      newState.userExistError = "Este correo ya está registrado en un usuario catalogado en la plataforma."
    } else {
      newState.userExistError = ""
    }

    return newState;
  } else if (type === activityConstants.VALIDATE_TEMPORAL_USER_NAME) {

    const query = el => el.name.trim().toLowerCase() === payload.trim().toLowerCase();
    const newState = {
      ...state,
      userNameError: null
    }

    const existsInUsers = state.usersToSign.find(query);
    const existsInUsersNotToSign = state.usersNotToSign.find(query);
    const existInSigners = state.signers.find(query);
    const existInNoSigners = state.noSigners.find(query);
    const existInTemportalUsersToSign = state.temporalUsersToSign.find(query);
    const existInTemportalUsersNotToSign = state.temporalUsersNotToSign.find(query);
    const existInTemporalSigners = state.temporalSigners.find(query);
    const existInTemporalNoSigners = state.temporalNoSigners.find(query);

    if (!payload) {
      newState.userNameError = "El nombre del usuario es requerido"
    } else if (!!existInSigners) {
      newState.userNameError = "Este nombre ya está registrado en un usuario catalogado en la plataforma y participa como firmante."
    } else if (!!existInNoSigners) {
      newState.userNameError = "Este nombre ya está registrado en un usuario catalogado en la plataforma y participa como no firmante."
    } else if (!!existInTemporalSigners) {
      newState.userNameError = "Este nombre ya está registrado en un usuario como firmante."
    } else if (!!existInTemporalNoSigners) {
      newState.userNameError = "Este nombre ya está registrado en un usuario como no firmante."
    } else if (!!existInTemportalUsersNotToSign) {
      newState.userNameError = "Este nombre ya está registrado en un usuario como no firmante."
    } else if (!!existInTemportalUsersToSign) {
      newState.userNameError = "Este nombre ya está registrado en un usuario como firmante."
    } else if (!!existsInUsers) {
      newState.userNameError = "Este nombre ya está registrado en un usuario catalogado en la plataforma."
    } else if (!!existsInUsersNotToSign) {
      newState.userNameError = "Este nombre ya está registrado en un usuario catalogado en la plataforma."
    } else {
      newState.userNameError = ""
    }

    return newState;
  } else if (type === activityConstants.VALIDATE_TEMPORAL_USER_RFC) {

    const query = el => el.rfc === payload;
    const newState = {
      ...state,
      userRFCError: null
    }
    const rfcReg = new RegExp(/^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{0,3})$/i);

    const existsInUsers = state.usersToSign.find(query);
    const existsInUsersNotToSign = state.usersNotToSign.find(query);
    const existInSigners = state.signers.find(query);
    const existInNoSigners = state.noSigners.find(query);
    const existInTemportalUsersToSign = state.temporalUsersToSign.find(query);
    const existInTemportalUsersNotToSign = state.temporalUsersNotToSign.find(query);
    const existInTemporalSigners = state.temporalSigners.find(query);
    const existInTemporalNoSigners = state.temporalNoSigners.find(query);

    if (!payload) {
      newState.userRFCError = ""
    } else if (!rfcReg.test(payload)) {
      newState.userRFCError = "RFC invalido"
    } else if (!!existInSigners) {
      newState.userRFCError = "Este RFC ya está registrado en un usuario catalogado en la plataforma y participa como firmante."
    } else if (!!existInNoSigners) {
      newState.userRFCError = "Este RFC ya está registrado en un usuario catalogado en la plataforma y participa como no firmante."
    } else if (!!existInTemporalSigners) {
      newState.userRFCError = "Este RFC ya está registrado en un usuario como firmante."
    } else if (!!existInTemporalNoSigners) {
      newState.userRFCError = "Este RFC ya está registrado en un usuario como no firmante."
    } else if (!!existInTemportalUsersNotToSign) {
      newState.userRFCError = "Este RFC ya está registrado en un usuario como no firmante."
    } else if (!!existInTemportalUsersToSign) {
      newState.userRFCError = "Este RFC ya está registrado en un usuario como firmante."
    } else if (!!existsInUsers) {
      newState.userRFCError = "Este RFC ya está registrado en un usuario catalogado en la plataforma."
    } else if (!!existsInUsersNotToSign) {
      newState.userRFCError = "Este RFC ya está registrado en un usuario catalogado en la plataforma."
    } else {
      newState.userRFCError = ""
    }

    return newState;

  } else if (type === activityConstants.SET_SIGNATURE_TYPE) {

    const newArray = item => {
      if (item.userId === payload.userId) {
        item.type = payload.type;
        item.stroke = payload.stroke;
      }
      return item;
    }
    return {
      ...state,
      signers: state.signers.map(newArray),
      temporalSigners: state.temporalSigners.map(newArray),
      usersToSign: state.usersToSign.map(newArray),
      temporalUsersToSign: state.temporalUsersToSign.map(newArray),
      allSigners: state.allSigners.map(newArray),
    }
  } else if (type === activityConstants.SET_ALL_SIGNERS) {
    return {
      ...state,
      allSigners: payload
    }
  } else if (type === activityConstants.DELETE_TEMPORAL_SIGNER) {
    const deleteQuery = el => el.userId !== payload;
    return {
      ...state,
      temporalUsersToSign: state.temporalUsersToSign.filter(deleteQuery),
      temporalSigners: state.temporalSigners.filter(deleteQuery),
      allSigners: state.allSigners.filter(deleteQuery),
    }
  } else if (type === activityConstants.DELETE_TEMPORAL_NO_SIGNER) {
    const deleteNoSigner = el => el.userId !== payload;
    return {
      ...state,
      temporalUsersNotToSign: state.temporalUsersNotToSign.filter(deleteNoSigner),
      temporalNoSigners: state.temporalNoSigners.filter(deleteNoSigner),
      allNoSigners: state.allNoSigners.filter(deleteNoSigner),
    }
  } else if (type === activityConstants.RESTART) {
    return {
      ...state,
      usersToSign: [],
      temporalUsersToSign: [],
      usersNotToSign: [],
      temporalUsersNotToSign: [],
      signers: [],
      noSigners: [],
      temporalSigners: [],
      temporalNoSigners: [],
      allSigners: [],
      allNoSigners: [],
      endorser: [],
      endorsee: [],
      temporalEndorser: [],
      temporalEndorsee: [],
    }
  } else if (type === activityConstants.DELETE_ERROR) {
    return {
      ...state,
      userExistError: null,
      userNameError: null,
      userRFCError: null,
    }


  } else {
    return state;
  }

}