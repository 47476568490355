import { Dialog, Button } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import PropTypes from "prop-types";

export const AlertComponent = ({ open, title, onConfirm, type }) => {
	return (
		<Dialog open={open}>
			<div className="p-4 text-center">
				<div>
					{type === "success" && (
						<CheckCircleIcon className="text-success dialog-icon" />
					)}

					{type === "error" && <ErrorIcon className="text-error dialog-icon" />}
				</div>

				<h4>{title}</h4>

				<div>
					<Button
						color="primary"
						variant="contained"
						onClick={() => onConfirm && onConfirm()}>
						Aceptar
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

AlertComponent.propTypes = {
	open: PropTypes.bool,
	title: PropTypes.string,
	onConfirm: PropTypes.func,
	type: PropTypes.string,
};
