

export const handleErrorAxios = (error) => {
  if (error.response) {
    return (error.response.data);
    // console.log(error.response.status);
    // console.log(error.response.headers);
  } else if (error.request) {
    return (error.request);
  } else if (error.message) {
    return (error.message);
  } else {
    return error
  }
  // console.log(error.config);
  // console.log(Object.keys(error));
}