import React, { Component } from "react";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import { FoldersHttp } from "../../../core/http/folders.http";
import { Typography, Box, ThemeProvider } from '@material-ui/core';
import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { tokenExpired } from "../../../core/utils";
import { authActions } from "../../../core/actions";
import { connect } from "react-redux";

let theme = createTheme();
theme = responsiveFontSizes(theme);

theme.typography.h6 = {
  [theme.breakpoints.up("sm")]: {
    fontSize: "0.8rem",
  },
};

export class FileTree extends Component {
  tree = [];
  foldersHttp = new FoldersHttp();

  constructor(props) {
    super(props);

    this.state = {
      loadRoot: false,
      data: [],
    };
  }

  componentDidMount() {
    this.getRoot();
  }

  authHeader() {
    // return authorization header with jwt token
    let token = sessionStorage.access_token;

    if (token) {
      return { Authorization: "Bearer " + token };
    } else {
      return {};
    }
  }

  getRoot() {
    const {isLoggedIn, userLoggedOut} = this.props;  

    if(isLoggedIn && tokenExpired()){
         userLoggedOut()
         return
       }  
    this.setState({ loadRoot: true });
    
    this.foldersHttp.getFoldersDetails(
      (data) => {
        const root = [];
        data.forEach((item) => {
          root.push({
            name: item.name,
            companyId: item.companyId,
            load: false,
          });
        });

        this.setState({ data: root, loadRoot: false });
        this.tree = root;
      },
      (error) => {
        this.setState({ loadRoot: false });
      }
    );
  }

  addChild(root) {
    root.load = true;
    root.click = true;
    this.setState({ data: this.tree });

    if (root.folderId) {
      const {isLoggedIn, userLoggedOut} = this.props;  

      if(isLoggedIn && tokenExpired()){
           userLoggedOut()
           return
         }  
      this.foldersHttp.getFolderInfo(
        root.folderId,
        root.companyId,
        (data) => {
          const folders = [];

          data.subfolders.forEach((item) => {
            folders.push({
              name: item.name,
              companyId: item.companyId,
              folderId: item._id,
              click: false,
              documents: data.documents,
            });
          });

          //Aqui se añade el nuevo nivel
          root.child = folders.length > 0 ? folders : null;
          root.load = false;
          root.documents = data.documents;

          this.setState({ data: this.tree });
          this.props.onSelect(data.documents);
        },
        () => {
          root.load = false;
          this.setState({ data: this.tree });
        }
      );
    } else {
      const {isLoggedIn, userLoggedOut} = this.props;  

 if(isLoggedIn && tokenExpired()){
      userLoggedOut()
      return
    }  
      this.foldersHttp.foldersCompanies(
        root.companyId,
        (data) => {
          const folders = [];

          data.subfolders.filter(el => el.name !== "Trash").forEach((item) => {
            folders.push({
              name: item.name,
              companyId: item.companyId,
              folderId: item._id,
              click: false,
              documents: data.documents,
            });
          });

          //Aqui se añade el nuevo nivel
          root.child = folders.length > 0 ? folders : null;
          root.load = false;
          root.documents = data.documents;

          this.setState({ data: this.tree });
          this.props.onSelect(data.documents);
        },
        () => {
          root.load = false;
          this.setState({ data: this.tree });
        }
      );
    }
  }

  renderTreeFolders(data, level) {
    if (!data && level !== -1) {
      return undefined;
    }

    const { onSelect } = this.props;

    return (
      <ThemeProvider theme={theme}>
        {data &&
          data.map((item, index) => (
            <TreeItem
              style={{
                color: "#FAFAFA",
                pointerEvents: item.load ? "none" : "",
              }}
              key={`${item.companyId}-${index}`}
              nodeId={`${item.folderId}-${index}${level}`}
              label={
                <Box display={'flex'} alignItems={'center'} paddingY={0.2}>
                  {item.load ? (
                    <em className="fa fa-spinner fa-spin"></em>
                  ) : (
                    <>
                      {item.folderId ? (
                        <em className="fa fa-folder" />
                      ) : (
                        <em className="far fa-building"></em>
                      )}
                    </>
                  )}
                  <Typography
                    className="font-withe"
                    style={{ paddingLeft: "5px" }}
                    variant="h6"
                  >
                    {`${item.name}`}
                  </Typography>
                </Box>
              }
              onClick={() =>
                !item.click
                  ? this.addChild(item, index)
                  : onSelect(item.documents)
              }
            >
              {this.renderTreeFolders(item.child)}
            </TreeItem>
          ))}
      </ThemeProvider>
    );
  }

  render() {
    const { data, loadRoot } = this.state;

    return (
      <div>
        <div className="text-center" style={{ color: "#FAFAFA" }}>
          {loadRoot && <em className="fa fa-spinner fa-spin"></em>}
        </div>

        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {this.renderTreeFolders(data, -1)}
        </TreeView>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.authReducer.loggedIn,
  }
}

const mapDispatchToProps = {
  userLoggedOut: authActions.userLoggedOut,
}

export default connect(mapStateToProps, mapDispatchToProps)(FileTree);