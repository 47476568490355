import React from 'react'
import { Button, alpha, makeStyles } from '@material-ui/core';
import SignerCard from "./SignerCard";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '288px',
    height: "91vh",
    flexShrink: 0,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    overflowX: "hidden",
    overflowY: "auto",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  drawerContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));

const SideMenuSigners = (props) => {
  const {
    signers,
    handleGenerateTag,
    sendDocumentFirm,
    filterItem,
  } = props;
  const classes = useStyles();

  return (
    <div
      className={classes.drawer}
    >
      <div className={classes.drawerContainer}>
        {signers.length > 0 && signers.map((data, index) => (
          <SignerCard
            key={index}
            name={data.name}
            userId={data.userId}
            userCertificate={data.userCertificate}
            onGenerateTag={handleGenerateTag}
            check={data.check ?? false}
          />
        ))}
      </div>
      <div >
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={sendDocumentFirm}
          disabled={!signers.every(filterItem)}
        >
          Finalizar
        </Button>
      </div>
    </div>
  )
}

export default SideMenuSigners