export function sortData(data) {
  return data.sort((x,y) => {
    let a = x.name.toLowerCase(),
    b = y.name.toLowerCase();
    return a === b ? 0 : a > b ? 1 : -1;
  })
}

export function sortObjectsByValue(data, value) {
  return data.sort((x,y) => {
    let a = x[value],
    b = y[value];
    return a === b ? 0 : a > b ? 1 : -1;
  })
}

export function sortDescObjectsByValue(data, value) {
  return data.sort((x,y) => {
    let a = x[value],
    b = y[value];
    return a === b ? 0 : a < b ? 1 : -1;
  })
}
