import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, Tabs, Tab } from '@material-ui/core';
import { BlockChainConfirmIcon } from 'axeleratum-sgc-frontend-library/components/icons/blockchain-confirm-icon';
import FolderIcon from '@material-ui/icons/Folder';
import { TabPanel } from 'axeleratum-sgc-frontend-library/components/tab-panel/tab-panel';
import { DocumentsHttp } from '../../core/http/documents.http';
import { DocFileSelected } from 'axeleratum-sgc-frontend-library/components/icons/doc-file-selected';
import { FoldersHttp } from '../../core/http/folders.http';
import DetailsContent from './details/details-content';
import DetailsActivity from './details/details-activity';
import { CollaboratorsHttp } from '../../core/http/collaborators.http';
import { sortDescObjectsByValue, tokenExpired } from '../../core/utils';
import { DocumentUndefined, ExcelIcon, PdfIcon, WordIcon } from '../../icons';
import { FolderFileSystemIcon } from '../../icons/FolderFileSystemIcon';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../core/actions';

export const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '180px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '250px',
    },
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '8px',
    height: '500px',
    maxHeight: '500px',
    marginTop: '20px'
  },
  iconFolder: {
    fontSize: '2.5rem'
  },
  blockchainContent: {
    backgroundColor: 'rgba(33, 70, 183, 0.05)',
    borderRadius: '8px',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textColorDisabled: {
    color: 'rgba(92, 123, 240, 0.6)'
  },
  textDescription: {
    overflowWrap: 'anywhere'
  },
  list: {
    paddingTop: '0px'
  },
  listItem: {
    paddingLeft: '0px',
    paddingRight: '0px',
    '& h6': {
      fontSize: '1.1rem',
      marginBottom: '8px'
    }
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  divider: {
    marginTop: '10px',
    marginBottom: '10px'
  },
  tabPanel: {
    height: '380px',
    overflowY: 'auto'
  },
  detailsContainer: {
  },
  detailsHidden: {
    display: 'none'
  },
  header: {
    marginLeft: '15px',
    marginRight: '15px',
    width: '100%'
  },
  closeDetailButton: {
    position: 'relative',
    float: 'right',
    top: '2px',
    height: '20px',
    width: '20px',
    right: '-93%',
  }
}));

function Details(props) {
  const classes = useStyles()
  const { itemSelected, company } = props
  const [tabSelected, setTab] = useState(0)
  const documentsHttp = new DocumentsHttp()
  const foldersHttp = new FoldersHttp()
  const collaboratorHttp = new CollaboratorsHttp()
  const [itemInfo, setItemInfo] = useState(null)
  const [historyInfo, setHistoryInfo] = useState(null)
  const [collaborators, setCollaborators] = useState([])
  const valid_result = itemSelected !== null ? (!itemSelected.onlyExistInMongo && !itemSelected.onlyExistInKaleido) : false

  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    if (itemSelected !== null && itemSelected.isFolder && !itemSelected.isRoot && !itemSelected.onlyExistInKaleido) {
      getFolderData()
    } else if (itemSelected !== null && !itemSelected.isFolder && !itemSelected.onlyExistInKaleido) {

      getDocumentData()
    } else {
      setItemInfo(null)
      setHistoryInfo(null)
      setCollaborators([])
    }
  }, [itemSelected])

  function getFolderData() {


if(isLoggedIn && tokenExpired()){
      dispatch(authActions.userLoggedOut())
      return
    }
    
    foldersHttp.getFolderInfo(itemSelected.id, company.id, data => {
      const itemInfo = {
        author: data.createdBy.completeName,
        path: data.path,
        isFolder: true
      }
      setItemInfo(itemInfo);   
  

      const formatInfo = data.folderHistories.map(history => ({
        user: history.user.completeName,
        date: history.actionDate,
        action: history.action,
        isFolder: true
      }));

      setHistoryInfo(sortDescObjectsByValue(formatInfo, 'date'));
    }, errorResponse => { console.error(errorResponse) })
  }

  function getDocumentData() {
    if(isLoggedIn && tokenExpired()){
      dispatch(authActions.userLoggedOut())
      return
    }
    documentsHttp.getDocumentInfo(itemSelected.id, data => {
      const itemInfo = {
        author: data.UserBy ? data.UserBy.completeName : '',
        hash: data.Hash,
        description: data.Description,
        extension: data.Extension,
        isFolder: false,
        statusBpm: data.StatusBpm,
        size: data.Size,    
        hasNom151: data.hasNom151  
      }
      setItemInfo(itemInfo)

         const history = data.DocumentHistory.map(history => ({

        user: history.UserBy ? history.UserBy : '',
        date: history.UpdateAt,
        action: history.Action,
        StatusBpm: history.StatusBpm,
        isFolder: false,
      }));

      let uniqueAction = history.filter((elem, index) =>
        history.findIndex(obj => obj.user === elem.user && obj.action === "firmó el " && obj.StatusBpm !== "Aprobado Con Firma") === index);

      let info = history.filter(item => item.action !== "firmó el ");

      let formatInfo = info.concat(uniqueAction);

      setHistoryInfo(
        sortDescObjectsByValue(
          formatInfo,
          'date'
        )
      )
    }, errorResponse => { console.error(errorResponse) })
  }
  
  const iconDocument = (extension, props) => {
    if (extension === '.pdf')
      return <PdfIcon  {...props} />
    if (extension === '.docx')
      return <WordIcon  {...props} />
    if (extension === '.xlsx')
      return <ExcelIcon  {...props} fill={"#fff"} />

    return <DocumentUndefined {...props} />
  }
 
  return (
    
    <Grid container className={`${classes.drawerPaper} animate__animated animate__fadeIn`}>
      <Grid item md={12} className={classes.header}>
        <Grid container alignItems='center' >
          <Grid item xs={2}>
            {itemSelected && !itemSelected?.isFolder && iconDocument(itemSelected?.extension, { className: classes.iconFolder })}
            {itemSelected?.isFolder && (
              <FolderFileSystemIcon
                className={classes.iconFolder}
              />
            )}

          </Grid>

          <Grid item xs={8} style={{ display: 'flex', overflowY: 'auto', height: '62px', maxHeight: '62px' }}>
            {
              itemSelected &&
              <h3 style={{ wordBreak: 'break-all', margin: 'auto auto auto 0' }}>{itemSelected.name + (itemSelected.extension ?? '')}</h3>
            }
          </Grid>

          <Grid item xs={2}>
            {
              itemSelected &&
              <div className={classes.blockchainContent} style={{
                backgroundColor: !valid_result ? '#f44336' : ''
              }}>
                <BlockChainConfirmIcon color='primary' />
              </div>
            }
          </Grid>

        </Grid>

      </Grid>
      <Grid item md={12} style={{ width: '100%' }}>
        <Tabs
          value={tabSelected}
          indicatorColor="primary"
          textColor="primary"
          onChange={(event, newValue) => setTab(newValue)}
          aria-label="disabled tabs example"

        >
          <Tab label="Detalles" classes={{ textColorPrimary: classes.textColorDisabled }} />
          <Tab label="Actividad" classes={{ textColorPrimary: classes.textColorDisabled }} />
        </Tabs>
        <TabPanel value={tabSelected} index={0} className={classes.tabPanel}>
          <Grid container spacing={2} className={classes.detailsContainer}>

            {(itemSelected && itemInfo) && (
              <DetailsContent
                classes={classes}
                dataElement={itemInfo}
                accessElements={collaborators}
              />
            )}

            {!itemSelected && (
              <Grid item xs={12}>
                No hay elemento seleccionado.
              </Grid>
            )}

            {(itemSelected && !itemInfo) && (
              <Grid item xs={12}>
                No hay información.
              </Grid>
            )}

          </Grid>
        </TabPanel>

        <TabPanel value={tabSelected} index={1} className={classes.tabPanel}>
          <Grid container spacing={2} className={classes.detailsContainer}>

            {(itemSelected && historyInfo) && (
              <DetailsActivity
                dataElement={historyInfo}
                classes={classes}
              />
            )}

            {!itemSelected && (
              <Grid item xs={12} >
                No hay elemento seleccionado.
              </Grid>
            )}

            {(itemSelected && !historyInfo) && (
              <Grid item xs={12}>
                No hay información.
              </Grid>
            )}
          </Grid>
        </TabPanel>
      </Grid>
    </Grid>
  )
}

export default Details