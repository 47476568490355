import {
  Box,
  Button,
  DialogContent,
  Grid,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { createTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { uuid } from "axeleratum-sgc-frontend-library";
import React, { useState } from "react";
import { FileTree } from "./file-tree";
import "./view-file.scss";

// const theme = createTheme();

// theme.typography.h3 = {
//   padding: "10px",
//   [theme.breakpoints.down("sm")]: {
//     fontSize: "1rem",
//   },
// };

const ViewFile = ({ open, onClose, onSelectFile, type }) => {
  const [documents, setDocuments] = useState([]);
  const [selectedItem, setSelectedItem] = useState(-1);
  const [selectedItemData, setSelectedItemData] = useState(null);

  const filteredDocuments = documents.filter(
    (documet) =>
      (documet.StatusBpm === "Aprobado Sin Firma" ||
        documet.StatusBpm === "Aprobado") && documet.Extension === ".pdf"
  );

  const filteredDocumentReview = documents.filter(
    (documet) => documet.StatusBpm === "Preparar Revisión" && documet.Extension === ".pdf"
  );

  return (
    // <ThemeProvider theme={theme}>
    <Dialog fullWidth maxWidth="sm" aria-labelledby="vault-dialog" open={open}>
      <DialogContent
        style={{ height: "600px", padding: 0, overflow: "hidden" }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          style={{ height: "100%" }}
        >
          <Grid item xs={5} sm={4} style={{ backgroundColor: "#1E88E5", display: "flex", height: "100%", flexDirection: 'column' }}>
            <h3 style={{ paddingLeft: "15px" }} className="font-withe">
              BÓVEDA
            </h3>
            <Box style={{ overflow: "auto", height: '100%' }}>
              <div className="tree-container">
                <FileTree
                  onSelect={(documents) => {
                    setDocuments(documents);
                  }}
                />
              </div>
            </Box>
          </Grid>
          <Grid item xs={7} sm={8} style={{ display: "flex", height: "100%", flexDirection: 'column' }}>
            <Box style={{ overflow: "auto", height: "100%" }}>
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <b>Nombre</b>
                      </TableCell>

                      <TableCell align="left">
                        <b>Tipo</b>
                      </TableCell>

                      <TableCell align="left">
                        <b>Fecha de modificación</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {type ? (
                    <TableBody>
                      {filteredDocuments.map((element, index) =>
                        type === element.Extension.split('.')[1] ? (
                          <TableRow
                            className="table-item"
                            key={uuid()}
                            selected={index === selectedItem}
                            onClick={() => {
                              setSelectedItem(index);
                              setSelectedItemData(element);
                            }}
                          >
                            {
                              <React.Fragment>
                                <TableCell align="left">
                                  <strong className="fas fa-file font-color"></strong>
                                  &nbsp;
                                  {element.Name}
                                </TableCell>
                                <TableCell align="left">
                                  {element.Extension}
                                </TableCell>

                                <TableCell align="left">
                                  {element.updatedAt &&
                                    element.updatedAt.split("T")[0]}
                                </TableCell>
                              </React.Fragment>
                            }
                          </TableRow>
                        ) : undefined
                      )}
                    </TableBody>
                  ) : (
                    <TableBody>
                      {filteredDocumentReview.map((element, index) => (
                        <TableRow
                          className="table-item"
                          key={uuid()}
                          selected={index === selectedItem}
                          onClick={() => {
                            setSelectedItem(index);
                            setSelectedItemData(element);
                          }}
                        >
                          {
                            <React.Fragment>
                              <TableCell align="left">
                                <strong className="fas fa-file font-color"></strong>
                                &nbsp;
                                {element.Name}
                              </TableCell>

                              <TableCell align="left">
                                {element.Extension}
                              </TableCell>

                              <TableCell align="left">
                                {element.updatedAt &&
                                  element.updatedAt.split("T")[0]}
                              </TableCell>
                            </React.Fragment>
                          }
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>

              {filteredDocumentReview.length === 0 && (
                <div className="col-md-12 col-xs-12 text-center mt-3">
                  No hay elementos para mostrar
                </div>
              )}
            </Box>
            <Box textAlign={"right"} paddingY={1} marginRight={1}>
              <Button
                variant="outlined"
                className="font-color"
                onClick={() => {
                  setDocuments([]);
                  setSelectedItemData(null);
                  onClose();
                }}
              >
                Cancelar
              </Button>

              <Button
                style={{ marginLeft: "5px" }}
                variant="contained"
                color="primary"
                onClick={() => {
                  onSelectFile && onSelectFile(selectedItemData, selectedItem);
                  setDocuments([]);
                  setSelectedItemData(null);
                  onClose();
                }}
                disabled={!selectedItemData}
              >
                Abrir
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ViewFile;
