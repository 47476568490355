import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

const RenderCheckbox = (props) => {
  const {
    disabled,
    input,
    label,
    checked,
    onChange,
  } = props;

  

  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={disabled}
          { ...input }
          onChange={ (evt) => {
            input.onChange(evt.target.checked); 
            onChange && onChange(evt.target.checked);
          } }
          checked={ checked ||  input.value }
        />
      }
      label={ label }
    />
  );
}


export default RenderCheckbox;
