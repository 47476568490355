import { activityConstants } from "../constants"



const saveInternalUsersToSign = (users) => ({
  type: activityConstants.SAVE_INTERAL_USERS_TO_SIGN,
  payload: users
});

const saveInternalUsersNotToSign = (users) => ({
  type: activityConstants.SAVE_INTERAL_USERS_NOT_SIGN,
  payload: users
});

const saveSigners = (signers) => ({
  type: activityConstants.SAVE_SIGNERS,
  payload: signers
});

const saveTemporalSigners = (temporalSigner) => ({
  type: activityConstants.SAVE_TEMPORAL_SIGNERS,
  payload: temporalSigner
});

const saveNoSigners = (noSigners) => ({
  type: activityConstants.SAVE_NO_SIGNERS,
  payload: noSigners
});

const saveTemporalNoSigners = (temporalNoSigner) => ({
  type: activityConstants.SAVE_TEMPORAL_NO_SIGNERS,
  payload: temporalNoSigner
});

const validateTemporalUser = (temporalUser) => ({
  type: activityConstants.VALIDATE_TEMPORAL_USER,
  payload: temporalUser
});
const validateTemporalUserName = (temporalUser) => ({
  type: activityConstants.VALIDATE_TEMPORAL_USER_NAME,
  payload: temporalUser
});
const validateTemporalUserRFC = (temporalUser) => ({
  type: activityConstants.VALIDATE_TEMPORAL_USER_RFC,
  payload: temporalUser
});

const restartProcess = () => ({
  type: activityConstants.RESTART
});

const setSignatureType = (user) => ({
  type: activityConstants.SET_SIGNATURE_TYPE,
  payload: user
});

const setAllSigners = (signers) => ({
  type: activityConstants.SET_ALL_SIGNERS,
  payload: signers
});

const saveTemporalUserToSign = (temporal) => ({
  type: activityConstants.SAVE_TEMPORAL_USERS_TO_SIGN,
  payload: temporal
});

const saveTemporalNotToSign = (temporal) => ({
  type: activityConstants.SAVE_TEMPORAL_NOT_TO_SIGN,
  payload: temporal
})

const deleteTemporalSigner = (temporalId) => ({
  type: activityConstants.DELETE_TEMPORAL_SIGNER,
  payload: temporalId
});

const deleteError = () => ({
  type: activityConstants.DELETE_ERROR
});

const deleteTemporalNoSigner = (temporalId) => ({
  type: activityConstants.DELETE_TEMPORAL_NO_SIGNER,
  payload: temporalId
})


//! ------------- endosos -----------------------------------

const saveEndorser = (endorser) => ({
  type: activityConstants.SAVE_ENDORSER,
  payload: endorser,
})

const saveEndorsee = (endorsee) => ({
  type: activityConstants.SAVE_ENDORSEE,
  payload: endorsee,
})

const markEndorser = () => ({
  type: activityConstants.MARK_ENDORSER,
  payload: 'Endosante',
})
const markEndorsee = () => ({
  type: activityConstants.MARK_ENDORSEE,
  payload: 'Endosatario',
})

const saveTemporalEndorser = (temporalEndoser) => ({
  type: activityConstants.SAVE_TEMPORAL_ENDORSER,
  payload: temporalEndoser,
})

const saveTemporalEndorsee = (temporalEndosee) => ({
  type: activityConstants.SAVE_TEMPORAL_ENDORSEE,
  payload: temporalEndosee,
})



export const activityActions = {
  saveInternalUsersToSign,
  saveInternalUsersNotToSign,
  saveSigners,
  saveTemporalSigners,
  saveNoSigners,
  saveTemporalNoSigners,
  validateTemporalUser,
  validateTemporalUserName,
  validateTemporalUserRFC,
  restartProcess,
  setSignatureType,
  setAllSigners,
  saveTemporalUserToSign,
  deleteTemporalSigner,
  deleteError,
  deleteTemporalNoSigner,
  saveTemporalNotToSign,
  saveEndorser,
  saveEndorsee,
  markEndorser,
  markEndorsee,
  saveTemporalEndorser,
  saveTemporalEndorsee,
}