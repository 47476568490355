import React, { Component } from 'react';
import { Dialog } from '@material-ui/core';

export class CalendarSync extends Component {
  
  render() {
    return (
      <Dialog 
        open={ true }
      >
        <div style={{ width: '400px', height:'150px' }}> 
          <div className="col-md-12 text-center">
            <h2>Sincronizando el calendario</h2>
            <em className="mt-2 fa fa-sync fa-spin fa-2x primary-text"></em>
          </div>
        </div>
      </Dialog>
    );
  }

}
