



export const calculateZoom = (zoom, type) => {

  if (zoom < 25) {
    return 25
  }
  if (zoom > 500) {
    return 500
  }

  if (zoom >= 25 && zoom < 33) {
    if (type === "in") return 33
    if (type === "out") return 25
  }
  if (zoom >= 33 && zoom < 50) {
    if (type === "in") return 50
    if (type === "out") {
      if (zoom == 33) {
        return 25
      } else {
        return 33
      }

    }
  }
  if (zoom >= 50 && zoom < 67) {
    if (type === "in") return 67
    if (type === "out") {
      if (zoom == 50) {
        return 33
      } else {
        return 50
      }

    }
  }
  if (zoom >= 67 && zoom < 75) {
    if (type === "in") return 75
    if (type === "out") {
      if (zoom == 67) {
        return 50
      } else {
        return 67
      }

    }
  }
  if (zoom >= 75 && zoom < 80) {
    if (type === "in") return 80
    if (type === "out") {
      if (zoom == 75) {
        return 67
      } else {
        return 75
      }

    }
  }
  if (zoom >= 80 && zoom < 90) {
    if (type === "in") return 90
    if (type === "out") {
      if (zoom == 80) {
        return 75
      } else {
        return 80
      }

    }
  }
  if (zoom >= 90 && zoom < 100) {
    if (type === "in") return 100
    if (type === "out") {
      if (zoom == 90) {
        return 80
      } else {
        return 90
      }

    }
  }
  if (zoom >= 100 && zoom < 110) {
    if (type === "in") return 110
    if (type === "out") {
      if (zoom == 100) {
        return 90
      } else {
        return 100
      }

    }
  }
  if (zoom >= 110 && zoom < 125) {
    if (type === "in") return 125
    if (type === "out") {
      if (zoom == 110) {
        return 100
      } else {
        return 110
      }

    }
  }
  if (zoom >= 125 && zoom < 150) {
    if (type === "in") return 150
    if (type === "out") {
      if (zoom == 125) {
        return 110
      } else {
        return 125
      }

    }
  }
  if (zoom >= 150 && zoom < 175) {
    if (type === "in") return 175
    if (type === "out") {
      if (zoom == 150) {
        return 125
      } else {
        return 150
      }

    }
  }
  if (zoom >= 175 && zoom < 200) {
    if (type === "in") return 200
    if (type === "out") {
      if (zoom == 175) {
        return 150
      } else {
        return 175
      }

    }
  }
  if (zoom >= 200 && zoom < 250) {
    if (type === "in") return 250
    if (type === "out") {
      if (zoom == 200) {
        return 175
      } else {
        return 200
      }

    }
  }
  if (zoom >= 250 && zoom < 300) {
    if (type === "in") return 300
    if (type === "out") {
      if (zoom == 250) {
        return 200
      } else {
        return 250
      }

    }
  }
  if (zoom >= 300 && zoom < 400) {
    if (type === "in") return 400
    if (type === "out") {
      if (zoom == 300) {
        return 250
      } else {
        return 300
      }

    }
  }
  if (zoom >= 400 && zoom < 500) {
    if (type === "in") return 500
    if (type === "out") {
      if (zoom == 400) {
        return 300
      } else {
        return 400
      }

    }
  }
  if (zoom == 500) {
    if (type === "in") return 500
    if (type === "out") return 400
  }
}