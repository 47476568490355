function numberDayOrMonth(value) {
  return (value < 10) ? '0' + value : value;
}

function getDateDDMMYYYY () {
  var date = new Date(this);
  return [numberDayOrMonth(date.getDate()), numberDayOrMonth(date.getMonth()+1), date.getFullYear()].join('/');
}

// eslint-disable-next-line no-extend-native
String.prototype.getDateDDMMYYYY = getDateDDMMYYYY;