import { authHeader, axiosSignatureRequest } from "../../../configs/axios.config"
import { handleErrorAxios } from "../../helpers"


export const getAvailableSignatures = async () => {
  try {
    const { data } = await axiosSignatureRequest({
      url: "/available/documents",
      headers: authHeader()
    });

    return data;
  } catch (error) {
    const message = handleErrorAxios(error)
    throw message
  }
}

export const downloadScannedCopy = async (body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: "/download/scanned-document",
      method: 'post',
      headers: authHeader(),
      responseType: 'arraybuffer',
      data: body
    });

    return data;
  } catch (error) {
    const message = handleErrorAxios(error)
    throw message
  }
}

export const sendScannedCopy = async (body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: "/send/scanned-document",
      method: 'post',
      headers: authHeader(),
      data: body
    });

    return data;
  } catch (error) {
    const message = handleErrorAxios(error)
    throw message
  }
}

export const getClientConfiguration = async (documentId, type = "fim") => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `${documentId}/invitation-email/configuration?type=${type}`,
      headers: authHeader()
    });
    return data;
  } catch (error) {
    const message = handleErrorAxios(error)
    throw message
  }
}
export const cancelSignatureProccess = async (id, body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/${id}/cancel`,
      method: 'post',
      headers: authHeader(),
      data: body,
    });
    return data;
  } catch (error) {
    const message = handleErrorAxios(error)
    throw "Error cancelando actividad"
  }
}

export const updateSigner = async (documentId, body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/signer/update`,
      method: 'put',
      headers: authHeader(),
      data: body,
    })

    return data;
  } catch (error) {
    const message = handleErrorAxios(error)
    throw message
  }
}

export const sendSignBlock = async (body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/send/block-signature/email`,
      method: 'post',
      headers: authHeader(),
      data: body,
    })
    return data;
  } catch (error) {
    const message = handleErrorAxios(error)
    throw message
  }
}

export const editExpirationDate = async (activityId, body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/${activityId}/update/expiration_date`,
      method: 'put',
      headers: authHeader(),
      data: body,
    })
    return data;
  } catch (error) {
    const message = handleErrorAxios(error)
    throw message
  }
}

export const createEndorseActivity = async (documentId, body, flag) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/endorse/${documentId}`,
      method: 'POST',
      headers: authHeader(),
      data: body,
      params: { q: flag }
    })
    return data;
  } catch (error) {
    const message = handleErrorAxios(error)
    throw message.error
  }
}

export const getAllStickers = async (documentId) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/endorse/${documentId}/stickers`,
    })
    return data;
  } catch (error) {
    const message = handleErrorAxios(error)
    throw message.error
  }
}

export const createSignActivity = async (documentId, body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/${documentId}/create`,
      method: 'POST',
      headers: authHeader(),
      data: body,
    })
    return data;
  } catch (error) {
    const message = handleErrorAxios(error)
    throw message.error
  }
}

