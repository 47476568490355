import React from 'react';
import { Alert } from 'axeleratum-sgc-frontend-library';
import { CalendarHttp } from '../../../../core/http/calendar.http';
import { Button } from '@material-ui/core';
import { tokenExpired } from '../../../../core/utils';
import { connect } from 'react-redux';
import { authActions } from '../../../../core/actions';

export class FirmButton extends React.Component {
  calendarHttp = new CalendarHttp();

  constructor(props) {
    super(props);

    this.state = {
      openAlert: false,
      typeMessage: '',
      message: '',
      openDialog: true,
      refresh: false,
      isRefresh: false,
      disabled: false,
      signed: false,
      noUser: false,
      total: 0,
      signedUsers: 0
    };
  }

  componentDidMount() {
    const { item } = this.props;


    this.isTurn(item.documentId);
    this.isSigned(item.documentId, false);

    let existInSign = false;
    let sign = 0;
    const total = item.signerReviewer.length;

    item.signerReviewer.forEach(element => {
      if (sessionStorage.getItem('userId') === element.userId) {
        existInSign = true;
      }

      if (element.signed) {
        sign++;
      }
    });

    this.setState({
      noUser: existInSign,
      total: total,
      signedUsers: sign
    });
  }

  isTurn(documentId) {
    const { isLoggedIn, userLoggedOut } = this.props;

    if (isLoggedIn && tokenExpired()) {
      userLoggedOut();
      return;
    }

    this.setState({ refresh: true, isRefresh: true });
    this.calendarHttp
      .getTurn(documentId)
      .then(({ data }) => {
        this.setState({ refresh: false, isRefresh: false, disabled: !data });
      })
      .catch(() => {
        this.setState({ refresh: false, isRefresh: false, disabled: true });
      });
  }

  isSigned(documentId, isRefreshData) {
    const { isLoggedIn, userLoggedOut } = this.props;

    if (isLoggedIn && tokenExpired()) {
      userLoggedOut()
      return
    }
    this.setState({ refresh: true, isRefresh: true });
    this.calendarHttp
      .isSigned(documentId)
      .then(({ data }) => {
        if (isRefreshData) {
          this.setState({ isRefresh: false, signed: data });
        } else {
          this.setState({ refresh: false, isRefresh: false, signed: data });
        }
      })
      .catch(() => {
        this.setState({ refresh: false, isRefresh: false, signed: false });
      });
  }

  render() {
    const { item, disabledButton } = this.props;
    const {
      openAlert,
      typeMessage,
      message,
      openDialog,
      refresh,
      isRefresh,
      disabled,
      signed,
      noUser,
      total,
      signedUsers
    } = this.state;

    return (
      <div>
        <Alert
          open={openAlert && openDialog}
          title={message}
          onConfirm={() => this.setState({ openAlert: false })}
          type={typeMessage}
        />

        {
          !noUser ?
            <div
              className="circle-check"
              style={{ background: signedUsers === 0 ? '#b0bec5' : (total !== signedUsers) && '#2196f3' }}
              title={`${signedUsers === 0 ? 'Sin firmas' : (total === signedUsers ? 'Firmado' : 'En proceso de firma')}`}
            >
              <em
                className={`${signedUsers === 0 ? 'far fa-clock' : (total === signedUsers ? 'fa fa-check' : 'far fa-clock')}`}
              >
              </em>
            </div>
            :
            <React.Fragment>
              {
                item.statusBpm === 'Rechazo de Firma' ?
                  <div style={{ background: '#ba000d' }} className="circle-check is-sign">
                    <em class="fas fa-times"></em>
                  </div>
                  :
                  signed ?
                    <div className="circle-check is-sign">
                      <em className="fa fa-check"></em>
                    </div>
                    :
                    !refresh ?
                      (<Button
                        className="firm-element"
                        color="primary"
                        variant="contained"
                        disabled={disabled || disabledButton}
                        onClick={() => {
                          const { isLoggedIn, userLoggedOut } = this.props;

                          if (isLoggedIn && tokenExpired()) {
                            userLoggedOut()
                            return
                          }
                          new CalendarHttp().getFirm(item.documentId)
                            .then(({ data }) => {
                              window.open(data.documentUrl);
                              this.setState({ refresh: true });
                            }).catch((error) => {
                              this.setState({
                                openAlert: true,
                                typeMessage: 'error',
                                message: 'Tuvimos problemas para conectarnos con FirmaMex'
                              });
                            });
                        }
                        }
                      >
                        Firmar
                      </Button>
                      ) : (
                        <Button
                          color='primary'
                          disabled={disabled || disabledButton}
                          variant='contained'
                          onClick={() => this.isSigned(item.documentId, true)}
                        >
                          {isRefresh ? (
                            <em className='fas fa-sync fa-spin'></em>
                          ) : (
                            <em className='fas fa-sync'></em>
                          )}
                        </Button>
                      )
              }
            </React.Fragment>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.authReducer.loggedIn,
  }
}

const mapDispatchToProps = {
  userLoggedOut: authActions.userLoggedOut,
}

export default connect(mapStateToProps, mapDispatchToProps)(FirmButton);
