export default {
  "userManagement": {
    "groupName": "Gestion de usuarios"
  },
  "roleManagement": {
    "groupName": "Gestion de roles"
  },
  "businessManagement": {
    "groupName": "Gestión de Bóvedas"
  },
  "documentManagement": {
    "groupName": "Gestion de Documentos"
  },
  "templateManagement": {
    "groupName": "Gestión de Plantillas"
  },
  "systemAdministration": {
    "groupName": "Administración del Sistema"
  }
}