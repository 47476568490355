import { TextField } from '@material-ui/core';
import React from 'react'

export const InputForm = (props) => {
  const { id, label, value, error, helperText, onChange, fullWidth, disabled, onBlur } = props;
  return (
    <TextField
      id={id}
      label={label}
      value={value}
      error={error}
      helperText={helperText}
      onChange={e => onChange(e.target.value)}
      variant='outlined'
      size='small'
      fullWidth={fullWidth ?? false}
      margin="dense"
      disabled={disabled}
      onBlur={onBlur}
    />
  )
}
