import React, { useState } from 'react'
import { GenericDialog } from '../Generic-with-children/GenericDialog'
import { InputForm } from '../../vault/Business-Management/InputForm';
import './newchart.scss'

export const CreateNewChartDialog = (props) => {

  const { openCreateDialog, setOpenCreateDialog, handleCreate, currentSubCharts = [] } = props;

  const [name, setName] = useState("")
  const [error, setError] = useState("")

  const handleChange = (value) => {
    setName(value)
    if (error) {
      setError("")
    }
  }

  const handleClickCreate = () => {
    if (currentSubCharts.some(el => el.toLowerCase() === name.trim().toLowerCase())) {
      setError("Ya existe un cuadro con el nombre")
      return
    }
    handleCreate(name.trim())
  }

  return (
    <>
      <GenericDialog
        nameComponent="Crear Cuadro"
        nameButton1="cancelar"
        nameButton2="guardar"
        open={openCreateDialog}
        setOpen={setOpenCreateDialog}
        onAccept={handleClickCreate}
        className="rootDialogCreateChart"
      >
        <InputForm
          label="Nombre del cuadro*"
          value={name}
          error={!!error}
          helperText={error}
          onChange={handleChange}
          fullWidth
        />
      </GenericDialog>
    </>
  )
}
