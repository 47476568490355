const cancelationConstants = {
  SAVE_ALL_USERS_CANCELATION: "SAVE_ALL_USERS_CANCELATION",
  SAVE_AVAILABLE_USERS_NOT_TO_SIGN_CANCELATION: "SAVE_AVAILABLE_USERS_NOT_TO_SIGN_CANCELATION",
  SAVE_SIGNERS_CANCELATION: "SAVE_SIGNERS_CANCELATION",
  SAVE_ENDORSER_CANCELATION: "SAVE_ENDORSER_CANCELATION",
  SAVE_ENDORSEE_CANCELATION: "SAVE_ENDORSEE_CANCELATION",
  RESTART_CANCELATION: "RESTART_CANCELATION",
  EDIT_SIGNER_CANCELATION: "EDIT_SIGNER_CANCELATION",
  SAVE_USERS_TO_SIGN_CANCELATION: "SAVE_USERS_TO_SIGN_CANCELATION",
}

export default cancelationConstants;