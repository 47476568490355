export const fileSystemConstants = {
  SELECT_ITEM: "SELECT_ITEM_FILESYSTEM_CONSTANTS",
  OPEN_BACKDROP: "OPEN_BACKDROP_FILESYSTEM_CONSTANTS",
  LOADING_ITEMS: "LOADING_ITEMS_FILESYSTEM_CONSTANTS",
  ADD_FILTER_TAG: "ADD_FILTER_TAG_FILESYSTEM_CONSTANTS",
  SEARCH_ELEMENTS: "SEARCH_ELEMENTS_FILESYSTEM_CONSTANTS",
  SAVE_ARRAY_PATH: "SAVE_ARRAY_PATH_FILESYSTEM_CONSTANTS",
  SELECT_DOCUMENT: "SELECT_DOCUMENT_FILESYSTEM_CONSTANTS",
  REMOVE_FILTER_TAG: "REMOVE_FILTER_TAG_FILESYSTEM_CONSTANTS",
  OPEN_DOCUMENT_PDF: "OPEN_DOCUMENT_PDF_FILESYSTEM_CONSTANTS",
  SAVE_ITEMS_FILE_SYSTEM: "SAVE_ITEMS_FILE_SYSTEM_FILESYSTEM_CONSTANTS",
  SET_INCLUDE_DESCRIPTION: "SET_INCLUDE_DESCRIPTION_FILESYSTEM_CONSTANTS",
}