import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useState } from "react";

export const DialogComponent = ({
	open,
	setOpen,
	textContent,
	title,
	onConfirm,
}) => {
	const [inputDialog, setInputDialog] = useState("");

	const onInputChange = (event) => {
		setInputDialog(event.target.value);
	};

	const handleConfirm = () => {
		onConfirm(inputDialog);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title">
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<strong>{textContent}</strong>
					</DialogContentText>
					<TextField
						value={inputDialog}
						autoFocus
						margin="dense"
						id="reason"
						label="Motivo de cancelación*"
						type="text"
						fullWidth
						variant="outlined"
						multiline
						minRows={2}
						onChange={onInputChange}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary" variant="outlined">
						Cancelar
					</Button>
					<Button
						disabled={inputDialog.length === 0}
						onClick={handleConfirm}
						color="primary"
						variant="contained">
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
