import { 
  Dialog, 
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button
} from "@material-ui/core";


export const DocumentsWithProcessList = ({open, onClose, documents =[], title=''}) => {

  return (
    <Dialog open={open}>
      <DialogTitle 
        disableTypography
        style={{
          background: '#1e88e5',
          display: 'flex',
          justifyContent:'center'
        }}
      >
        <h3 style={{color:'#fff'}}>
          {title}
        </h3>
      </DialogTitle>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{fontWeight:'bold', fontSize:'14px'}}>Tipo de proceso</TableCell>
              <TableCell style={{fontWeight:'bold', fontSize:'14px'}}>Ruta</TableCell>
              <TableCell style={{fontWeight:'bold', fontSize:'14px'}}>Nombre del documento</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              documents.map(document => (
                <TableRow key={document._id}>
                  <TableCell>{document.StatusBpm}</TableCell>
                  <TableCell>{`${document.Path}/`}</TableCell>
                  <TableCell>{`${document.Name}${document.Extension}`}</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>

      <div
        style={{
          display:'flex', 
          padding:'10px',
          justifyContent: 'center'
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => onClose()}
        >
          Cerrar
        </Button>
      </div>

    </Dialog>
  )
}
