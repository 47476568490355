//Material UI
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
//Otros
import PropTypes from "prop-types";


const useStyles = makeStyles((theme) => ({
	dialogActivity: {
		padding: "8px",
	},
	actions: {
		padding: "24px 24px",
	},
}));

export const GenericDialog = (props) => {
	const {
		nameComponent,
		nameButton1 = "CANCELAR",
		nameButton2 = "ACEPTAR",
		open,
		setOpen,
		children,
		containerStyles,
		className,
		onAccept,
		disableAccept = false,
		contentStyles,
	} = props;
	const classes = useStyles();

	const handleClose = () => {
		setOpen(false);
	};
	const handleClick = () => {
		onAccept();
	};

	return (
		<Dialog
			className={className || classes.dialogActivity}
			style={containerStyles}
			open={open}
			onClose={handleClose}
		>
			<DialogTitle id="alert-dialog-title">{nameComponent}</DialogTitle>
			<DialogContent style={contentStyles}>{children}</DialogContent>
			<DialogActions className={classes.actions}>
				<Button onClick={handleClose} color="primary" variant="outlined">
					{nameButton1}
				</Button>
				<Button color="primary" variant="contained" onClick={handleClick} disabled={disableAccept}>
					{nameButton2}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

GenericDialog.propTypes = {
	nameComponent: PropTypes.string.isRequired,
};
