import React from "react";
import Menu from "@material-ui/core/Menu";
import List from "@material-ui/core/List";
import CardSearched from "./CardSearched";
import { Divider } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Pagination from "@material-ui/lab/Pagination";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
	root: {
		width: "100%",
		backgroundColor: "#fff",
		position: "relative",
	},
});

const StyledMenu = withStyles({
	paper: {
		border: "1px solid #d3d4d5",
		maxHeight: "500px",
		minWidth: "300px",
		textAlign: "center"
	},
})((props) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "center",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "left",
		}}
		{...props}
	/>
));

export const MenuListAnchor = (props) => {
	const {
		page,
		options,
		anchorEl,
		totalPages,
		loading,
		setPage,
		handleCloseMenu,
		handleClickElement,
	} = props;

	const classes = useStyles(props);
	const open = Boolean(anchorEl);

	const onClickElement = (element) => {
		handleClickElement(element);
	};

	const handlePagination = (event, page) => {
		setPage(page);
	};

	return (
		<StyledMenu
			id="long-menu"
			anchorEl={anchorEl}

			open={open}
			onClose={handleCloseMenu}
		>
			{
				options.length > 0 && (
					<List className={classes.root} subheader={<li />}>
						<ListSubheader style={{ display: 'flex', justifyContent: 'center' }}>
							<Pagination
								size="small"
								count={totalPages}
								color="primary"
								onChange={handlePagination}
								page={page}
							/>
						</ListSubheader>
						{options.map((file) => (
							<div key={file.id}>
								<MenuItem
									style={{
										paddingTop: '10px',
										paddingBottom: '10px'
									}}
									onClick={() => onClickElement(file)}
								>
									<CardSearched
										id={file.id}
										path={file.path}
										name={file.name}
										extension={file.extension}
										vaultName={file.vaultName}
										vaultId={file.vaultId}
										isVault={file.isVault}
										author={file.author}
										updatedAt={file.updatedAt}
									/>
								</MenuItem>
								<Divider />
							</div>
						))}
					</List>
				)
			}
			{
				loading && (
					<CircularProgress color="primary" />
				)
			}

			{
				!loading && options.length === 0 ? (
					<span>No se encontraron archivos</span>
				) : <p></p>
			}
		</StyledMenu>
	);
};
