import React from 'react';
import { Tabs, Tab, Box } from '@material-ui/core';
import { useState } from 'react';

export const TabPanelApp = ({tabs, content, classes}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Tabs 
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {
          tabs.map((tabName, i) =>(
            <Tab 
              key={`${tabName}-${i}`}
              id={i}
              label={tabName}
              classes={{textColorPrimary: classes.textColorDisabled}}
            />
          ))
        }        
      </Tabs>

      {
        content.map((tabContent, i) => (
          <div
            key={`content-${i}`}
            id={i}
            role='tabpanel'
            hidden={value !== i}
            style={{
              height: '330px',
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
          >
            {
              value === i && (
                <Box>
                  {tabContent}
                </Box>
              )
            }
          </div>
        ))
      }
    </Box>
  )
}