import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import { Button, Divider } from '@material-ui/core';
import { uuid } from 'axeleratum-sgc-frontend-library';
import './view-file.scss';
//import VaultApi from './home-vault-api';


class HomeVault extends Component {

  constructor(props) {
    super(props);

    this.state = {
      documents: [],
      selectedItem: -1,
      selectedItemData: null,
      load : true,
      activeRow: null,
      openTemplateUsage:false,
      business : null
    }
  }

  componentDidMount() {
  // this.getTemplates();
    //this.findBusinessById();
  }

 /* getTemplates(){
    /*VaultApi.findDocuments()
    .then((data) => this.setState({ documents: data})
    .catch(this.handleError)
  )}*/

  handleError = (error) => {
    this.setState({errorMessage: error});
}


  render() {
    const { documents, selectedItem, selectedItemData, load } = this.state;
    const { open, onClose, onSelectFile, type, business } = this.props;
    const {openTemplateUsage, templateSelected} = this.state;

    return (
      <Dialog
        open={ open }
        className="modal-size"
      >
        <div className="ViewFile">
          <div className="dialog-size">
            <div className="col-md-4 col-xs-4 tree-container">
              <h3 className="font-withe"></h3>

            </div>

            <div className="col-md-8 col-xs-6 mt-3 scroll">
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <b>Nombre</b>
                      </TableCell>

                      <TableCell align="left">
                        <b>Tipo</b>
                      </TableCell>

                      <TableCell align="left">
                        <b>Fecha de modificación</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>



                  {
                  
                        documents.map((element, index) => (
                          
                            <TableRow
                              className="table-item"
                              style={{}}
                              key={ index }
                              style={
                                this.state.activeRow === index
                                  ? { background: 'whiteSmoke' }
                                  : { background: '' }
                              }
                              selected={ index === selectedItem }
                              onClick={ () =>
                                this.toggleActive(index,element)
                                
                              }
                            >
                              {
                                
                                  <React.Fragment>
                                    <TableCell align="left">
                                      <strong className="fas fa-file font-color"></strong>
                                      &nbsp;
                                      { element.name }
                                    </TableCell>

                                    <TableCell align="left">
                                      { element.name.split('.')[1] }
                                    </TableCell>

                                    <TableCell align="left">
                                      {
                                        element.lastModified &&
                                          element.lastModified.split('T')[0]
                                      }
                                    </TableCell>
                                  </React.Fragment>  
                                
                              }      
                            </TableRow>
                        
                        )) 
                      }

                  </TableBody>

                
                </Table>
              </TableContainer>

              {
                documents.length === 0 &&
                  <div className="col-md-12 col-xs-12 text-center mt-3">
                    No hay elementos para mostrar
                  </div>
              }
            </div>
          </div>

          <div className="text-right margin">
            <Button
              variant="outlined"
              className="mr-2 font-color"
              onClick={ () => {
                onClose();
                this.setState({selectedItemData: null })
              }}
            >
              Cancelar
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onSelectFile && onSelectFile(selectedItemData, selectedItem);
                //onClose();
                this.setState({selectedItemData: null ,openTemplateUsage:true})
              }}
              disabled={ !selectedItemData }
            >
              Usar Plantilla
            </Button>
          </div>
        </div>
        {openTemplateUsage && this.renderTemplateUsage()}
      </Dialog>
    )
  }
}

export default(HomeVault);
