import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import { Header } from "./sections/header";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import TabContext from "@material-ui/lab/TabContext";
import { TabCalendar } from "./sections/tab-calendar";
import { TabDiary } from "./sections/tab-diary";
import moment from "moment";
import { CalendarHttp } from "../../../core/http/calendar.http";
import { Alert } from "axeleratum-sgc-frontend-library";

import { FormActivityNative } from "./forms/form-activity-native";
import "./calendar.scss";
import "./forms/drop-zone.scss";
import { DialogSignatureStatus } from "../../dialogs/activities/DialogSignatureStatus";
import { authActions } from "../../../core/actions";
import { tokenExpired } from "../../../core/utils";
import { connect } from "react-redux";

export class Calendar extends Component {
	calendarHttp = new CalendarHttp();

	constructor(props) {
		super(props);

		this.state = {
			open: false,
			openNew: false,
			selectedTab: "1",
			selectedActivity: null,
			selectedActivityDate: moment(new Date()).format("YYYY-MM"),
			upload: false,
			disabled: false,
			selectedItem: {},
			openActivity: false,
			openAlert: false,
			openDetail: false,
			message: "",
			typeMessage: "",
			loadActivities: false,
			activities: [],
			activitiesDiary: [],
			monthYear: "",
			external: false,
		};

		this.getActivities(moment(new Date()).format("YYYY-MM-DD"));
		this.getMonthActivities(moment(new Date()).format("YYYY-MM"));
	}

	componentDidMount() {
		localStorage.removeItem("selectActivity");
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.open !== prevState.open) {
			if (nextProps.open && localStorage.getItem("selectActivity")) {
				const activity = JSON.parse(localStorage.getItem("selectActivity"));

				if (activity.documents) {
					activity.documents.forEach((document) => {
						if (document.statusBpm === "Rechazo de Firma") {
							activity.statusBpm = "Rechazo de Firma";
						}

						if (document.signerReviewer) {
							document.signerReviewer.forEach((user) => {
								if (user.rejected) {
									activity.rejected = user.rejected;
								}
							});
						}
					});
				}

				return {
					open: nextProps.open,
					selectedItem: activity,
					openDetail: true,
					external: true,
				};
			}

			localStorage.removeItem("selectActivity");
			return { open: nextProps.open, external: false };
		} else return null;
	}

	initialValues() {
		const { selectedItem } = this.state;
		const out = [];

		if (selectedItem.tags) {
			selectedItem.tags.forEach((item) => {
				out.push({ label: item, value: item });
			});
		}

		return out;
	}

	getActivities(date) {
		const { isLoggedIn, userLoggedOut } = this.props;

		if (isLoggedIn && tokenExpired()) {
			userLoggedOut()
			return
		}

		this.setState({ loadActivities: true });
		this.calendarHttp.getActivities(
			date,
			(resp) => {
				this.setState({ loadActivities: false, activities: resp });
			},
			(error) => {
				this.setState({ loadActivities: false });
			}
		);
	}

	getMonthActivities(monthYear) {
		const { isLoggedIn, userLoggedOut } = this.props;
		if (isLoggedIn && tokenExpired()) {
			userLoggedOut()
			return
		}
		this.setState({ load: true });
		this.calendarHttp.getMonthActivities(
			monthYear,
			(resp) => {
				this.setState({ load: false, activitiesDiary: resp, monthYear });
			},
			(error) => {
				this.setState({ load: false });
			}
		);
	}

	onDrop(id) {
		const { isLoggedIn, userLoggedOut } = this.props;

		if (isLoggedIn && tokenExpired()) {
			userLoggedOut()
			return
		}
		new CalendarHttp()
			.deleteActivity(id)
			.then((resp) => {
				this.setState({
					openDetail: false,
					openAlert: true,
					message: "Actividad eliminada correctamente",
					typeMessage: "success",
				});

				this.reloadCalendar();
			})
			.catch((err) => {
				this.setState({
					openAlert: true,
					typeMessage: "error",
					message: "No se pudo eliminar la actividad",
				});
			});
	}

	reloadCalendar() {
		this.getActivities(moment(new Date()).format("YYYY-MM-DD"));
		this.getMonthActivities(moment(new Date()).format("YYYY-MM"));
	}

	render() {
		const {
			open,
			selectedTab,
			selectedActivity,
			selectedItem,
			openActivity,
			upload,
			openAlert,
			message,
			typeMessage,
			openDetail,
			loadActivities,
			activities,
			activitiesDiary,
			monthYear,
			external,
		} = this.state;
		const { onExit } = this.props;
		return (
			<Dialog className={`Calendar ${external && "no-calendar"}`} open={open}>
				<Alert
					open={openAlert}
					title={message}
					onConfirm={() => this.setState({ openAlert: false })}
					type={typeMessage}
				/>

				{openDetail && (
					<DialogSignatureStatus
						open={openDetail}
						setOpen={(value) => this.setState({ openDetail: value })}
						item={selectedItem}
						onExitDetail={() => {
							this.setState({
								openDetail: false,
								selectedItem: {},
							});

							if (external) {
								document.getElementById("calendarClose").click();
							}

							this.reloadCalendar();
						}}
					/>
				)}

				<FormActivityNative
					open={openActivity}
					load={upload}
					newOrUpdate={true}
					id={selectedItem.id}
					type={selectedItem.type}
					name={selectedItem.name}
					description={selectedItem.description}
					tags={
						selectedItem.tags
							? selectedItem.tags.map((item) => ({
								value: item,
								label: item,
							}))
							: []
					}
					limitDate={selectedItem.date}
					hour={selectedItem.hour}
					recordatory={selectedItem.recordatory}
					files={
						selectedItem.documents
							? selectedItem.documents.map((item) => ({
								...item,
								type: "exist",
								name: item.documentName,
								reviewers: item.signerReviewer
									? item.signerReviewer.map((child) => ({
										...child,
										value: child.userId,
										label: child.name,
										color: "",
										colorUser: child.color,
									}))
									: [],
							}))
							: []
					}
					onSubmit={(activity) => {
						this.getActivities(activity.date);
						this.getMonthActivities(monthYear);

						this.setState({
							openAlert: true,
							message: "Actividad actualizada",
							typeMessage: "success",
							upload: false,
							selectedActivity: activity,
							selectedItem: activity,
							openActivity: false,
						});

						let selectData = selectedTab === "1" ? "2" : "1";
						let select2 = selectData === "2" ? "1" : "2";

						this.setState({ selectedTab: selectData });
						this.setState({ selectedTab: select2 });
					}}
					onCancel={() =>
						this.setState({ openActivity: false, selectItem: null })
					}
				/>

				<div className="p-3 scroll-calendar">
					<div>
						<Header onExit={() => onExit()} />
					</div>

					<TabContext value={selectedTab}>
						<div className="">
							<TabList
								variant="scrollable"
								allowScrollButtonsMobile
								onChange={(evt, value) => {
									this.setState({
										selectedTab: value,
										selectedActivity: null,
									});
									this.getActivities(moment(new Date()).format("YYYY-MM-DD"));
								}}
								aria-label="simple tabs example">
								<Tab label="Calendario" value="1" />
								<Tab label="Agenda" value="2" />
							</TabList>
						</div>

						<TabPanel value="1">
							<TabCalendar
								onSelectActivity={(data) => {
									this.setState({
										selectedItem: data,
										openDetail: true,
									});
								}}
								onNewActivity={() => this.setState({ openNew: true })}
								onChange={(date) => this.getActivities(date)}
								loadActivities={loadActivities}
								activities={activities}
								onSave={(selectedDate) =>
									this.getActivities(moment(selectedDate).format("YYYY-MM-DD"))
								}
							/>
						</TabPanel>

						<TabPanel className="no-scroll" value="2">
							<TabDiary
								yearMonth={moment(new Date()).format("YYYY-MM")}
								selectActivity={selectedActivity}
								onExitDetail={() => this.setState({ selectedActivity: null })}
								onEdit={(a) =>
									this.setState({ selectedItem: a, openActivity: true })
								}
								seMore={(item) =>
									this.setState({ selectedItem: item, openDetail: true })
								}
								onSelectMonth={(e) => this.getMonthActivities(e)}
								onSelectYear={(e) => this.getMonthActivities(e)}
								activities={activitiesDiary}
								load={loadActivities}
								onDrop={(id) => this.onDrop(id)}
							/>
						</TabPanel>
					</TabContext>
				</div>
			</Dialog>
		);
	}
}

const mapStateToProps = state => {
	return {
		isLoggedIn: state.authReducer.loggedIn,
	}
}

const mapDispatchToProps = {
	userLoggedOut: authActions.userLoggedOut,
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);