import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  Grid,
  makeStyles,
  Checkbox,
  DialogActions,
  TextField,
  Button,
  MenuItem,
} from '@material-ui/core';
import { useState } from 'react';
import moment from "moment";
import { getClientConfiguration } from '../../../../../../core/http/functionRequests/signature.http';
import { getCurrentUser } from '../../../../../../core/helpers';


const useStyles = makeStyles((theme) => ({
  container: {
    padding: "15px 15px 15px 15px !important",
  },
  titleContainer: {
    padding: "5px 15px 5px 15px !important",
  },
  containerChecks: {
    padding: "0px 15px 0px 15px !important",
  },
  checkboxContainer: {
    textAlign: "right"
  },
  title: {
    paddingLeft: "0px"
  },
  cancelContainer: {
    padding: "15px 15px 15px 15px !important",
  },
  cancel: {
    paddingRight: "0px"
  },
  daysLeft: {
    paddingRight: "40px !important"
  },
  iconButton: {
    padding: "12px 0px 12px 0px",
  }
}));

const expirationDates = [
  // { value: '0', label: "Sin recordatorio" },
  { value: '12h', label: "Cada 12 horas" },
  { value: '1d', label: "Cada 1 día" },
  { value: '2d', label: "Cada 2 días" },
  { value: '3d', label: "Cada 3 días" },
  { value: '4d', label: "Cada 4 días" },
  { value: '5d', label: "Cada 5 días" },
  { value: '6d', label: "Cada 6 días" },
  { value: '7d', label: "Cada 7 días" },
];

export const SignatureNotificationConfig = ({ open, onCancel, onSave, prev, documentId, edit = false, type = "firm" }) => {
  const classes = useStyles();
  const block_expired = true;

  const [notifyExpiringDay, setNotifyExpiringDay] = useState(prev.notifyExpiringDay ?? true);
  const [subject, setSubject] = useState(prev.subject ?? "");
  const [message, setMessage] = useState(prev.message ?? "");
  const [remind_every, setRemind_every] = useState(prev.remind_every ?? '7d');
  const [expiration_date, setExpiration_date] = useState(prev.expiration_date ?? "");
  const [daysLeft, setDaysLeft] = useState(0);
  const [errors, setErrors] = useState({
    expiration_date: "",
    subject: "",
    message: ""
  })

  const calculateDays = (expirationDate) => {
    const today = moment().format("YYYY-MM-DD");
    const selectedDate = moment(expirationDate).format("YYYY-MM-DD");
    const diff = moment(selectedDate).diff(moment(today), 'days');
    return diff;
  }

  const handleChangeExpirationDate = (e) => {
    const exp_date = e.target.value;

    setExpiration_date(exp_date);
    const diff = calculateDays(exp_date)
    setDaysLeft(diff);

    if (diff <= 0) {
      setErrors({
        expiration_date: "Se debe seleccionar una fecha posterior a hoy"
      })
    } else {
      setErrors({ expiration_date: "" });
    }

  }

  const handleChangeSubject = (e) => {
    const value = e.target.value;
    if (value.length <= 150) {
      setSubject(value)
    }
  }

  const handleChangeMessage = (e) => {
    const value = e.target.value;
    if (value.length <= 3000) {
      setMessage(value)
    }
  }

  const handleSubmit = () => {
    if (edit) {
      const objReq = {
        notifyExpiringDay,
        remind_every: remind_every === 0 ? null : remind_every,
        expiration_date: expiration_date,
      }
      onSave(prev.id, objReq)
      return
    }
    onSave({
      notifyExpiringDay,
      block_expired,
      subject,
      message,
      remind_every: remind_every === 0 ? null : remind_every,
      expiration_date: expiration_date,
      // expiration_date: moment(expiration_date).format("DD/MM/YYYY"),
    })
  }

  const getInvitationMessage = async () => {
    try {
      const data = await getClientConfiguration(documentId, type);

      if (!subject) {
        setSubject(data.subject);
      }
      if (!message) {
        setMessage(data.message);
      }

    } catch (error) {
      console.error(error);
    }
  }

  const setDefaultDate = () => {
    const { monthsExpirationDefault } = getCurrentUser()
    const threeMonths = moment().add(monthsExpirationDefault ?? 3, 'months').format("YYYY-MM-DD");
    setExpiration_date(threeMonths);
    setDaysLeft(calculateDays(threeMonths))
  }

  useEffect(() => {
    if (!edit && (!message || !subject)) {
      getInvitationMessage();
    }

    if (!prev?.expiration_date) {
      setDefaultDate()
    } else {
      setDaysLeft(calculateDays(prev.expiration_date))
    }
    // eslint-disable-next-line
  }, [])



  return (
    <Dialog open={open}>
      <Grid container spacing={1}>
        <Grid
          container
          item
          spacing={3}
          className={classes.titleContainer}
          alignItems='center'
        >
          <Grid item xs>
            <DialogTitle className={classes.title}>{edit ? 'Recordatorios y fecha de expiración' : 'Notificación y recordatorios'}</DialogTitle>
          </Grid>
          <Grid item>
          </Grid>
          <Grid item>
          </Grid>
        </Grid>
        {
          !edit && (
            <>
              <Grid
                container
                item
                spacing={3}
                className={classes.container}
                alignItems='center'
              >
                <Grid item xs={3}>
                  <span>Asunto</span>
                </Grid>
                <Grid item xs>
                  <TextField
                    value={subject}
                    error={errors.subject}
                    helperText={errors.subject}
                    onChange={handleChangeSubject}
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={`${subject.length} de 150 caracteres`}
                  />
                </Grid>
                <Grid item>

                </Grid>
              </Grid>
              <Grid
                container
                item
                spacing={3}
                className={classes.container}
                alignItems='center'
              >
                <Grid item xs={3}>
                  <span>Mensaje inicial</span>
                </Grid>
                <Grid item xs>
                  <TextField
                    value={message}
                    error={errors.message}
                    helperText={errors.message}
                    onChange={handleChangeMessage}
                    variant="outlined"
                    size="small"
                    fullWidth
                    multiline
                    maxRows={3}
                    label={`${message.length} de 3000 caracteres`}
                  />
                </Grid>
                <Grid item>

                </Grid>
              </Grid>
            </>
          )
        }
        <Grid
          container
          item
          spacing={3}
          className={classes.container}
          alignItems='center'
        >
          <Grid item xs={3}>
            <span>Recordatorio cada</span>
          </Grid>
          <Grid item xs={5}>
            <TextField
              variant="outlined"
              size='small'
              select
              value={remind_every}
              onChange={(e) => setRemind_every(e.target.value)}
            >
              {
                expirationDates.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              }
            </TextField>
          </Grid>
          <Grid item xs={4}>

          </Grid>
        </Grid>

        <Grid
          container
          item
          spacing={3}
          className={classes.container}
          alignItems='center'
        >
          <Grid item xs={3}>
            <span>Expira el</span>
          </Grid>
          <Grid item xs={errors.expiration_date ? 8 : 4}>
            <TextField
              helperText={errors.expiration_date || (daysLeft > 0 ? `Al final del día` : null)}
              error={errors.expiration_date}
              value={expiration_date}
              variant="outlined"
              size='small'
              type='date'
              onChange={handleChangeExpirationDate}
            />
          </Grid>
          <Grid item xs className={classes.daysLeft}>
            <span>
              {
                daysLeft > 0 ? `Disponible ${daysLeft} ` : null
              }
              {
                daysLeft > 0 && daysLeft < 2 ? 'día' : null
              }
              {
                daysLeft >= 2 ? 'días' : null
              }
            </span>
          </Grid>
        </Grid>

        {
          expiration_date ? (
            <>
              <Grid
                container
                item
                spacing={3}
                className={classes.containerChecks}
                alignItems='center'
              >
                <Grid item xs={3} className={classes.checkboxContainer}>
                  <Checkbox
                    checked={notifyExpiringDay}
                    color='primary'
                    onClick={() => setNotifyExpiringDay(e => !e)}
                  />
                </Grid>
                <Grid item xs={5}>
                  <span>Notificar el día de expiración</span>
                </Grid>
                <Grid item xs={4}>

                </Grid>
              </Grid>
            </>
          ) : null
        }

        <Grid
          container
          item
          spacing={3}
          className={classes.cancelContainer}
          alignItems='center'
          justifyContent='flex-end'
        >
          <Grid item>
          </Grid>
          <Grid item xs>
            <DialogActions className={classes.cancel}>
              <Button
                variant='outlined'
                color='primary'
                onClick={() => onCancel()}
              >
                Cancelar
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit}
                disabled={Object.values(errors).some(el => el)}
              >
                Aceptar
              </Button>
            </DialogActions>
          </Grid>
          <Grid item>
          </Grid>
        </Grid>
      </Grid>

    </Dialog>
  )
}
