import {alertConstants} from '../constants'
import initialState from '../../configs/intialState.config';

export function alertReducer(state = initialState.alert, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: 'success',
        message: action.payload
      }
    
    case alertConstants.ERROR:
      return {
        type: 'error',
        message: action.payload
      }
    
    case alertConstants.WARNING:
      return {
        type: 'warning',
        message: action.payload
      }

    case alertConstants.INFO:
      return {
        type: 'info',
        message: action.payload
      }

    case alertConstants.CLOSE_ALERT:
      return {}
  
    default:
      return state;
  }
}