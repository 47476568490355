import { endorseConstants } from "../constants"





const saveEndorser = (endorser) => ({
  type: endorseConstants.SAVE_ENDORSER,
  payload: endorser,
})

const saveEndorsee = (endorsee) => ({
  type: endorseConstants.SAVE_ENDORSEE,
  payload: endorsee,
})
const saveTemporalEndorser = (endorser) => ({
  type: endorseConstants.SAVE_TEMPORAL_ENDORSER,
  payload: endorser,
})

const saveTemporalEndorsee = (endorsee) => ({
  type: endorseConstants.SAVE_TEMPORAL_ENDORSEE,
  payload: endorsee,
})
const setEndorseType = (user_endorse) => ({
  type: endorseConstants.SET_ENDORSE_TYPE,
  payload: user_endorse,
})

const setAllParticipants = (participants) => ({
    type: endorseConstants.SET_ALL_PARTICIPANTS,
    payload: participants
  });




export const endorseActions = { 
  saveEndorser,
  saveEndorsee,
  saveTemporalEndorser,
  saveTemporalEndorsee, 
  setEndorseType,
  setAllParticipants,
}