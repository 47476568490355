import { Button, Dialog, Grid, Typography } from '@material-ui/core';
import { isMobileOnly, RenderTextField, uuid } from 'axeleratum-sgc-frontend-library';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { FoldersHttp } from '../../core/http/folders.http';
import { sortData, tokenExpired } from '../../core/utils';
import FolderSelector from '../templates/folder-selector';
import { compose } from 'redux';
import { authActions } from '../../core/actions';
import { connect } from 'react-redux';

class RestoreFile extends Component {

  foldersHttp = new FoldersHttp();

  constructor(props) {
    super(props);
    this.state = {
      companyId: null,
      folders: [],
      openFolderSelector: false,
      folderSelected: null,
    };
  }

  componentDidMount() {
    this.props.initialize({
      name: this.props.folderName ? this.props.folderName : ''
    });

    this.getFoldersByCompany();
  }

  getFoldersByCompany() {
    const {isLoggedIn, userLoggedOut} = this.props;  

 if(isLoggedIn && tokenExpired()){
      userLoggedOut()
      return
    } 
    this.foldersHttp.foldersCompanies(this.props.company.id, data => {
      const folders = sortData(data.subfolders.filter(el => el.name !== "Trash").map(item => {
        return {
          id: item._id,
          name: item.name,
          type: 'folder',
          isFolder: true,
          onlyExistInMongo: item.onlyExistInMongo,
          onlyExistInKaleido: item.onlyExistInKaleido,
          path: item.name,
          pathLabel: `${this.props.company.name}/${item.name}`,
          children: [],
          childrenLoaded: false,
        }
      }));

      const companyFolders = [{
        id: this.props.company.id,
        name: this.props.company.name,
        path: '',
        pathLabel: this.props.company.name,
        children: [...folders],
        childrenLoaded: true,
      }];
      this.setState({
        folders: companyFolders,
      })
    }, () => { })
  }

  loadSubfolders(folder) {
    const {isLoggedIn, userLoggedOut} = this.props;  

 if(isLoggedIn && tokenExpired()){
      userLoggedOut()
      return
    } 
    const { folders } = this.state;
    
    this.foldersHttp.getFolderInfo(folder.id, this.props.company.id, (resp) => {
      const subfolders = sortData(
        resp.subfolders.map((item) => {
          return {
            id: item._id,
            name: item.name,
            type: 'folder',
            isFolder: true,
            onlyExistInMongo: item.onlyExistInMongo,
            onlyExistInKaleido: item.onlyExistInKaleido,
            path: `${item.path}/${item.name}`,
            pathLabel: `${this.props.company.name}/${item.path}/${item.name}`,
            children: [],
            childrenLoaded: false,
          };
        })
      );

      folder.children = [...subfolders];
      folder.childrenLoaded = true;

      this.setState({ folders });
    }, () => { });
  }

  render() {
    const { open, enableName, handleSubmit, onCancel, submitAction } = this.props;
    const { openFolderSelector, folderSelected } = this.state;

    return (
      <React.Fragment>
        <Dialog
          open={open}
          maxWidth="xs"
          fullWidth
        >
          <form
            className={isMobileOnly ? "p-3" : "p-5"}
            onSubmit={handleSubmit((values) => { submitAction(values, folderSelected) })}
          >
            <Grid container>
              <Grid item sm={12}>
                <h3>Restaurar documento</h3>
                <Field
                  label="Nombre del documento"
                  name="name"
                  required
                  disabled={enableName}
                  component={RenderTextField}
                />
                <Grid item xs={12}>
                  <p>Ruta del documento  </p>
                  <Grid container item direction="row" alignItems="center" xs={12}>
                    <Button
                      className="mr-3 mb-2"
                      variant="contained"
                      color="primary"
                      onClick={() => { this.setState({ openFolderSelector: true }) }}
                    >
                      Seleccionar...
                    </Button>
                    {folderSelected &&
                      <Typography>{folderSelected.pathLabel}</Typography>
                    }
                  </Grid>
                </Grid>

                <div className="text-right pt-3">
                  <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={() => onCancel()}
                  >
                    Cancelar
                  </Button>
                  <Button
                    className="ml-2"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!folderSelected?.pathLabel}
                  >
                    Restaurar
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </Dialog>

        {openFolderSelector &&
          <FolderSelector
            open={openFolderSelector}
            folders={this.state.folders}
            onClose={() => { this.setState({ openFolderSelector: false }) }}
            onSelected={(value) => {
              this.setState({
                folderSelected: {
                  id: value.id,
                  name: value.name,
                  path: value.path,
                  pathLabel: value.pathLabel,
                  companyId: this.props.company.id,
                  onlyExistInKaleido: value.onlyExistInKaleido,
                  onlyExistInMongo: value.onlyExistInMongo
                },
                openFolderSelector: false
              });
            }}
            onClick={(value) => this.loadSubfolders(value)}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.authReducer.loggedIn,
  }
}

const mapDispatchToProps = {
  userLoggedOut: authActions.userLoggedOut,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'DialogRestoreFile' }))(RestoreFile);