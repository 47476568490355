import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { activityActions } from '../../../../core/actions/activity.actions';
import { FormTemporalUsers } from '../../../team/users/FormTemporalUsers';
import { AlertConfirm } from '../../../from-library/alerts/AlertConfirm';

export const AddMultipleNoSigners = () => {
  const dispatch = useDispatch();

  const availableUsers = useSelector(state => state.activityReducer.usersNotToSign);
  const temporalNotToSign = useSelector(state => state.activityReducer.temporalUsersNotToSign);
  const selectedOptions = useSelector(state => state.activityReducer.allNoSigners);

  const [checked, setChecked] = useState(selectedOptions);
  const [openList, setOpenList] = useState(false);
  const [openExternalForm, setOpenExternalForm] = useState(false);
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
  const [temporalSelected, setTemporalSelected] = useState(null);

  const handleChange = (user) => {
    const currentIndex = checked.map(e => e.userId).indexOf(user.userId);
    const newChecked = [...checked];

    if (!!user.temporal) {
      setTemporalSelected(user.userId);
      setOpenConfirmAlert(true);
      return
    }

    if (currentIndex === -1) {
      newChecked.push(user);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  }

  const handleAddUsers = () => {
    dispatch(activityActions.saveNoSigners(checked.filter(el => !el.temporal)));
    dispatch(activityActions.saveTemporalNoSigners(checked.filter(el => el.temporal)));

    setOpenList(false);
  }

  const handleCancel = () => {
    setOpenList(false);
    setChecked(selectedOptions);
  }

  const handleSubmitExternalUser = (user) => {
    dispatch(activityActions.saveTemporalNotToSign([...temporalNotToSign, user]));
    setChecked(ch => [...ch, user]);
    setOpenExternalForm(false);
  }
  useEffect(() => {
    console.log(temporalNotToSign);
  }, [temporalNotToSign])

  return (
    <>
      <div>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpenList(true)}
        >
          Agregar
        </Button>
      </div>

      {
        openList && (
          <Dialog open={openList}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
              <DialogTitle>Seleccionar Usuarios</DialogTitle>


              <div style={{ padding: '20px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => { setOpenExternalForm(true) }}
                >
                  Añadir Externo
                </Button>
              </div>


            </div>
            <DialogContent style={{ paddingTop: "0px", marginTop: "-16px" }}>
              <List style={{ paddingTop: "0px" }}>
                {
                  [...availableUsers, ...temporalNotToSign].map((item, index) => (
                    <ListItem
                      key={`${item.userId}-${index}`}
                      onClick={() => handleChange(item)}
                      style={{ paddingTop: '0px', paddingBottom: "0px" }}
                    >
                      <Checkbox
                        id={`check${item.userId}`}
                        checked={checked.some(some => some.userId === item.userId)}
                      />
                      <ListItemText primary={!!item.temporal ? `${item.name} (externo 1 vez)` : item.name} />
                    </ListItem>
                  ))
                }
              </List>
            </DialogContent>
            <DialogActions style={{ justifyContent: "flex-end" }} >
              <Button
                className="primary-text"
                color="primary"
                variant="outlined"
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddUsers}
              >
                Aceptar
              </Button>

            </DialogActions>
          </Dialog>
        )
      }

      {
        openExternalForm && (
          <FormTemporalUsers
            open={openExternalForm}
            onCancel={() => setOpenExternalForm(false)}
            submitActions={handleSubmitExternalUser}
            hideRfc
          />
        )
      }

      {
        openConfirmAlert && (
          <AlertConfirm
            open={openConfirmAlert}
            onCancel={() => setOpenConfirmAlert(false)}
            onConfirm={() => {
              dispatch(activityActions.deleteTemporalNoSigner(temporalSelected));
              setChecked(curr => curr.filter(el => el.userId !== temporalSelected))
              setTemporalSelected(null);
              setOpenConfirmAlert(false);
            }}
            textContent="El usuario se eliminará de la lista. ¿Deseas continuar?"
          />
        )
      }
    </>
  );

}
