import moment from "moment";

function firstToUpperCase(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function formatDate(date) {
  const dateFormatead = moment(date).format("MMM D YYYY, HH:mm:ss (Z [GMT])");
  return firstToUpperCase(dateFormatead);
}
