import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { userConstants } from "../core/constants";
import { getVaultsUser } from "../core/http/functionRequests";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { authActions } from "../core/actions";
import { tokenExpired } from "../core/utils";



export const useCompaniesUser = () => {
  
  const params = useParams();
  const companies = useSelector(state => state.userReducer.companies);
  const fetchingCompanies = useSelector(state => state.userReducer.fetchingCompanies);
  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);
  const dispatch = useDispatch();

 

  const getCompanies = async () => {
    try {
      const { vaultId } = params;
      dispatch({
        type: userConstants.FETCHING_COMPANIES,
      });

      const response = await getVaultsUser();

      dispatch({
        type: userConstants.SET_AVAILABLE_COMPANIES,
        payload: vaultId ? response?.filter(el => el.id !== vaultId) : response
      });

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
		if (isLoggedIn && tokenExpired()) {
			dispatch(authActions.userLoggedOut());
			return;
		}

		getCompanies();
		// eslint-disable-next-line
	}, []);


  return {
    companies,
    fetchingCompanies,
  }

}