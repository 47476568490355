import { axiosRequestBlockChain, authHeader } from '../../configs/axios.config';

export class ReviewersHttp {
  getReviewersByDocument(documentId, success, error) {
    axiosRequestBlockChain.get(`/document/reviewers?documentId=${documentId}`, {headers: authHeader()})
    .then(({data}) => success(data))
    .catch(err => error(err))
  }

  saveReview(review, success, error) {
    axiosRequestBlockChain.post(`/document/new-review`, review, {headers: authHeader()})
    .then(({data}) => success(data))
    .catch(err => error(err))
  }

  updateReview(review, success, error) {
    axiosRequestBlockChain.put(`/document/update-review`, review, {headers: authHeader()})
    .then(({data}) => success(data))
    .catch(err => error(err))
  }

  async isReviewed(documentId, success) {
    axiosRequestBlockChain.get(`/document/reviewers?documentId=${documentId}`, {headers: authHeader()})
      .then(({data}) => { 
        const user = data.Users.find(user => user.userId === sessionStorage.getItem('userId'));

        if (user) {
          success(user.statusReview);
        } else {
          success(false); 
        }
        
      })
      .catch(err => success(false));
  }
}