import React, { Component } from 'react';
import { uuid } from 'axeleratum-sgc-frontend-library';
import { Card, Grid, Button } from '@material-ui/core';
import AlertInfo from '@material-ui/lab/Alert';
import { VerticalCenterDiv } from 'axeleratum-sgc-frontend-library/components/vertical-center-div/vertical-center-div';

export class Schedule extends Component {

  color = { i: 0 }

  constructor(props) {
    super(props);

    this.state = {
      load: false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if (nextProps.load !== prevState.load) {
      return { load: nextProps.load };
    }
    else return null;
  }

  getColor() {
    let out = '';

    if (this.color.i == 3) {
      this.color.i = 0
    }

    switch (this.color.i) {
      case 0:
        out = '#729FF9';
      break;

      case 1:
        out = '#729FF9';
      break;

      case 2:
        out = '#729FF9';
      break;
    }

    this.color.i++;
    return out;
  }

  hasActivity(hour) {
    const { activities, onSelect } = this.props;

    if (activities) {
      let activityOut = [];
      activities.forEach((activity) => {
        if (hour == parseInt(activity.hour.split(':')[0])) {
          activityOut.push(activity);
        }
      });

      if (activityOut.length == 0) {
        return undefined;
      }

      return activityOut.map((item, index) => (
        <Card
          key={ uuid() }
          className="card-color"
          style={{ marginTop: index == 0 ? '' : '8px', background: this.getColor() }}
        >
          <div className="row p-3">
            <div className="col-md-10 col-xs-10 row">
              <div className="col-md-12 mb-2">
                <strong>{ item.name }</strong>
              </div>

              <div className="col-md-12">
                { item.description }
              </div>
            </div>

            <div className="col-md-1 col-xs-1 mt-3">
              <VerticalCenterDiv>
                <Button
                  className="card-color"
                  onClick={ () => onSelect && onSelect(item) }
                >
                  <i className="fa fa-arrow-right" />
                </Button>
              </VerticalCenterDiv>
            </div>
          </div>
        </Card>
      ));
    }

    return undefined;
  }

  getHours() {
    const { activities } = this.props;
    let lessDay = 7;

    if (activities) {
      activities.forEach((activity) => {
        if (parseInt(activity.hour.split(':')[0]) < 7) {
          lessDay = parseInt(activity.hour.split(':')[0]);
        }
      });
    }

    switch(lessDay) {
      case 1: return [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,0];
      case 2: return [2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,0];
      case 3: return [3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,0];
      case 4: return [4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,0];
      case 5: return [5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,0];
      case 6: return [6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,0];
    }

    return [7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,0]
  }

  render() {
    const { load } = this.state;

    return (
      <React.Fragment>
        {
          load ?
            <Grid container style={{ justifyContent: 'center', width: '100%' }}>
              <AlertInfo
                className="load-alert"
                severity="info"
              >
                Cargando calendario...
              </AlertInfo>
            </Grid>
          :
            <React.Fragment>
              {
                this.getHours()
                  .map((hour) => (
                    <div key={ uuid() } className="row">
                      <hr />
                      {
                        hour == 0 ?
                          <div className="col-md-1">
                            <label className="font-color">
                              24:00
                            </label>
                          </div>
                        :
                          <div className="col-md-1">
                            <label className="font-color">
                              { hour >= 10 ? hour : `0${hour}` }:00
                            </label>
                          </div>
                      }

                      <div className="ml-4 col-md-10">
                        { this.hasActivity(hour) }
                      </div>
                    </div>
                  ))
              }
              <hr />
            </React.Fragment>
        }
      </React.Fragment>
    );
  }

}
