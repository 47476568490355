const colorsJSON = {
  'primary-500': [
    '#F44336',
    '#2196F3',
    '#E91E63',
    '#3F51B5',
    '#9C27B0',
    '#03A9F4',
    '#673AB7',
    '#4CAF50',
    '#00BCD4',
    '#8BC34A',
    '#009688',
    '#CDDC39',
    '#FF9800',
    '#9E9E9E',
    '#FBC02D',
    '#795548',
    '#FF6F00'
  ],
  'basicColors': [
    '#FF4E3A',
    '#89D694',
    '#729FF9',
    '#AA63F0',
    '#89D694',
    '#AA63F0',
    '#FF4E3A',
    '#729FF9'
  ]
}

export default colorsJSON;
