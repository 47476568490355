import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton } from '@material-ui/core';
import uid from 'uid';
import PropTypes from 'prop-types';

export const MenuComponent = ({ buttonText, buttonType, items, onSelectOption, getCurrentTarget, deleted }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {
        buttonType === 'icon' ?
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={ handleClick }
            disabled={ deleted ? deleted : false }
          >
            { buttonText }
          </IconButton>
          :
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={ handleClick }
            disabled={ deleted ? deleted : false }
          >
            { buttonText }
          </Button>
      }

      <Menu
        id="simple-menu"
        anchorEl={ anchorEl }
        keepMounted
        open={ Boolean(anchorEl) }
        onClose={ handleClose }
      >
        {
          items && items.map(item => (
            <MenuItem
              key={ uid() }
              onClick={ (evt) => { handleClose();  
                onSelectOption && onSelectOption(item.value);
                getCurrentTarget && getCurrentTarget(evt) 
              } }
              disabled={ item.disabled ? item.disabled : false }
            >
              { item.label }
            </MenuItem>
          ))
        }
      </Menu>
    </div>
  );
}

MenuComponent.propTypes = {
  buttonText: PropTypes.any,
  buttonType: PropTypes.string,
  items: PropTypes.array,
  onSelectOption: PropTypes.func,
  getCurrentTarget: PropTypes.func,
  deleted: PropTypes.bool
}
