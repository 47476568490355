import React, { Component } from 'react';
import { Grid, Card, Badge, CardActionArea } from '@material-ui/core';
import { VerticalCenterDiv } from 'axeleratum-sgc-frontend-library/components/vertical-center-div/vertical-center-div';
import LockIcon from "@material-ui/icons/Lock"
import { uuid } from 'axeleratum-sgc-frontend-library';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import colorsJSON from '../../../assets/material-colors';
import './business-group.scss';

export class BusinessGroup extends Component {

  getColor(colors) {
    if (colors.i > 7) {
      colors.i = 0;
    }

    const out = colorsJSON.basicColors[colors.i];
    colors.i++;

    return out;
  }

  render() {
    const { business, baseUrl } = this.props;
    let colors = { i: 0 };

    return (
      <React.Fragment>
        {
          business.length === 0 ?
            <Grid container justifyContent="center">
              No se encuentran bóvedas registradas.
            </Grid>
            :
            business.map(business => (
              <Grid
                key={uuid()}
                className="BusinessGroup animate__animated animate__fadeIn"
                item
                md={3}
                sm={6}
                xs={12}
              >
                <Badge
                  className={`w-100 ${business.firmsToExpire ? business.pendingFirms ? 'badge' : 'badge-no-space' : ''}`}
                  badgeContent={business.firmsToExpire}
                  color="primary"
                  title={business.firmsToExpire ? 'Actividades pendientes.' : ''}
                >
                  <Badge
                    className={`w-100 ${business.pendingFirms ? 'badge-secondary' : ''}`}
                    badgeContent={business.pendingFirms}
                    color="primary"
                    title={business.pendingFirms ? 'Documentos enviados a firma.' : ''}
                  >
                    <Link className="w-100" to={`${baseUrl}${business.id}`}>
                      <Card className="card pointer">
                        <CardActionArea className="action-area" style={{ height: '100%' }}>
                          <VerticalCenterDiv>
                            <Grid container>
                              <Grid item md={4} sm={5} xs={5}>
                                <VerticalCenterDiv className="icon text-center ml-3" style={{ background: this.getColor(colors) }}>
                                  <LockIcon />
                                </VerticalCenterDiv>
                              </Grid>

                              <Grid item md={6} sm={6} xs={6}>
                                <VerticalCenterDiv className="text">
                                  {business.name}
                                </VerticalCenterDiv>
                              </Grid>
                            </Grid>
                          </VerticalCenterDiv>
                        </CardActionArea>
                      </Card>
                    </Link>
                  </Badge>
                </Badge>
              </Grid>
            ))
        }
      </React.Fragment>
    );
  }
}

BusinessGroup.propTypes = {
  business: PropTypes.array,
  baseUrl: PropTypes.string
}