import { Button, Grid, Box } from "@material-ui/core";
import React, { Component } from "react";
import "react-dropzone-uploader/dist/styles.css";
import { DocumentsHttp } from "../../../../core/http/documents.http";
import ViewFile from "../../../vault/view-file/view-file";
import { getBase64 } from "../../../../core/utils/convert-file";
import { uuid, Alert } from "axeleratum-sgc-frontend-library";
import { FormBussines } from "./form-bussines";
import { ItemFile } from "./item-file";
import { tokenExpired } from "../../../../core/utils";
import { authActions } from "../../../../core/actions";
import { connect } from "react-redux";

export class DropZoneNative extends Component {
  documentsHttp = new DocumentsHttp();

  constructor(props) {
    super(props);

    this.state = {
      viewFileOpen: false,
      formBussinesOpen: false,
      temFileUpload: [],
      openAlert: false,
      message: "",
      typeMessage: "",
    };
  }

  addLocalFile(file, companyId) {
    const { isLoggedIn, userLoggedOut } = this.props;

    if (isLoggedIn && tokenExpired()) {
      userLoggedOut()
      return
    }



    const { onAddFile } = this.props;
    this.documentsHttp
      .existDocument(companyId, "", file[0].name)
      .then(() => {
        this.setState({
          temFileUpload: [],
          formBussinesOpen: false,
          openAlert: true,
          message: "El archivo ya existe",
          typeMessage: "error",
        });
      })
      .catch(() => {
        getBase64(file[0], (result) => {
          this.setState({ temFileUpload: [], formBussinesOpen: false });

          console.log("onAddFile: ", {
            type: "local",
            companyId,
            name: file[0].name,
            file: result,
            size: file[0].size,
            extension:
              file[0].name.split(".").length > 1
                ? `.${file[0].name.split(".")[1]}`
                : "",
            owner: sessionStorage.getItem("userId"),
            users: [],
          });

          onAddFile({
            type: "local",
            companyId,
            name: file[0].name,
            file: result,
            size: file[0].size,
            extension:
              file[0].name.split(".").length > 1
                ? `.${file[0].name.split(".")[1]}`
                : "",
            owner: sessionStorage.getItem("userId"),
            users: [],
          });
        });
      });
  }

  addFile(file, fileType, companyId) {
    const { onAddFile } = this.props;

    //Aquí se añaden para verse y salir
    if (fileType === "local") {
      this.addLocalFile(file, companyId);
    } else {
      onAddFile({
        type: "cloud",
        name: file.Name,
        file: { documentId: file._id, name: file.Name },
        users: [],
      });
    }
  }

  // specify upload params and url for your files
  getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  // called every time a file's `status` changes
  handleChangeStatus = ({ meta, file, remove }, status) => {
    const { temFileUpload } = this.state;
    const { handleLoad } = this.props;
    if (status === "preparing") {
      handleLoad(true);
    } else if (status === "done") {
      handleLoad(false);
      this.setState({
        formBussinesOpen: true,
        temFileUpload: [...temFileUpload, ...[file]],
      });
      remove();
    }

    console.log(status, meta, file);
  };

  render() {
    const { type, files, onAddUsers, onRemoveItem, hideSearchFile } = this.props;
    const {
      viewFileOpen,
      formBussinesOpen,
      temFileUpload,
      openAlert,
      message,
      typeMessage,
    } = this.state;

    return (
      <Grid container direction="column">
        <ViewFile
          open={viewFileOpen}
          onClose={() => this.setState({ viewFileOpen: false })}
          onSelectFile={(file) => this.addFile(file)}
          type={type === "firm" ? "pdf" : null}
        />

        <FormBussines
          open={formBussinesOpen}
          onSubmit={(formData) =>
            this.addFile(temFileUpload, "local", formData)
          }
          onCancel={() => this.setState({ formBussinesOpen: false })}
        />

        <Alert
          open={openAlert}
          title={message}
          onConfirm={() => this.setState({ openAlert: false })}
          type={typeMessage}
        />

        <Box>
          {files.map((file) => (
            <ItemFile
              hideRemoveButton={hideSearchFile}
              disabled
              key={uuid()}
              fileId={
                file.documentName ? file.documentId : file.file.documentId
              }
              name={file.name}
              users={file.reviewers}
              type={type}
              checked={file.withOrder}
              onRemoveItem={(name) => onRemoveItem(name)}
              onAddUsers={(users, check) => onAddUsers(file.name, users, check)}
              bpmStatus={file.statusBpm}
            />
          ))}
        </Box>
        {
          !hideSearchFile && (
            <Box className="drop-zone" p={2} my={2} textAlign="center">
              <Button
                disabled={files.length > 0}
                className={"font-color mt-1 button-drop-zone-position"}
                variant="outlined"
                onClick={() => this.setState({ viewFileOpen: true })}
              >
                Busca en la bóveda
              </Button>
            </Box>
          )
        }
      </Grid>
    );
  }
}


const mapStateToProps = state => {
  return {
    isLoggedIn: state.authReducer.loggedIn,
  }
}

const mapDispatchToProps = {
  userLoggedOut: authActions.userLoggedOut,
}

export default connect(mapStateToProps, mapDispatchToProps)(DropZoneNative);
