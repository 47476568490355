import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import {
  IconButton,
  Grid,
  Breadcrumbs,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Header from '../../components/dashboard/header';
import { Link } from "react-router-dom";
import { ButtonMenu } from '../../components/button-menu/ButtonMenu';
import { getCurrentUser } from '../../core/helpers';
import { uuid } from 'axeleratum-sgc-frontend-library';
import { useDispatch, useSelector } from 'react-redux';
import { tokenExpired } from '../../core/utils';
import { authActions } from '../../core/actions';
import { deleteSpreadSheetChart, getChartById, getSpreadSheetChart, updateSpreadCheetChart } from '../../core/http/functionRequests/business-management';
import { TableData } from '../../components/Tables/Business-management/TableData';


const Shareholders = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const [chart, setChart] = useState(null);
  const [spreadSheet, setSpreadSheet] = useState(null)
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mockOptionsMenu, setmockOptionsMenu] = useState([]);
  const [allowEdit, setAllowEdit] = useState(false);

  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);

  const deleteSpreadSheet = async () => {
    try {
      await deleteSpreadSheetChart(spreadSheet)
    } catch (error) {
      console.error(error);
    }
  }

  const updateSpreadSheet = async () => {
    try {
      setLoading(true)
      const updateChart = await updateSpreadCheetChart(chart.id, {
        spreadSheetId: spreadSheet
      })
      setChart(updateChart)
      setSpreadSheet(null)
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }

  const getSpreadSheetId = async () => {
    try {
      setLoading(true)
      const { spreadSheetId } = await getSpreadSheetChart(chart.id, chart.name)
      setSpreadSheet(spreadSheetId)
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }

  const handleAction = (action) => {
    if (action === 'edit') {
      setEditMode(true)
      getSpreadSheetId()
    }

    if (action === 'save') {
      setEditMode(false)
      updateSpreadSheet()
    }
  }

  const requestChart = async () => {
    try {
      setLoading(true)

      const { chartId } = params;
      const requestChart = await getChartById(chartId)
      setChart(requestChart)

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }
    setmockOptionsMenu([
      { label: "Editar", value: "edit", disabled: editMode },
      { label: "Guardar", value: "save", disabled: !editMode },
    ])
  }, [editMode])

  useEffect(() => {
    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }
    const { hasBusinessMgmt, authorities } = getCurrentUser();

    if (
      !hasBusinessMgmt || (
        !authorities.includes("Crear Gobierno Corporativo") &&
        !authorities.includes("Consultar Gobierno Corporativo") &&
        !authorities.includes("Editar Gobierno Corporativo")
      )
    ) {
      return history.push("/dashboard/home")
    }
    setAllowEdit(authorities.includes("Editar Gobierno Corporativo") || authorities.includes("Crear Gobierno Corporativo"))
    requestChart()
  }, [])

  useEffect(() => {
    return () => {
      if (spreadSheet) {
        deleteSpreadSheet()
      }
    }
  }, [spreadSheet])


  return (
    <>
      <Header
        titleLeft={chart ? chart.name : ''}
        titleRight={chart ? chart.businessId.name : ''}
        actions={[
          <IconButton variant='outlined'>
            <NotificationsIcon color='primary' />
          </IconButton>,
          <IconButton variant='outlined'>
            <EventIcon color='primary' />
          </IconButton>,
        ]}
      />
      <Grid container spacing={3}>
        {
          chart ? (
            <Grid container item>
              <Grid item xs>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                  <Link color='inherit' to={`/dashboard/business-management/${chart.businessId.id}`}>
                    {chart.businessId.name}
                  </Link>
                  <span>{chart.name}</span>
                </Breadcrumbs>
              </Grid>
              {
                allowEdit ? (
                  <Grid item>
                    <ButtonMenu
                      name={"+ Operaciones"}
                      buttonStyle={{ variant: "contained", color: "primary" }}
                      menuItems={mockOptionsMenu}
                      handleClick={handleAction}
                    />
                  </Grid>
                ) : <></>
              }
            </Grid>
          ) : <></>
        }
        {
          (!editMode && !loading && chart?.data) ? (
            <Grid item>
              <TableData
                values={chart?.data ?? []}
              />
            </Grid>
          ) : <></>
        }
        {
          (!editMode && !loading && !chart?.data) ? (
            <Grid item>
              <span>No hay datos guardados</span>
            </Grid>
          ) : <></>
        }
        {
          (editMode && !loading && spreadSheet) ? (
            <Grid item xs={12}>
              <iframe
                src={`https://docs.google.com/spreadsheets/d/${spreadSheet}/`}
                style={{ width: '100%', height: '100vh' }}
              >
              </iframe>
            </Grid>
          ) : <></>
        }
        {
          loading && (
            <Grid item xs={12}>
              <span>Cargando datos...</span>
            </Grid>
          )
        }

      </Grid >
    </>
  )
}

export default Shareholders