import React, { Component } from 'react';
import { Button, IconButton, Grid, TextField, InputAdornment, Menu, MenuItem, InputLabel, Select, Box, Dialog } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AlertInfo from "@material-ui/lab/Alert";
import { TagTemplateFilter, CardTemplate, AlertConfirm, Alert, FilterIcon, uuid } from 'axeleratum-sgc-frontend-library';
import FormTemplate from './form-template';
import img from '../../assets/img/IconoKasia.jpg';
import './templates.scss';
import { TemplatesHttp } from '../../core/http/templates.http';
import { validatePermissions, permissionScheme } from '../../core/utils/can-i';
import TemplateUsage from './template-usage';
import { connect } from 'react-redux';
import { DocumentsHttp } from '../../core/http/documents.http';
import saveAs from 'file-saver';
import { isExpired, sortData, timeAgo, tokenExpired } from '../../core/utils';
import { FoldersHttp } from '../../core/http/folders.http';
import { BusinessHttp } from '../../core/http/business.http';
import { handleErrorAxios } from '../../core/helpers';
import { authActions } from '../../core/actions';
import { getVaultById } from '../../core/http/functionRequests';

class TemplateList extends Component {

  filters = {
    name: '',
    author: '',
    lastModification: '',
    tags: 'Todos'
  };

  templatesHttp = new TemplatesHttp();
  documentsHttp = new DocumentsHttp();
  foldersHttp = new FoldersHttp();
  businessHttp = new BusinessHttp();

  constructor(props) {
    super(props);

    this.state = {
      business: null,
      templateList: null,
      templateListFiltered: null,
      templateSelected: null,
      tagList: [],
      authors: [],
      tagSelected: 'Todos',
      anchorEl: null,
      openForm: false,
      filters: this.filters,
      formFilters: this.filters,
      querySearch: '',
      openAlertDelete: false,
      openTemplateUsage: false,
      templatesFolder: null,
      alertStatus: false,
      alertStatusError: { show: false, message: '' },
      addTemplateIsEnabled: false,
      documentTypes: [],
    }
  }

  componentDidMount() {
    this.findBusinessById();
    this.getTemplateFolder();
    this.getPermissions();
    this.getDocumentTypes()
  }

  getPermissions() {
    let setMenuItem = []
    validatePermissions((permissionScheme.templateManagement.addTemplate)).then(enabled => {
      this.setState({ addTemplateIsEnabled: enabled });
    });


    validatePermissions((permissionScheme.templateManagement.useTemplate)).then(enabled => {
      this.setState({ useTemplateIsEnabled: enabled });

      setMenuItem.push({
        label: 'Utilizar',
        value: 'use',
        disabled: !enabled
      })

    });
    validatePermissions((permissionScheme.templateManagement.editTemplate)).then(enabled => {
      this.setState({ editTemplateIsEnabled: enabled });
      setMenuItem.push({
        label: 'Descargar',
        value: 'download',
        disabled: !enabled
      })
    });
    validatePermissions((permissionScheme.templateManagement.deleteTemplate)).then(enabled => {
      this.setState({ deleteTemplateIsEnabled: enabled });

      setMenuItem.push({
        label: 'Eliminar',
        value: 'delete',
        style: {
          color: 'red'
        },
        disabled: !enabled
      })

      this.setState({ menuItem: setMenuItem });
    });
  }

  findBusinessById() {
    const { isLoggedIn, userLoggedOut } = this.props;

    if (isLoggedIn && tokenExpired()) {
      userLoggedOut()
      return
    }
    getVaultById(this.props.business.id)
      .then(data => this.setState({ business: data }))
      .catch(err => console.error(err))
  };

  findAllTags(tagList = []) {
    let tagsCount = tagList.reduce((acc, val) => {
      acc[val] = acc[val] === undefined ? 1 : acc[val] += 1;
      return acc;
    }, {});

    let tags = [];

    Object.entries(tagsCount).forEach(([key, value]) => {
      const tag = {
        value: key,
        label: key,
        count: value
      }

      tags.push(tag);
    });

    this.setState({ tagList: tags });
  }

  getTemplateFolder() {
    const { isLoggedIn, userLoggedOut } = this.props;

    if (isLoggedIn && tokenExpired()) {
      userLoggedOut()
      return
    }
    this.foldersHttp.getContractFolderByCompanyId(this.props.business.id, 'Plantillas', (resp) => {
      if (resp && resp._id) {
        this.setState({ templatesFolder: resp });
        this.findTemplates();
      } else {
        this.createFolder();
      }
    }, error => {
    });
  };

  createFolder() {
    const { isLoggedIn, userLoggedOut } = this.props;

    if (isLoggedIn && tokenExpired()) {
      userLoggedOut()
      return
    }
    this.foldersHttp.createFolder(this.props.business.id, 'Plantillas',
      data => {
        this.getTemplateFolder();
      }, error => {
        this.setState({
          openDialog: false,
          alertError: true,
          messageError: `Error al crear carpeta de la bóveda.`
        });
      }
    );
  }

  findTemplates() {
    const { isLoggedIn, userLoggedOut } = this.props;

    if (isLoggedIn && tokenExpired()) {
      userLoggedOut()
      return
    }
    this.templatesHttp.getAllTemplates(this.props.business.id, data => {
      let tagList = [];

      const documents = sortData(data.documents.map(document => {
        if (document.Tags !== null && document.Tags.length > 0) {
          tagList = tagList.concat(document.Tags);
        }

        return {
          name: document.Name,
          id: document._id,
          isFolder: false,
          type: 'file',
          onlyExistInMongo: false,
          onlyExistInKaleido: false,
          hash: document.Hash,
          author: document.UserBy ? document.UserBy.completeName : 'Desconocido',
          lastUpdated: document.UpdatedAt ? timeAgo.format(new Date(document.UpdatedAt)) : '',
          lastModification: document.UpdatedAt,
          tags: document.Tags,
          ownerId: document.Owner ? document.Owner : 'Desconocido',
          nameDisplay: document.NameDisplay,
          // extension: /(?:\.([^.]+))?$/.exec(document.name)[0],
          extension: document.Extension,
          hasThumbnail: false,
          urlThumbnail: ''
        }
      }));

      const authors = [];
      documents.forEach(item => {
        if (!authors.some(author => author.value === item.ownerId)) {
          authors.push({ label: item.author, value: item.ownerId })
        }
      });

      this.findAllTags(tagList);
      this.setState({
        templateList: [...documents],
        templateListFiltered: [...documents],
        authors: authors,
        formFilters: this.filters,
      }, () => this.getImages());
    }, error => { });
  }

  getImages() {
    const { templateList, templateListFiltered } = this.state;

    templateList.forEach(item => {
      this.downloadThumbnail(item);
    })
  }

  downloadThumbnail(template) {
    const { templateList, templateListFiltered } = this.state;
    const params = {
      name: `${template.id}.jpg`
    };
    const { isLoggedIn, userLoggedOut } = this.props;

    if (isLoggedIn && tokenExpired()) {
      userLoggedOut()
      return
    }
    this.documentsHttp.downloadThumbnail(params, (resp) => {
      if (resp.status === 200) {
        const url = URL.createObjectURL(
          new Blob([resp.data], {
            type: 'application/jpg',
          })
        );

        template.urlThumbnail = url;
        template.hasThumbnail = true;

        this.setState({ templateListFiltered: [...templateList] });
      }
    }, (error) => {
    });
  }

  getDocumentTypes() {
    const { isLoggedIn, userLoggedOut } = this.props;

    if (isLoggedIn && tokenExpired()) {
      userLoggedOut()
      return
    }
    this.documentsHttp.getDocumentTypes((resp) => {
      const documentTypes = resp.map(item => ({ label: item.Name, value: item._id }));
      this.setState({ documentTypes: documentTypes });
    }, (error) => { });
  }

  saveTemplate(template) {
    const { business } = this.props;
    const { templatesFolder, documentTypes } = this.state;
    const formD = new FormData();
    const documentTypeId = documentTypes.find(item => item.label === 'Documento genérico').value;


    if (template.file) {
      formD.append('document', template.file[0]);
      formD.append('extension', /(?:\.([^.]+))?$/.exec(template.file[0].path)[0])
      formD.append('size', template.file[0].size)
    } else {
      this.setState({ alertStatusError: { show: true, message: 'Es necesario cargar un archivo.' } })
      return
    }

    // if (template.image) {
    //   formD.append('image', template.image[0])
    // }

    formD.append('name', template.name ? template.name.trim() : '');
    // formD.append('documentTypeId', template.documentType);
    formD.append('documentTypeId', documentTypeId);
    formD.append('path', 'Plantillas');
    formD.append('companyId', business.id)
    formD.append('folderId', templatesFolder._id)
    formD.append('owner', this.props.currentUser.userId)

    if (template.tags) {
      template.tags.forEach((item, index) => {
        formD.append(`tags[${index}]`, item.value)
      })
    }
    const { isLoggedIn, userLoggedOut } = this.props;

    if (isLoggedIn && tokenExpired()) {
      userLoggedOut()
      return
    }
    this.templatesHttp.createTemplate(formD,
      data => {
        this.setState({ openForm: false, alertStatus: true });
      },
      error => {
        const msg = handleErrorAxios(error);
        this.setState({
          openForm: false,
          alertStatusError: { show: true, message: `Ocurrió un error al guardar. ${msg}` }
        });
      }
    )
  }

  updateTemplate(template) {
    const { business } = this.props;
    const { templateSelected, templatesFolder } = this.state;


    const formD = new FormData();

    // formD.append('documentTypeId', template.documentType);
    formD.append('documentTypeId', '60d1faed675d660946c8e9ed');
    formD.append('path', 'Plantillas')
    formD.append('folderId', templatesFolder._id)
    formD.append('owner', this.props.currentUser.userId)
    formD.append('companyId', business.id)

    if (template.tags) {
      template.tags.forEach((item, index) => {
        formD.append(`tags[${index}]`, item.value)
      })
    }

    // if (template.image) {
    //   formD.append('image', template.image[0])
    // }

    if (template.file) {
      const auxName = templateSelected.name.split('.');
      const extension = auxName[auxName.length - 1];

      formD.append('document', template.file[0]);
      formD.append('extension', /(?:\.([^.]+))?$/.exec(template.file[0].path)[0])
      formD.append('size', template.file[0].size)
      formD.append('name', template.name);
      formD.append('oldName', templateSelected.name)
      formD.append('oldExtension', extension)

      const { isLoggedIn, userLoggedOut } = this.props;

      if (isLoggedIn && tokenExpired()) {
        userLoggedOut()
        return
      }
      this.templatesHttp.createTemplate(formD,
        data => {
          this.setState({ openForm: false, alertStatus: true });
        },
        error => {
          const msg = handleErrorAxios(error);
          this.setState({
            openForm: false,
            alertStatusError: { show: true, message: `Ocurrió un error al guardar. ${msg}` }
          });
        }
      );
    } else {
      formD.append('documentId', templateSelected.id);
      const { isLoggedIn, userLoggedOut } = this.props;

      if (isLoggedIn && tokenExpired()) {
        userLoggedOut()
        return
      }
      this.templatesHttp.updateTemplate(formD,
        data => {
          this.setState({ openForm: false, alertStatus: true });
        },
        error => {
          this.setState({
            openForm: false,
            alertStatusError: { show: true, message: `Ocurrió un error al guardar.` }
          });
        }
      );
    }
  }

  deleteTemplate() {
    const { isLoggedIn, userLoggedOut } = this.props;

    if (isLoggedIn && tokenExpired()) {
      userLoggedOut()
      return
    }
    const { templateSelected, templatesFolder } = this.state;
    const dataDocument = {
      documentId: templateSelected.id,
      path: 'Plantillas',
      name: templateSelected.name,
      companyId: this.props.business.id,
      owner: this.props.currentUser.userId
    }

    this.setState({ openAlertDelete: false });


    this.templatesHttp.deleteTemplate(dataDocument,
      (resp) => {
        this.setState({ templateSelected: null });
        this.findTemplates();
      },
      (error) => {
        this.setState({
          templateSelected: null,
          alertStatusError: { show: true, message: `Ocurrió un error al eliminar.` }
        });
      }
    );
  }

  downloadTemplate(template) {
    const { isLoggedIn, userLoggedOut } = this.props;

    if (isLoggedIn && tokenExpired()) {
      userLoggedOut()
      return
    }
    const params = {
      documentId: template.id,
      path: `Plantillas/${template.name}`,
      companyId: this.props.business.id,
      owner: this.props.currentUser.userId
    };


    this.documentsHttp.downloadDocument(params, (resp) => {
      const blob = new Blob([resp.data], { type: 'application/octet-stream' });
      saveAs(blob, `${template.name}${template.extension}`);
    }, (error) => {
      this.setState({ alertStatusError: { show: true, message: `Ocurrió un error al descargar la plantilla. Intente más tarde.` } })
    });
  }

  onTagSelected(tagSelected) {
    const { templateList } = this.state;
    const templatesFiltered = tagSelected === 'Todos' ? [...templateList] :
      templateList.filter((template) => template.tags && template.tags.some(tag => tag === tagSelected));

    this.setState({ tagSelected: tagSelected, templateListFiltered: templatesFiltered });
  }

  handleSearch = (filters, query) => {
    const { templateList } = this.state;
    const name = query !== '' ? query.toLowerCase() : filters.name.toLowerCase();
    const templatesFiltered = templateList.filter(template =>
      (filters.tags === 'Todos' || (template.tags && template.tags.some(tag => tag === filters.tags)))
      && (filters.author === '' || template.ownerId === filters.author)
      && (filters.lastModification === '' || template.lastModification === filters.lastModification)
      && template.name.toLowerCase().indexOf(name) !== -1
    );

    this.setState({
      tagSelected: filters.tags,
      templateListFiltered: templatesFiltered,
      filters: filters,
    });
  }

  handleSort = (evt) => {
    this.setState({ anchorEl: evt.currentTarget })
  }

  templateOptionSelected(option, template) {
    switch (option) {
      case 'use':
        this.setState({ openTemplateUsage: true });
        break;
      case 'download':
        this.downloadTemplate(template);
        break;
      case 'clone':
        this.cloneTemplate(template.id);
        break;
      case 'delete':
        this.setState({ openAlertDelete: true });
        break;
      default:
        break;
    }
  }

  renderFilterTags() {
    const {
      templateList,
      tagList,
      tagSelected,
      addTemplateIsEnabled
    } = this.state;

    return (
      <Grid container direction="row">
        <Grid className="animate__animated animate__fadeIn"
          item
          container
          sm={10}
          direction="row"
        >
          <Box key={`tag-filter-all`} mr={1.5} mb={1}>
            <TagTemplateFilter
              label="Todos"
              selected={tagSelected === 'Todos'}
              count={templateList ? templateList.length : 0}
              onClick={() => {
                this.onTagSelected('Todos');
              }}
            />
          </Box>
          {
            (templateList && templateList.length > 0) &&
            tagList.map((tag, index) => {
              return (
                <Box key={`tag-filter-${index}`} mr={1.5} mb={1}>
                  <TagTemplateFilter
                    label={tag.label}
                    selected={tagSelected === tag.label}
                    count={tag.count}
                    onClick={() => {
                      this.onTagSelected(tag.label);
                    }}
                  />
                </Box>
              )
            })
          }
        </Grid>
        <Grid item xs={12} sm={2} className="text-right">
          <Button
            disabled={!addTemplateIsEnabled}
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => { this.setState({ openForm: true, templateSelected: null }) }}
          >
            + Nueva Plantilla
          </Button>
        </Grid>
      </Grid>
    );
  }

  renderTemplatesList() {
    const {
      templateList, templateListFiltered, menuItem, editTemplateIsEnabled
    } = this.state;

    return (
      <React.Fragment>
        {
          !templateList &&
          <Grid container style={{ justifyContent: 'center', width: '100%' }}>
            <AlertInfo severity="info">Cargando plantillas...</AlertInfo>
          </Grid>
        }

        {
          templateList && templateList.length === 0 &&
          <Grid container style={{ justifyContent: 'center', width: '100%' }}>
            <AlertInfo severity="info">No hay plantillas registradas</AlertInfo>
          </Grid>
        }

        {
          (templateList && templateList.length > 0) &&
          <Grid container direction="row" spacing={2}>
            {
              templateListFiltered.map((template, index) => {
                return (
                  <Grid item key={index}>
                    <CardTemplate
                      img={template.hasThumbnail ? template.urlThumbnail : img}
                      name={`${template.nameDisplay}${template.extension}`}
                      menuItems={menuItem}
                      onClick={() => {
                        if (editTemplateIsEnabled) {
                          this.setState({ openForm: true, templateSelected: template })
                        }
                      }}
                      onOptionSelected={(data) => {
                        this.templateOptionSelected(data, template);
                        this.setState({ templateSelected: template })
                      }}
                    />
                  </Grid>
                )
              })
            }
          </Grid>
        }
      </React.Fragment>
    );
  }

  renderForm() {
    const {
      openForm,
      templateSelected
    } = this.state;

    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openForm}
      >
        <FormTemplate
          templateSelected={templateSelected}
          submitActions={(formData) => templateSelected ? this.updateTemplate(formData) : this.saveTemplate(formData)}
          onCancel={() => this.setState({ openForm: false })}
        />
      </Dialog>
    );
  }

  renderTemplateUsage() {
    const { openTemplateUsage, templateSelected } = this.state;

    return (
      <TemplateUsage
        title={templateSelected ? `${templateSelected.nameDisplay}` : ''}
        business={this.state.business}
        currentUser={this.props.currentUser}
        templateSelected={templateSelected}
        open={openTemplateUsage}
        onClose={() => { this.setState({ openTemplateUsage: false }) }}
      />
    );
  }

  render() {
    const {
      business,
      anchorEl,
      openAlertDelete,
      openTemplateUsage,
      alertStatus,
      alertStatusError,
      templatesFolder,
      tagList,
      authors,
      formFilters,
      querySearch,
    } = this.state;

    return (
      <React.Fragment>
        {
          business &&
          this.renderFilterTags()
        }
        <Grid container className="pt-2">
          <Grid item xs>
            <TextField
              label="¿Qué buscas?"
              variant="outlined"
              value={querySearch}
              onChange={(evt) => {
                this.handleSearch(this.state.filters, evt.target.value);
                this.setState({
                  querySearch: evt.target.value,
                  formFilters: { ...this.state.formFilters, name: evt.target.value }
                });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              margin="dense"
              fullWidth
            ></TextField>
          </Grid>
          <Grid className="text-right" item xs={2} sm={1}>
            <IconButton variant="outlined" color="secondary" onClick={(evt) => this.handleSort(evt)}>
              <FilterIcon color="primary" />
            </IconButton>
          </Grid>
        </Grid>
        <Menu
          id="simple-menu-filter-template"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => this.setState({ anchorEl: null, formFilters: this.state.filters })}
        >
          <Box p={2}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Grid container item xs={12} direction="column">
                  <InputLabel>Nombre</InputLabel>
                  <TextField
                    id="outlined-required"
                    value={formFilters.name ? formFilters.name : ''}
                    variant="outlined"
                    margin="dense"
                    onChange={(evt) => this.setState({ formFilters: { ...this.state.formFilters, name: evt.target.value } })}
                  />
                </Grid>
                <Grid container item xs={12} direction="column">
                  <InputLabel>Autor</InputLabel>
                  <Select
                    onChange={(evt) => this.setState({ formFilters: { ...this.state.formFilters, author: evt.target.value } })}
                    value={formFilters.author ? formFilters.author : ''}
                    name="author"
                    variant="outlined"
                    margin="dense"
                  >
                    <MenuItem value="">
                      <em>Todos</em>
                    </MenuItem>
                    {authors.length > 0 &&
                      authors.map((item, index) =>
                        <MenuItem key={index} value={item.value}>
                          <em>{item.label}</em>
                        </MenuItem>
                      )
                    }
                  </Select>
                </Grid>
                <Grid container item xs={12} direction="column">
                  <InputLabel>Fecha última modificación</InputLabel>
                  <TextField
                    type="date"
                    value={formFilters.lastModification}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(evt) => this.setState({
                      formFilters: { ...this.state.formFilters, lastModification: evt.target.value }
                    })
                    }
                    variant="outlined"
                    margin="dense"
                  />
                </Grid>
                <Grid container item xs={12} direction="column">
                  <InputLabel>Etiqueta</InputLabel>
                  <Select
                    onChange={(evt) => this.setState({ formFilters: { ...this.state.formFilters, tags: evt.target.value } })}
                    value={formFilters.tags ? formFilters.tags : ''}
                    name="tags"
                    variant="outlined"
                    margin="dense"
                  >
                    <MenuItem value="Todos">
                      <em>Todos</em>
                    </MenuItem>
                    {tagList.length > 0 &&
                      tagList.map((item, index) =>
                        <MenuItem key={index} value={item.value}>
                          <em>{item.label}</em>
                        </MenuItem>
                      )
                    }
                  </Select>
                </Grid>
              </Grid>
              <Grid item>
                <div className="text-center">
                  <Button
                    type="button"
                    variant="outlined"
                    color="primary"
                    onClick={() => this.setState({
                      anchorEl: null,
                      filters: this.filters,
                      formFilters: this.filters,
                    }, this.handleSearch(this.filters, this.state.querySearch))
                    }
                  >
                    Reestablecer
                  </Button>

                  <Button
                    className="ml-2"
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.setState({ anchorEl: null, querySearch: '' })
                      this.handleSearch(this.state.formFilters, this.state.querySearch)
                    }
                    }
                  >
                    Aceptar
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Menu>

        {
          business &&
          <Box mt={3}>
            {this.renderTemplatesList()}
          </Box>
        }

        {this.renderForm()}

        {openTemplateUsage && this.renderTemplateUsage()}

        <AlertConfirm
          open={openAlertDelete}
          onCancel={() => this.setState({ openAlertDelete: false })}
          onConfirm={() => this.deleteTemplate()}
          textContent="La plantilla seleccionada será eliminada. ¿Deseas continuar?"
        />

        <Alert
          open={alertStatus}
          title={`Se ha guardado correctamente.`}
          type="success"
          onConfirm={() => {
            this.setState({ alertStatus: false });
            this.findTemplates();
          }}
        />

        <Alert
          open={alertStatusError.show}
          title={alertStatusError.message}
          type="error"
          onConfirm={() => {
            this.setState({
              alertStatusError: { show: false, message: '' },
            })
          }}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.authReducer.currentUser,
    isLoggedIn: state.authReducer.loggedIn
  }
}

const mapDispatchToProps = {
  userLoggedOut: authActions.userLoggedOut,
}


export default connect(mapStateToProps, mapDispatchToProps)(TemplateList);