import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import ClearIcon from '@material-ui/icons/Clear';


const useStyles = makeStyles(() => (
  {
    root: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#5C7BF0',
      borderRadius: '6px',
      paddingLeft: '12px',
      paddingRight: '12px',
      paddingTop: '5px',
      paddingBottom: '5px',
      color: 'white',
      margin: '5px',
      '& span': {
        cursor: 'pointer'
      }
    },
    tagSelected: {
      backgroundColor: 'white',
      color: '#5C7BF0',
      border: '1px solid'
    },
    clearIcon: {
      color: 'white',
      marginLeft: '5px',
      cursor: 'pointer'
    }
  }
))

export function TagFilter(props) {
  const classes = useStyles()
  const { label, onClear, onClick, tagSelected } = props

  return (
    <div className={clsx({
      [classes.root]: true,
      [classes.tagSelected]: tagSelected,
    })}
    >
      <span onClick={onClick}>{label}</span>

      {tagSelected &&
        <ClearIcon
          fontSize='small'
          className={classes.clearIcon}
          onClick={onClear}
          style={{ color: tagSelected ? '5C7BF0' : '' }}
        />
      }

    </div>
  )
}

TagFilter.propTypes = {
  label: PropTypes.string,
  onClear: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  tagSelected: PropTypes.bool
}