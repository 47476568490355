import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { editBusiness, getBusinessById } from '../../../core/http/functionRequests/business-management';
import { InputForm } from './InputForm';
import './businessManagement.scss'
import { SimpleSelect } from '../../select/SimpleSelect';
import { uuid } from 'axeleratum-sgc-frontend-library';

const options = [
  { value: "string", label: "Texto" },
  { value: "number", label: "Número" },
  { value: "date", label: "Fecha" },
]


export const DialogFormEditBusinessMgmt = (props) => {
  const { open, onClose, setOpenBackDrop, openAlert, onComplete, businessId, creator } = props;

  const [customForm, setCustomForm] = useState([])
  const [errors, setErrors] = useState([])
  const [showDialog, setShowDialog] = useState(true)
  const [loading, setLoading] = useState(false)

  const editBusinessMgmt = async () => {
    try {
      if (loading) return
      const body = {
        name: customForm[0].value,
        fields: customForm,
      }

      setShowDialog(false)
      setOpenBackDrop(true)
      const response = await editBusiness(businessId, body)
      onComplete()
      openAlert(response, "success")
      onClose()
      setOpenBackDrop(false)

    } catch (error) {
      openAlert(error, "error")
      setShowDialog(true)
      setLoading(false)
      setOpenBackDrop(false)
    }
  }

  const validateField = (element, fieldType) => {
    if (fieldType) {
      const objError = { [fieldType]: "" }
      if (fieldType === "label") {
        if (!element.label) {
          objError.label = "El campo es obligatorio"
        }

        return objError
      }

      if (!element.value) {
        objError.value = "El campo es obligatorio"
      } else if (element.type === "number") {
        const regex = new RegExp(/^\d+$/);
        if (!regex.test(element.value)) {
          objError.value = "El tipo de dato es número"
        }
      } else if (element.type === "date") {
        const regex = new RegExp(/^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/)
        if (!regex.test(element.value)) {
          objError.value = "El formato de fecha es dd-mm-yyyy"
        }
      }

      return objError

    }

    const objError = { label: "", value: "" }

    if (!element.value) {
      objError.value = "El campo es obligatorio"
    } else if (element.type === "number") {
      const regex = new RegExp(/^\d+$/);
      if (!regex.test(element.value)) {
        objError.value = "El tipo de dato es número"
      }
    } else if (element.type === "date") {
      const regex = new RegExp(/^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/)
      if (!regex.test(element.value)) {
        objError.value = "El formato de fecha es dd-mm-yyyy"
      }
    }

    if (!element.label) {
      objError.label = "El campo es obligatorio"
    }

    return objError
  }

  const handleSubmit = () => {
    const arrayErrors = customForm.reduce((prev, curr) => {
      return [
        ...prev,
        validateField(curr)
      ]
    }, [])
    setErrors(arrayErrors)

    const errorsValues = arrayErrors.filter(el => el.value || el.label)
    if (errorsValues.length > 0) {
      return
    }

    editBusinessMgmt()

  }

  const handleChange = (key, value, index) => {

    setErrors(state => state.reduce((prev, curr, i) => {
      if (index === i) {
        return [...prev, { ...curr, [key]: null }]
      }
      return [...prev, curr]
    }, []))

    const editElement = (el, i) => {
      if (i === index) {
        return { ...el, [key]: value }
      } return el
    }
    setCustomForm(prev => prev.map(editElement))
  }

  const handleDelete = (index) => {
    const deleteElement = (el, i) => i !== index
    setCustomForm(prev => prev.filter(deleteElement))
  }

  const getBusiness = async () => {
    try {
      setLoading(true)
      const business = await getBusinessById(businessId)
      setCustomForm(business.fields)
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }

  const addBusinessData = () => {
    const arrayInfo = [...customForm];
    const arrayErrors = [...errors]
    arrayInfo.push({ label: "", value: "", type: "string" })
    arrayErrors.push({ label: null, value: null })
    setCustomForm(arrayInfo)
    setErrors(arrayErrors)
  }

  const handleBlur = (index, fieldType) => {
    setErrors(state => state.reduce((prev, curr, i) => {
      if (index === i) {
        return [...prev, { ...curr, ...validateField(customForm[i], fieldType) }]
      }
      return [...prev, curr]
    }, []))
  }

  useEffect(() => {
    if (!loading && errors.length === 0 && customForm.length > 0) {
      const initErrors = customForm.reduce((prev, curr) => [...prev, { label: null, value: null }], [])
      setErrors(initErrors)
    }
  }, [customForm, loading])

  useEffect(() => {
    getBusiness()
  }, [])


  return (
    <>
      <Dialog open={open && showDialog} className='dialog-new-business'>
        <DialogTitle>
          Editar Gobierno Corporativo
        </DialogTitle>
        <DialogContent>
          {
            !loading && customForm.length > 0 ? (
              <Grid container spacing={3} alignItems='center' justifyContent='center'>
                <Grid item container xs={4} justifyContent='flex-start'>
                  <Grid item>
                    <strong>{customForm[0].label}</strong>
                  </Grid>
                </Grid>
                <Grid item xs >
                  <InputForm
                    id={"Input-nombre-gobierno"}
                    value={customForm[0].value}
                    label={`Valor*`}
                    error={!!errors[0]?.value}
                    helperText={errors[0]?.value}
                    onChange={(value) => handleChange("value", value, 0)}
                    onBlur={() => handleBlur(0, "value")}
                    fullWidth
                  />
                </Grid>

                {
                  creator ? (
                    <Grid item>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={addBusinessData}
                        size='small'
                      >
                        Agregar dato
                      </Button>

                    </Grid>
                  ) : <></>
                }
              </Grid>
            ) : <></>
          }


          <Grid container spacing={3} justifyContent='center'>
            {
              !loading ? (
                customForm.map((cell, i) => {
                  if (i === 0) return <div key={uuid()}></div>
                  return (
                    <>
                      <Grid item xs={4} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                        <InputForm
                          id={"dato-gobierno " + uuid()}
                          value={cell.label}
                          label={`Dato*`}
                          error={!!errors[i]?.label}
                          helperText={errors[i]?.label}
                          onChange={label => handleChange("label", label, i)}
                          fullWidth
                          onBlur={() => handleBlur(i, "label")}
                        />

                      </Grid>
                      <Grid item xs={3} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                        <SimpleSelect
                          id={"Select-tipo-dato"}
                          options={options}
                          selected={cell.type ?? "string"}
                          setSelected={(type) => handleChange("type", type, i)}
                          label={"Tipo de dato"}
                        />
                      </Grid>
                      <Grid item xs={4} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                        <InputForm
                          id={"valor-gobierno " + uuid()}
                          value={cell.value}
                          label={`Valor*`}
                          error={!!errors[i]?.value}
                          helperText={errors[i]?.value}
                          onChange={(value) => handleChange("value", value, i)}
                          fullWidth
                          onBlur={() => handleBlur(i, "value")}
                        />
                      </Grid>
                      <Grid item xs={1} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                        <IconButton
                          onClick={() => handleDelete(i)} size='small'
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </>
                  )
                })
              ) : <></>
            }

            {
              !loading ? (
                customForm.length === 1 && (
                  <Grid item container justifyContent='center'>
                    <Grid item>Para ingresar mas información da click en "AGREGAR DATO"</Grid>
                  </Grid>
                )
              ) : <></>
            }
            {
              loading ? (
                <Grid item>
                  <span>Cargando informacion...</span>
                </Grid>
              ) : <></>
            }
          </Grid>

        </DialogContent >
        <DialogActions>
          <Grid container spacing={3} justifyContent='flex-end'>
            <Grid item>
              <Button
                variant='outlined'
                color='secondary'
                onClick={onClose}
              >
                Cancelar
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit}
                disabled={loading}
              >
                Guardar
              </Button>
            </Grid>

          </Grid>
        </DialogActions>
      </Dialog >
    </>
  )
}
