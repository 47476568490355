import { folderConstants } from "../constants";
import intialState from "../../configs/intialState.config";

export function folderReducer( state = intialState.subfolderId, action ){
  switch (action.type) {
    case folderConstants.SET_SUBFOLDER_ID:
      return action.payload
    case folderConstants.RESET_SUBFOLDER_ID:
      return null;
    default:
      return state;
  }
}