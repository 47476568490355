

import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import LinearLoading from '../utils/LinearLoading'
import EditIcon from '@material-ui/icons/Edit';
import ValidateInput from '../utils/ValidateInput'
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { GestorCapitalHttp } from '../../core/http/gestor-capital.http';
import './inversores.scss';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: 'red',
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon color='red' />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: { padding: theme.spacing(1) },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0, padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function DialogEditShareholder(props) {
    const [open, setOpen] = React.useState(false);
    const [load, setLoad] = React.useState(false);
    const [touched, setTouched] = React.useState('');
    const [shaheholderInfo, setShaheholderInfo] = useState(props);

    const [errorMessage, setErrorMessage] = React.useState('');

    useEffect(() => {
        setShaheholderInfo(props.data);
    }, [props]);

    const gestorCapitalHttp = new GestorCapitalHttp();

    const clearState = () => {
        setTouched("")
        setErrorMessage("")
        setShaheholderInfo(props.data);
    };

    const change = (key, value) => {
        const tempData = { ...shaheholderInfo };
        tempData[key] = value;
        setShaheholderInfo(tempData);
    };

    // OPEN-CLOSE MENU SECCIONES
    const handleClickOpen = () => setOpen(true);

    const handleClose = () => {
        clearState();
        setShaheholderInfo(props.data);
        setOpen(false);
    }

    const saveUser = () => {
        setLoad(true);

        if (!shaheholderInfo.name.length || !shaheholderInfo.rfc.length) {
            setLoad(false);
            setTouched(true);
            setErrorMessage("Complete todos los campos");
        }
        else {
            let idShaheholder = shaheholderInfo.id;
            let data = {
                "name": shaheholderInfo.name,
                "rfc": shaheholderInfo.rfc,
            }

            gestorCapitalHttp.updateShareHolder(
                idShaheholder, data,
                (resp) => {
                    setLoad(false);
                    setOpen(false);
                    handlePopulate();
                    clearState();
                }, (error) => {
                    handleError()
                }
            );
        }
    };

    const handlePopulate = () => {
        setTouched(false);
        props.refresh();
    };

    const handleError = (error) => {
        setLoad(false);
        setTouched(true);
        setErrorMessage(errorMessage ? "Ocurrio un error" : "Ocurrio un error");

    };
    const checkDisabled = () => {
        let colorBoton = props.proccesStart === true ? "gray" : "";
        return colorBoton;
    }

    return (
        <div className="App">
            <button className="boton"
                disabled={props.proccesStart}
                style={{ backgroundColor: checkDisabled() }}
                onClick={handleClickOpen}>
                <EditIcon style={{ color: 'white', fontSize: 18 }} />
            </button>
            <div>
                <Dialog onClose={handleClose} fullWidth={true}
                    maxWidth={'xs'} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle style={{ backgroundColor: 'white' }} id="customized-dialog-title">
                        Editar Accionista
                    </DialogTitle>
                    <LinearLoading show={load} delay={15} />
                    <DialogContent style={{ backgroundColor: 'white' }} >
                        <Typography component={'span'}>
                            <List>
                                <div style={{ marginTop: '2rem' }}>
                                    <div style={{ paddingBottom: '2rem', paddingLeft: '.3rem' }}>
                                        <ValidateInput invalid={touched && !shaheholderInfo.name} message='Este campo es requerido'>
                                            <TextField fullWidth required id="outlined-basic" name="name" type='text'
                                                value={shaheholderInfo.name} onChange={(e) => change('name', e.target.value)} label="Nombre Completo" variant="outlined" />
                                        </ValidateInput>
                                    </div>
                                    <div style={{ paddingBottom: '2rem', paddingLeft: '.3rem' }}>
                                        <ValidateInput invalid={touched && !shaheholderInfo.rfc} message='Este campo es requerido'>
                                            <TextField fullWidth required id="outlined-basic" name="rfc" type='text'
                                                value={shaheholderInfo.rfc} onChange={(e) => change('rfc', e.target.value)} label="RFC" variant="outlined" />
                                        </ValidateInput>
                                    </div>
                                </div>
                            </List>
                        </Typography>
                        {
                            errorMessage &&
                            <Alert className="top"
                                severity='error'
                                onClose={() => setErrorMessage(null)}>
                                {errorMessage}
                            </Alert>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus variant="outlined" onClick={handleClose} color="secondary">
                            Cancelar
                        </Button>
                        <Button autoFocus variant="contained" onClick={saveUser} color="primary">
                            Agregar Cambios
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
};
DialogEditShareholder.propTypes = {
    refresh: PropTypes.object,
    close: PropTypes.bool,
    data: PropTypes.object,
};

