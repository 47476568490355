import React, { Component } from 'react';
import { Dialog, Button } from '@material-ui/core';

export class ConfirmDialog extends Component {
  render() {
    const { open, title, onConfirm, onCancel } = this.props;

    return (
      <Dialog open={ open }>
        <div className="p-4 text-center">
          <h4>{ title }</h4>

          <div>
            <Button
              variant="contained"
              onClick={ () => onCancel && onCancel() }
            >
              Cancelar
            </Button>

            <Button
              color="primary"
              variant="contained"
              onClick={ () => onConfirm && onConfirm() }
              className="ml-3"
            >
              Aceptar
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}
