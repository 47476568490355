import { vaultConstants } from "../constants"


const initialState = {
  items: [],
  alertType: "",
  filterTags: [],
  showAlert: false,
  searchedItems: [],
  deepSearch: false,
  confirmMessage: "",
  showConfirm: false,
  loadingItems: false,
  searchedItemsLocal: [],
  includeDescription: false,
  loadingSearchedItems: false,
}

export const vaultReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case vaultConstants.SAVE_ITEMS:
      return {
        ...state,
        items: payload,
        searchedItemsLocal: payload,
        loadingItems: false
      }
    case vaultConstants.LOADING_ITEMS:
      return {
        ...state,
        loadingItems: true
      }

    case vaultConstants.SEARCH_ITEMS_LOCAL:
      const regex = new RegExp(`${payload.trim() === '' ? ' ' : payload.trim()}`, 'gi')
      // para que permita el parentesis
      // const firstReplace = payload.replace("(", "\\(")
      // const result = firstReplace.replace(")", "\\)")
      // const regex = new RegExp(`${result.trim()}`, 'gi')

      const processItems = state.items.filter(item => {
        const { name } = item;
        return name.match(regex)
      })

      return {
        ...state,
        searchedItemsLocal: processItems
      }

    case vaultConstants.SET_INCLUDE_DESCRIPTION:
      console.log("en incluir descripcion");
      return {
        ...state,
        includeDescription: payload
      }

    default:
      return state
  }
}
