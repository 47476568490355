export const convertBytes = function (bytes) {
    const units = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) {
      return "n/a";
    }
    const size = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    if (size == 0) {
      return bytes + " " + units[size];
    }
    return (bytes / Math.pow(1024, size)).toFixed(1) + " " + units[size];
  }