export const userConstants = {
  FETCH_USERS: 'fetch_users',
  FETCH_USERS_SUCCESS: 'fetch_users_success',
  FETCH_USERS_FAILURE: 'fetch_users_failure',
  CREATE_NEW_USER: 'create_new_user',
  CREATE_NEW_USER_SUCCESS: 'create_new_user_success',
  CREATE_NEW_USER_FAILURE: 'create_new_user_failure',
  EDIT_USER: 'edit_user',
  EDIT_USER_SUCCESS: 'edit_user_success',
  EDIT_USER_FAILURE: 'edit_user_failure',
  DISABLE_USER: 'disable_user',
  DISABLE_USER_SUCCESS: 'disable_user_success',
  DISABLE_USER_FAILURE: 'disable_user_failure',
  DELETE_USER: 'delete_user',
  DELETE_USER_SUCCESS: 'delete_user_success',
  DELETE_USER_FAILURE: 'delete_user_failure',
  GET_USER_SUCCESS: 'get_user_success',
  GET_USER_FAILURE: 'get_user_failure',
  FILTER_USERS_SUCCESS: 'filter_users_success',
  SORT_USER: 'sort_user',
  ASSIGN_ROLES: 'assign_roles',
  ASSIGN_ROLES_SUCCES: 'assign_roles_success',
  ASSIGN_ROLES_FAILURE: 'assign_roles_failure',
  SET_AVAILABLE_COMPANIES: 'SET_AVAILABLE_COMPANIES',
  FETCHING_COMPANIES: 'FETCHING_COMPANIES',
  FETCH_DATA_TEAM_USERS: 'FETCH_DATA_TEAM_USERS',
  FETCH_DATA_TEAM_USERS_SUCCESS: 'FETCH_DATA_TEAM_USERSSUCCESS',
  SAVE_USERS_SEARCH: 'SAVE_USERS_SEARCH',
  SET_CURRENT_PAGE_USERS: 'SET_CURRENT_PAGE_USERS',
  SET_SEARCH_USERS: 'SET_SEARCH_USERS',
  SET_INCLUDE_EMAIL_SEARCH: 'SET_INCLUDE_EMAIL_SEARCH',
}