import React, { useEffect, useState } from 'react';
import { Dialog, Grid, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { isMobile, isMobileOnly } from 'axeleratum-sgc-frontend-library';
import { } from '../../control/calendar/forms/fields/text-field-native';
import { MultiSelectNative } from '../../control/calendar/forms/fields/multi-select-native';
import { SelectNative } from '../../control/calendar/forms/fields/select-native';
import Alert from '@material-ui/lab/Alert';

const types = [
  { label: "Todos", value: "Todos" },
  { label: "Revisión", value: "Revisión" },
  { label: "Firma", value: "Firma" },
];

export const DialogFilter = (props) => {
  const {
    openFilters,
    availableVaults,
    handleClose,
    handleFilter,
    currentValues
  } = props;

  const [involved, setInvolved] = useState(currentValues ? currentValues.involved : false);
  const [vaultsSelected, setVaultsSelected] = useState(currentValues ? currentValues.vaults : []);
  const [type, setType] = useState(currentValues ? currentValues.type : "Todos");
  const [options, setOptions] = useState([]);
  const [alert, setAlert] = useState(false);

  const handleCancel = () => {
    handleClose();
    setInvolved(false);
    setVaultsSelected([]);
    setType("Todos");
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (vaultsSelected.length === 0 && type === "Todos" && !involved) {
      setAlert(true)
      return
    }
    handleFilter({
      type,
      vaults: vaultsSelected,
      involved
    });
  }

  const handleChangeVaults = (value) => {
    setVaultsSelected(value ? value.map(el => el.label) : [])
    setAlert(false);
  }

  const handleChangeType = (value) => {
    setType(value);
    setAlert(false);
  }

  useEffect(() => {
    setOptions(availableVaults.map(el => ({ value: el, label: el })))
  }, [availableVaults])

  return (
    <Dialog className={isMobile ? '' : 'w-50-dialog'} open={openFilters}>
      <form className={isMobileOnly ? 'p-3' : 'p-5'} onSubmit={handleSubmit}>
        <Grid container>
          <Grid item md={12}>
            <h3>Filtros</h3>
            {
              alert &&
              <Alert severity="warning">No has seleccionado ningún filtro.</Alert>
            }

            <div className='MultiSelect-Documents-Information'>
              <MultiSelectNative
                clearable
                label='Bóvedas'
                name='vaults'
                options={options}
                value={vaultsSelected.map(el => ({ search: el, label: el }))}
                onChange={handleChangeVaults}
              />
            </div>

            <SelectNative
              label='Tipo de Actividad'
              name='type'
              value={type}
              items={types}
              onChange={handleChangeType}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={involved}
                  onChange={(evt) => setInvolved(evt.target.checked)}
                />
              }
              label={`Involucrado`}
            />

          </Grid>

          <Grid className='mt-3' container justifyContent='flex-end'>
            {isMobile ? (
              <React.Fragment>
                <Grid item>
                  <Button
                    type='button'
                    variant='outlined'
                    color='secondary'
                    onClick={handleCancel}
                  >
                    Reestablecer
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    className='ml-2'
                    type='submit'
                    variant='contained'
                    color='primary'
                  >
                    Filtrar
                  </Button>
                </Grid>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button
                  type='button'
                  variant='outlined'
                  color='secondary'
                  onClick={handleCancel}
                >
                  Reestablecer
                </Button>

                <Button
                  className='ml-2'
                  type='submit'
                  variant='contained'
                  color='primary'
                >
                  Filtrar
                </Button>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </form>
    </Dialog>
  )
}
