import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { uuid } from 'axeleratum-sgc-frontend-library';

const useStyles = makeStyles((theme) => ({
  formControl: {

    minWidth: 120,
  },
}));

export const SimpleSelect = (props) => {
  const classes = useStyles()
  const { options, selected, setSelected, label, disabled = false } = props;


  const handleSelect = (e) => {
    setSelected(e.target.value)
  }

  return (
    <>
      <FormControl
        className={classes.formControl}
        size='medium'
        margin='dense'
        variant='outlined'
        disabled={disabled}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          value={selected}
          onChange={handleSelect}
          label={label}
        >
          {
            options.map(el => (
              <MenuItem value={el.value} key={uuid()}>{el.label}</MenuItem>
            ))
          }
        </Select>
      </FormControl>

    </>
  )
}
