import React, { Component } from "react";
import clsx from "clsx";

import {
  Drawer,
  withStyles,
  IconButton,
  Avatar,
  Grid,
  Tooltip,
} from "@material-ui/core";

import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import ArrowForwardSharpIcon from '@material-ui/icons/ArrowForwardSharp';

import AppMenuItems from "./app-menu-items";
import { isMobile } from "axeleratum-sgc-frontend-library";

const drawerWidth = 240;

const useStyles = (theme) => ({
  drawerPaper: {
    backgroundColor: theme.palette.primary.main,
    flexShrink: 0,
    whiteSpace: "nowrap",
    overflowY: 'revert'
  },
  toolbar: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(2),
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "revert",
    width: theme.spacing(10) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(10) + 1,
    },
  },
  toolbarButton: {
    position: "absolute",
    bottom: 0,
    left: "100%",
    top: '6px',
    width: "24px",
    zIndex: 3,
    transform: "translateZ(0px)",
  },
  toolbarButtonIcon: {
    opacity: 1,
    position: "absolute",
    top: "36px",
    transform: "translate(-50%)",
    background: `${theme.palette.secondary.main}`,
    borderRadius: '100%',
    outline: '0px',
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
    },
    padding: '1px'
  },
  collapseIcon: {
    color: theme.palette.background.paper,
    fontSize: '1.3rem',
    "&:hover": {
      color: theme.palette.secondary.main,
    }
  },
  avatar: {
    border: `2px solid ${theme.palette.background.paper}`,
  },
  iconLabel: {
    width: '18px',
    height: '18px',
  }
});

class NavBarMenu extends Component {
  state = {
    itemSelected: "",
    expanded: false,
  };

  handleItemSelected = (item) => {
    if (item === 'logout') {
      this.props.onLogout()

      return;
    }
    if (item === 'idmgmt') {
      window.open("https://demo-dashboard.incode.com/log-in")
      return
    }

    this.setState({ itemSelected: item });
  };

  handleExpandNav = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { classes, menuItems, userName } = this.props;
    const { itemSelected, expanded } = this.state;

    return (
      <Drawer
        variant="permanent"
        anchor="left"
        classes={{
          paper: clsx(classes.drawerPaper, {
            [classes.drawerOpen]: expanded,
            [classes.drawerClose]: !expanded,
          }),
        }}
        className={clsx(classes.drawerPaper, {
          [classes.drawerOpen]: expanded,
          [classes.drawerClose]: !expanded,
        })}
      >
        <Grid container className={classes.toolbar} justifyContent="center">
          <Grid item>
            <Tooltip title={userName ? userName : ''}>
              <Avatar
                className={classes.avatar}
              >
                {userName ? userName.toUpperCase().charAt(0) : ''}
              </Avatar>
            </Tooltip>
          </Grid>
        </Grid>

        {menuItems.length > 0 &&
          <AppMenuItems
            menuItems={menuItems}
            itemSelected={itemSelected}
            expanded={expanded}
            onItemSelected={(item) => this.handleItemSelected(item)}
          />
        }

        {!isMobile && (
          <div className={classes.toolbarButton}>
            <div>
              <div>
                <IconButton
                  onClick={() => this.handleExpandNav()}
                  classes={{ root: classes.toolbarButtonIcon, label: classes.iconLabel }}
                  size='small'
                >
                  {expanded ? (
                    <ArrowBackSharpIcon className={classes.collapseIcon} />
                  ) : (
                    <ArrowForwardSharpIcon className={classes.collapseIcon} />
                  )}
                </IconButton>
              </div>
            </div>
          </div>
        )}


      </Drawer>
    );
  }
}


export default withStyles(useStyles, { withTheme: true })(NavBarMenu);
