import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { FilterIcon } from 'axeleratum-sgc-frontend-library/components/icons/filter-icon';
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Box,
} from '@material-ui/core';
export const SearchBar = ({ handleSearch, openFilter, valueName }) => {
  return (
    <Box className='animate__animated animate__fadeIn'>
      <Grid container alignItems='center'>
        <Grid item sm={11} xs={10} >
          <TextField
            margin='dense'
            label='¿Nombre del documento?'
            variant='outlined'
            fullWidth={true}
            onChange={handleSearch}
            value={valueName}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid className='text-right' item xs={2} sm={1}>
          <IconButton
            variant='outlined'
            color='secondary'
            onClick={openFilter}
          >
            <FilterIcon color='primary' />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  )
}
