import React, { Component } from 'react';
import { change, Field, formValueSelector, reduxForm, untouch, touch } from "redux-form";
import { RenderTextField, isMobileOnly } from 'axeleratum-sgc-frontend-library';
import { RenderMultiSelect } from '../../from-library/redux-form/RenderMultiSelect';
import { Button, DialogActions, Grid } from "@material-ui/core";
import RenderCheckbox from '../../../core/redux-form-fields/render-checkbox';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { UserHttp } from '../../../core/http/user.http';
import { tokenExpired } from '../../../core/utils';
import { authActions } from '../../../core/actions';

class FormUsers extends Component {
  usersHttp = new UserHttp();
  constructor(props) {
    super(props);
    this.state = {
      loginSSO: false,
      internalUser: false,
      externalUsersManager: props.externalUsersManager
    }
  }
  componentDidMount() {
    const { userSelected, externalUsersManager } = this.props;

    if (userSelected?.internalUse) {
      this.setState({ internalUser: true })
    }

    const initData = {
      completeName: userSelected?.completeName ? userSelected.completeName : '',
      email: userSelected?.email ? userSelected.email : '',
      rfc: userSelected?.rfc ? userSelected.rfc : '',
      roles: (userSelected?.roles) ? userSelected.roles.map(role => ({ label: role.name, value: role.id, color: role.color })) :
        [],
      companies: (userSelected?.companies) ?? [],
      externalProvider: userSelected?.externalProvider ? userSelected.externalProvider :
        externalUsersManager ? true : false,
      ssoAuth: userSelected?.ssoAuth ?? false
    }

    this.setState({ loginSSO: this.props.currentUser.loginSSO })
    this.props.initialize(initData);
    this.validateIfAllowSaveUsers(initData);

  }

  handleExternalProviderCheck() {
    this.props.dispatch(change('FormUsers', 'roles', []));
    this.props.dispatch(untouch('FormUsers', 'roles'));
    this.props.dispatch(change('FormUsers', 'ssoAuth', false));
  }

  validateIfAllowSaveUsers = async (initData) => {
    const { usersAvailable, onErrorAvailableUsers, userSelected } = this.props;
    try {

      const { isLoggedIn, userLoggedOut } = this.props;

      if (isLoggedIn && tokenExpired()) {
        userLoggedOut()
        return
      }
      const { data } = await this.usersHttp.allowSaveUsers();
      const { available, numberOfUsers, quantityAvailable } = data;
      if (!available && (!userSelected || userSelected?.externalProvider)) {
        usersAvailable(0);
        this.props.initialize({ ...initData, externalProvider: true })
        this.setState({ externalUsersManager: true })
      } else {
        usersAvailable(quantityAvailable - numberOfUsers);
      }

    } catch (error) {
      onErrorAvailableUsers()
    }
  }

  render() {
    const {
      handleSubmit,
      submitActions,
      roles,
      onCancel,
      title,
      companies,
      isExternalProvider,
    } = this.props;

    const { internalUser, externalUsersManager, loginSSO } = this.state;

    return (
      <form className={isMobileOnly ? 'p-3' : 'p-5'} style={{ overflowY: 'auto' }} onSubmit={handleSubmit(submitActions)}>
        <Grid container>
          <Grid item md={12}>
            <h3>{title}</h3 >

            <Field
              label="Nombre del usuario"
              name="completeName"
              disabled={internalUser}
              required
              component={RenderTextField}
            />

            <Field
              label="Email"
              name="email"
              type='email'
              required
              component={RenderTextField}
            />

            {
              !internalUser &&
              <>
                <Field
                  label="RFC (opcional)"
                  name="rfc"
                  component={RenderTextField}
                />

                <Field
                  className="roles"
                  label="Agregar roles al usuario"
                  name="roles"
                  options={roles}
                  required
                  disabled={externalUsersManager || isExternalProvider}
                  component={RenderMultiSelect}
                />

                <Field
                  className="roles"
                  label="Bóvedas"
                  name="companies"
                  options={companies}
                  required
                  onCloseSelect={() => {
                    this.props.dispatch(touch("FormUsers", "companies"));
                  }}
                  component={RenderMultiSelect}
                />

                {
                  loginSSO ? (
                    <Grid item xs={12}>
                      <Field
                        className="roles"
                        label="Inicio de Sesión Único (SSO)"
                        name="ssoAuth"
                        disabled={externalUsersManager || isExternalProvider}
                        component={RenderCheckbox}
                      />
                    </Grid>
                  ) : <></>
                }

                <Field
                  className="roles"
                  label="Usuario externo"
                  name="externalProvider"
                  onChange={() => this.handleExternalProviderCheck()}
                  checked={externalUsersManager}
                  disabled={externalUsersManager}
                  component={RenderCheckbox}
                />

              </>
            }

            <DialogActions>
              <Button
                type="button"
                variant="outlined"
                color="secondary"
                onClick={() => onCancel()}
              >
                Cancelar
              </Button>

              <Button
                className="ml-2"
                type="submit"
                variant="contained"
                color="primary"
              >
                Guardar
              </Button>
            </DialogActions>
          </Grid >
        </Grid >
      </form >
    );
  }
}

const validate = (values) => {

  if (Object.entries(values).length === 0) return;

  const errors = {
    completeName: '',
    email: '',
    roles: '',
    companies: ''
  }

  if (values.companies?.length === 0) {
    errors.companies = 'Seleccionar al menos una bóveda';
  }

  if (!values.externalProvider) {
    if (values.roles.length === 0) {
      errors.roles = "EL rol es requerido"
    }
  }

  if (!values.completeName) {
    errors.completeName = 'El nombre del usuario es requerido';
  }

  if (values.username) {
    if (values.completeName > 50) {
      errors.completeName = 'El nombre del usuario no puede ser mayor a 100 caracteres';
    }
  }

  if (!values.email) {
    errors.email = 'El email es requerido';
  } else {
    const emailReg = new RegExp(/\S+@\S+\.\S+/);
    if (!emailReg.test(values.email)) {
      errors.email = "Email inválido"
    }
  }

  if (values.rfc) {
    const rfcReg = new RegExp(/^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{0,3})$/i);

    if (!rfcReg.test(values.rfc)) {
      errors.rfc = 'RFC invalido';
    }
  }
  return errors;
}

const selector = formValueSelector('FormUsers')

const mapStateToProps = (state) => {
  return {
    isExternalProvider: selector(state, 'externalProvider'),
    isLoggedIn: state.authReducer.loggedIn,
    currentUser: state.authReducer.currentUser,
  };
}
const mapDispatchToProps = {
  userLoggedOut: authActions.userLoggedOut,
}



export default compose(
  connect(
    mapStateToProps, mapDispatchToProps
  ),
  reduxForm({
    form: 'FormUsers',
    validate
  })
)(FormUsers);

