import "./popup-user.scss";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { CardUserFn } from "./CardUserFn";
import { UserHttp } from "../../../../core/http/user.http";
import Alert from "@material-ui/lab/Alert";
import { useEffect, useState } from "react";
import { DraggableList } from "../../../draggableList/DraggableList";
import { useDispatch, useSelector } from "react-redux";
import { activityActions } from "../../../../core/actions/activity.actions";
import { AddMultipleUsersToSign } from "./AddMultipleUsersToSign";
import { tokenExpired } from "../../../../core/utils";
import { authActions } from "../../../../core/actions";

export const PopUpSignersUsers = ({ open, onCancel, onSubmit, labelType = "firm", checkedOrder, documentId, hasNom151 = false }) => {

  const dispatch = useDispatch();
  const showOrder = true;
  const usersSelected = useSelector(state => state.activityReducer.allSigners);

  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);



  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [order, setOrder] = useState(checkedOrder);

  const onChangeUser = (user) => {
    dispatch(activityActions.setSignatureType({
      userId: user.userId,
      type: user.type,
      stroke: user.stroke
    }))
  }

  const handleDragg = (newList) => {
    dispatch(activityActions.setAllSigners(newList))
  }


  const handleSubmit = () => {
    onSubmit && onSubmit(usersSelected, order);
  }

  const handleCancel = () => {
    onCancel();
  }

  const processUsers = () => {
    // if (usersSelected.length > 1) {
    //   const hideCheckBox = usersSelected.find(el => el.type === "link");
    //   if (!!hideCheckBox) {
    //     setShowOrder(false);
    //     setOrder(false);
    //   } else {
    //     setShowOrder(true);
    //   }
    // }
  }

  useEffect(() => {
    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }
    const userHttp = new UserHttp();

    setLoad(true);
    userHttp.findAllUsersByDocumentId(
      documentId,
      "firm",
      (resp) => {
        const sortUsersName = (x, y) => {
          return x.name.localeCompare(y.name);
        };
        let s = resp.sort(sortUsersName);
        dispatch(activityActions.saveInternalUsersToSign(s));
        setLoad(false);
      },
      (error) => {
        setLoad(false);
        setError(true);
      }
    );
    processUsers();
    // eslint-disable-next-line 
  }, []);


  useEffect(() => {
    processUsers();
    // eslint-disable-next-line 
  }, [usersSelected])


  return (
    <Dialog className="popup-user" open={open}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginRight: "10px" }}>
        <DialogTitle>Firmantes seleccionados</DialogTitle>

        {!load && (
          <AddMultipleUsersToSign />
        )}
      </div>

      {error && (
        <DialogContent>
          <Alert severity="error">
            Hubo un problema al cargar lo usuarios inténtalo mas tarde
          </Alert>
        </DialogContent>
      )}

      {load ? (
        <DialogContent className="text-center">
          <CircularProgress />
        </DialogContent>
      ) : (
        <>
          {!error && (
            <>
              <DialogContent className="card-zone">
                <Grid
                  container
                  spacing={1}
                  direction="column"
                >
                  <DraggableList
                    items={usersSelected}
                    setItems={handleDragg}
                  >
                    {

                      usersSelected.map((item, index) => (

                        <CardUserFn
                          order={order}
                          key={item.userId}
                          labelType={labelType}
                          onlyShow={false}
                          id={item.userId}
                          name={item.name}
                          index={index}
                          email={item.email}
                          color={item.color}
                          type={item.type}
                          rfc={item.rfc}
                          useRFC={item.temporal ? !!item.rfc : item.hasRFC}
                          stroke={item.stroke}
                          onChange={el => onChangeUser(el)}
                          disableMethod={!hasNom151}
                        />
                      ))}
                  </DraggableList>
                </Grid>

                {usersSelected.length === 0 && (
                  <div className="text-center mt-2">
                    No hay usuarios seleccionados
                  </div>
                )}
              </DialogContent>
            </>
          )}
        </>
      )}
      <div className={`bottom-options${usersSelected.length > 1 ? ' bottom-options-between' : ''}`}>
        {(usersSelected.length < 2) ? null : (
          <div>
            <FormControlLabel
              className="ml-3 mr-3 mb-1"
              control={
                <Checkbox
                  checked={order}
                  onChange={(evt) => setOrder(evt.target.checked)}
                  disabled={!showOrder}
                />
              }
              label={`Notificar en orden a los firmantes`}
            />
            <p className={`info-text ${!showOrder && `disabled`}`}>como se muestra en la lista</p>
          </div>
        )}


        <DialogActions>
          <>
            <Button
              className="primary-text"
              color="primary"
              variant="outlined"
              onClick={() => handleCancel()}
            >
              Cancelar
            </Button>

            <Button
              color="primary"
              variant="contained"
              disabled={usersSelected.length === 0}
              onClick={() => handleSubmit()}
            >
              Aceptar
            </Button>
          </>
        </DialogActions>
      </div>
    </Dialog>
  );
}
