import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Grid,
  Card,
  // CardActionArea,
  CardContent,
  makeStyles,
  // Button,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Header from '../../components/dashboard/header';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { getCurrentUser } from '../../core/helpers';
import colorsJSON from '../../assets/material-colors';
import { VerticalCenterDiv } from '../../components/from-library/vertical-center-div/vertical-center-div';
import "./corporateGovernance.scss";
import { useDispatch, useSelector } from 'react-redux';
import { tokenExpired } from '../../core/utils';
import { authActions } from '../../core/actions';
import { getAllBusiness } from '../../core/http/functionRequests/business-management';


const useStyles = makeStyles((theme) => ({
  link: {
    '&:hover': { textDecoration: "none" }
  },
  business: {
    fontWeight: 'bold',
    padding: '16px !important'
  },
  icon: {
    color: "#fff",
    width: "40px",
    height: "40px",
    bordeRadius: "50px",
  }
}));

export const BusinessManagement = () => {
  const colors = { i: 0 };

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [allBusiness, setAllBusiness] = useState([])
  const [loading, setLoading] = useState(false)

  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);

  const getColor = (colors) => {
    if (colors.i > 7) {
      colors.i = 0;
    }
    const out = colorsJSON.basicColors[colors.i];
    colors.i++;

    return out;
  }

  const requestAllBusiness = async () => {
    try {
      setLoading(true)
      const data = await getAllBusiness()
      setAllBusiness(data)
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }
    const { hasBusinessMgmt, authorities } = getCurrentUser();

    if (
      !hasBusinessMgmt || (
        !authorities.includes("Crear Gobierno Corporativo") &&
        !authorities.includes("Consultar Gobierno Corporativo") &&
        !authorities.includes("Editar Gobierno Corporativo")
      )
    ) {
      return history.push("/dashboard/home")
    }

    requestAllBusiness()
  }, [])

  return (
    <>
      <Header
        titleRight={'Gobierno Corporativo'}
        actions={[
          <IconButton variant='outlined'>
            <NotificationsIcon color='primary' />
          </IconButton>,
          <IconButton variant='outlined'>
            <EventIcon color='primary' />
          </IconButton>,
        ]}
      />

      <Grid container spacing={3} className='CorporateGovernanceDashboard'>
        <Grid item container spacing={3} >
          {
            allBusiness.map(business => (
              <Grid key={business.id} item>
                <Link
                  className={classes.link}
                  to={`/dashboard/business-management/${business.id}`}
                >
                  <Card>
                    <CardContent className={classes.business}>
                      <Grid item container spacing={3} alignItems='center'>
                        <Grid item >
                          <VerticalCenterDiv className="icon text-center" style={{ background: getColor(colors) }}>
                            <AccountBalanceIcon />
                          </VerticalCenterDiv>
                        </Grid>
                        <Grid item >
                          <VerticalCenterDiv className="text">
                            {business.name}
                          </VerticalCenterDiv>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))
          }
        </Grid>


        {
          (allBusiness.length === 0 && !loading) ? (
            <Grid item container spacing={3} justifyContent='center' alignItems='center'>
              <Grid item>
                <span>No hay empresas creadas</span>
              </Grid>
            </Grid>
          ) : <></>
        }

        {
          loading ? (
            <Grid item container spacing={3} justifyContent='center' alignItems='center'>
              <Grid item>
                <span>Cargando empresas ...</span>
              </Grid>
            </Grid>
          ) : <></>
        }

      </Grid>
    </>
  )
}
