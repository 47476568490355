import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ValidateInput from '../utils/ValidateInput'
import LinearLoading from '../utils/LinearLoading'
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import { GestorCapitalHttp } from '../../core/http/gestor-capital.http';
import './inversores.scss';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: 'red',
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon color='red' />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: { padding: theme.spacing(1) },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0, padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function DialogAddShareholder(props) {
    const [open, setOpen] = React.useState(false);
    const [load, setLoad] = React.useState(false);
    const [touched, setTouched] = React.useState('');
    const [companyId, setCompanyId] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [shareholder, setShareholder] = React.useState({
        name: "", rfc: ""
    });

    useEffect(() => {
        setCompanyId(props.idCompany.id);
        // eslint-disable-next-line
    }, []);

    const gestorCapitalHttp = new GestorCapitalHttp();

    const change = (key, value) => {
        const temp = { ...shareholder };
        temp[key] = value;
        setShareholder(temp);
    };

    const clearState = () => {
        setShareholder({ name: "", rfc: "" });
        setErrorMessage("")
        setTouched("");
    };

    const saveUser = () => {
        setLoad(true);
        if (!shareholder.name.length || !shareholder.rfc.length) {
            setLoad(false);
            setTouched(true);
            setErrorMessage("Complete todos los campos");
        }
        else {
            let data = {
                "name": shareholder.name,
                "rfc": shareholder.rfc,
                "companyId": companyId
            }

            gestorCapitalHttp.createShareHolder(
                data,
                (resp) => {
                    setLoad(false);
                    setOpen(false);
                    handlePopulate();
                    clearState();
                }, (error) => {
                    handleError()
                }
            );
        }
    };

    const handlePopulate = () => {
        props.refresh();
    };

    const handleError = (error) => {
        setLoad(false);
        setTouched(true);
        setErrorMessage(errorMessage ? "Ocurrio un error" : "Ocurrio un error");

    };

    const handleClickOpen = () => setOpen(true);

    const handleClose = () => {
        clearState();
        setOpen(false);
    };

    return (
        <div>
            <Button
                variant='contained'
                color='primary'
                className="w-100"
                disabled={props.proccesStart}
                onClick={handleClickOpen}>
                +  Agregar Nuevo Accionista
            </Button>
            <Dialog onClose={handleClose} fullWidth={true} style={{ borderRadius: '10px' }}
                maxWidth={'xs'} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle style={{ backgroundColor: 'white', borderRadius: '10px' }} id="customized-dialog-title" > {/*onClose={handleClose} */}
                    Agregar Accionista
                </DialogTitle>
                <LinearLoading show={load} delay={15} />
                <DialogContent style={{ backgroundColor: 'white' }} >
                    <Typography component={'span'}>
                        <List>
                            <div style={{ marginTop: '2rem' }}>
                                <div style={{ paddingBottom: '1rem', paddingLeft: '.3rem' }}>
                                    <ValidateInput invalid={touched && !shareholder.name} message='Este campo es requerido'>
                                        <TextField fullWidth value={shareholder.name} onChange={(e) => change('name', e.target.value)} name="name" id="addName" label="Nombre Completo" variant="outlined" />
                                    </ValidateInput>
                                </div>
                                <div style={{ paddingBottom: '1rem', paddingLeft: '.3rem' }}>
                                    <ValidateInput invalid={touched && !shareholder.rfc} message='Este campo es requerido'>
                                        <TextField fullWidth value={shareholder.rfc} onChange={(e) => change('rfc', e.target.value)} name="rfc" id="addRfc" label="RFC" variant="outlined" />
                                    </ValidateInput>
                                </div>
                            </div>
                        </List>
                    </Typography>
                    {
                        errorMessage &&
                        <Alert className="top"
                            severity='error'
                            onClose={() => setErrorMessage(null)}>
                            {errorMessage}
                        </Alert>
                    }
                </DialogContent>
                <DialogActions style={{ backgroundColor: 'white' }}>
                    <Button autoFocus variant="outlined" onClick={handleClose} color="secondary">
                        Cancelar
                    </Button>
                    <Button autoFocus variant="contained" onClick={saveUser} color="primary">
                        Agregar Accionista
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}