import React from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  Typography,
  Grid,
  FormHelperText,
  FormControl,
} from "@material-ui/core";

const RenderDragAndDrop = (props) => {
  const {
    input,
    name,
    disabled,
    meta: { error, touched },
    description,
  } = props;

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: props.accept,
    noClick: true,
    noKeyboard: true,
    disabled: disabled,
    onDropAccepted: (files) => {
      input.onChange(files);
    },
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <Grid container direction="column">
      <FormControl error={touched && error && error.length > 0}>
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} name={name} />
          {description && (
            <Typography
              variant="subtitle1"
              color={disabled ? "inherit" : "primary"}
              gutterBottom
            >
              {description}
            </Typography>
          )}
          <Button
            variant="outlined"
            color="primary"
            onClick={open}
            disabled={disabled}
          >
            Buscar en tus archivos
          </Button>
        </div>
        <aside>
          <ul>{files}</ul>
        </aside>
        <FormHelperText>{touched && error && error}</FormHelperText>
      </FormControl>
    </Grid>
  );
};

export default RenderDragAndDrop;
