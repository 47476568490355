import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { Button, Dialog } from '@material-ui/core';
import calendarIcon from './calendar.png'
import outlookIcon from './outlook.png'

class FormSync extends Component {

  render() {
    const { handleSubmit, submitActions, open, onCancel, onGoogle, onOutlook, loadGoogle, loadOutlook } = this.props;

    return (
      <Dialog open={open}>
        <form className="p-4" onSubmit={handleSubmit(submitActions)}>
          <div className="row">
            <div className="col-md-12">
              <h3>
                Sincronización de calendario
              </h3>

              <div className="text-center">
                <Button
                  variant="outlined"
                  className="primary-text"
                  onClick={() => onGoogle()}
                  disabled={loadGoogle}
                >
                  {
                    loadGoogle && (<React.Fragment><em className="fa fa-spinner fa-spin"></em>&nbsp;</React.Fragment>)
                  }
                  <img
                    src={calendarIcon}
                    width={25}
                    height={25}
                    alt='imagen1'
                  >
                  </img>
                  &nbsp;&nbsp;Sincronizar con Google Calendar
                </Button>

                <Button
                  variant="outlined"
                  className="primary-text mt-2"
                  onClick={() => onOutlook()}
                  disabled={loadOutlook}
                >
                  {
                    loadOutlook && (<React.Fragment><em className="fa fa-spinner fa-spin"></em>&nbsp;</React.Fragment>)
                  }
                  <img
                    src={outlookIcon}
                    width={25}
                    height={25}
                    alt='imagen2'
                  >
                  </img>
                  &nbsp;&nbsp;Sincronizar con Outlook Calendar
                </Button>
              </div>

              <div className="text-right mt-3">
                <Button
                  className="font-color mr-2"
                  variant="outlined"
                  onClick={() => onCancel()}
                  disabled={loadGoogle}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Dialog>
    );
  }
}

const validate = (values) => {
  const errors = {
    email: '',
    password: ''
  }

  if (!values.email) {
    errors.email = 'El nombre de la bóveda es requerido';
  }

  if (!values.password) {
    errors.password = 'La contraseña es requerida';
  }

  return errors;
}

export default reduxForm({
  form: 'FormSync',
  validate
})(FormSync);
