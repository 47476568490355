import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Grid } from '@material-ui/core';
import { PropTypes } from 'prop-types';

export const AlertConfirm = (props) => {
  const { open, title, textContent, onConfirm, onCancel, actionText } = props;
  return (
    <Dialog open={open}>
      <DialogTitle>
        {title}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <strong>{textContent}</strong>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Grid container spacing={1}>

          {
            actionText ? (
              <Grid container spacing={1}>
                <Grid item container xs={12} justifyContent='flex-end' spacing={1}>
                  <Grid item>
                    <label key={"texto-del-endoso"} style={{ color: "#5C7BF0", margin: 0, fontSize: "18px" }}>
                      <strong>
                        {actionText}
                      </strong>
                    </label>
                  </Grid>
                </Grid>
                <Grid item container xs={12} justifyContent='flex-end' spacing={1}>
                  <Grid item>
                    <label key={"estas-seguro-?"} style={{ color: "#5C7BF0", margin: 0, fontSize: "18px" }}>
                      <strong>
                        ¿Estás seguro?
                      </strong>
                    </label>
                  </Grid>
                </Grid>
              </Grid>
            ) : <></>
          }

          <Grid
            item
            container
            xs={12}
            spacing={1}
            justifyContent='flex-end'
          >

            <Grid item>
              <Button
                onClick={() => onCancel && onCancel()}
                color="primary"
                variant="outlined"
                autoFocus
              >
                Cancelar
              </Button>
            </Grid>

            <Grid item>
              <Button
                onClick={() => onConfirm && onConfirm()}
                color={"primary"}
                variant="contained"
              >
                Aceptar
              </Button>
            </Grid>
          </Grid>

        </Grid>
      </DialogActions>
    </Dialog>
  );
}


AlertConfirm.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  textContent: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
}