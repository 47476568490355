import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../core/actions';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(state => state.authReducer.loggedIn);
  const currentuser = useSelector(state => state.authReducer.currentUser);

  useEffect(() => {
    if (!loggedIn) {
      dispatch(authActions.logout());
    }
  }, [loggedIn])

  return (
    <Route {...rest} render={props => (
      loggedIn
        ? <Component {...props} />
        : <Redirect to={{ pathname: `/${currentuser?.clientKey ?? ""}`, state: { from: props.location } }} />
    )} />
  )
}
