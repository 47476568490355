import { MultiSelect } from 'axeleratum-sgc-frontend-library/components/multi-select/multi-select';
import React, { Component } from 'react';

export class MultiSelectNative extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      showLabel: false
    }
  }
  
  render () {
    const {
      label,
      noOptionsMessage,
      options,
      searchable,
      clearable,
      message,
      value,
      onChange
    } = this.props;

    return (
      <div className="MuiFormControl-root MuiFormControl-marginDense MuiFormControl-fullWidth">
        <label 
          className={`
            MuiFormLabel-root 
            MuiInputLabel-root 
            MuiInputLabel-formControl 
            MuiInputLabel-animated 
            MuiInputLabel-shrink 
            MuiInputLabel-marginDense 
            MuiInputLabel-outlined 
            MuiFormLabel-filled
            select-material-label
            select-material-label-no-show
          `}
        >
          { label }
        </label>

        <MultiSelect 
          className=""
          classNamePrefix={ 'select' }
          options={ options } 
          onChange={ (value) => onChange(value) }
          placeholder={ label }
          noOptionsMessage={ noOptionsMessage }
          defaultValue={ value }
          isSearchable={ searchable }
          isClearable={ clearable }
        />
        {
          message && 
            <p className={`
                MuiFormHelperText-root 
                MuiFormHelperText-contained 
                Mui-error 
                MuiFormHelperText-marginDense
              `}
            >
              { message }
            </p>
        }
      </div>
    )
  }
}