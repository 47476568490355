import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { useState } from "react";
import { TextFieldNative } from "../../control/calendar/forms/fields/text-field-native";

export const TemporalUsersToCancel = ({ submitActions, onCancel, open, hideRfc = false }) => {

  const temporalUsersNotToSign = useSelector(state => state.cancelationReducer.usersNotToSign).filter(el => el.temporal);
  const usersNotToSign = useSelector(state => state.cancelationReducer.usersNotToSign).filter(el => !el.temporal);
  const allUsers = useSelector(state => state.cancelationReducer.allUsers);
  const endorser = useSelector(state => state.cancelationReducer.endorser);
  const endorsee = useSelector(state => state.cancelationReducer.endorsee);


  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [rfc, setRfc] = useState("");
  const [errors, setErrors] = useState({ name: "", email: "", rfc: "" })
  const handleSubmit = () => {

    const submitErrors = {
      name: errors.name,
      email: errors.email,
      rfc: errors.rfc
    }


    if (!name && !errors.name) {
      submitErrors.name = 'El nombre del usuario es requerido';
    }

    if (name) {
      if (name > 50) {
        submitErrors.name = 'El nombre del usuario no puede ser mayor a 100 caracteres';
      }
    }

    if (!email && !errors.email) {
      submitErrors.email = 'El correo es requerido';
    } else {
      const emailReg = new RegExp(/\S+@\S+\.\S+/);
      if (!emailReg.test(email)) {
        submitErrors.email = "Correo inválido"
      }
    }

    if (rfc && !errors.rfc) {
      const rfcReg = new RegExp(/^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{0,3})$/i);

      if (!rfcReg.test(rfc)) {
        submitErrors.rfc = 'RFC invalido';
      }
    }

    setErrors(submitErrors);
    if (!!submitErrors.name || !!submitErrors.email || !!submitErrors.rfc) return

    const id = uuidv4();
    submitActions({
      id,
      userId: id,
      name: name.trim(),
      email: email.trim().toLowerCase(),
      rfc: rfc.trim(),
      temporal: true,
    })
  }

  const handleChangeName = (name) => {
    setName(name);
  }

  const handleChangeEmail = (email) => {
    setEmail(email);
  }

  const handleChangeRfc = (rfc) => {
    setRfc(rfc);
  }

  const validateName = () => {
    const query = (obj) => obj.name.trim().toLowerCase() == name.trim().toLowerCase();

    if (name === "") {
      setErrors((pv) => ({
        ...pv,
        name: "El nombre del usuario es requerido",
      }));
      return;
    }

    const temporalUsersNotToSignToFind = temporalUsersNotToSign.some(query);
    if (temporalUsersNotToSignToFind) {
      setErrors((pv) => ({ ...pv, name: "Este nombre ya está registrado en un usuario como no firmante.", }));
      return;
    }


    if (endorser?.name.trim().toLowerCase() === name.trim().toLowerCase()) {
      setErrors((pv) => ({ ...pv, name: "Este nombre ya está registrado en un usuario y participa como la persona que cancela.", }));
      return;
    }

    if (endorsee?.name.trim().toLowerCase() === name.trim().toLowerCase()) {
      setErrors((pv) => ({ ...pv, name: "Este nombre ya está registrado en un usuario y participa como firmante adicional.", }));
      return;
    }

    const userNotToSignToFind = usersNotToSign.some(query);
    if (userNotToSignToFind) {
      setErrors((pv) => ({ ...pv, name: "Este nombre ya está registrado en un usuario catalogado en la plataforma y participa como no firmante.", }));
      return;
    }

    const userToFindInAllUsers = allUsers.some(query);
    if (userToFindInAllUsers) {
      setErrors((pv) => ({ ...pv, name: "Este nombre ya está registrado en un usuario catalogado en la plataforma.", }));
      return;
    }

    setErrors((pv) => ({ ...pv, name: "", }));

  };

  const validateEmail = () => {
    const query = (obj) => obj.email.trim().toLowerCase() == email.trim().toLowerCase();

    const emailReg = new RegExp(/\S+@\S+\.\S+/);
    if (!emailReg.test(email)) {
      setErrors((pv) => ({ ...pv, email: "Correo inválido" }));
      return;
    }

    if (email === "") {
      setErrors((pv) => ({ ...pv, email: "El correo es requerido" }));
      return;
    }

    const temporalUsersNotToSignToFind = temporalUsersNotToSign.some(query);
    if (temporalUsersNotToSignToFind) {
      setErrors((pv) => ({ ...pv, email: "Este correo ya está registrado en un usuario como no firmante." }));
      return;
    }

    if (endorser?.email.trim().toLowerCase() === email.trim().toLowerCase()) {
      setErrors((pv) => ({ ...pv, email: "Este correo ya está registrado en un usuario y participa como la persona que cancela.", }));
      return;
    }

    if (endorsee?.email.trim().toLowerCase() === email.trim().toLowerCase()) {
      setErrors((pv) => ({ ...pv, email: "Este correo ya está registrado en un usuario y participa como firmante adicional.", }));
      return;
    }

    const userNotToSignToFind = usersNotToSign.some(query);
    if (userNotToSignToFind) {
      setErrors((pv) => ({ ...pv, email: "Este correo ya está registrado en un usuario catalogado en la plataforma y participa como no firmante." }));
      return
    }

    const userToFindInAllUsers = allUsers.some(query);
    if (userToFindInAllUsers) {
      setErrors((pv) => ({ ...pv, email: "Este correo ya está registrado en un usuario catalogado en la plataforma." }));
      return;
    }

    setErrors((pv) => ({ ...pv, email: "", }));
  };

  const validateRFC = () => {

    const query = (obj) => obj.rfc.trim().toLowerCase() == rfc.trim().toLowerCase();

    if (rfc === '') {
      setErrors((pv) => ({ ...pv, rfc: "" }));
      return
    }

    const rfcReg = new RegExp(/^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{0,3})$/i);

    if (!rfcReg.test(rfc)) {
      setErrors((pv) => ({ ...pv, rfc: "RFC inválido" }));
      return;
    }

    const temporalUsersNotToSignToFind = temporalUsersNotToSign.some(query)
    if (temporalUsersNotToSignToFind) {
      setErrors((pv) => ({ ...pv, rfc: "Este RFC ya está registrado en un usuario como no firmante." }));
      return;
    }

    if (endorser?.rfc.trim().toLowerCase() === rfc.trim().toLowerCase()) {
      setErrors((pv) => ({ ...pv, rfc: "Este rfc ya está registrado en un usuario y participa como la persona que cancela.", }));
      return;
    }

    if (endorsee?.rfc.trim().toLowerCase() === rfc.trim().toLowerCase()) {
      setErrors((pv) => ({ ...pv, rfc: "Este rfc ya está registrado en un usuario y participa como firmante adicional.", }));
      return;
    }

    const userNotToSignToFind = usersNotToSign.some(query)
    if (userNotToSignToFind) {
      setErrors((pv) => ({ ...pv, rfc: "Este RFC ya está registrado en un usuario catalogado en la plataforma y participa como no firmante." }));
      return;
    }

    const userToFindInAllUsers = allUsers.some(query)
    if (userToFindInAllUsers) {
      setErrors((pv) => ({ ...pv, rfc: "Este RFC ya está registrado en un usuario catalogado en la plataforma." }));
      return;
    }

    setErrors((pv) => ({ ...pv, rfc: "" }));

  }


  return (
    <Dialog open={open}>
      <DialogTitle>Usuario Externo</DialogTitle>
      <DialogContent style={{ paddingTop: "0px", marginTop: "-16px" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextFieldNative
              label="Nombre*"
              value={name}
              message={errors.name}
              onChange={(e) => handleChangeName(e)}
              onBlur={validateName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldNative
              label="Email*"
              value={email}
              type="email"
              message={errors.email}
              onChange={(e) => handleChangeEmail(e)}
              onBlur={() => validateEmail(email)}
            />
          </Grid>
          {
            !hideRfc && (
              <Grid item xs={12}>
                <TextFieldNative
                  label="RFC (opcional)"
                  value={rfc}
                  message={errors.rfc}
                  onChange={(e) => handleChangeRfc(e)}
                  onBlur={() => validateRFC(rfc)}
                />
              </Grid>
            )
          }
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-end" }}>
        <Button
          className="font-color mr-2"
          variant="outlined"
          onClick={() => {
            onCancel();
          }}
          disabled={false}
        >
          Cancelar
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={!!Object.values(errors).find(el => el)}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
