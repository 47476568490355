import React, { Component } from 'react';
import { Card, Collapse, Grid, ClickAwayListener } from '@material-ui/core';
import { VerticalCenterDiv } from 'axeleratum-sgc-frontend-library/components/vertical-center-div/vertical-center-div';
import LockIcon from "@material-ui/icons/Lock"
import PeopleIcon from '@material-ui/icons/People';
import DescriptionIcon from '@material-ui/icons/Description';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import './dashboard-card.scss';

export class DashboardCard extends Component {
  state = {
    expanded: false
  }

  getColor() {
    const { type, style } = this.props;

    switch (type) {
      case 'business': return '#729FF9';
      case 'members': return style === 'detail' ? '#E49AB0' : '#AA63F0';
      case 'invitations': return style === 'detail' ? '#E9C46A' : '#89D694';
      case 'contracts': return '#DD7373';
      case 'activities': return '#59C3C3';
      default: break;
    }
  }

  render() {
    const { expanded } = this.state;
    const { number, text, type, style, enabledExpand, id, onExpand, loading } = this.props;

    return (
      <Grid
        className="DashboardCard animate__animated animate__fadeIn"
        item
        md={3}
        sm={6}
        xs={12}
      >
        <Card onMouseLeave={() => this.setState({ expanded: false })}>
          <Grid
            className="card"
            container
            style={{ color: style !== 'detail' ? this.getColor() : '#fff', background: style !== 'detail' && this.getColor() }}
          >
            <Grid
              className="ml-3"
              container
              item
              md={4}
              sm={3}
              xs={3}
            >
              <VerticalCenterDiv>
                <div
                  className="icon text-center"
                  style={{ background: style === 'detail' && this.getColor() }}
                >
                  <VerticalCenterDiv>
                    {
                      type === 'business' &&
                      <LockIcon />
                    }

                    {
                      type === 'members' &&
                      <PeopleIcon />
                    }

                    {
                      type === 'invitations' &&
                      <DescriptionIcon />
                    }

                    {
                      type === 'contracts' &&
                      <DescriptionIcon />
                    }

                    {
                      type === 'activities' &&
                      <WatchLaterIcon />
                    }
                  </VerticalCenterDiv>
                </div>
              </VerticalCenterDiv>
            </Grid>

            <Grid
              item
              md={6}
              sm={6}
              xs={6}
            >
              <VerticalCenterDiv>
                <Grid container>
                  <Grid
                    className="number-container"
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ color: style === 'detail' && '#000' }}
                  >
                    <label id={id}>{!loading ? number : <i className="far fa-clock"></i>}</label><br />
                    {text}
                  </Grid>
                </Grid>
              </VerticalCenterDiv>
            </Grid>
          </Grid>

          {enabledExpand &&
            <React.Fragment>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <ClickAwayListener onClickAway={() => this.setState({ expanded: false })}>
                  <Grid
                    className="card-content"
                    container
                  >
                    <Grid item xs={12} style={{ padding: '15px' }}>
                      {this.props.children}
                    </Grid>
                  </Grid>
                </ClickAwayListener>
              </Collapse>

              {expanded
                ? <ExpandLessIcon onClick={() => this.setState({ expanded: false })} style={{ display: 'block', marginLeft: '87%', marginBottom: '10px', cursor: 'pointer' }} />
                : <ExpandMoreIcon onClick={() => { this.setState({ expanded: true }); onExpand && onExpand() }} style={{ display: 'block', marginLeft: '87%', marginBottom: '10px', cursor: 'pointer' }} />
              }
            </React.Fragment>
          }
        </Card>
      </Grid>
    );
  }
}