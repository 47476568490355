import { Dialog, IconButton, Typography } from "@material-ui/core"
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

export const BasicModal = ({
  open,
  onClose,
  className,
  title,
  children,
}) => {
  return (
    <Dialog
      className={className}
      open={open}
    >
      <div className="col-md-6 col-xs-6 header">
        <IconButton
          id="calendarClose"
          className="button-border mini-button-size m-2 "
          variant="outlined"
          onClick={() => onClose()}
        >
          <strong className="fas fa-times font-color"></strong>
        </IconButton>
      </div>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent dividers>
        {children}
      </DialogContent>
    </Dialog>
  )
}
