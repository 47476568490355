import cancelationConstants from "../constants/cancelation.constants";


const cancelationActions = {

  saveAllUsers: (users) => ({
    type: cancelationConstants.SAVE_ALL_USERS_CANCELATION,
    payload: users
  }),

  saveUsersNotToSign: (users) => ({
    type: cancelationConstants.SAVE_AVAILABLE_USERS_NOT_TO_SIGN_CANCELATION,
    payload: users
  }),

  saveEndorser: (user) => ({
    type: cancelationConstants.SAVE_ENDORSER_CANCELATION,
    payload: user
  }),

  saveEndorsee: (user) => ({
    type: cancelationConstants.SAVE_ENDORSEE_CANCELATION,
    payload: user
  }),

  saveSigners: (signers) => ({
    type: cancelationConstants.SAVE_SIGNERS_CANCELATION,
    payload: signers
  }),

  saveUsersToSign: (signers) => ({
    type: cancelationConstants.SAVE_USERS_TO_SIGN_CANCELATION,
    payload: signers
  }),

  editSigner: (signer) => ({
    type: cancelationConstants.EDIT_SIGNER_CANCELATION,
    payload: signer
  }),

  restartCancelation: () => ({
    type: cancelationConstants.RESTART_CANCELATION
  }),
}

export default cancelationActions;