import React, { Component } from 'react';
import { Button, Dialog, FormHelperText } from "@material-ui/core";
import { BusinessHttp } from '../../../../core/http/business.http';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { uuid } from 'axeleratum-sgc-frontend-library';

export class FormBussines extends Component {

  bussinesHttp = new BusinessHttp()

  constructor(props) {
    super(props);

    this.state = {
      bussines: [],
      error: false,
      selectElement: null
    }

    this.bussinesHttp.findAllCompaniesUser(
      (resp) => {
        console.log("MDR en finallCompanies")
        this.setState({ bussines: resp });
      }, () => { }
    );
  }


  render() {
    const { onSubmit, open, onCancel } = this.props;
    const { bussines, error, selectElement } = this.state;

    return (
      <React.Fragment>
        <Dialog
          open={ open }
        >
          <div className="p-5">
            <div className="row">
              <div className="col-md-12">
                <h3>
                  Selecciona una bóveda
                </h3>

                <FormControl
                  variant="outlined"
                  color="primary"
                  fullWidth={ true }
                  margin="dense"
                  error={ error && (selectElement == null) }
                >
                  <InputLabel id="demo-simple-select-label">Bóveda*</InputLabel>
                  <Select
                    onChange={
                      (element) =>
                        this.setState({ selectElement: element.target.value })
                    }
                    labelWidth={ 'Bóveda*'.length * 8.5 }
                  >
                    {
                      bussines.map((item) => (
                        <MenuItem
                          key={ uuid() }
                          value={ item.value }
                        >
                          { item.label }
                        </MenuItem>
                      ))
                    }
                  </Select>

                  { (error && (selectElement == null)) && <FormHelperText>La bóveda es requerida</FormHelperText> }
                </FormControl>

                <div className="text-right mt-2">
                  <Button
                    className="font-color mr-2"
                    variant="outlined"
                    onClick={ () => onCancel() }
                  >
                    Cancelar
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    type="button"
                    onClick={() => {
                      if (selectElement == null) {
                        this.setState({ error: true });
                      } else {
                        onSubmit(selectElement);
                        this.setState({ selectElement: null });
                      }
                    }}
                  >
                    Aceptar
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}
