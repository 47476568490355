import { uuid } from 'axeleratum-sgc-frontend-library';
import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';

export class Rejected extends Component {

  render() {
    const { users, onClose } = this.props;

    return (
      <div className="p-4 rejected-dialog">
        <div className="close-button-position">
					<button
						className="primary-text"
						onClick={ () => onClose() }
					>
						<CloseIcon />
					</button>
				</div>

        <h3 className="mb-3">Firmantes</h3>
        {
          users.map(user => 
            (
              user.userId == sessionStorage.getItem('userId') ? 
                undefined
              :  
                <div key={ uuid() } className="row mb-3">
                  { console.log(user) }
                  <div className="col-md-2">
                    <div
                      className="user-icon-lg"
                      title={ user.name }
                      style={{ background: user.color }}
                    >
                      { user.name.charAt(0) }
                    </div>
                  </div>

                  <div className="col-md-8">
                    { user.name } <br />
                    <span className="primary-text">{ user.email }</span>
                  </div>

                  {
                    user.rejected &&
                      <div className="col-md-2">
                        <div style={{ background: '#ba000d' }} className="circle-check is-sign">
                          <em class="fas fa-times"></em>
                        </div>
                      </div>
                  }   
                </div>    
            )
          )  
        }
      </div>
    );
  }

}