import { alertConstants } from '../constants'

export const alertActions = {
  success,
  error,
  info,
  warning,
  close
}

function success(message) {

  return {type: alertConstants.SUCCESS, payload: message}
}

function info(message) {
  return {type: alertConstants.INFO, payload: message}
}

function warning(message) {
  return {type: alertConstants.WARNING, payload: message}
}

function error(message) {
  return {type: alertConstants.ERROR, payload: message}
}

function close() {
  return {type: alertConstants.CLOSE_ALERT}
}