import "./popup-user.scss";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { CardUserFn } from "./CardUserFn";
import { UserHttp } from "../../../../core/http/user.http";
import Alert from "@material-ui/lab/Alert";
import { useEffect, useState } from "react";
import { DraggableList } from "../../../draggableList/DraggableList";
import { AddMultipleUsers } from "./AddMultipleUsers";
import { tokenExpired } from "../../../../core/utils";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../../core/actions";


export const PopUpUserFn = (props) => {

  const { open, onlyShow, onCancel, onSubmit, labelType, initialValues, checkedOrder } = props;
  const showOrder = true;
  const [load, setLoad] = useState(false);
  const [usersSelected, setUsersSelected] = useState(initialValues ?? []);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(false);
  const [order, setOrder] = useState(checkedOrder);

  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);
  const dispatch = useDispatch();

  const onChangeUser = (user) => {
    const newArray = usersSelected.map(item => {
      if (item.id === user.userId) {
        item.type = user.type;
      }
      return item;
    });
    setUsersSelected(newArray);
  }


  const handleSubmit = () => {
    onSubmit && onSubmit(usersSelected, order);
  }

  const handleCancel = () => {
    onCancel();
  }

  const processUsers = () => {
    // nueva implementacion usa flujos de firmamex, por lo que no importa el tipo de autenticacion para el orden de firmantes
    // if (usersSelected.length > 1) {
    //   const hideCheckBox = usersSelected.find(el => el.type === "link");
    //   if (!!hideCheckBox) {
    //     setShowOrder(false);
    //     setOrder(false);
    //   } else {
    //     setShowOrder(true);
    //   }
    // }
  }

  useEffect(() => {
    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }
    const userHttp = new UserHttp();

    setLoad(true);
    userHttp.findAllUsersByDocumentId(
      props.documentId,
      props.labelType,
      (resp) => {
        const sortUsersName = (x, y) => {
          return x.name.localeCompare(y.name);
        };
        let s = resp.sort(sortUsersName);
        setUsers(s);
        setLoad(false);
      },
      (error) => {
        setLoad(false);
        setError(true);
      }
    );
    processUsers();
    // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    processUsers();
  }, [usersSelected]);




  return (
    <Dialog className="popup-user" open={open}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
        <DialogTitle>
          {onlyShow ?
            "Usuarios del documento" :
            labelType === "firm" ?
              "Firmantes seleccionados" :
              "Añade usuarios al documento"
          }
        </DialogTitle>

        {(!onlyShow && !load) && (
          <AddMultipleUsers
            values={users}
            initialValues={usersSelected}
            setItems={e => setUsersSelected(e)}
            labelType={labelType}
          />
        )}
      </div>

      {error && (
        <DialogContent>
          <Alert severity="error">
            Hubo un problema al cargar lo usuarios inténtalo mas tarde
          </Alert>
        </DialogContent>
      )}

      {load ? (
        <DialogContent className="text-center">
          <CircularProgress />
        </DialogContent>
      ) : (
        <>
          {!error && (
            <>


              <DialogContent className="card-zone">
                <Grid
                  container
                  spacing={1}
                  direction="column"
                >
                  <DraggableList
                    items={usersSelected}
                    setItems={setUsersSelected}
                  >
                    {usersSelected.map((item) => (
                      <CardUserFn
                        key={item.userId}
                        labelType={labelType}
                        onlyShow={onlyShow}
                        id={item.userId}
                        name={item.name}
                        email={item.email}
                        color={item.color}
                        type={item.type}
                        rfc={item.rfc}
                        useRFC={item.hasRFC}
                        onChange={el => onChangeUser(el)}
                      />
                    ))}
                  </DraggableList>
                </Grid>

                {usersSelected.length === 0 && (
                  <div className="text-center mt-2">
                    No hay usuarios seleccionados
                  </div>
                )}
              </DialogContent>
            </>
          )}
        </>
      )}
      <div className={`bottom-options${usersSelected.length > 1 && labelType === "firm" ? ' bottom-options-between' : ''}`}>
        {(labelType !== "firm" || usersSelected.length < 2) ? null : (
          <div>
            <FormControlLabel
              className="ml-3 mr-3 mb-1"
              control={
                <Checkbox
                  checked={order}
                  onChange={(evt) => setOrder(evt.target.checked)}
                  disabled={!showOrder}
                />
              }
              label={`Notificar en orden a los firmantes`}
            />
            <p className={`info-text ${!showOrder && `disabled`}`}>como se muestra en la lista</p>
          </div>
        )}

        <DialogActions>
          {onlyShow ? (
            <Button
              className="primary-text"
              color="primary"
              variant="outlined"
              onClick={() => onCancel()}
            >
              Cerrar
            </Button>
          ) : (
            <>
              <Button
                className="primary-text"
                color="primary"
                variant="outlined"
                onClick={() => handleCancel()}
              >
                Cancelar
              </Button>

              <Button
                color="primary"
                variant="contained"
                disabled={usersSelected.length === 0}
                onClick={() => handleSubmit()}
              >
                Aceptar
              </Button>
            </>
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
}
