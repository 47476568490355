import React from 'react'
import {
  Menu,
  MenuItem,
} from "@material-ui/core";

export const OptionsMenu = (props) => {

  const {
    options,
    anchorEl,
    handleCloseMenu,
    handleClickMeuItem,
  } = props;

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl) && options.length > 0}
      onClose={handleCloseMenu}
    >
      {
        options.map((item, index) => (
          <MenuItem
            key={`${index}${Date.now()}`}
            onClick={(evt) => handleClickMeuItem(item.value)}
            disabled={!!item.disblaed}
          >
            {item.label}
          </MenuItem>
        ))
      }
    </Menu>
  )
}
