import React, { Component } from 'react';
import { Card, Grid } from '@material-ui/core';
import { VerticalCenterDiv } from 'axeleratum-sgc-frontend-library/components/vertical-center-div/vertical-center-div';
import { uuid } from 'axeleratum-sgc-frontend-library';
import FolderIcon from '@material-ui/icons/Folder';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import './business-folders.scss';

class BusinessFolders extends Component {
  render() {
    const { folders, onClick, showReturn, onReturn } = this.props;
    
    return (
      <React.Fragment>
        <Grid container item className={"animate__animated animate__fadeIn"} spacing={2}>
          {
            showReturn &&
            <Grid className="BusinessFolders" item
            md={ 2 }
            sm={ 4 }
            xs={ 12 }
              onClick={ () => onReturn && onReturn() }
            >
              <Card className="card">
                <Grid container justifyContent="center">
                  <VerticalCenterDiv>
                    <KeyboardReturnIcon className="mt-4" />
                  </VerticalCenterDiv>
                </Grid>
              </Card>
            </Grid>
          }

          { showReturn && folders.length === 0 &&
            <Grid item xs={ 11 } className="mt-3">
              No se encontraron folders
            </Grid>
          }
      
          { folders && folders.length > 0 &&
            folders.map((folder) => (
              <Grid
                key={ uuid() }
                className={`BusinessFolders ${!(!folder.onlyExistInMongo && !folder.onlyExistInKaleido) ? 'BusinessFolders-folderDisabled' : ''}`}
                item
                md={ 3 }
                sm={ 6 }
                xs={ 12 }
                onClick={ () => (onClick && (!folder.onlyExistInMongo && !folder.onlyExistInKaleido)) && onClick(folder) }
              >
                <Card className={`card`} >
                  <VerticalCenterDiv>
                    <Grid container>
                      <Grid className="text-center" item md={ 3 } sm={ 3 }>
                        <FolderIcon className="icon" />
                      </Grid>

                      <Grid className="mt-1" item md={ 6 } sm={ 6 }>
                        <strong>{ folder.name }</strong>
                      </Grid>
                    </Grid>
                  </VerticalCenterDiv>
                </Card>
              </Grid>
            ))
          }
        </Grid>

        {
          (folders.length === 0 && !showReturn)&&
            <Grid container item xs={12}>
              No se encontraron folders
            </Grid>
        }
      </React.Fragment>
    );
  }
}

export default BusinessFolders;
