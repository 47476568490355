export const permissions = {
  //usuarios
  editUser: "Editar Usuarios",
  registerPlatformUser: "Alta de Usuarios",
  reactiveUserAccounts: "Reactivar Cuentas de Usuario",
  blockPlatformUser: "Bloquear usuario de la plataforma",
  deletePlatformUsers: "Borrar Usuarios de la Plataforma",
  externalUsersManager: "Mantenimiento de usuarios Externos",
  //?
  DesignateAssistantAdmin: "Designar Asistente Admin",
  AssignUserAsBusinessAdmin: "Asignar un usuario como Admin de Bóveda",
  AssignModifyRolesOfAllUsers: "Asignar/Modificar Roles de todos los Usuarios",
  //bovedas
  editCompany: "Editar una Bóveda",
  createCompany: "Crear una Bóveda",
  reactivateCompany: "Reactivar Bóveda",
  blockCompany: "Bloquear/Reactivar Bóveda",
  removeUserfromCompany: "Retirar Usuario de una Bóveda",
  enrollUsersInCompany: "Inscribir usuarios dentro de una bóveda",
  //documentos
  createFolder: "Crear Folder",
  signBlock: "Firmar en Bloque",
  inviteToSign: "Invitar a Firmar",
  uploadDocument: "Subir Documento",
  signDocuments: "Firmar documentos",
  reviewDocument: "Revisar Documento",
  deleteActivity: "Eliminar Actividad",
  prepareSignatures: "Preparar Firmas",
  deleteDocument: "Eliminar Documento",
  modifyDocument: "Modificar Documento",
  restoreDocument: "Restaurar Documentos",
  accessDocuments: "Acceder a los Documentos",
  createReviewActivity: "Crear Actividad de Revision",
  createSignatureActivity: "Crear Actividad de Firma",
  moveDocumentsAndFolders: "Mover documentos o folders",
  addCollaboratorsToDocument: "Agregar Colaboradores a un Documento",
  moveDocumentsAndFoldersFromCompany: "Mover documentos o folders a otra bóveda",
  //plantillas
  useTemplate: "Utilizar Plantilla",
  addTemplate: "Agregar Plantilla",
  editTemplate: "Editar Plantilla",
  deleteTemplate: "Eliminar Plantilla",

  allAuthorities: "Todos",

}