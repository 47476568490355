import React from 'react'
import { Dialog, Slide } from '@material-ui/core';
import PDFViewerEndorsement from '../../../../../from-library/pdf-viewer-v2/PDFViewerEndorsement';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DocumentEndorsement = (props) => {
  const {
    open,
    onClose,
    signers,
    url,
    documentName,
    onSaveSigns,
    stickersValue,
    currentSignatures,
    strokeAndText
  } = props;
  return (
    <Dialog
      maxWidth={false}
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <PDFViewerEndorsement
        users={signers}
        url={url}
        documentName={documentName}
        onClose={onClose}
        onSaveSigns={onSaveSigns}
        signatures={stickersValue ?? []}
        currentSignatures={currentSignatures}
        strokeAndText={strokeAndText}
      />
    </Dialog>
  )
}
