import { axiosRequestBlockChain, axiosBusinessRequest, authHeader } from '../../configs/axios.config';

export class CorporateBooksHttp {
  getAllBooks(companyId, success, error) {
    axiosRequestBlockChain.get(`/corporate-book/${companyId}`, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp))
  }

  downloadCorporateBook(params, success, error) {
    axiosRequestBlockChain.post(`/book/download`, params, {headers: authHeader(),// no se esta usando
      responseType: 'arraybuffer'})
    .then((data) => success(data))
    .catch(errorResp => error(errorResp));
  }

}