import React, { Component } from "react";
import { Grid, IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import imgTc from '../../assets/img_tc.png';

class TermsAndConditions extends Component {
  render() {
    const { className, onReturn } = this.props;

    return (
      <Grid className={className} container item xs={12}>
        <Grid
          item
          container
          md={12}
          spacing={1}
          alignItems="center"
          justifyContent='center'
        >
          <Grid
            item
            md={10}
            xs={10}
            container
            direction="row"
            alignItems="center"
          >
            <Grid item xs={2}>
              <IconButton
                color="primary"
                edge="start"
                onClick={() => onReturn && onReturn()}
              >
                <ArrowBackIcon />
              </IconButton>
            </Grid>

            <Grid item xs={10}>
              <h3>Términos y condiciones</h3>
            </Grid>
          </Grid>

          <Grid
            item
            md={10}
            xs={10}
            container
            alignItems="center"
          >

          </Grid>

          <Grid
            item
            md={10}
            xs={10}
            container
            alignItems="center"
          >
            <Grid item>
              <p>Fecha de última actualización: 08 de junio de 2022</p>
            </Grid>
            <Grid item>
              <p>Este sitio web es propiedad de GC DIGITAL, S.A. DE C.V. (“KÄSIÄ” o “KÄSIÄ SOLUTIONS”) quien es titular de todos los derechos de propiedad intelectual relacionados con KÄSIÄ y es operado por el equipo de KÄSIÄ SOLUTIONS.</p>
              <p>En todo el sitio, los términos “nosotros”, “nos” y “nuestro” se refieren al equipo KÄSIÄ SOLUTIONS (refiriéndose todo el tiempo a este sitio como http:// www.kasiasolutions.com) como un servicio de software a nuestros clientes.  Estos Términos y condiciones de los Servicios de KÄSIÄ SOLUTIONS (los "Términos") rigen el acceso y el uso de los sitios web y Servicios de KÄSIÄ SOLUTIONS ("KÄSIÄ SOLUTIONS", "nosotros", “nos” o "nuestro") (colectivamente, el "Sitio") para todos los visitantes del sitio ("Visitantes del Sitio"), y personas o entidades que adquieren nuestros servicios (los "Servicios de KÄSIÄ SOLUTIONS") o que crean una cuenta ("Cuenta"), así como para todos sus Usuarios Autorizados (todos colectivamente, los "Clientes"). Al utilizar el Sitio o cualquiera de los Servicios de KÄSIÄ SOLUTIONS, usted como Visitante del Sitio o Cliente acepta estos Términos (ya sea en nombre propio o de la entidad legal a la que usted representa). Un "Usuario Autorizado" de un Cliente es una persona física, ya sea un empleado, socio comercial, contratista o agente de un Cliente que está registrado o autorizado por el Cliente para utilizar los Servicios de KÄSIÄ SOLUTIONS, sujetos a estos Términos y hasta un número máximo de usuarios o usos determinados al momento de la adquisición de los mismos. Los Clientes y Visitantes del Sitio pueden ser referidos dentro de estos Términos como "usted" y/o "su", según corresponda.</p>
              <p>AL ACCEDER, UTILIZAR O DESCARGAR CUALQUIER MATERIAL DEL SITIO, USTED ACEPTA ESTAR OBLIGADO Y CUMPLIR CON ESTOS TÉRMINOS. SI USTED NO ESTÁ DE ACUERDO CON ESTOS TÉRMINOS, USTED NO ESTÁ AUTORIZADO Y DEBE DEJAR DE UTILIZAR EL SITIO INMEDIATAMENTE.</p>
            </Grid>
            <Grid item>
              <h3>1. ACEPTACIÓN DE CONDICIONES.</h3>
              <p>1.1 Aceptación de Condiciones por el Uso. Usted acepta respetar y regirse con respecto a los servicios de KÄSIÄ SOLUTIONS en el uso de su Plataforma Tecnológica por el uso de Software como Servicio, y en general cualquier otro servicio proporcionado por KÄSIÄ SOLUTIONS, mediante los presentes Términos, los cuales son aceptados expresamente por el Cliente cuando se de alguno de los siguientes hechos ya sea de manera independiente o conjunta:</p>
              <p>1.1.1 Uso del Software como Servicio. Por el uso del Sitio de KÄSIÄ SOLUTIONS para hacer uso del Software como Servicio, el uso de la plataforma tecnológica y cualquier otro servicio prestado por KÄSIÄ SOLUTIONS desde el sitio.</p>
              <p>1.1.2 Al proporcionar sus datos de contacto. Cuando para hacer uso por primera ocasión del Servicio proporcione su número telefónico y/o su correo electrónico; estos serán vinculados de forma independiente o conjunta, a un número de identificación único de usuario, el cual será asignado para activar el primer uso del Servicio contratado.</p>
            </Grid>

            <Grid item>
              <h3>2.ACTUALIZACIONES Y COMUNICACIONES</h3>
              <p><strong>2.1</strong> Podemos modificar estos Términos o cualquiera de los Términos y condiciones adicionales que son relevantes para algún Servicio de KÄSIÄ SOLUTIONS en particular, de vez en cuando, para reflejar cambios en las legislaciones aplicables o respecto de los Servicios de KÄSIÄ SOLUTIONS. Publicaremos los términos revisados en el Sitio con la referencia y fecha de "última actualización". Por favor, revise el sitio de manera regular para contar con un aviso oportuno respecto de cualquier posible modificación. Si usted continúa utilizando los servicios de KÄSIÄ SOLUTIONS después de que las modificaciones entren en vigor, usted se compromete a respetar dichos términos modificados. Usted acepta que no seremos responsables ante usted, ni ante ningún tercero, por ninguna modificación a los Términos.</p>
              <p><strong>2.2</strong> Usted acepta recibir, todas y cualquier comunicación, acuerdo y aviso que le proporcionemos en relación con cualquier Servicio de KÄSIÄ SOLUTIONS ("Comunicaciones"), incluyendo de manera enunciativa más no limitativa, Comunicaciones relacionadas con los Servicios de KÄSIÄ SOLUTIONS y de su compra de la suscripción de los Servicios de KÄSIÄ SOLUTIONS, por medios electrónicos, incluso por correo electrónico, mensaje de texto, notificaciones en el producto, o publicaciones en el Sitio o a través de cualquier Servicio de KÄSIÄ SOLUTIONS. Usted acepta que todas las Comunicaciones proporcionadas a usted electrónicamente satisfacen los mismos requisitos legales como si dichas comunicaciones se hicieran por escrito o que se entreguen en una manera en particular; y usted acepta mantener actualizada la información de contacto de su Cuenta.</p>

            </Grid>

            <Grid item>
              <h3>3.TÉRMINOS ADICIONALES PARA LOS SERVICIOS DE KÄSIÄ SOLUTIONS</h3>
              <p><strong>3.1 Firma de KÄSIÄ SOLUTIONS.</strong>  Si usted utiliza la Firma de KÄSIÄ SOLUTIONS, usted acepta los Términos del Programa de la Firma de KÄSIÄ SOLUTIONS que se describe <u>a continuación.</u></p>
              <p><strong>3.2 Proveedor de Firma electrónica avanzada de KÄSIÄ SOLUTIONS.</strong> Si usted utiliza la función de Firma electrónica avanzada, reconoce y acepta que este servicio es prestado de forma independiente a KÄSIÄ SOLUTIONS por un tercero: Firmamex Technologies S.A.P.I. (el “Proveedor”), por lo que desde este momento se compromete a apegarse a los Términos y condiciones del Proveedor y a cumplir todas las medidas de seguridad pertinentes para su correcto uso y funcionamiento. Usted acepta que KÄSIÄ SOLUTIONS no tiene responsabilidad sobre esta funcionalidad.</p>
              <p><strong>3.3 Emisor y Administrador de servicios de Certificación.</strong> A efecto de proporcionar la emisión de certificados digitales con validez jurídica en el Sector Comercial y Constancias de Conservación de Mensajes de Datos de acuerdo a los estándares establecidos por la Norma Oficial Mexicana NOM 151, el Proveedor de KÄSIÄ SOLUTIONS acredita mediante un tercero denominado:  Cecoban S. A. de C. V. la emisión de sellos digitales de tiempo, conforme a la autorización otorgada por Banco de México para emitir Certificados Digitales como la Agencia Registradora para todos los bancos en México.</p>
              <p><strong>3.4 Contenido de Terceros.</strong> Podemos proporcionar, o terceros pueden proporcionar, enlaces a sitios web de terceros, servicios o recursos que están fuera de nuestro control. No hacemos ninguna declaración en cuanto a la calidad, idoneidad, funcionalidad o legalidad de cualquier contenido de terceros de los cuales se hayan proporcionado enlaces, y usted renuncia a cualquier reclamación que pueda tener contra nosotros con respecto a dichos servicios. KÄSIÄ SOLUTIONS NO ES RESPONSABLE DEL CONTENIDO EN INTERNET O DE LAS PÁGINAS WEB QUE SE ENCUENTRAN FUERA DEL SITIO O DE LAS PUBLICACIONES DE CONTENIDO CREADAS POR LOS USUARIOS. Su correspondencia o tratos comerciales, o la participación en promociones de anunciantes o socios encontrados en o a través del Sitio, incluido el pago y la entrega de bienes o servicios relacionados, y cualquier otro término, condición, garantía o declaración asociada con tales transacciones, son únicamente entre usted y dicho anunciante o socio. Usted acepta que no somos responsables de ninguna pérdida o daño de ningún tipo incurrido como resultado de tales transacciones o como resultado de la presencia de dichos anunciantes o enlaces a sitios web o recursos de terceros en el Sitio.</p>
            </Grid>

            <Grid item>
              <h3>4.DERECHOS DE USO Y ACCESO</h3>
              <p><strong>4.1 Elegibilidad para Utilizar.</strong> Usted declara y garantiza que: (a) es mayor de edad (tiene 18 años o más o que usted es mayor de edad en su jurisdicción) y que es competente o cuenta las facultades necesarias para aceptar estos Términos; y (b) que usted (o sus Usuarios Autorizados, según corresponda), al utilizar el Sitio, no se encuentra o encontrará localizado o bajo el control de una persona perteneciente a algún país o territorio respecto del cual contravenga las leyes o regulaciones vigentes en Firma Electrónica Digital. En este supuesto, Usted reconoce y acepta que no tiene permitido utilizar el Sitio, si no puede confirmar que usted no cae en ninguna de las situaciones anteriormente descritas. Si KÄSIÄ SOLUTIONS le prohibió anteriormente acceder al Sitio o utilizar los Servicios de KÄSIÄ SOLUTIONS, no tiene permitido acceder al Sitio, ni utilizar los Servicios de KÄSIÄ SOLUTIONS. Si está aceptando estos Términos en nombre de una organización o entidad, usted declara y garantiza que está debidamente autorizado para aceptar estos Términos, en nombre y representación de esa organización o entidad, por lo cual los obliga a cumplir con estos Términos (en cuyo caso, las referencias a "usted" y "su"" en estos Términos, se refieren a dicha organización o entidad).</p>
              <p><strong>4.2 Limitación respecto de la Licencia</strong> Tras su aceptación de estos Términos, le otorgamos una licencia limitada, no exclusiva e intransferible para acceder y utilizar el Sitio para sus fines comerciales internos y solo según lo expresamente permitido en estos Términos, así como para cualquier Servicio que haya sido pagado por el Cliente y que permita el registro de una Cuenta para el uso de un Servicio de KÄSIÄ SOLUTIONS según corresponda. No deberá utilizar, ni permitir el uso del Sitio, para ningún propósito ilegal o que de cualquier manera sea incompatible con las disposiciones previstas por estos Términos. Si usted es o se convierte en un competidor directo de KÄSIÄ SOLUTIONS, no puede acceder, ni utilizar los Servicios de KÄSIÄ SOLUTIONS, sin el consentimiento explícito, previo y por escrito, de KÄSIÄ SOLUTIONS, y solo podrá ser utilizados para los fines que expresamente sean autorizados por escrito. Excepto que esté restringido de otra manera por estos Términos, KÄSIÄ SOLUTIONS le otorga permiso de forma no exclusiva, intransferible y limitada para mostrar, copiar y descargar contenido y materiales en el Sitio siempre que usted: (a) sea titular de los derechos de autor y otros derechos de propiedad intelectual según los avisos sobre el contenido y materiales; (b) los utilice únicamente para uso personal o interno, no comercial o de acuerdo con cualquier Plan de Suscripción aplicable; y (c) no los modifique de ninguna manera. Cada uno de los servicios de KÄSIÄ SOLUTIONS, incluye restricciones y requisitos que describen las funcionalidades a las que el Cliente podrá acceder. Cualquier violación, por su parte, de las disposiciones de licencia contenidas en esta Sección 4, puede resultar en la terminación inmediata de su derecho para utilizar el Sitio, así como la responsabilidad potencial por infracción de derechos de autor u otros reclamos dependiendo de las circunstancias.</p>
            </Grid>
            <Grid item>
              <h3>5. PROPIEDAD</h3>
              <p><strong>5.1 Propiedad Intelectual.</strong> El Sitio contiene materiales que son propiedad de KÄSIÄ SOLUTIONS y están protegidos por las leyes de derechos de autor, disposiciones de tratados internacionales, marcas y otras leyes y tratados de propiedad intelectual.</p>
              <p><strong>5.1.1</strong> El Sitio también está protegido como una obra colectiva o una compilación, de acuerdo con los derechos de autor, así como otras leyes y tratados internacionales. Usted acepta cumplir con todas las leyes de derechos de autor y cualquier otra ley aplicable, así como con cualquier aviso o restricción respecto de los derechos de autor adicionales contenidos en el Sitio. Usted reconoce que el Sitio contiene obras originales que han sido desarrolladas, compiladas, preparadas, revisadas, seleccionadas y organizadas por KÄSIÄ SOLUTIONS y por otros a través de la aplicación de métodos y estándares, mediante la inversión sustancial de tiempo, esfuerzo y dinero, y por lo cual constituye propiedad intelectual valiosa que pertenece a KÄSIÄ SOLUTIONS y a otros. Todos los derechos presentes y futuros respecto de secretos comerciales, patentes, diseños, derechos de autor, marcas, derechos sobre las bases de datos, conocimientos técnicos y otros derechos de propiedad intelectual u otros derechos de propiedad de cualquier tipo, documentación, mejoras, contribuciones de diseño u obras derivados, así como cualquier conocimiento o proceso relacionado con los mismos, incluidos los derechos en y para todas las aplicaciones y registros relacionados con el Sitio, serán, para usted y KÄSIÄ SOLUTIONS, en todo momento propiedad única y exclusiva de KÄSIÄ SOLUTIONS.</p>
              <p><strong>5.1.2</strong>Las marcas, logotipos y eslóganes o avisos comerciales que se muestran en el Sitio (colectivamente, las "Marcas") son marcas registradas y no registradas, propiedad de KÄSIÄ SOLUTIONS y de otros. Por lo general, las Marcas no deben ser utilizadas en ningún tipo de campaña o publicidad, ni para indicar el patrocinio o la afiliación de KÄSIÄ SOLUTIONS con ningún producto, servicio, evento u organización sin la autorización previa y por escrito de KÄSIÄ SOLUTIONS. KÄSIÄ SOLUTIONS reconoce las marcas de otras organizaciones para sus respectivos productos o servicios mencionados en el Sitio. Todos los derechos no expresamente otorgados en estos Términos están reservados por KÄSIÄ SOLUTIONS.</p>
              <p><strong>5.1.3 3    Derechos de Autor.</strong> KÄSIÄ SOLUTIONS respeta las leyes de derechos de autor y espera que sus usuarios hagan lo mismo. Si usted considera que algún contenido o material que se muestra en el Sitio viola los derechos de autor de su propiedad, por favor notifíquenoslo al correo de contacto <a href="mailto: info@kasiasolutions.com">info@kasiasolutions.com</a> o al teléfono +52 55 7973 2838</p>
              <p><strong>5.2 Restricciones para el Uso del Software.</strong> El software disponible para descarga, a través del Sitio o de sitios web o aplicaciones de terceros (el "Software"), es propiedad intelectual de KÄSIÄ SOLUTIONS y de sus proveedores externos. El uso del software se rige por estos Términos. La reproducción o distribución no autorizada del Software está expresamente prohibida por la ley y puede dar lugar a sanciones tanto civiles, como penales. Se podrá iniciar procedimientos en contra de los infractores.</p>
              <p><strong>5.3 Contenido del Usuario y Envíos que no son Documentos Electrónicos.</strong></p>
              <p><strong>5.3.1 Presentaciones.</strong>El Sitio o los Servicios de KÄSIÄ SOLUTIONS pueden permitirle enviar, publicar, cargar o poner a disposición (colectivamente, publicar) contenido como preguntas, mensajes públicos, ideas, comentarios de productos, comentarios y otro contenido (colectivamente, "Contenido del Usuario"), mismo que puede o no ser visibles por otros usuarios. Si usted publica Contenido de Usuario, a menos que indiquemos lo contrario, usted nos otorga una licencia no exclusiva, libre del pago de regalías y totalmente sublicenciable para acceder, ver, usar, reproducir, modificar, adaptar, publicar, traducir, crear obras derivadas, distribuir, copiar y mostrar dicho Contenido de Usuario en todo el mundo en cualquier forma, medio o tecnología ahora conocida o desarrollada en el futuro. También permite que cualquier otro usuario vea, copie, acceda, almacene o reproduzca dicho Contenido de Usuario para el uso personal de ese usuario. Usted nos otorga el derecho de utilizar el nombre y otra información sobre usted que envíe en relación con dicho Contenido de Usuario. Usted declara y garantiza que: (a) posee o controla de otro modo todos los derechos sobre el Contenido del Usuario que publica; (b) el contenido del usuario que publica es veraz y exacto; y (c) el uso del Contenido del Usuario que publica no infringe estos Términos ni ninguna ley aplicable. Para evitar dudas, el Contenido del Usuario no incluye ningún documento, como un contrato, divulgación o aviso que sea depositado en el servicio de Firma de KÄSIÄ SOLUTIONS para su procesamiento ("Documentos Electrónicos").</p>
              <p><strong>5.3.2 Detección y Eliminación.</strong>Usted reconoce y acepta que KÄSIÄ SOLUTIONS y las personas que KÄSIÄ SOLUTIONS designe pueden o no, a discreción de KÄSIÄ SOLUTIONS, realizar una revisión previa del Contenido del Usuario, antes de su aparición en el Sitio o los Servicios de KÄSIÄ SOLUTIONS. Sin embargo, KÄSIÄ SOLUTIONS no tiene la obligación de hacerlo. Además, reconoce y acepta que KÄSIÄ SOLUTIONS se reserva el derecho (pero no asume la obligación), a su exclusivo criterio, de rechazar, mover, editar o eliminar cualquier Contenido de Usuario que se haya contribuido al Sitio o a los Servicios de KÄSIÄ SOLUTIONS. Sin perjuicio de lo anterior, KÄSIÄ SOLUTIONS y sus designados tendrán derecho a eliminar cualquier Contenido de Usuario que infrinja estos Términos o que sea objetable, a entera discreción de KÄSIÄ SOLUTIONS. Usted reconoce y acepta que KÄSIÄ SOLUTIONS no verifica, adopta, ratifica, ni sanciona el Contenido del Usuario, y acepta que debe evaluar y asumir todos los riesgos asociados con su uso del Contenido del Usuario o su confianza en la precisión, integridad o utilidad del Contenido del Usuario.</p>
            </Grid>
            <Grid item>
              <h3>6. RESTRICCIONES DE USO DEL SITIO</h3>
              <p><strong>6.1</strong> Al utilizar el Sitio, incluido cualquier Servicio de KÄSIÄ SOLUTIONS, usted acepta específicamente, no participar en ninguna actividad, ni transmitir ninguna información que a nuestro exclusivo criterio:</p>
              <ol>
                <li>i.	Sea ilegal, o viole cualquier ley o disposición federal, estatal o local;</li>
                <li>ii.	Incite actividades ilegales o discuta actividades ilegales con la intención de cometerlas;</li>
                <li>iii.	Viole cualquier derecho de terceros, incluidos, entre otros, el derecho a la privacidad, el derecho a la propia imagen, el derecho de autor, derechos de marca, patentes, secretos comerciales o industriales; o cualquier otra propiedad intelectual o derechos de propiedad;</li>
                <li>iv.	Sea dañina, amenazante, abusiva, acosadora, indecente, difamatoria, sexualmente explícita o pornográfica, discriminatoria, vulgar, profana, obscena, calumniosa, incite al odio o violencia, o sea de otra manera objetable;</li>
                <li>v.	Interfiera con el uso y disfrute de los Servicios por parte de terceros conforme al numeral 3;</li>
                <li>vi.	Se proponga hacerse pasar por otra persona o entidad;</li>
                <li>vii.	Sea comercial de tal manera que infrinja estos Términos, incluidos, entre otros, el uso del Sitio para correo no deseado, encuestas, concursos, esquemas piramidales u otros materiales publicitarios;</li>
                <li>viii.	Declare falsamente, tergiverse u oculte su afiliación con otra persona o entidad;</li>
                <li>ix.	Acceda o utilice la Cuenta de otro usuario sin permiso;</li>
                <li>x.	Distribuya virus informáticos u otros códigos, archivos o programas que interrumpan, destruyan o limiten la funcionalidad de cualquier software o hardware o equipo de comunicaciones electrónicas;</li>
                <li>xi.	Interfiera, interrumpa, deshabilite, sobrecargue o destruya la funcionalidad o el uso de cualquier característica del Sitio, o los servidores o redes conectadas al Sitio, o cualquiera de los Servicios de KÄSIÄ SOLUTIONS;</li>
                <li>xii.	"Hackee" o acceda sin permiso a nuestros registros privados o confidenciales, los de otro usuario, o los de cualquier otra persona;</li>
                <li>xiii.	Solicite información personal o confidencial de otros usuarios, incluyendo, entre otros, la dirección, la información de la tarjeta de crédito o información financiera, o las contraseñas;</li>
                <li>xiv.	Descompile, haga ingeniería inversa, desensamble o intente obtener el código fuente del Sitio, excepto lo expresamente permitido en estos Términos o por ley, a menos que sea permitido por la ley aplicable sin que exista consentimiento;</li>
                <li>xv.	Elimine, eluda, inhabilite, dañe o interfiera de cualquier otro modo con las características relacionadas con la seguridad, o las características que hagan cumplir las limitaciones de uso del Sitio;</li>
                <li>xvi.	Utilice medios automáticos o manuales para violar las restricciones en los encabezados de exclusión de robots en el Sitio, si los hay, o evite o eluda otras medidas empleadas para prevenir o limitar el acceso, por ejemplo, al participar en prácticas como “screen scraping”, “database scraping,” o cualquier otra actividad, con el propósito de obtener listas de usuarios u otra información;</li>
                <li>xvii.	Modifique, copie, raspe u obtenga, exhiba, distribuya, publique, licencie, venda, alquile, arriende, preste, transfiera o comercialice cualquier material o contenido en el Sitio;</li>
                <li>xviii.	Utilice los Servicios para realizar evaluaciones comparativas, o para compilar información para un producto o servicio;</li>
                <li>xix.	Descargue (que no sean a través del almacenamiento en caché de páginas necesario para uso personal, o según lo expresamente permitido por estos Términos), distribuya, publique, transmita, realice, reproduzca, difunda, duplique, cargue, licencie, cree obras derivadas u ofrezca vender cualquier contenido u otra información contenida u obtenida a través del Sitio o los Servicios de KÄSIÄ SOLUTIONS, por cualquier medio, excepto según lo estipulado en estos Términos o con el consentimiento previo por escrito de KÄSIÄ SOLUTIONS; o</li>
                <li>xx.	Se proponga hacer cualquiera de los anteriores.</li>
              </ol>
              <p>Si cree que el contenido del sitio infringe las restricciones anteriores, contáctenos aquí: <a href="mailto:info@kasiasolutions.com"> info@kasiasolutions.com</a></p>
              <p><strong>6.2</strong> Además, los Clientes no deberán y no permitirán que otros lleven cabo las siguientes actividades con respecto a los Servicios de KÄSIÄ SOLUTIONS:</p>
              <ol>
                <li>i.	Utilizar los Servicios de KÄSIÄ SOLUTIONS o permitir el acceso a ellos, de una manera que evite las restricciones contractuales de uso; que excedan las métricas de uso; o uso autorizado establecidas en estos Términos o la Política de Uso Razonable de KÄSIÄ SOLUTIONS.</li>
                <li>ii.	Licenciar, sublicenciar, vender, revender, alquilar, arrendar, transferir, distribuir o compartir en el tiempo, o realizar cualquier parte de los Servicios de KÄSIÄ SOLUTIONS o la documentación técnica y funcional actual de KÄSIÄ SOLUTIONS para los Servicios de KÄSIÄ SOLUTIONS ("Documentación") disponibles para el acceso de terceros, a menos que se indique lo contrario en estos Términos; o</li>
                <li>iii.	Acceder o utilizar los Servicios o la Documentación de KÄSIÄ SOLUTIONS con el fin de (i) desarrollar u operar productos o servicios destinados a ser ofrecidos a terceros como competencia de los Servicios de KÄSIÄ SOLUTIONS o (ii) permitir el acceso a un competidor directo de KÄSIÄ SOLUTIONS.</li>
                <li>iv.	No puede enmarcar ("frame") el Sitio, colocar ventanas emergentes sobre sus páginas o de cualquier manera afectar la visualización de sus páginas. Puede vincular al Sitio, siempre que reconozca y acepte que no vinculará el Sitio a ningún sitio web que contenga ningún tema, nombre, material o información inapropiada, profana, difamatoria, infractora, obscena, indecente o ilegal o que viole cualquier propiedad intelectual, propiedad, privacidad o derechos de imagen. Cualquier violación de esta disposición puede, a nuestro exclusivo criterio, dar como resultado la terminación inmediata de su uso y acceso al Sitio.</li>
                <p>Usted reconoce que no tenemos la obligación de monitorear su acceso o uso del Sitio, o el de cualquier otra persona, para identificar violaciones de estos Términos, o para revisar o editar cualquier contenido. Sin embargo, tenemos el derecho de hacerlo, con el fin de operar y mejorar el Sitio (incluyendo, entre otros, prevención de fraude, evaluación de riesgos, investigación y soporte al cliente, análisis y publicidad), para garantizar el cumplimiento de estos Términos y para cumplir con la ley aplicable o la orden o requisito de un tribunal, decreto de consentimiento, agencia administrativa u otro organismo gubernamental.</p>
              </ol>
            </Grid>
            <Grid item>
              <h3>7. PRIVACIDAD</h3>
              <p><strong>7.1 Política de privacidad de KÄSIÄ SOLUTIONS.</strong> Usted reconoce que, a excepción de lo descrito en estos Términos o los Términos Corporativos aplicables, la información que nos proporcione o que recolectemos se usará y se protegerá tal como se describe en el Aviso de Privacidad de KÄSIÄ SOLUTIONS. Por favor, lea el Aviso de Privacidad atentamente.</p>
              <p><strong>7.2 Acceso y Divulgación.</strong> Podemos acceder, preservar o compartir su información cuando creemos, de buena fe, que tal intercambio es razonablemente necesario para investigar, prevenir o tomar medidas con respecto a posibles actividades ilegales o para cumplir con procesos legales (por ejemplo, un requerimiento u otro proceso legal). También podemos compartir su información en situaciones que impliquen amenazas potenciales a la seguridad física de cualquier persona, violaciones al Aviso de Privacidad de KÄSIÄ SOLUTIONS o de los contratos o términos de los usuarios; o para responder a reclamaciones respecto a posibles violaciones de derechos de terceros y/o para proteger los derechos, propiedad y seguridad de KÄSIÄ SOLUTIONS, nuestros empleados, usuarios o el público en general. Esto puede implicar el intercambio de su información con la policía, agencias o autoridades gubernamentales, tribunales y/u otras organizaciones.</p>

            </Grid>
            <Grid item>
              <h3>8. TÉRMINOS ESPECÍFICOS DE LOS SERVICIOS DE KÄSIÄ SOLUTIONS</h3>
              <p><strong>8.1 Derecho a Usar los Servicios de KÄSIÄ SOLUTIONS.</strong> Sujeto a estos Términos, KÄSIÄ SOLUTIONS proporcionará los Servicios de KÄSIÄ SOLUTIONS a los Clientes, de acuerdo con el Plan de Suscripción que cada Cliente haya adquirido, y KÄSIÄ SOLUTIONS otorga a cada Cliente un derecho y una licencia, ambos limitados, no exclusivos e intransferibles, durante el Plazo estipulado, únicamente para fines comerciales internos y en todo momento de acuerdo con la Documentación, para: (a) utilizar los Servicios de KÄSIÄ SOLUTIONS; (b) implementar, configurar y, por medio de su Administrador(es) de Cuenta, permitir a sus Usuarios Autorizados acceder y usar los Servicios de KÄSIÄ SOLUTIONS, hasta cualquier límite o máximo aplicable; y (c) para acceder y usar la Documentación.</p>
              <p><strong>8.1.1 Usuarios Autorizados.</strong> Los Usuarios Autorizados del Cliente deben ser identificados por una dirección de correo electrónico y un nombre de usuario únicos, y dos o más personas físicas no pueden usar los Servicios de KÄSIÄ SOLUTIONS como el mismo Usuario Autorizado. Si el Usuario Autorizado no es un empleado del Cliente solo se permitirá el uso de los Servicios de KÄSIÄ SOLUTIONS si el usuario se encuentra bajo obligaciones de confidencialidad y otras obligaciones con el Cliente, siempre y cuando dichas obligaciones sean al menos tan restrictivas como las contenidas en los presentes Términos, y se le permite el acceso o la utilización de los Servicios de KÄSIÄ SOLUTIONS únicamente para respaldar los propósitos comerciales internos del Cliente.</p>
              <p><strong>8.1.2 Administrador de Cuenta.</strong> El Cliente podrá asignar y expresamente autorizar a Usuarios Autorizados como su agente para administrar la Cuenta del Cliente y la administración de la Cuenta del Cliente, misma que incluye de manera enunciativa más no limitativa, la configuración de los ajustes de administración, asignación de acceso y de autorización de uso, solicitar servicios adicionales o diferentes, proporcionando registros de utilización y rendimiento, administrando plantillas, ejecutando campañas y eventos aprobados, asistiendo en la integración de productos de terceros y aceptando avisos, divulgaciones y términos y condiciones ("Administrador de Cuenta"). El Cliente podrá designar a un empleado o a un tercero como socio comercial o a un contratista para actuar como su Administrador(es) de Cuenta y podrá cambiar dichas asignaciones en cualquier momento por medio de su Cuenta.</p>
              <p>SOLUTIONS, los impuestos serán agregados al monto a pagar y deberán ser pagados a KÄSIÄ SOLUTIONS al mismo tiempo que el pago. Los impuestos no se deducirán de los pagos debidos a KÄSIÄ SOLUTIONS, excepto lo que la Ley exija, en tal caso el Cliente aumentará la cantidad a pagar según sea necesario, para que después de realizar todas las deducciones y retenciones requeridas, KÄSIÄ SOLUTIONS reciba y conserve (libre de cualquier obligación tributaria) un monto igual al monto que habría recibido si no se hubiesen realizado tales deducciones o retenciones. El Cliente confirma que KÄSIÄ SOLUTIONS puede utilizar y confiar en el nombre y la dirección establecidos en su registro para un Plan de Suscripción, como el lugar de suministro o como el domicilio fiscal. Las obligaciones de KÄSIÄ SOLUTIONS y del Cliente en virtud de esta Sección 7.2.8 (Responsabilidad fiscal) seguirán vigentes tras la terminación o el vencimiento de la vigencia de estos Términos.</p>
              <p><strong>8.2 Prueba Gratuita y Ofertas Especiales para los Servicios de KÄSIÄ SOLUTIONS.</strong></p>
              <p><strong>8.2.1</strong> Si usted se registra para una prueba gratuita, oferta promocional u otro tipo de oferta limitada para el uso de los Servicios de KÄSIÄ SOLUTIONS ("Prueba Gratuita"), se le pueden presentar términos y condiciones adicionales al registrarse para dicha Prueba Gratuita, y cualquiera de tales términos y condiciones adicionales se incorporan a estos Términos por referencia y son legalmente vinculantes. Esta Sección 7.3 (Prueba Gratuita y Ofertas Especiales para Servicios de KÄSIÄ SOLUTIONS) es aplicable y reemplaza cualesquiera disposiciones contradictorias con respecto al acceso y uso de una Prueba Gratuita.</p>
              <p><strong>8.2.2</strong> KÄSIÄ SOLUTIONS se reserva el derecho de reducir el plazo de un período de prueba o finalizarlo por completo sin previo aviso.</p>
              <p><strong>8.2.3</strong> La versión de los Servicios de KÄSIÄ SOLUTIONS que está disponible para una Prueba Gratuita puede no incluir o permitir el acceso a todas las características o funcionalidades. CUALQUIER DATO QUE UN CLIENTE INTRODUZCA MEDIANTE LA UTILIZACIÓN DE LOS SERVICIOS DE KÄSIÄ SOLUTIONS, ASÍ COMO CUALQUIER CONFIGURACIÓN REALIZADA POR O PARA UN CLIENTE, DURANTE LA PRUEBA GRATUITA, SE PERDERÁ PERMANENTEMENTE AL FINALIZAR EL PERÍODO DE PRUEBA A MENOS QUE EL CLIENTE: (a) ADQUIERA UN PLAN DE SUSCRIPCIÓN QUE SEA EQUIVALENTE O MAYOR QUE LOS CUBIERTOS POR LA PRUEBA; O EXPORTE DICHOS DATOS ANTES DE FINALIZAR EL PERIODO DE PRUEBA.</p>
              <p><strong>8.2.4</strong> Sin perjuicio de cualquier otra disposición de estos Términos, incluidas, entre otras, las garantías descritas a continuación o cualquier término y condición específica del servicio aplicable a un Servicio de KÄSIÄ SOLUTIONS en particular, incluidas las pruebas y los adjuntos que acompañan a dicho calendario ("Programa de Servicio "), durante una Prueba Gratuita, los Servicios de KÄSIÄ SOLUTIONS se proporcionan "TAL CUAL" y "según su disponibilidad", sin que se ofrezca ninguna garantía, salvo lo que se establezca en estos Términos, y KÄSIÄ SOLUTIONS RECHAZA CUALQUIER GARANTÍA IMPLÍCITA, INCLUYENDO, SIN LIMITACIÓN, LA GARANTÍA DE COMERCIALIZACIÓN O IDONEIDAD PARA UN PROPÓSITO EN PARTICULAR Y LA RESPONSABILIDAD TOTAL ACUMULADA DE KÄSIÄ SOLUTIONS, DERIVADA O RELACIONADA CON LA UTILIZACIÓN DE LA PRUEBA GRATUITA DEL CLIENTE, ES DE $100.</p>
              <p><strong>8.3.1 Datos en General.</strong> Usted es el único responsable de determinar la idoneidad de los Servicios de KÄSIÄ SOLUTIONS para su bóveda u organización, así como de cumplir con todas las disposiciones, leyes o convenciones aplicables a los datos que proporciona, y su uso de los Servicios y el Sitio de KÄSIÄ SOLUTIONS.</p>
              <p><strong>8.3.2 Datos Personales.</strong> El Cliente garantiza que su recolección y uso de cualquier información personal o datos proporcionados durante el uso del Sitio, cumple con todas las leyes, normas y reglamentos de protección de datos aplicables. El Cliente y sus Usuarios Autorizados reconocen que KÄSIÄ SOLUTIONS puede procesar o tratar dichos datos personales de conformidad con el Aviso de Privacidad de KÄSIÄ SOLUTIONS </p>
              <p><strong>8.4 Garantías del Cliente.</strong> El Cliente declara y garantiza a KÄSIÄ SOLUTIONS que: (a) tiene todos los derechos y las facultades necesarias para utilizar los Servicios de KÄSIÄ SOLUTIONS en virtud de estos Términos y para otorgar todos los derechos y autorizaciones aplicables, según se describe en estos Términos; (b) es responsable de todo y cualquier uso de los Servicios de KÄSIÄ SOLUTIONS asociados con su Cuenta; (c) es el único responsable de mantener la confidencialidad de sus nombres de Cuenta y contraseña(s); (d) acepta notificar inmediatamente a KÄSIÄ SOLUTIONS sobre cualquier uso no autorizado de la Cuenta del Cliente del que tenga conocimiento; (e) acepta que KÄSIÄ SOLUTIONS no será responsable de las pérdidas sufridas como resultado del uso por parte de un tercero de su Cuenta, independientemente de si dicho uso es con o sin su conocimiento y consentimiento; (f) usará los Servicios de KÄSIÄ SOLUTIONS solo para fines legales y sujetos a estos Términos; (g) cualquier información que envíe a KÄSIÄ SOLUTIONS es verdadera, precisa y correcta; y (h) no intentará obtener acceso no autorizado al Sistema ni a los Servicios de KÄSIÄ SOLUTIONS, a otras cuentas, sistemas informáticos o redes bajo el control o la responsabilidad de KÄSIÄ SOLUTIONS mediante piratería informática, descifrado, extracción de contraseñas o cualquier otro medio no autorizado.</p>
              <p><strong>8.5 Confidencialidad</strong></p>
              <p><strong>8.5.1 Información Confidencial.</strong> "Información Confidencial" se refiere (a) para KÄSIÄ SOLUTIONS, los Servicios y Documentación de KÄSIÄ SOLUTIONS, (b) para el Cliente, los Datos del Cliente; (c) cualquier otra información de una de las partes que sea divulgada de manera escrita o verbal y que está sea designada como confidencial o propietaria en el tiempo en que dicha información fue divulgada (y, en el caso de divulgaciones verbales, resumidas por escrito en los treinta (30) días siguientes a la divulgación inicial y entregadas al Receptor), o que debido a la naturaleza de la información, el Receptor debiera claramente entender que dicha información divulgada por la parte, es información confidencial de dicha parte; y (d) los términos y condiciones específicos de estos Términos, y cualquier modificación y anexo del mismo, entre las partes. La Información Confidencial no deberá incluir ninguna información que: (i) haya sido o llegue a ser de conocimiento público, sin que el receptor tenga la culpa de esto o sea como resultado de incumplimiento de estos Términos; (ii) estaba legítimamente en posesión del Receptor en el momento de dicha divulgación, sin restricción alguna de uso o divulgación; (iii) fue desarrollada independientemente por el Receptor sin la utilización de la Información Confidencial de la parte divulgadora; o (iv) fue obtenida legítimamente por el Receptor por medio de un tercero que no tenía obligación de confidencialidad y sin restricciones de uso o divulgación.</p>
              <p><strong>8.5.2 Uso Restringido y No Divulgación.</strong> Durante y después del Periodo de Suscripción, la parte receptora de la Información Confidencial ("Receptor") deberá: utilizar la Información Confidencial de la otra parte solo para los propósitos para los cuales se proporcionó dicha información; (b) no divulgar dicha Información Confidencial a un tercero, salvo que sea necesario, a sus abogados, auditores, consultores y proveedores de servicios, que estén bajo obligaciones de confidencialidad al menos tan restrictivas como las que se incluyen en este documento; y (c) proteger dicha Información Confidencial del uso y divulgación no autorizado en la misma medida (pero utilizando un grado de cuidado no menor al razonable) que protege su propia Información Confidencial o de naturaleza similar.</p>
              <p><strong>8.5.3 Divulgación Necesaria.</strong> Si la ley exige que KÄSIÄ SOLUTIONS revele Información Confidencial, KÄSIÄ SOLUTIONS dará aviso por escrito al Cliente antes de hacer la divulgación, a menos dicho aviso esté prohibido por alguna disposición o procedimiento legal o administrativo, y KÄSIÄ SOLUTIONS ayudará al Cliente a obtener, cuando esté disponible, una orden que proteja la Información Confidencial de la divulgación pública.</p>
              <p><strong>8.5.4 Propiedad.</strong> Sin perjuicio de cualquier otra disposición de estos Términos, KÄSIÄ SOLUTIONS reconoce que, entre las partes, toda la Información Confidencial que recibe del Cliente, incluidas todas las copias que posee o controla el Receptor, por cualquier medio, es propiedad exclusiva del Cliente. Nada en estos Términos confiere a KÄSIÄ SOLUTIONS ningún derecho, título o interés sobre la Información Confidencial del Cliente. La incorporación de KÄSIÄ SOLUTIONS de la Información Confidencial de la parte divulgadora a cualquiera de sus propios materiales no hará que la Información Confidencial sea considerada como no confidencial.</p>
            </Grid>
            <Grid item>
              <h3>9. PLAZO Y TERMINACIÓN</h3>
              <p><strong>9.1 Terminación: Visitantes del Sitio.</strong> Un Visitante del Sitio podrá finalizar su uso del Sitio en cualquier momento al dejar de usar el Sitio. KÄSIÄ SOLUTIONS puede cancelar el uso del Sitio, a quien así lo determine, y negar el acceso al Sitio a su entera discreción, por cualquier razón o inclusive sin razón alguna, incluyendo la violación a estos Términos.</p>
              <p><strong>9.2 Plazo y Terminación - Clientes.</strong></p>
              <p><strong>9.2.1 Suspensión del Acceso a los Servicios de KÄSIÄ SOLUTIONS.</strong> KÄSIÄ SOLUTIONS podrá suspender el uso de cualquiera de los servicios, remover cualquier contenido o deshabilitar o terminar cualquier Cuenta o Usuario Autorizado que KÄSIÄ SOLUTIONS, razonablemente y en Buena fe, crea que ha violado estos Términos. KÄSIÄ SOLUTIONS usará esfuerzos comercialmente razonables para notificar a dicho Usuario antes de cualquier suspensión o deshabilitación, a menos que KÄSIÄ SOLUTIONS crea que: (a) le está prohibido actuar de esa manera en base a la ley aplicable o bajo algún procedimiento legal, tal como proceso un administrativo, gubernamental o judicial, órdenes, mandatos o similares; (b) sea necesario el retraso de dicha notificación para evitar daño eminente a los Servicios de KÄSIÄ SOLUTIONS o a terceros. En las circunstancias en donde la notificación haya sido retrasada, KÄSIÄ SOLUTIONS hará dicha notificación cuando, y solamente cuando, dichas restricciones indicadas en el párrafo anterior ya no sean aplicables.</p>
              <p><strong>9.2.2 Término.</strong>El período de vigencia de estos Términos (el "Plazo") con respecto a los Servicios de KÄSIÄ SOLUTIONS, comienza en la fecha en que el Cliente los acepta y continúa hasta que el Plan de Suscripción del Cliente expire o cese el uso de los Servicios de KÄSIÄ SOLUTIONS, lo que sea posterior.</p>
              <p><strong>9.2.3 Plazo de Suscripción y Renovaciones Automáticas</strong> Los Planes de Suscripción de KÄSIÄ SOLUTIONS se renuevan automáticamente a menos que se indique lo contrario. Si usted adquiere o contrata un Plan de Suscripción, usted acepta pagar la tarifa vigente en dicho momento, la cual se encuentra asociada con el Plan de Suscripción y además acepta y reconoce que este se renovará automáticamente, a menos que, antes de finalizar el período de vigencia actual del Plan de Suscripción ("Plazo de Suscripción"): (a) usted cancele su Cuenta; (b) usted configure su Cuenta entrando a los Servicios de KÄSIÄ SOLUTIONS para que no se renueve automáticamente; (c) KÄSIÄ SOLUTIONS se niegue a o decida no renovar su Plan de Suscripción; o (d) estos Términos sean debidamente terminados, según lo expresamente permitido en este documento. El Plan de Suscripción se renovará automáticamente de manera mensual o anual, dependiendo del método que usted elija ("Término de Renovación").</p>
              <p>Nos reservamos el derecho a modificar, terminar o corregir las tarifas y características asociadas con su Plan de Suscripción. Nosotros también podremos recomendarle a usted que adquiera un nuevo Plan de Suscripción que sea comparable a su Plan de Suscripción anterior que está por finalizar. Antes de cambiar las tarifas y cargos vigentes o antes de que agreguemos nuevas tarifas y cargos, le daremos un aviso de al menos treinta (30) días de anticipación. Si le notificamos con al menos treinta (30) días de anticipación, su utilización continua de los Servicios de KÄSIÄ SOLUTIONS, después de que se hayan realizado los cambios, constituirá la aceptación de dichos cambios. Si usted no desea continuar con su suscripción con las nuevas tarifas o funciones, usted podrá terminar su Plan de Suscripción tal como se especifica en la Sección 8.2.4. Si usted acepta el nuevo Plan de Suscripción, sus términos y condiciones con estos Términos se aplicarán en el Plazo de Renovación y posteriormente.</p>
              <p><strong>9.2.4 Predeterminado; Terminación por parte de KÄSIÄ SOLUTIONS.</strong> Un Cliente estará en incumplimiento de estos Términos si: (a) no paga oportunamente cualquier cantidad que se nos adeude a nosotros o una Afiliada nuestra; (b) él o un Usuario Autorizado asociado con su Cuenta incumple cualquier disposición de estos Términos o viola cualquier política publicada y que sea aplicable a los Servicios de KÄSIÄ SOLUTIONS; (c) está o queda sujeto a cualquier procedimiento de liquidación o Bancarrota; o (d) si, a nuestro exclusivo criterio, consideramos que el uso continuo de los Servicios de KÄSIÄ SOLUTIONS por parte del Cliente (o sus Usuarios o Usuarios Autorizados) crea un riesgo legal para KÄSIÄ SOLUTIONS o representa una amenaza para la seguridad de los Servicios de KÄSIÄ SOLUTIONS o los Clientes de KÄSIÄ SOLUTIONS. Si un Cliente se encuentre en incumplimiento, podemos, sin previo aviso: (i) suspender su Cuenta y el uso de los Servicios de KÄSIÄ SOLUTIONS; (ii) finalizar su Cuenta; (iii) cobrar tarifas de reactivación para reactivar su Cuenta; y (iv) buscar cualquier otro remedio disponible para nosotros. </p>

            </Grid>
            <Grid item>
              <h3>10. GARANTÍAS Y LIMITACIONES DE RESPONSABILIDAD</h3>
              <p><strong>10.1</strong> Los servicios KÄSIÄ SOLUTIONS, la documentación y el sitio se proporcionan "tal cual" y "según su disponibilidad". El uso que usted haga de los servicios, la documentación y el sitio de KÄSIÄ SOLUTIONS, será bajo su propio riesgo. KÄSIÄ SOLUTIONS y sus respectivos oficiales, directores, empleados, miembros, accionistas, agentes, afiliados, subsidiarias y licenciatarios ("partes de KÄSIÄ SOLUTIONS "): (a) no realizan ninguna declaración o garantía de ningún tipo, ya sea expresa, implícita (ya sea de hecho o derecho), o legal, en cuanto a cualquier asunto; (b) excluyen expresamente todas y cualquier garantía implícita de comerciabilidad, idoneidad para un propósito particular, calidad, precisión y propiedad; y (c) no garantizan que los servicios, la documentación, o el sitio de KÄSIÄ SOLUTIONS estén o estarán libres de errores, cumplirán con todos los requisitos, o serán oportunos o seguros. Usted será el único responsable por cualquier daño resultante del uso de los servicios, documentación, o del sitio de KÄSIÄ SOLUTIONS.</p>
              <p><strong>10.1.1</strong> Las partes de KÄSIÄ SOLUTIONS no ofrecen ninguna garantía o declaración respecto de que los servicios, la documentación y el sitio KÄSIÄ SOLUTIONS se han proporcionado con debida competencia, cuidado y diligencia o respecto de la exactitud o integridad de los servicios de KÄSIÄ SOLUTIONS, la documentación y el contenido del sitio; y no asume ninguna responsabilidad por cualquier: (i) error o imprecisiones en el contenido; (ii) lesiones personales o daños materiales, de cualquier naturaleza, como resultado de su acceso y uso de los servicios, la documentación y el sitio de KÄSIÄ SOLUTIONS; (iii) cualquier acceso no autorizado o uso de nuestros servidores y/o cualquier información personal y/o información financiera almacenada en el mismo; (iv) cualquier interrupción o suspensión de la transmisión al o desde el sitio; (v) cualquier error, virus, "trojan" o similares, mismos que pudieran ser transmitidos a o desde el sitio, mediante la participación de terceros; (vi) cualquier pérdida de sus datos o contenidos del sitio; y/o (vii) cualquier error u omisión en cualquier contenido o cualquier pérdida o daño de cualquier tipo, incurrido como resultado del uso de cualquier contenido publicado, enviado por correo electrónico, transmitido o de otra forma disponible a través de los servicios de KÄSIÄ SOLUTIONS, la documentación, y el sitio. Usted no tendrá derecho a realizar o eximirse de ninguna declaración o garantía en nombre de KÄSIÄ SOLUTIONS a un tercero. </p>
              <p><strong>10.1.2</strong> las partes de documentos no garantizan, respaldan, avalan, ni asumen la responsabilidad por ningún producto o servicio anunciado u ofrecido por un tercero a través del sitio, y las partes en cualquier documento, no serán parte, ni de ninguna manera responsables de supervisar ninguna transacción entre usted y los proveedores de productos o servicios de terceros. Usted es el único responsable de todas sus comunicaciones e interacciones con otras personas con las que se comunica o interactúa como resultado de su uso del sitio.</p>
              <p><strong>10.2      OBLIGACIONES DE INDEMNIZACIÓN</strong></p>
              <p><strong>10.2.1</strong> Usted defenderá, indemnizará y mantendrá libres de cualquier reclamación, responsabilidad civil, daños y perjuicios de terceros (incluidos, entre otros, los honorarios de abogados), a nosotros, a nuestros Filiales, funcionarios, directores, empleados, proveedores, consultores y agentes, cuando estos surjan o estén relacionados con, según corresponda: (a) su acceso y uso del Sitio; (b) la violación de estos Términos por usted o por su(s) Administrador(es) de Cuenta o por los Usuarios Autorizados, según corresponda; (c) infracciones, por usted, de cualquier propiedad intelectual u otro derecho de cualquier persona o entidad; (d) la naturaleza y el contenido de todos los Datos del Cliente que sean procesados o tratados por los Servicios de KÄSIÄ SOLUTIONS; o (e) cualquier producto o servicio comprado u obtenido por usted en relación con el Sitio.</p>
              <p><strong>10.2.2</strong> KÄSIÄ SOLUTIONS mantiene el derecho exclusivo de resolver, comprometer, y pagar, sin su consentimiento previo, todas y cada una de las reclamaciones o causales de actuación que se nos imputen. Nos reservamos el derecho de asumir la defensa y el control exclusivo de cualquier asunto por el que se deba de indemnizarnos y usted acepta cooperar con nuestra defensa de dichas reclamaciones. Usted acepta no solucionar ningún asunto en el que se nos nombre como demandado y/o para el cual tenga obligaciones de indemnización sin nuestro consentimiento por escrito previo. Haremos todos los esfuerzos razonables para notificarle cualquier reclamación, acción o procedimiento al tomar conocimiento de ello.</p>

            </Grid>

            <Grid item>
              <h3>11.       LIMITACIÓN DE RESPONSABILIDAD</h3>
              <p><strong>11.1 Limitación de Responsabilidad por Daños Consecuenciales. </strong></p>
              <p><strong>11.1.1</strong> Sin perjuicio de lo contrario incluido en estos términos KÄSIÄ SOLUTIONS no será responsable ante usted por ningún daño consecuente, incidental, especial, cubierto, punitivo o que sea utilizado a manera de ejemplo, en cuanto al pago de daños, derivado o relacionado con las transacciones contempladas bajo estos términos, incluyendo, pero no limitado a, buena fama, pérdida de trabajo, pérdida de beneficios o pérdida de negocios, incluso si se pudo prever de la probabilidad de tales pérdidas, y de si dichas reclamaciones se hacen valer con base en un contrato, agravio (incluyendo negligencia) o cualquier otra teoría legal.</p>
              <p><strong>11.1.2</strong> Hasta el máximo permitido por la ley, en ningún caso las partes de KÄSIÄ SOLUTIONS serán responsables ante usted por cualquier daño directo, indirecto, incidental, especial, punitivo o consecuencial que resulte de cualquiera: (A) uso del sitio, documentación, o servicios KÄSIÄ SOLUTIONS; (B) errores, faltas o imprecisiones en el contenido; (C) daños personales o daños materiales, de cualquier naturaleza, resultantes de su acceso y uso del sitio, la documentación o los servicios de KÄSIÄ SOLUTIONS; (D) cualquier acceso no autorizado o uso de nuestros servidores y/o cualquier información personal y/o información financiera almacenada en el mismo; (E) cualquier interrupción o suspensión de transmisión a o desde nuestros servidores; (F) cualquier error, virus, "trojan", o similares, que puedan ser transmitidos por o desde el sitio, la documentación o los servicios de KÄSIÄ SOLUTIONS mediante la participación de un tercero; (G) cualquier pérdida de sus datos o contenido desde el sitio, la documentación o los servicios de KÄSIÄ SOLUTIONS; (H) cualquier error u omisión en cualquier contenido o cualquier pérdida o daño de cualquier tipo, incurrido como resultado del uso de cualquier contenido publicado, transmitido o de otro modo puesto a disposición, a través del sitio, la documentación o los servicios de KÄSIÄ SOLUTIONS, ya sea basado en una garantía, contrato, agravio (incluyendo negligencia), o cualquier otra teoría legal, ya sea que las partes de KÄSIÄ SOLUTIONS hubieran sido advertidas de la posibilidad de dichos daños; y/o (I) la divulgación de información de conformidad con estos términos o con nuestro aviso de privacidad.</p>
              <p><strong>11.2 Tope para los Daños.</strong> Nuestra responsabilidad total con usted por cualquier causa que surja de o esté relacionada con estos términos o con el uso del sitio (incluyendo sin limitación las reclamaciones de garantía), independientemente del foro e independientemente de si alguna acción o reclamación se basa en un contrato, agravio (incluyendo negligencia), o cualquier otra teoría legal o en equidad, no excederá la cantidad total pagada por usted a KÄSIÄ SOLUTIONS por los servicios de KÄSIÄ SOLUTIONS que den justificación a la reclamación, de conformidad con estos términos, durante el período de doce (12) meses anterior a la fecha del primer evento que le dio origen a la responsabilidad. La existencia de más de una reclamación no ampliará este límite.</p>

            </Grid>
            <Grid item>
              <h3>12.       GENERAL</h3>
              <p><strong>12.1 Relación.</strong> En todo momento, usted y KÄSIÄ SOLUTIONS son contratistas independientes, y no son los agentes o representantes del otro. Estos términos no pretenden crear una relación de negocio conjunto, sociedad o franquicia entre las partes. Los que no son partes no se benefician y no pueden hacer cumplir estos Términos. No hay terceros beneficiarios de estos Términos. No se debe manifestar a nadie que usted es un agente de KÄSIÄ SOLUTIONS o que está autorizado para vincular o comprometer a KÄSIÄ SOLUTIONS de cualquier forma sin la autorización previa por escrito de KÄSIÄ SOLUTIONS.</p>
              <p><strong>12.2 Transmisibilidad.</strong> No puede transmitir o ceder sus derechos u obligaciones en virtud de estos Términos sin el previo consentimiento por escrito de KÄSIÄ SOLUTIONS. Si se otorgara el consentimiento, estos Términos obligarán a sus sucesores y cesionarios. Cualquier intento de su parte para transferir sus derechos, obligaciones u obligaciones en virtud de estos Términos, salvo lo expresamente estipulado en estos Términos, será nulo. KÄSIÄ SOLUTIONS podrá libremente transmitir, bajo estos Términos, sus derechos, deberes y obligaciones.</p>
              <p><strong>12.3 Avisos.</strong> A excepción de lo permitido por estos Términos, cualquier notificación requerida o permitida en relación con los Servicios de KÄSIÄ SOLUTIONS será efectiva solo si se realiza por escrito y se envía utilizando: (a) Servicios de KÄSIÄ SOLUTIONS; (b) por correo certificado o registrado; o (c) el servicio de mensajería asegurado, a la parte correspondiente en la dirección indicada en la información de registro del Cliente o en el Sitio de KÄSIÄ SOLUTIONS. El Cliente o KÄSIÄ SOLUTIONS puede cambiar su dirección de recepción de notificaciones mediante notificación a la otra parte de acuerdo con esta Sección 12. Las notificaciones se consideran entregadas en el momento de la recepción, si se entregan utilizando los Servicios de KÄSIÄ SOLUTIONS, dos (2) días hábiles posteriores a la fecha de correo o un (1) día hábil después de la entrega a un servicio de mensajería.</p>
              <p><strong>12.4 Términos Completos. </strong> Estos Términos son la expresión final, completa y exclusiva del acuerdo entre usted y KÄSIÄ SOLUTIONS con respecto a los Servicios de KÄSIÄ SOLUTIONS proporcionados en virtud de estos Términos. Estos Términos sustituyen, y las partes renuncian a confiar en comunicaciones orales y escritas previas (incluidos los acuerdos de confidencialidad pertenecientes a los Servicios de KÄSIÄ SOLUTIONS en virtud de estos Términos) con respecto al objeto del presente y se aplican a la exclusión de cualquier otro término que pretenda imponer o incorporar, o que están implícitos en el comercio, la costumbre, la práctica o el curso de la negociación. Por el presente documento, KÄSIÄ SOLUTIONS rechaza cualquier término adicional o conflictivo que aparezca en una orden de compra o en cualquier otro material de pedido enviado por el Cliente y las condiciones se basan únicamente en estos Términos y condiciones de estos Términos, tal como lo ofrece KÄSIÄ SOLUTIONS. A excepción de lo que se permite explícitamente en estos Términos, ninguna modificación o enmienda a estos Términos tendrá vigencia a menos que se haga por escrito y esté firmada por un agente autorizado de la parte contra la cual se afirma la modificación o enmienda. En caso de incoherencia o conflicto, el orden de precedencia en orden de control descendente es el siguiente: (a) el Plan de Suscripción; (b) cualquier archivo adjunto o apéndice (s) al Programa (s) de Servicio; (c) el cronograma del servicio; y (d) estos Términos.</p>

            </Grid>
            <Grid item>
              <h3>13. RESPONSABILIDADES ADICIONALES DEL CLIENTE</h3>
              <p><strong>13.1</strong> La disposición de KÄSIÄ SOLUTIONS del servicio de KÄSIÄ SOLUTIONS está condicionada a que el Cliente reconozca y acepte lo siguiente:</p>
              <p><strong>13.1.1</strong> El Cliente es responsable de todas las actividades que se lleven a cabo en la Cuenta del Cliente y debe garantizar que el uso cumpla con estos Términos; </p>
              <p><strong>13.1.2</strong> El Cliente tiene el control exclusivo y la responsabilidad sobre el contenido, la calidad y el formato de cualquier transacción facilitada a través de KÄSIÄ SOLUTIONS. No se puede interpretar que nada en este Programa de servicios haga que KÄSIÄ SOLUTIONS sea parte en ninguna transacción procesada a través de KÄSIÄ SOLUTIONS, y KÄSIÄ SOLUTIONS no ofrece ninguna declaración o garantía con respecto a las transacciones que se pretende realizar por medio de KÄSIÄ SOLUTIONS por parte del Cliente;</p>
              <p><strong>13.1.3</strong> El Cliente es el único responsable de la fiabilidad de cualquier contenido, consejo, opinión, declaración, sugerencia u otra información ("Contenido de Terceros") ofrecido por terceros que se comparte o distribuye a través de KÄSIÄ SOLUTIONS. Cualquier Contenido de terceros presentado o divulgado a través de su cuenta representa únicamente la opinión del autor o proveedor respectivo de dicho contenido, que sigue siendo el único responsable de la exactitud o confiabilidad del mismo.</p>
              <p><strong>13.1.4</strong> El Cliente es responsable de determinar durante cuánto tiempo se requiere que los documentos electrónicos se conserven o almacenen de conformidad con las leyes, normativas o procesos legales o administrativos vigentes. </p>
              <p><strong>13.1.5</strong> El Cliente es responsable de cumplir con cualquier protección al consumidor, leyes o regulaciones similares que puedan imponer requisitos especiales con respecto a las transacciones electrónicas que involucren a uno o más "consumidores", tales como (entre otros) requisitos para que el consumidor acepte el método de realizar transacciones y/o proporcionar al consumidor una copia, o acceso a una copia, de un documento u otro registro escrito no electrónico de la transacción.</p>
            </Grid>
          </Grid>

          <Grid
            item
            md={10}
            xs={10}
            container
            alignItems="center"
          >
            <Grid item xs={10}>
              <h3>AVISO DE PRIVACIDAD </h3>
            </Grid>
            <Grid item>
              <h3>IDENTIDAD Y DOMICILIO DEL RESPONSABLE</h3>
              <p>De conformidad con la Ley Federal de Protección de Datos Personales en Posesión de Particulares (en adelante, “LFPDPPPP”), y demás normatividad secundaria vigente aplicable, así como estándares nacionales e internacionales en materia de protección de datos personales, <strong>GC DIGITAL, S.A. DE C.V.</strong> con domicilio para temas de privacidad y de protección de datos personales ubicado en Bosque de Ciruelos núm. 304, Piso 5, Bosque de las Lomas, Cd. de México, C.P. 11700, México, (en lo sucesivo el <strong>“Responsable”</strong>) reconoce que la privacidad y la seguridad de sus datos personales, mismos constituyen derechos de suma importancia para usted, nos comprometemos a resguardarlos bajo los más altos índices de seguridad legal, tecnológica y administrativa. </p>
              <p>El Responsable podrá recabar a través de los siguientes medios: (i) de manera directa, cuando el Titular los ingresa a través del sitio web <a href="www.kasiasolutions.com">www.kasiasolutions.com</a>  (el “Sitio Web”), (ii) de manera directa, cuando el Titular los proporciona vía telefónica o por mensaje, (iii) de manera indirecta, cuando otras bóvedas nos los transfieren, y (iv) de manera indirecta, cuando se obtienen a través de fuentes de acceso público permitidas por la LFPDPPP; pone a disposición del Titular el presente aviso de privacidad integral (el "Aviso de Privacidad") -previo a la obtención de los Datos Personales- en estricto apego a los -principios de información, licitud, consentimiento, calidad, finalidad, lealtad, proporcionalidad y responsabilidad- contemplados en la LFPDPPP.</p>
            </Grid>

            <Grid item>
              <h3>DATOS PERSONALES QUE SE RECABAN</h3>
              <p>Para los fines establecidos en este Aviso de Privacidad, podemos obtener los datos personales que se refieren a continuación:</p>
              <ul>
                <li>•	Datos de identificación: información que permite diferenciarlo de otros individuos. Estos datos incluyen: nombre, fecha de nacimiento, firma electrónica, registro federal de contribuyentes (RFC); copia del documento con el cual acredita su identidad (Credencial para Votar con Fotografía u otro tipo de documento).</li>
                <li>•	Datos de contacto: información que nos permite mantenernos en contacto con usted y la persona de su preferencia en caso de emergencia.<p>Esos datos incluyen: nombre completo, código postal, correo electrónico, teléfono particular u otro teléfono de contacto.</p> </li>
                <li>•	Registros electrónicos: esta información incluye Whatsapp, cuenta de Facebook u otras redes sociales, cuenta de plataformas de videoconferencia como Zoom, dirección IP con la que accede al Sitio Web, preferencias de usuario, tipo de navegador; actividad en el sitio, cookies.</li>
                <li>•	Datos Financieros: Para gestionar el pago por los servicios prestados y solo en caso de ser necesario, se recabarán los siguientes datos financieros: datos de cuenta bancaria y datos fiscales para emisión de facturas.</li>
              </ul>
              <p>El Responsable se compromete y obliga a observar y cumplir los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad de conformidad con la LFPDPPP.</p>
              <p>El Responsable no recaba información personal de menores de edad, por lo que al proporcionar sus datos usted reconoce ser mayor de edad y haber leído el contenido de este aviso de privacidad. </p>
            </Grid>

            <Grid item>
              <h3>FINALIDADES DEL TRATAMIENTO DE LOS DATOS PERSONALES</h3>
              <p>Los datos personales que se recaben serán utilizados para realizar las siguientes finalidades:</p>
              <p><strong>Finalidades Primarias.</strong> Necesarias para prestarle los servicios que en su caso nos solicite, las cuales consisten en:</p>
              <ul>
                <li>a)	Verificar su identidad e información;</li>
                <li>b)	Creación de su cuenta y/o usuario en nuestro Sitio Web y/o Aplicaciones para permitirle solicitar los servicios.</li>
                <li>c)	Actualizar nuestros sistemas de tratamientos y base de datos; </li>
                <li>d)	Procesar las solicitudes para mantener la bóveda documental Blockchain, para la gestión contractual y de gobiernos corporativos del cliente.</li>
                <li>e)	Prestar los servicios de firma electrónica avanzada; </li>
                <li>f)	Informarle y/o contactarle por cualquier medio óptico, sonoro, tecnológico o físico (WhatsApp, SMS, teléfono, correo electrónico, mensajería, entre otros), para la prestación de los servicios de plataforma LegalTech.</li>
                <li>g)	Gestionar y/o procesar los pagos bancarios de los servicios solicitados</li>
                <li>h)	Llevar un expediente tecnológico del cliente;</li>
                <li>i)	Gestionar la devolución y/o cambio de servicios; </li>
                <li>j)	La emisión de facturas; </li>
                <li>k)	Conservar su información para el cumplimiento de las disposiciones legales aplicables y requerimientos de las autoridades con base en Ley para el cumplimiento de las disposiciones legales y requerimientos de diversas autoridades; y</li>
                <li>l)	Atender sus dudas, quejas, sugerencias, aclaraciones y/o dar seguimiento a las mismas.</li>
              </ul>
              <p><strong>Finalidades Secundarias.</strong> De manera adicional, utilizaremos su información personal para las siguientes finalidades que no son necesarias, pero que nos permiten y facilitan brindarle una mejor atención y servicio:</p>
              <ul>
                <li>a)	Enviarle a través de diversos medios, información de carácter publicitario, promocional y/o informativo; </li>
                <li>b)	Realizar estadísticas, encuestas o estudios sobre sus preferencias, gustos e intereses que tengan por finalidad evaluar la calidad de los servicios, y/o experiencia en la plataforma;</li>
                <li>c)	Invitarlo, conforme a las bases de participación, a nuestros eventos, concursos, sorteos, promociones y actividades y contactarlo a través de distintos medios de comunicación físicos y/o electrónicos; y</li>
                <li>d)	Ofrecerle cualquiera de nuestros servicios y productos;</li>
              </ul>
              <p>Usted como titular de los datos personales tiene derecho a oponerse a que sus datos personales sean usados para las Finalidades Secundarias señalados anteriormente. Lo anterior quiere decir que usted en cualquier momento puede oponerse a cualquiera de ellas, o bien, revocar su consentimiento. </p>
              <p>En caso de que desee que el Responsable no trate sus datos personales para las Finalidades Secundarias, usted podrá manifestar su negativa enviando un correo electrónico a la cuenta info@kasiasolutions.com o llamando al +52 55 7973 2838 a efecto de que el Responsable no trate sus datos para dichos fines. En caso contrario, se entenderá que el titular consiente el tratamiento de sus datos para todos los fines enumerados anteriormente. </p>
              <p>La negativa para el uso de sus datos personales sobre las Finalidades Secundarias no será motivo para que le neguemos nuestros servicios.</p>
            </Grid>
            <Grid item>
              <h3>OPCIONES Y MEDIOS OFRECIDOS POR EL RESPONSABLE PARA LIMITAR EL USO O DIVULGACIÓN DE LOS DATOS</h3>
              <p>Para limitar el uso o divulgación de sus datos personales, usted deberá enviar un correo electrónico, dirigido a la siguiente dirección electrónica: info@kasiasolutions.com o llamando al +52 55 7973 2838 solicitando la limitación del uso o divulgación de que se trate.</p>
              <p>Adicionalmente, le informamos que puede requerir su registro en el Registro Público para Evitar Publicidad (REPEP), a través de la página de Internet de la Procuraduría Federal del Consumidor (PROFECO), https://repep.profeco.gob.mx/ con el objeto de que sus datos personales no sean utilizados para recibir publicidad o promociones de bóvedas de bienes o servicios. Para más información sobre este registro, usted puede consultar el portal de Internet de la PROFECO, o bien ponerse en contacto directo con ésta.</p>

            </Grid>
            <Grid item>
              <h3>MEDIO Y PROCEDIMIENTO PARA EJERCER EL DERECHO DE ACCESO, RECTIFICACIÓN, CANCELACIÓN Y OPOSICIÓN (“DERECHOS ARCO”) Y REVOCACIÓN DEL CONSENTIMIENTO </h3>
              <p>De conformidad con la LFPDPPP, usted tiene derecho a conocer los datos personales que tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). De igual forma, tiene el derecho de solicitar la corrección de sus datos personales en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); asimismo, puede solicitar que eliminemos sus datos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada conforme a los principios, deberes y obligaciones previstas en la normativa (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO. </p>
              <p>Para el ejercicio de sus Derechos ARCO o de su derecho de revocación, puede hacerlo por escrito a la dirección de corre <a href="mailto:info@kasiasolutions.com">info@kasiasolutions.com</a> o llamando al +52 55 7973 2838 donde se le informará al procedimiento a seguir.</p>
              <p>El Responsable, podrá negar el acceso a los datos personales, o a realizar la rectificación o cancelación u oponerse al tratamiento de los mismos, en los supuestos establecidos en el artículo 34 de la Ley.<br />El Responsable no estará obligado a cancelar los datos personales del titular, bajo los supuestos establecidos en el artículo 26 de la Ley.
              </p>
            </Grid>
            <Grid item>
              <h3>TRANSFERENCIA DE LOS DATOS PERSONALES QUE SE EFECTÚEN</h3>
              <p>Usted como titular de los datos personales acepta y reconoce que el Responsable, <strong>–no requiere de su consentimiento para realizar transferencias nacionales o internacionales de los Datos Personales-</strong>, en términos de lo estatuido por el artículo 37 de la LFPDPPP, o en cualquier otro caso de excepción previsto por la legislación aplicable, en el entendido de que el tratamiento que estos terceros otorguen a los Datos Personales deberá ajustarse a lo establecido en el Aviso de Privacidad.</p>

            </Grid>
            <Grid item>
              <h3>SEGURIDAD DE LOS DATOS PERSONALES</h3>
              <p>Los datos personales recabados y tratados por el Responsable y/o sus encargados se encontrarán protegidos por medidas de seguridad administrativas, técnicas y físicas adecuadas contra el daño, pérdida, alteración, destrucción o uso, acceso o tratamiento no autorizados, de conformidad con lo dispuesto en la LFPDPPPP, de la regulación administrativa derivada de la misma, de la legislación bancaria y normas secundarias emitidas por las autoridades competentes.</p>
            </Grid>
            <Grid item>
              <h3>USO DE COOKIES</h3>
              <p>El Responsable utiliza varias tecnologías para mejorar la eficiencia de sus sitios web, incluyendo su experiencia cuando navega por dichos sitios. Entre estas tecnologías se incluye el uso de cookies. Las cookies son pequeñas cantidades de información que se almacenan en el navegador utilizado por cada usuario para que el servidor recuerde cierta información que posteriormente pueda utilizar. </p>
              <p>Dichos archivos pueden contener información tal como la identificación proporcionada por usted, o información para rastrear las páginas de preferencia de este, para monitorear su comportamiento como usuario de internet, brindarles un mejor servicio y experiencia de usuario al navegar en el Sitio Web. Una Cookie no puede leer los datos o información de su disco duro ni leer las cookies creadas por otros sitios o páginas. En caso de que usted desee rechazar las cookies o aceptarlas de manera selectiva, usted puede deshabilitar o ajustar el uso de cookies siguiendo los procedimientos del navegador de internet que utiliza.</p>
            </Grid>
            <Grid item>
              <h3>MECANISMOS REMOTOS DE COMUNICACIÓN ELECTRÓNICA QUE RECABAN LOS DATOS PERSONALES DE MANERA AUTOMÁTICA </h3>
              <p>Algunas partes del Sitio Web pueden utilizar Cookies para simplificar la navegación. Las Cookies son archivos de texto que son descargados automáticamente y almacenados en el disco duro del equipo de cómputo del usuario al navegar en una página de Internet específica, que permiten recordar al servidor de Internet algunos datos sobre este usuario, entre ellos, sus preferencias de compra para la visualización de las páginas en ese servidor, nombre y contraseña. Le informamos que utilizamos Cookies para obtener información personal de usted, como la siguiente: (i). El tipo de navegador y sistema operativo que utiliza; (ii). Las páginas de Internet que visita en forma previa y posterior a la entrada del Sitio Web; (iii). Los vínculos que sigue y permanencia en el Sitio Web; (iv). Su dirección IP; (v). Lugar desde el cual visita el Sitio Web y estadísticas de navegación. Estas Cookies y otras tecnologías pueden ser deshabilitadas. Puede buscar información sobre los navegadores conocidos y averiguar cómo ajustar las preferencias de las Cookies en los siguientes sitios web:</p>
              <ul>
                <li><strong>Microsoft Internet Explorer:</strong> <a href="http://www.microsoft.com/info/cookies.htm">http://www.microsoft.com/info/cookies.htm</a></li>
                <li><strong>Mozilla Firefox:</strong> <a href="http://www.mozilla.org/projects/security/pki/psm/help_21/using_priv_help.html">http://www.mozilla.org/projects/security/pki/psm/help_21/using_priv_help.html</a> </li>
                <li><strong>Google Chrome:</strong> <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=es Apple Safari: https://support.apple.com/es-es/guide/safari/sfri11471/mac"> https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=es Apple Safari: https://support.apple.com/es-es/guide/safari/sfri11471/mac </a> </li>
                <li><strong>Apple Safari:</strong> <a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac"> https://support.apple.com/es-es/guide/safari/sfri11471/mac </a> </li>
              </ul>
              <p>En el caso de empleo de Cookies, el botón de "ayuda" que se encuentra en la barra de herramientas de la mayoría de los navegadores, le dirá cómo evitar aceptar nuevas Cookies, cómo hacer que el navegador le notifique cuando recibe una nueva cookie o cómo deshabilitar todas las Cookies.</p>
            </Grid>
            <Grid item>
              <h3>EXCLUSIÓN DE RESPONSABILIDAD </h3>
              <p>Nuestro sitio web podría contener enlaces, hipervínculos o hipertextos “links”, banners, botones y/o herramientas de búsqueda en internet que al ser utilizados por los Titulares transportan a otros portales o sitios de internet que podrían ser propiedad de terceros. Por lo tanto, no controla dichos sitios y no es responsable por los avisos de privacidad que ostenten, o en su caso, a la falta de ellos; los Datos Personales que los Titulares llegasen a proporcionar a través dichos portales o sitios de internet distintos al Sitio Web, por lo que deberá verificar el aviso de privacidad en cada sitio al que acceda. Algunos vínculos, banners y/o botones que solicitan Datos Personales dentro del Sitio Web son responsabilidad de terceros ajenos al Responsable, quienes en ocasiones son proveedores de servicios, por lo que se rigen por sus propios términos y políticas de privacidad. Para conocer más acerca de este tema, lo invitamos a consultar la sección términos y condiciones.</p>
              <p><strong>INAI</strong></p>
              <p>En caso de que el Titular considere que su derecho de protección de los datos personales ha sido lesionado por el tratamiento indebido de los mismos, podrá interponer queja o denuncia correspondiente ante el INAI, por lo que podrá consultar el sitio web <a href="www.inai.org.mx">www.inai.org.mx</a> para más información.</p>
              <p><strong>MODIFICACIONES AL AVISO DE PRIVACIDAD </strong></p>
              <p>El Responsable se reserva el derecho de actualizar o modificar periódicamente el Aviso de Privacidad conforme a los cambios de las prácticas de información, en atención a las novedades legislativas, políticas internas o nuevos requerimientos para la prestación de los servicios. </p>
              <p>Dichas actualizaciones o modificaciones serán notificadas al titular a través del Sitio Web, en la sección Aviso de Privacidad. Se recomienda y consultar el Aviso de Privacidad, por lo menos semestralmente, para estar actualizado de las condiciones y términos del mismo. </p>
              <p><strong>CONSENTIMIENTO PARA EL TRATAMIENTO DE LOS DATOS PERSONALES </strong></p>
              <p>Usted como titular de los datos personales manifiesta que ha leído, entendido y aceptado los términos expuestos en el Aviso de Privacidad, lo que constituye el –consentimiento, libre, específico, inequívoco e informado-, inclusive con respecto a los cambios establecidos en las actualizaciones realizadas al mismo, con respecto al tratamiento de los Datos Personales en cumplimiento a lo establecido por la LFPDPPP y los Lineamientos.</p>
              <p></p>
              <p><strong>Fecha de última actualización: 08 de junio de 2022</strong></p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default TermsAndConditions;
