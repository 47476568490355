import { useEffect, useRef, useState } from "react";
import {
	Button,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Grid,
	InputAdornment,
	TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
// import { MenuListAnchor } from "./MenuListAnchor";
import CardSearched from "./CardSearched";
import { MenuListAnchor } from "./MenuListAnchor";
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom";
import { getElementsSearchedBar } from "../../../core/http/functionRequests/vault.http";
import { tokenExpired } from "../../../core/utils";
import { authActions } from "../../../core/actions";
import { useDispatch, useSelector } from "react-redux";


const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			margin: theme.spacing(1),
			marginTop: theme.spacing(2),
		},
	},
	subfolders: {
		color: '#5C7BEC'
	}
}));

export const SearchBarVaults = (props) => {
	const { isVaultView = false } = props

	const classes = useStyles();

	const params = useParams()
	const location = useLocation()
	const history = useHistory()
	const searchRef = useRef(null);

	const dispatch = useDispatch();
	const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null);
	const [totalPages, setTotalPages] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [inputChange, setInputChange] = useState("");
	const [checkedDescription, setCheckedDescription] = useState(false);

	const {
		searchlocal,
		includedescription,
		documentselect,
	} = props

	const searchElements = async () => {
		if (isLoggedIn && tokenExpired()) {
			dispatch(authActions.userLoggedOut())
			return
		}

		setLoading(true)
		setData([])
		const { vaultId } = params;
		const { search } = location;
		const path = new URLSearchParams(search).get('p');

		const requestObject = {
			search: inputChange,
			deep: true,
			path,
			page: currentPage,
		}

		if (checkedDescription)
			requestObject.desc = inputChange

		if (vaultId)
			requestObject.vault = vaultId



		try {
			const response = await getElementsSearchedBar(requestObject);
			const { data, totalPages } = response
			setData(data)
			setTotalPages(totalPages)
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}

	const handleClick = () => {
		setAnchorEl(searchRef.current);
		searchElements()
	};


	const handleClose = () => {
		setAnchorEl(null);
		setTotalPages(1)
		setCurrentPage(1)
	};

	const handleInputChange = (evt) => {
		if (/["*:<>?/\\|]/.test(evt.target.value)) return
		setInputChange(evt.target.value);
		searchlocal(evt.target.value);

	};

	const onClickDescription = () => {
		setCheckedDescription(!checkedDescription);
		if (!isVaultView) {
			includedescription && includedescription({
				description: !checkedDescription,
				value: inputChange
			});
		}
	};

	const handleClickElementSearch = (element) => {
		const objHistory = {}
		setAnchorEl(null)
		console.log(element);

		if (element.isVault) {
			objHistory.pathname = `/dashboard/vault/${element.id}`
		} else if (!element.extension) {
			const search = element.path ? `${element.path}/${element.name}` : element.name;
			objHistory.pathname = `/dashboard/vault/${element.vaultId}`
			objHistory.search = `p=${encodeURIComponent(search)}`
		} else {
			const search = encodeURIComponent(element.path)
			const input = encodeURIComponent(inputChange)
			const name = encodeURIComponent(element.name)
			const extension = encodeURIComponent(element.extension)
			const desc = encodeURIComponent(checkedDescription)
			objHistory.pathname = `/dashboard/vault/${element.vaultId}`
			objHistory.search = `p=${search}&input=${input}&id=${element.id}&desc=${desc}&ext=${extension}&name=${name}`
		}

		history.push(objHistory)

	}

	useEffect(() => {
		const { search } = location;
		const desc = new URLSearchParams(search).get('desc');
		const input = new URLSearchParams(search).get('input');
		const documentId = new URLSearchParams(search).get('id');
		const extension = new URLSearchParams(search).get('ext');
		const name = new URLSearchParams(search).get('name');

		if (!input) return
		setInputChange(input)

		if (desc === "true") {
			setCheckedDescription(true)
			console.log("ejecutando 1");
			includedescription({
				description: true,
				value: input
			});
		} else {
			searchlocal(input)
		}
		if (documentId && extension && name) {
			documentselect({
				id: documentId,
				extension,
				name
			})
		}
	}, [location.search])

	useEffect(() => {
		if (!inputChange) return
		searchElements()
	}, [currentPage])


	return (
		<Grid item container justifyContent="flex-start" alignItems="center" spacing={3} {...props}>
			<Grid item xs={12} md={7}>
				<TextField
					ref={searchRef}
					fullWidth={true}
					value={inputChange}
					margin="dense"
					label={`Texto a buscar en el Nombre ${checkedDescription ? 'y la Descripción' : ''}`}
					variant="outlined"
					onChange={(evt) => handleInputChange(evt)}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start" >
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
			</Grid>

			<Grid item container xs={6} md={5} alignItems="center" justifyContent="flex-start" >
				<Grid item >
					<FormGroup row>
						<FormControlLabel
							control={
								<Checkbox
									checked={checkedDescription}
									onChange={onClickDescription}
									name="descripcion"
									color="primary"
								/>
							}
							label="Descripción"
						/>
					</FormGroup>
				</Grid>
				<Grid item >
					<Button
						aria-controls="customized-menu"
						aria-haspopup="true"
						variant="contained"
						color="primary"
						disabled={!(inputChange.length > 0)}
						onClick={handleClick}
						startIcon={<SearchIcon />}
					>
						subfolders
					</Button>
				</Grid>
			</Grid>

			<MenuListAnchor
				options={data}
				loading={loading}
				page={currentPage}
				anchorEl={anchorEl}
				totalPages={totalPages}
				setPage={setCurrentPage}
				handleCloseMenu={handleClose}
				handleClickElement={handleClickElementSearch}
			/>
		</Grid>
	);
};
