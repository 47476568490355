import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import BusinessFolders from "./business-folders";
import { TableFolder } from "axeleratum-sgc-frontend-library";
import "./table-business.scss";
import { DocumentsHttp } from "../../../core/http/documents.http";
import { timeAgo, sortData } from "../../../core/utils";
import { FoldersHttp } from "../../../core/http/folders.http";
import Details from "../../vault/details";
import AlertInfo from "@material-ui/lab/Alert";

const headCells = ["Nombre", "Autor", "Última modificación", ""];

class TableBusiness extends Component {
  foldersHttp = new FoldersHttp();
  documentsHttp = new DocumentsHttp();

  constructor(props) {
    super(props);

    this.state = {
      folderSelect: null,
      selectElement: null,
      tabSelect: "1",
      contractsFolder: null,
    };
  }

  render() {
    const {
      onFolderSelected,
      folderSelected,
      documentSelected,
      folders,
      contracts,
      onClickContract,
      onClickContractOptions,
      onDoubleClickFile,
      loading,
    } = this.props;

    return (
      <React.Fragment>
        {loading && (
          <Grid container style={{ justifyContent: "center", width: "100%" }}>
            <AlertInfo severity="info">Cargando archivos...</AlertInfo>
          </Grid>
        )}

        {!loading && (
          <Grid container className="table-business">
            <Grid item md={12}>
              <BusinessFolders
                showReturn={folderSelected !== null}
                folders={folders}
                onClick={(value) => {
                  onFolderSelected(value);
                }}
                onReturn={() => {
                  onFolderSelected(folderSelected.parentFolder);
                }}
              />
            </Grid>
 
              <Grid item md={9} style={{ paddingTop: '25px', paddingRight: "25px" }}>
                <TableFolder
                  files={contracts}
                  headCells={headCells}
                  itemSelected={documentSelected}
                  onClickItem={(file) => {
                    onClickContract(file);
                  }}
                  onClickOptions={(evt, file) => {
                    if (file.type === "file" && !file.onlyExistInKaleido) {
                      onClickContractOptions(evt, file);
                    }
                  }}
                  onDoubleClick={(evt, file) => {
                    if (file.type === "file" && !file.onlyExistInKaleido) {
                      onDoubleClickFile(evt, file);
                    }
                  }}
                />

                {contracts.length === 0 && (
                  <div className="no-folder">No hay documentos que mostrar</div>
                )}
              </Grid>

              <Grid item md={3}>
                <Details
                  itemSelected={documentSelected}
                  onCloseDetails={() => {
                    this.props.onCloseDetails();
                  }}
                  company={this.props.BusinessFolders}
                />
              </Grid>
            </Grid>
        )}
      </React.Fragment>
    );
  }
}

export default TableBusiness;
