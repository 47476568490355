import { businessMgmtInstance, authHeader } from "../../../configs/axios.config"



export const createSubChart = async (chartId, body) => {

  try {
    const { data } = await businessMgmtInstance({
      method: "POST",
      url: `/chart/${chartId}`,
      data: body,
      headers: authHeader()
    })
    return data
  } catch (error) {
    throw error.response.data.error
  }
}

export const updateSpreadCheetChart = async (chartId, body) => {

  try {
    const { data } = await businessMgmtInstance({
      method: "PUT",
      url: `/chart/${chartId}`,
      data: body,
      headers: authHeader()
    })
    return data
  } catch (error) {
    throw error.response.data.error
  }
}

export const deleteSpreadSheetChart = async (spreadSheetId) => {
  try {
    await businessMgmtInstance({
      method: "DELETE",
      url: `/chart/${spreadSheetId}`,
      headers: authHeader()
    })

  } catch (error) {
    throw error.response.data.error
  }
}

export const getSpreadSheetChart = async (id, fileName) => {
  try {
    const { data } = await businessMgmtInstance({
      url: `/chart/${id}/${fileName}`,
      headers: authHeader()
    })
    return data
  } catch (error) {
    throw error.response.data.error
  }
}

export const getChartById = async (id) => {
  try {
    const { data } = await businessMgmtInstance({
      url: `/chart/${id}`,
      headers: authHeader()
    })
    return data
  } catch (error) {
    throw error.response.data.error
  }
}

export const getAllBusiness = async () => {
  try {
    const { data } = await businessMgmtInstance({
      url: '/business',
      headers: authHeader()
    })
    return data

  } catch (error) {
    throw error.response.data.error
  }
}

export const getBusinessById = async (businessId) => {
  try {
    const { data } = await businessMgmtInstance({
      url: `/business/${businessId}`,
      headers: authHeader()
    })
    return data
  } catch (error) {
    throw error.response.data.error
  }
}

export const createBusiness = async (body) => {
  try {
    const { data } = await businessMgmtInstance({
      method: 'POST',
      url: '/business',
      data: body,
      headers: authHeader()
    })
    return data
  } catch (error) {
    throw error.response.data.error
  }
}

export const editBusiness = async (businessId, body) => {
  try {
    const { data } = await businessMgmtInstance({
      method: 'PUT',
      url: `/business/${businessId}`,
      data: body,
      headers: authHeader()
    })
    return data
  } catch (error) {
    throw error.response.data.error
  }
}
