import { DndContext, closestCenter } from "@dnd-kit/core"
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';

export const DraggableList = ({ children, items, setItems }) => {

  const handleDragEnd = (e) => {
    const { active, over } = e;

    const oldIndex = items.findIndex(el => el.id === active.id);
    const newIndex = items.findIndex(el => el.id === over.id);
    const newOrder = arrayMove(items, oldIndex, newIndex);

    setItems(newOrder);
  }

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={items}
        strategy={verticalListSortingStrategy}
      >
        {children}
      </SortableContext>
    </DndContext>
  )
}
