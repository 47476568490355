import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Slide,
  Grid,
  Box
} from "@material-ui/core";
import { SelectNative } from "./fields/select-native";
import { TextFieldNative } from "./fields/text-field-native";
import moment from "moment";
import { DropZoneNative } from "./drop-zone-native";
import { CalendarHttp } from "../../../../core/http/calendar.http";
import { Alert } from "axeleratum-sgc-frontend-library";
import "./form-activity-native.scss";
import {
  validatePermissions,
  permissionScheme,
} from "../../../../core/utils/can-i";
import { DocumentsHttp } from "../../../../core/http/documents.http";
import saveAs from "file-saver";
import { PDFViewer } from "library-signature";
import { tokenExpired } from "../../../../core/utils";
import { authActions } from "../../../../core/actions";
import { connect } from "react-redux";

export class FormActivityNative extends Component {
  calendarHttp = new CalendarHttp();
  documentsHttp = new DocumentsHttp();

  typeOptionsE = [
    {
      label: "Revisión",
      value: "revision",
    },
    {
      label: "Firma",
      value: "firm",
    },
  ];

  typeOptionsD = [
    {
      label: "Revisión",
      value: "revision",
    },
    {
      label: "Firma",
      value: "firm",
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      id: "",
      type: props.type ? props.type : "revision",
      name: props.name ? props.name : "",
      nameMessage: "",
      description: props.description ? props.description : "",
      limitDate: moment(new Date()).format("YYYY-MM-DD"),
      hour: moment(new Date()).format("HH:MM"),
      recordatory: null,
      files: props.document ? [props.document] : [],
      openWarning: false,
      openSignDialog: false,
      load: false,
      openAlert: false,
      typeMessage: "error",
      message: "",
      userFirm: [],
      proccessSticker: "",
      stickersValue: null,
      finalProcces: true,
      prepareSignaturesIsEnabled: false,
      createSignatureActivityIsEnabled: false,
    };
  }

  componentDidMount() {
    this.getPermissions();
  }

  getPermissions() {
    validatePermissions(
      permissionScheme.documentManagement.prepareSignatures
    ).then((enabled) => {
      this.setState({ prepareSignaturesIsEnabled: enabled });
    });

    validatePermissions(
      permissionScheme.documentManagement.createSignatureActivity
    ).then((enabled) => {
      this.setState({ createSignatureActivityIsEnabled: enabled });
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    //agregar valores
    this.setState({
      id: props.id,
      type: props.type ? props.type : "revision",
      name: props.name ? props.name : "",
      description: props.description ? props.description : "",
      files: props.files ? props.files : [],
    });
  }

  addUsersToFile(name, withOrder, form) {
    const { files, stickersValue } = this.state;
    let itemIndex = -1;

    files.forEach((item, index) => {
      if (name === item.name) {
        itemIndex = index;
      }
    });

    files[itemIndex].withOrder = withOrder;
    files[itemIndex].reviewers = form;
    files[itemIndex].stickersValue = stickersValue;
    this.setState({ files });
    this.setState({ userFirm: files });
    this.verifyDataStickerProccess();
  }

  removeFile(name) {
    const { files } = this.state;
    let itemIndex = -1;

    files.forEach((item, index) => {
      if (name === item.name) {
        itemIndex = index;
      }
    });

    files.splice(itemIndex, 1);
    this.setState({ files });
  }

  validate() {
    const { name } = this.state;
    let out = true;

    if (!name) {
      this.setState({ nameMessage: "El nombre de la actividad es requerido" });
      out = false;
    }

    if (out) {
      this.setState({ nameMessage: "" });
    }

    return out;
  }

  getPdf() {
    const { newOrUpdate } = this.props;
    const { files } = this.state;

    let nameDocument = "";
    let documentId = "";

    if (newOrUpdate) {
      nameDocument = files[0].documentName;
      documentId = files[0].documentId;
    } else {
      let document = files[0].file;

      documentId = document.documentId;
      nameDocument = document.name;
    }
    const { isLoggedIn, userLoggedOut } = this.props;

    if (isLoggedIn && tokenExpired()) {
      userLoggedOut()
      return
    }

    this.setState({ load: true });
    this.documentsHttp.downloadDocumentById(
      documentId,
      (resp, extension) => {
        this.setState({ loadFile: false });
        if (extension.toLowerCase() === ".pdf") {
          const url = URL.createObjectURL(
            new Blob([resp.data], {
              type: "application/pdf",
            })
          );
          this.setState({
            filePdf: url,
            titlePdf: nameDocument,
            openPdf: true,
            documentIdOpen: documentId,
            load: false,
          });
          this.handleOpenSignDialog();
        } else {
          const blob = new Blob([resp.data], {
            type: "application/octet-stream",
          });
          saveAs(blob, `${document.name}${extension}`);
        }
      },
      (error) => {
        console.log("error", error);
        this.setState({
          openAlert: true,
          typeMessage: "error",
          message: "Ocurrió un error al abrir documento. Intente más tarde.",
          loadFile: false,
          load: false,
        });
      }
    );
  }

  submit(e) {
    e.preventDefault();
    const {
      id,
      type,
      name,
      description,
      tags,
      limitDate,
      hour,
      recordatory,
      files,
      stickersValue,
    } = this.state;
    const { onSubmit, newOrUpdate } = this.props;
    const documentsExist = [];
    const documentsNew = [];
    const documents = [];

    if (this.validate()) {
      files.forEach((item) => {
        if (item.type === "cloud") {
          documentsExist.push({
            documentId: item.file.documentId,
            documentName: item.file.name,
            stickers: stickersValue,
            withOrder: item.withOrder ? item.withOrder : false,
            reviewers: item.reviewers
              ? item.reviewers.map((user, index) => ({
                email: user.email,
                userId: user.userId,
                name: user.name,
                color: user.color ? user.color : "darkgray",
                order: item.withOrder ? index : 0,
                type: user.type,
                rfc: user.rfc,
                temporal: false,
              }))
              : [],
          });
        }

        if (item.type === "local") {
          documentsNew.push({
            companyId: item.companyId,
            name: item.name.split(".")[0],
            description: item.name,
            extension: item.extension,
            size: item.size,
            owner: item.owner,
            withOrder: item.withOrder ? item.withOrder : false,
            file: {
              base64: item.file,
              name: item.name,
            },
            reviewers: item.reviewers
              ? item.reviewers.map((user, index) => ({
                email: user.email,
                userId: user.userId,
                name: user.name,
                color: user.color ? user.color : "darkgray",
                // order: item.withOrder ? index + 1 : 0,
                order: item.withOrder ? index : 0,
                type: user.type,
                rfc: user.rfc,
              }))
              : [],
          });
        }

        if (item.type === "exist") {
          documents.push({
            id: item.id,
            documentId: item.documentId,
            withOrder: item.withOrder ? item.withOrder : false,
            stickers: stickersValue,
            reviewers: item.reviewers
              ? item.reviewers.map((user, index) => ({
                email: user.email,
                userId: user.userId,
                name: user.name,
                color: user.color ? user.color : "darkgray",
                // order: item.withOrder ? index + 1 : 0,
                order: item.withOrder ? index : 0,
                type: user.type,
                rfc: user.rfc,
              }))
              : [],
          });
        }
      });

      this.setState({ load: true });

      if (newOrUpdate) {
        const { isLoggedIn, userLoggedOut } = this.props;

        if (isLoggedIn && tokenExpired()) {
          userLoggedOut()
          return
        }
        this.setState({ upload: true });
        this.calendarHttp
          .updateActivity({
            id,
            type,
            name,
            description,
            documentsNew,
            documentsExist,
            documents,
          })
          .then(({ data }) => {
            this.setState({
              load: false,
              openAlert: true,
              message: "Actividad creada",
              typeMessage: "success",
            });
            onSubmit(data);
            this.reset();
            this.updateLabels();
          })
          .catch((error) => {
            let checkMessage =
              error.message === "Cannot set property 'token' of undefined"
                ? true
                : false;
            if (checkMessage === true) {
              console.log("token error ommit");
            } else {
              /********************************************************
               *  Se cambia ala forma de validar el error ya que en  **
               * setState no acepta response                          *
               * ****************************************************** */
              const _err =
                error.response && error.response.data && error.response.status;
              const _msgError =
                _err === 401
                  ? "No tiene permisos parea crear una actividad"
                  : error.message;

              this.setState({
                load: false,
                openAlert: true,
                message:
                  _err !== 500
                    ? _msgError
                    : "Hubo un error al crear la actividad",
                typeMessage: "error",
              });
            }
          });
      } else {
        const { isLoggedIn, userLoggedOut } = this.props;

        if (isLoggedIn && tokenExpired()) {
          userLoggedOut()
          return
        }
        this.calendarHttp
          .createActivity({
            id,
            type,
            name,
            description,
            tags: tags ? tags.map((item) => item.label) : [],
            hour,
            date: limitDate,
            limitDate: limitDate,
            recordatory: recordatory ? recordatory : 0,
            documentsNew,
            documentsExist,
            documents,
          })
          .then((data) => {
            // debugger;
            this.setState({
              load: false,
              openAlert: false,
              message: "Actividad creada",
              typeMessage: "success",
            });
            onSubmit();
            this.reset();
            this.updateLabels();
          })
          .catch((error) => {
            let checkMessage =
              error.message === "Cannot set property 'token' of undefined"
                ? true
                : false;
            if (checkMessage === true) {
              console.log("token error ommit");
            } else {
              const _err =
                error.response && error.response.data && error.response.status;
              const _msgError =
                _err === 401
                  ? "No tiene permisos parea crear una actividad"
                  : error.message;
              this.setState({
                load: false,
                openAlert: true,
                message:
                  _err !== 500
                    ? _msgError
                    : "Hubo un error al crear la actividad",
                typeMessage: "error",
              });
            }
          });
      }
    }
  }

  reset() {
    this.setState({
      type: "revision",
      name: "",
      nameMessage: "",
      description: "",
      files: [],
      proccessSticker: "",
      stickersValue: null,
      finalProcces: true,
    });
  }

  updateLabels() {
    const { isLoggedIn, userLoggedOut } = this.props;

    if (isLoggedIn && tokenExpired()) {
      userLoggedOut()
      return
    }
    this.calendarHttp
      .getPendingActivities()
      .then(({ data }) => {
        document.getElementById("activities").innerHTML = data.length;
      })
      .catch((err) => { });

    // this.calendarHttp
    //   .getInvitations()
    //   .then(({ data }) => {
    //     document.getElementById("documents").innerHTML = data.length;
    //   })
    //   .catch((err) => { });
  }

  verifyDataStickerProccess() {
    const { type, userFirm } = this.state;

    if (type === "firm" && userFirm) {
      this.setState({ proccessSticker: "initStickerProccess" });
    } else {
      this.setState({ proccessSticker: "notData" });
    }
  }

  handleOpenSignDialog = () => {
    this.setState({ openSignDialog: true });
  };

  handleCloseSignDialog = () => {
    this.setState({ openSignDialog: false });
  };

  handleSaveSigns = (stickersValue) => {
    console.log(stickersValue);
    this.setState({ stickersValue, finalProcces: false });
  };

  handleLoad = (load) => {
    this.setState({ load });
  };

  render() {
    const {
      type,
      name,
      nameMessage,
      description,
      files,
      openWarning,
      load,
      openAlert,
      openSignDialog,
      typeMessage,
      message,
      proccessSticker,
      titlePdf,
      filePdf,
      finalProcces,
    } = this.state;

    const { open, newOrUpdate, onCancel, disabled } = this.props;
    return (
      <>
        <Dialog className="form-size" open={open}>
          <form
            className="form-activity-native"
            onSubmit={(e) => this.submit(e)}
          >
            <Dialog open={openWarning} onClose={() => { }}>
              <DialogTitle
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
              >
                Precaución
              </DialogTitle>

              <DialogContent>
                <DialogContentText>
                  Si cambias el tipo de actividad todos los archivos
                  seleccionados se perderán
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button
                  autoFocus
                  onClick={() =>
                    this.setState({
                      openWarning: false,
                      type: type === "firm" ? "revision" : "firm",
                    })
                  }
                  color="primary"
                >
                  Cancel
                </Button>

                <Button
                  onClick={() =>
                    this.setState({
                      openWarning: false,
                      files: [],
                    })
                  }
                  color="primary"
                >
                  Aceptar
                </Button>
              </DialogActions>
            </Dialog>

            <Alert
              open={openAlert}
              title={message}
              onConfirm={() => {
                this.setState({ openAlert: false });
              }}
              type={typeMessage}
            />

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <h3 style={{ margin: "2px" }}>{newOrUpdate ? "Editar actividad" : "Nueva actividad"}</h3>
              </Grid>

              <Grid item xs={12}>
                <SelectNative
                  disabled={disabled}
                  label="Tipo de actividad*"
                  value={type}
                  items={
                    this.state.createSignatureActivityIsEnabled
                      ? this.typeOptionsE
                      : this.typeOptionsD
                  }
                  message={""}
                  onChange={(e) =>
                    this.setState({
                      type: e,
                      openWarning: files ? files.length > 0 : false,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <TextFieldNative
                  label="Nombre de la actividad"
                  value={name}
                  message={nameMessage}
                  onChange={(e) => this.setState({ name: e })}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextFieldNative
                  label="Descripción (opcional)"
                  value={description}
                  message={""}
                  onChange={(e) => this.setState({ description: e })}
                />
              </Grid>

              <Grid item xs={12}>
                <h3>Documento</h3>
              </Grid>

              <Grid item xs={12}>
                {load && (
                  <div className="col-md-12 text-center">
                    <strong className="mb-2">
                      {proccessSticker === "initStickerProccess"
                        ? "Procesando su documento, espere por favor"
                        : "Tus archivos se están subiendo, espera por favor"}
                    </strong>
                    <LinearProgress
                      className="primary-color-bar"
                      color="primary"
                    />
                  </div>
                )}
                {/* {!load && ( */}
                <Box hidden={load}>
                  <DropZoneNative
                    hideSearchFile={disabled}
                    handleLoad={(load) => this.setState({ load })}
                    type={type}
                    files={files}
                    onAddFile={(file) => {
                      if (
                        !files.find(
                          (fileFilter) => fileFilter.name === file.name
                        )
                      ) {
                        files[0] = file;
                        // files.push(file);
                        this.setState({ files });
                      }
                    }}
                    onAddUsers={(name, form, withOrder) =>
                      this.addUsersToFile(name, withOrder, form)
                    }
                    onRemoveItem={(name) => this.removeFile(name)}
                  />
                </Box>
                {/* )} */}
              </Grid>

              {/** <ComponetPDF pdf={} documentName={} json={} /> */}

              <Grid container xs={12} spacing={2} justifyContent="flex-end">
                <Grid item>
                  <Button
                    className="font-color mr-2"
                    variant="outlined"
                    onClick={() => {
                      this.reset();
                      onCancel();
                    }}
                    disabled={load}
                  >
                    Cancelar
                  </Button>
                </Grid>

                {proccessSticker === "initStickerProccess" && (
                  <Grid item>
                    <Button
                      className="font-color mr-2"
                      variant="outlined"
                      onClick={() => {
                        this.getPdf();
                      }}
                      disabled={load}
                    >
                      Posicionar Etiquetas
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  {type === "firm" ? (
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={finalProcces}
                    >
                      Aceptar
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={load || files[0]?.reviewers.length === 0}
                    >
                      Aceptar
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Dialog>
        <Dialog
          fullScreen
          open={openSignDialog}
          onClose={this.handleCloseSignDialog}
          TransitionComponent={Transition}
        >
          <PDFViewer
            signers={files[0]?.reviewers}
            url={filePdf}
            documentName={titlePdf}
            onClose={this.handleCloseSignDialog}
            onSaveSigns={this.handleSaveSigns}
          />
        </Dialog>
      </>
    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const mapStateToProps = state => {
  return {
    isLoggedIn: state.authReducer.loggedIn,
  }
}

const mapDispatchToProps = {
  userLoggedOut: authActions.userLoggedOut,
}

export default connect(mapStateToProps, mapDispatchToProps)(FormActivityNative);