import React, { Component } from 'react';
import {
  AlertConfirm,
  Alert,
  isMobile,
} from 'axeleratum-sgc-frontend-library';
import { Button, Dialog, Grid } from '@material-ui/core';
import FormBusiness from './form-business';
import { UserHttp } from '../../core/http/user.http';
import businessImg from '../../assets/img/business.png';
import AlertInfo from '@material-ui/lab/Alert';
import { FoldersHttp } from '../../core/http/folders.http';
import { validatePermissions, permissionScheme } from '../../core/utils/can-i';
import ValidateCredentials from './validate-credentials';
import { AuthService } from '../../core/services';
import { CardBusiness } from '../from-library/card-business/card-business';
import { tokenExpired } from '../../core/utils';
import { connect } from 'react-redux';
import { authActions } from '../../core/actions';
import { allowStoreVaults, assignUsersVault, createVault, editVault, enabledVault, getVaultsByUser } from '../../core/http/functionRequests';

class Business extends Component {
  userHttp = new UserHttp();
  foldersHttp = new FoldersHttp();
  authService = new AuthService()

  constructor(props) {
    super(props);

    this.state = {
      businessList: null,
      users: null,
      businessSelect: null,
      tags: null,
      openDialog: false,
      openAlert: false,
      alert: false,
      alertError: false,
      messageError: '',
      openValidateCredentials: false,
      createCompanyIsEnabled: false,
      enrollUserInCompanyIsEnabled: false,
      editCompanyIsEnabled: false,
      blockCompanyIsEnabled: false,
      openConfirmDialog: false,
      formDataSaveVault: null,
      amountVaultsAvailable: 0,
      openSucessAlert: false,
      messageSuccessAlert: ''
    };
  }

  componentDidMount() {
    this.findAllUsers();
    this.findAllBusiness();
    this.getPermissions();
  }

  getPermissions() {
    validatePermissions(permissionScheme.businessManagement.createCompany).then(
      (enabled) => {
        this.setState({ createCompanyIsEnabled: enabled });
      }
    );
    validatePermissions(
      permissionScheme.businessManagement.enrollUsersInCompany
    ).then((enabled) => {
      this.setState({ enrollUserInCompanyIsEnabled: enabled });
    });
    validatePermissions(permissionScheme.businessManagement.editCompany).then(
      (enabled) => {
        this.setState({ editCompanyIsEnabled: enabled });
      }
    );
    validatePermissions(permissionScheme.businessManagement.blockCompany).then(
      (enabled) => {
        this.setState({ blockCompanyIsEnabled: enabled });
      }
    );
  }

  //gets
  findAllUsers() {
    const { isLoggedIn, userLoggedOut } = this.props;

    if (isLoggedIn && tokenExpired()) {
      userLoggedOut()
      return
    }

    this.userHttp.findAll(
      (users) => {
        const usersOut = [];
        console.log("MDR en findAllUsers 4    ");

        if (users) {
          users.forEach((user) => {
            if (!user.deleted && user.enabled) {
              usersOut.push({
                ...user,
                label: user.completeName ? user.completeName : user.username,
                value: user.id,
              });
            }
          });
        }

        this.setState({ users: usersOut });
      },
      () => { }
    );
  }

  findAllBusiness() {
    const { isLoggedIn, userLoggedOut } = this.props;
    if (isLoggedIn && tokenExpired()) {
      userLoggedOut()
      return
    }


    getVaultsByUser()
      .then(data => this.setState({ businessList: data }))
      .catch(err => console.error(err))

  }


  //========

  //cud
  async handleSetInformationToSaveVault(business) {
    const { isLoggedIn, userLoggedOut } = this.props;


    if (isLoggedIn && tokenExpired()) {
      userLoggedOut()
      return
    }
    const response = await allowStoreVaults();
    this.setState({ openConfirmDialog: true, formDataSaveVault: business, amountVaultsAvailable: (response.quantityAvailable - response.numberOfCompanies) - 1 })
  }


  updateBusiness(business) {
    const { isLoggedIn, userLoggedOut } = this.props;

    if (isLoggedIn && tokenExpired()) {
      userLoggedOut()
      return
    }

    const body = {
      name: business.name,
      enabled: business.enabled,
      users: business.users ? business.users.map(el => el.value) : [],
      tags: business.tags ? business.tags.map(el => el.value) : []
    }

    editVault(business.id, body)
      .then(() => {
        this.setState({ openDialog: false, openSucessAlert: true, messageSuccessAlert: 'editada' });
        this.findAllBusiness();
      })
      .catch((e) => {
        this.setState({
          openDialog: false,
          alertError: true,
          messageError: e.response && e.response.data,
        });
      })
  }

  assignUsers(id, users) {
    const { isLoggedIn, userLoggedOut } = this.props;


    if (isLoggedIn && tokenExpired()) {
      userLoggedOut()
      return
    }

    assignUsersVault(id, { users })
      .then(() => this.findAllBusiness())
      .catch((err) => console.error(err))
  }

  disableBusiness(businessSelect, credentials) {
    const { isLoggedIn, userLoggedOut } = this.props;


    if (isLoggedIn && tokenExpired()) {
      userLoggedOut()
      return
    }
    this.authService.login(credentials.username, credentials.password, sessionStorage.getItem("clientKey")).then((res) => {
      enabledVault(businessSelect.id, { enabled: !businessSelect.enabled })
        .then(() => {
          this.setState({ openValidateCredentials: false, alert: true });
          this.findAllBusiness();
        }).catch(() => {
          this.setState({
            openValidateCredentials: false,
            alertError: true,
            messageError: 'Ocurrió al habilitar/deshabilitar la bóveda.'
          });
        })
    }).catch((error) => {
      let message = '';
      if (error.response && error.response.status === 400) {
        if (error.response.data.error_description === 'Bad credentials') {
          message = 'Correo o contraseña incorrectos.';
        } else if (error.response.data.error_description === "User account is locked") {
          message = 'La cuenta ha sido bloqueada.';
        }
      } else {
        if (error.response.status === 401) {
          message = 'Usuario no autorizado.';
        } else if (error.response.status === 500) {
          message = 'Correo o contraseña incorrectos.';
        } else {
          message = 'Ocurrió un error inesperado, intenta más tarde.'
        }
      }

      this.setState({
        openValidateCredentials: false,
        alertError: true,
        messageError: message
      });
    });
  }
  //=======

  render() {
    const {
      users,
      businessList,
      openDialog,
      businessSelect,
      tags,
      openAlert,
      alert,
      alertError,
      messageError,
      createCompanyIsEnabled,
      enrollUserInCompanyIsEnabled,
      editCompanyIsEnabled,
      blockCompanyIsEnabled,
      openValidateCredentials,
      openConfirmDialog,
      formDataSaveVault,
      amountVaultsAvailable,
      openSucessAlert,
      messageSuccessAlert,
    } = this.state;

    const saveVault = () => {
      const request = {
        tags: formDataSaveVault.tags ? formDataSaveVault.tags.map(el => el.value) : [],
        users: formDataSaveVault.users ? formDataSaveVault.users.map(el => el.id) : [],
        name: formDataSaveVault.name
      }

      createVault(request)
        .then(() => {
          this.setState({ openDialog: false, openConfirmDialog: false, openSucessAlert: true, messageSuccessAlert: 'creada' });
          this.findAllBusiness();
        }).catch((e) => {

          this.setState({
            // openDialog: false,
            alertError: true,
            messageError: e.response.data.error,
            openConfirmDialog: false,
            // formDataSaveVault: null,
            amountVaultsAvailable: 0
          });
        })
    }

    return (
      <React.Fragment>
        <AlertConfirm
          open={openAlert}
          onCancel={() =>
            this.setState({ openAlert: false, businessSelect: null })
          }
          onConfirm={() => this.setState({ openAlert: false, openValidateCredentials: true })}
          textContent={`La bóveda se ${businessSelect
            ? businessSelect.enabled
              ? 'inhabilitará'
              : 'activará'
            : ''
            } ¿Deseas continuar?`}
        />

        <Alert
          open={alert}
          title={`La bóveda se ha ${businessSelect
            ? businessSelect.enabled
              ? 'inhabilitado'
              : 'activado'
            : ''
            } correctamente `}
          type='success'
          onConfirm={() => this.setState({ alert: false })}
        />

        <Alert
          open={alertError}
          title={messageError}
          type='error'
          onConfirm={() => this.setState({ alertError: false })}
        />

        <Dialog className={isMobile ? '' : 'w-50-dialog'} open={openDialog}>
          <FormBusiness
            initialValues={businessSelect ? businessSelect : { tags }}
            submitActions={(formData) =>
              businessSelect
                ? this.updateBusiness(formData)
                : this.handleSetInformationToSaveVault(formData)
            }
            users={users}
            tags={tags}
            onCancel={() =>
              this.setState({ openDialog: false })
            }
          />
          <AlertConfirm
            open={openConfirmDialog}
            onCancel={() => this.setState({ openConfirmDialog: false })}
            onConfirm={saveVault}
            textContent={`Se dará de alta la bóveda ${formDataSaveVault?.name}, quedando disponibles ${amountVaultsAvailable}.`}
          />

        </Dialog>
        <Alert
          open={openSucessAlert}
          title={`Bóveda ${messageSuccessAlert} correctamente.`}
          type="success"
          onConfirm={() => { this.setState({ openSucessAlert: false }) }}
        />

        <Grid container justifyContent="flex-end">
          <Grid className='text-right mb-2' item md={12}>
            <Button
              disabled={!createCompanyIsEnabled}
              variant='contained'
              color='primary'
              onClick={() =>
                this.setState({ openDialog: true, businessSelect: null })
              }
            >
              + AÑADIR BOVEDA
            </Button>
          </Grid>
        </Grid>

        {!businessList && (
          <Grid container style={{ justifyContent: 'center', width: '100%' }}>
            <AlertInfo severity='info'>Cargando bóvedas...</AlertInfo>
          </Grid>
        )}

        {businessList && businessList.length === 0 && (
          <Grid container style={{ justifyContent: 'center', width: '100%' }}>
            <AlertInfo severity='info'>No hay bóvedas registradas</AlertInfo>
          </Grid>
        )}

        {businessList &&
          businessList.length > 0 &&
          businessList.map((business, index) => (
            <div key={`card-business-${index}`}>
              <CardBusiness
                businessImage={businessImg}
                businessName={business.name}
                users={business.users}
                usersList={users}
                selectEdit={() => {
                  console.log("Editando.....");
                  this.setState({ openDialog: true, businessSelect: business })
                }
                }
                selectDisable={() =>
                  this.setState({ openAlert: true, businessSelect: business })
                }
                onSubmit={(usersId) => {
                  this.assignUsers(business.id, usersId);
                }}
                disabled={!business.enabled}
                enrollUserBtnEnabled={!enrollUserInCompanyIsEnabled || !business.enabled}
                editCompanyBtnEnabled={(!editCompanyIsEnabled || !business.enabled)}
                blockCompanyBtnEnabled={!blockCompanyIsEnabled}
              />
            </div>
          ))}

        {openValidateCredentials &&
          <ValidateCredentials
            open={openValidateCredentials}
            onSubmit={(credentials) => this.disableBusiness(businessSelect, credentials)}
            onCancel={() => this.setState({ openValidateCredentials: false })}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.authReducer.loggedIn,
  }
}

const mapDispatchToProps = {
  userLoggedOut: authActions.userLoggedOut,
}

export default connect(mapStateToProps, mapDispatchToProps)(Business);