import React, { useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { makeStyles, Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { v4 as uuidv4 } from 'uuid';
import { getCurrentUser } from "../../core/helpers";
import { AlertComponent } from "../AlertComponent";
import { deleteFromStorageProvider, getSaveRequest } from "../../core/http/functionRequests";
import { getHash, getHashWithEncrypt } from "../../core/helpers/documentsHelper";
import { PDFDocument } from "pdf-lib";

const useStyles = makeStyles((theme) => ({
  dropzone: {
    backgroundColor: "rgba(33, 70, 183, 0.05)",
    height: "138px",
    textAlign: "center",
    paddingTop: "20px",
    "& p": {
      color: "rgba(122, 146, 197, 0.87)",
    },
    border: "1px dashed rgba(92, 123, 240, 0.6)",
    borderRadius: "6px",
  },
  alertMessage: {
    marginLeft: "27px",
    marginTop: "28px",
    marginRight: "28px",
  },
  p: {
    margin: 0,
  },
}));



function DropzoneFiles(field) {
  const { onInitUploadFile, setDocumentName, setDocumentExtension, setOriginalName, originalName } = field;
  const classes = useStyles();
  const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
  const [aborted, setAborted] = useState(false)

  const getUploadParams = async (properties) => {
    const { file, meta } = properties;
    try {
      const { storageProvider, encryptedStorage } = getCurrentUser();
      const body = new FormData()

      const extension = /(?:\.([^.]+))?$/.exec(meta.name)[0]
      meta.extension = extension;
      meta.storageProvider = storageProvider;

      if (originalName.length === 0) {
        const newName = meta.name.split(extension)[0];
        setOriginalName(newName)
      }

      if (storageProvider === "kaleido") {
        body.append('file', file)
        const sizeLimit = 52428800 // 50 MB
        if (meta.size >= sizeLimit) {
          setOpenAlertConfirm(true)
          return { url: "" }
        }
        return { url: 'https://httpbin.org/post', body, meta }
      }

      const { request, name } = await getSaveRequest(extension);
      body.append('file', file)
      const readFile = await getHash(file, extension);

      meta.editable = false;
      if (extension === ".pdf") {
        const pdfDoc = await PDFDocument.load(readFile.buffer)
        const form = pdfDoc.getForm()
        const fields = form.getFields()
        meta.editable = fields.length > 0;
      }
      meta.hash = readFile.hash
      // if (encryptedStorage) {
      //   const { hash, encryptedFile } = await getHashWithEncrypt(file);
      //   console.log("tiene encriptacion");
      //   body.append('file', encryptedFile)
      //   meta.hash = hash;
      // } else {
      //   body.append('file', file)
      //   meta.hash = getHash(file);
      // }

      meta.blobname = name;

      setDocumentExtension && setDocumentExtension(extension);
      setDocumentName && setDocumentName(name)

      return { ...request, body, meta }
    } catch (error) {
      console.log(error);
      console.error("No se pudo obtener la url");
      return { url: "" }
    }
  };

  const restoreStates = () => {
    setDocumentName && setDocumentName(null)
    setDocumentExtension && setDocumentExtension(null)
    onInitUploadFile && onInitUploadFile(false)
  }

  const handleChangeStatus = ({ file, meta }, status) => {
    if (status === "preparing") {
      setAborted(false)
      onInitUploadFile && onInitUploadFile(true)
    }

    if (status === "restarted") {
      setAborted(false)
      onInitUploadFile && onInitUploadFile(true)
    }

    if (status === "done") {
      onInitUploadFile && onInitUploadFile(false)
      if (meta.storageProvider === "kaleido") {
        field.input.onChange([file])
      } else {
        const objResponse = {
          size: file.size,
          extension: meta.extension,
          storedName: meta.blobname,
          editable: meta.editable
        }
        if (meta.hash) objResponse.hash = meta.hash
        // console.log(meta);
        field.input.onChange([objResponse])
      }
    }

    if (status === "removed" && !aborted) {
      restoreStates()
      if (meta.storageProvider === "blob") {
        const docName = `${meta.blobname}${meta.extension}`
        deleteFromStorageProvider(docName)
      }
      // request para eliminar documento
    }

    if (status === "aborted") {
      setAborted(true)
      restoreStates()
      // no se necesita borrar el documento en este punto
    }
  };

  return (
    <div>

      <Dropzone
        maxFiles={1}
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        inputContent={() => (
          <Grid
            container
            direction="column"
            alignContent="center"
            justifyContent="center"
            alignItems="center"
          >
            <p className={classes.p}>Da click para buscar en tus archivos o arrastralo de otra ventana</p>
          </Grid>
        )}
        styles={{
          dropzone: {
            overflow: "hidden",
            backgroundColor: "rgba(33, 70, 183, 0.1)",
            border: "1px dashed #5C7BF0",
          },
          dropzoneActive: { borderColor: "green" },
        }}
      />
      {openAlertConfirm &&
        <AlertComponent
          open={openAlertConfirm}
          title={'No se puede subir el documento porque tiene un tamaño mayor a 50 MB'}
          onConfirm={() => setOpenAlertConfirm(false)}
          type={"error"}
        />
      }

    </div>
  );
}

export default DropzoneFiles;
