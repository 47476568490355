import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import Header from '../../components/dashboard/header';
import {
  IconButton,
  Grid,
  Breadcrumbs,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from "react-router-dom";
import { ButtonMenu } from '../../components/button-menu/ButtonMenu';

import { getCurrentUser } from '../../core/helpers';
import { tokenExpired } from '../../core/utils';
import { authActions } from '../../core/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { createSubChart, getChartById, getSpreadSheetChart, updateSpreadCheetChart } from '../../core/http/functionRequests/business-management';
import { CreateNewChartDialog } from '../../components/dialogs/Business-management/CreateNewChartDialog';
import { TableData } from '../../components/Tables/Business-management/TableData';

const Accounting = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);

  const [currentSubchart, setCurrentSubchart] = useState('');
  const [chart, setChart] = useState(null);
  const [spreadSheet, setSpreadSheet] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mockOptionsMenu, setmockOptionsMenu] = useState([]);
  const [subChartsSelect, setSubChartsSelect] = useState([]);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [subChart, setSubChart] = useState(null);
  const [allowEdit, setAllowEdit] = useState(false);

  const updateSpreadSheet = async () => {
    try {
      setLoading(true)
      const updateChart = await updateSpreadCheetChart(subChart.id, {
        spreadSheetId: spreadSheet
      })
      setSubChart(updateChart)
      setSpreadSheet(null)
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }

  const getSpreadSheetId = async () => {
    try {
      setLoading(true)
      const { spreadSheetId } = await getSpreadSheetChart(subChart.id, subChart.name)
      setSpreadSheet(spreadSheetId)
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }

  const handleAction = (action) => {
    if (action === 'create') {
      setOpenCreateDialog(true)
    }

    if (action === 'edit') {
      setEditMode(true)
      getSpreadSheetId()
    }

    if (action === 'save') {
      setEditMode(false)
      updateSpreadSheet()
    }
  }


  const requestChart = async () => {
    try {
      if (!loading) {
        setLoading(true)
      }

      const { chartId } = params;
      const requestChart = await getChartById(chartId)

      setSubChartsSelect(requestChart.subCharts.map(el => ({
        value: el.id,
        label: el.name
      })))
      setChart(requestChart);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }

  const requestSubChart = async (chartId) => {
    try {
      if (!loading) {
        setLoading(true)
      }

      const requestChart = await getChartById(chartId)
      setSubChart(requestChart)
      console.log(requestChart);


    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }

  const handleCreate = async (name) => {
    try {
      const { chartId } = params;
      setOpenCreateDialog(false);
      setLoading(true)
      await createSubChart(chartId, { name })
      await requestChart()

    } catch (error) {
      console.error(error);
    } finally {
      if (loading) {
        setLoading(false)
      }
    }

  }

  const handleChangeSubchart = (chartId) => {
    setCurrentSubchart(chartId);
    requestSubChart(chartId)
    if (editMode) setEditMode(false)
  }

  useEffect(() => {
    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }
    const { hasBusinessMgmt } = getCurrentUser();

    if (!hasBusinessMgmt) {
      history.push("/dashboard/home")
    }

    setmockOptionsMenu([
      { label: "Crear cuadro", value: "create", disabled: editMode },
      { label: "Editar", value: "edit", disabled: editMode || !currentSubchart },
      { label: "Guardar", value: "save", disabled: !editMode },
    ])
  }, [editMode, currentSubchart]);

  useEffect(() => {
    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }
    const { hasBusinessMgmt, authorities } = getCurrentUser();

    if (
      !hasBusinessMgmt || (
        !authorities.includes("Crear Gobierno Corporativo") &&
        !authorities.includes("Consultar Gobierno Corporativo") &&
        !authorities.includes("Editar Gobierno Corporativo")
      )
    ) {
      return history.push("/dashboard/home")
    }

    setAllowEdit(authorities.includes("Editar Gobierno Corporativo") || authorities.includes("Crear Gobierno Corporativo"))

    requestChart()
  }, [])

  return (
    <>
      <Header
        titleLeft={chart ? chart.name : ''}
        titleRight={chart ? chart.businessId.name : ''}
        actions={[
          <IconButton variant='outlined'>
            <NotificationsIcon color='primary' />
          </IconButton>,
          <IconButton variant='outlined'>
            <EventIcon color='primary' />
          </IconButton>,
        ]}
      />

      <Grid container spacing={3}>
        {
          chart ? (
            <Grid container item>
              <Grid item xs>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                  <Link color='inherit' to={`/dashboard/business-management/${chart.businessId.id}`}>
                    {chart.businessId.name}
                  </Link>
                  <span>{chart.name}</span>
                </Breadcrumbs>
              </Grid>
            </Grid>
          ) : <></>
        }
        <Grid container item alignItems='center'>
          <Grid item xs>
            {
              subChartsSelect.length > 0 ? (
                <FormControl margin='dense'>
                  <Select
                    variant="outlined"
                    size='small'
                    displayEmpty
                    value={currentSubchart}
                    onChange={(e) => handleChangeSubchart(e.target.value)}
                  >
                    <MenuItem value='' disabled>Seleccionar Cuadro contable</MenuItem>
                    {
                      subChartsSelect.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              ) : <></>
            }

            {
              subChartsSelect.length === 0 && !loading ? (
                <span>No hay cuadros creados</span>
              ) : <></>
            }
          </Grid>

          {
            chart && allowEdit ? (
              <Grid item>
                <ButtonMenu
                  name={"+ Operaciones"}
                  buttonStyle={{ variant: "contained", color: "primary" }}
                  menuItems={mockOptionsMenu}
                  handleClick={handleAction}
                />
              </Grid>
            ) : <></>
          }
        </Grid>

        {
          loading && (
            <Grid item xs={12}>
              <span>Cargando datos...</span>
            </Grid>
          )
        }

        {
          (!editMode && !loading && subChart?.data) ? (
            <Grid item>
              <TableData
                values={subChart.data}
              />
            </Grid>
          ) : <></>
        }
        {
          (!editMode && !loading && !subChart?.data && subChart) ? (
            <Grid item xs={12}>
              <span>No hay datos guardados</span>
            </Grid>
          ) : <></>
        }
        {
          (editMode && !loading && spreadSheet) ? (
            <Grid item xs={12}>
              <iframe
                src={`https://docs.google.com/spreadsheets/d/${spreadSheet}/`}
                style={{ width: '100%', height: '100vh' }}
              >
              </iframe>
            </Grid>
          ) : <></>
        }
      </Grid>

      {
        openCreateDialog && (
          <CreateNewChartDialog
            openCreateDialog={openCreateDialog}
            setOpenCreateDialog={setOpenCreateDialog}
            handleCreate={handleCreate}
            currentSubCharts={subChartsSelect.map(el => el.label)}
          />
        )
      }
    </>
  )
}

export default Accounting