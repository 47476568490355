import React, { Component } from 'react';
import { CalendarHttp } from '../../../../core/http/calendar.http';
import { Grid } from '@material-ui/core';
import AlertInfo from '@material-ui/lab/Alert';
import { uuid } from 'axeleratum-sgc-frontend-library';
import { Activity } from './activity';
import { SelectTempo } from './select-tempo';

export class TabDiary extends Component {

  calendarHttp = new CalendarHttp();

  constructor(props) {
    super(props);

    this.state = {
      load: false,
      activities: [],
      month: props.yearMonth.split("-")[1],
      year: props.yearMonth.split("-")[0]
    }
  }

  render() {
    const { month, year, goTo } = this.state;
    const { activities, load, onEdit, seMore, onSelectMonth, onSelectYear, onDrop } = this.props;

    return (
      <React.Fragment>
        {
          load ?
            <Grid container style={{ justifyContent: 'center', width: '100%' }}>
              <AlertInfo
                className="load-alert"
                severity="info"
              >
                Cargando calendario...
              </AlertInfo>
            </Grid>
          :
            <div>
              <SelectTempo
                month={ month }
                year={ year }
                onSelectMonth={ (e) => {
                  this.setState({ month: e, goTo: null });
                  onSelectMonth(`${year}-${e}`);
                }}
                onSelectYear={ (e) => {
                  this.setState({ year: e, goTo: null });
                  onSelectYear(`${e}-${month}`);
                }}
              />

              {
                activities.map((item) => (
                  <Grid item
                    // id={ `${ item.uid }` }
                    // key={ uuid() }
                    // className={ `card-activity` }
                  >
                    <Activity
                      item={ item }
                      date={ item.date }
                      hour={ item.hour }
                      name={ item.name }
                      description={ item.description }
                      tags={ item.tags }
                      recordatory={ item.recordatory }
                      type={ item.type }
                      expand={ item.uid == goTo }
                      documents={ item.documents }
                      activityId={ item.id }
                      open={ false }
                      onSelect={ (item) => onEdit(item) }
                      seMore={ (item) => seMore(item) }
                      onDrop={ (id) => onDrop(id) }
                    />
                  </Grid>
                ))
              }

              {
                (!load && activities.length === 0) &&
                  <Grid container style={{ justifyContent: 'center', width: '100%' }}>
                    <AlertInfo
                      className="load-alert"
                      severity="info"
                    >
                      No hay Actividades para este mes y año
                    </AlertInfo>
                  </Grid>
              }
            </div>
        }
      </React.Fragment>
    );
  }

}
