import { CircularProgress, Dialog, DialogTitle, List, ListItem, ListItemText } from "@material-ui/core";
import BusinessIcon from '@material-ui/icons/Business';
import { useCompaniesUser } from "../../../hooks";
import AlertInfo from "@material-ui/lab/Alert";

export const SelectCompany = ({ open, onClose, selectedCompany }) => {

  const {
    companies,
    fetchingCompanies,
  } = useCompaniesUser();

  const handleClose = () => {
    onClose(selectedCompany);
  };

  const handleCompanyClick = (company) => {
    onClose(company);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle
        disableTypography
        style={{ background: '#1e88e5' }}
      >
        <span
          style={{
            color: '#fff',
            fontWeight: 500
          }}
        >
          Bóvedas activas
        </span>
      </DialogTitle>

      {
        fetchingCompanies ? <CircularProgress /> : (
          <List
            sx={{ pt: 0 }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '300px',
              overflow: "auto"
            }}
          >
            {
              companies?.length >= 1
                ? companies.map(company => (
                  <ListItem
                    button
                    key={company.id}
                    onClick={() => {
                      handleCompanyClick(company)
                    }}
                  >
                    <BusinessIcon />
                    <ListItemText primary={company.name} />
                  </ListItem>
                ))
                : <AlertInfo severity="info">Sin datos para mostrar</AlertInfo>
            }

          </List>
        )
      }

    </Dialog>
  )
}
