import "./popup-user.scss";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { CardUserFn } from "./CardUserFn";
import { UserHttp } from "../../../../core/http/user.http";
import Alert from "@material-ui/lab/Alert";
import { useEffect, useState } from "react";
import { DraggableList } from "../../../draggableList/DraggableList";
import { useDispatch, useSelector } from "react-redux";
import { activityActions } from "../../../../core/actions/activity.actions";
import { tokenExpired } from "../../../../core/utils";
import { authActions } from "../../../../core/actions";
import { AddEndorser } from "./AddEndorser";
import { AddEndorsee } from "./AddEndorsee";
import { endorseActions } from "../../../../core/actions/endorse.actions";
import { getCurrentUser } from "../../../../core/helpers";

export const PopUpEndorse = ({ open, onCancel, onSubmit, labelType = "firm", checkedOrder, documentId }) => {

  const dispatch = useDispatch();
  const showOrder = true;
  const endorserSelected = useSelector(state => state.activityReducer.endorser);
  const usersSelected = useSelector(state => state.activityReducer.signers);

  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);

  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [order, setOrder] = useState(checkedOrder);

  const onChangeUser = (user) => {
    dispatch(endorseActions.setEndorseType({
      userId: user.userId,
      type: user.type,
      stroke: user.stroke
    }))
  }

  const handleDragg = (newList) => {
    dispatch(activityActions.saveSigners(newList))
  }

  const handleSubmit = () => {
    onSubmit && onSubmit(usersSelected, order);
  }

  const handleCancel = () => {
    onCancel();
  }

  useEffect(() => {
    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }

    const userHttp = new UserHttp();

    setLoad(true);
    userHttp.findAllUsersByDocumentId(
      documentId,
      "firm",
      (resp) => {
        const sortUsersName = (x, y) => {
          return x.name.localeCompare(y.name);
        };
        let s = resp.sort(sortUsersName);
        dispatch(activityActions.saveInternalUsersToSign(s));
        setLoad(false);
      },
      (error) => {
        setLoad(false);
        setError(true);
      }
    );
    // eslint-disable-next-line 
  }, []);


  return (
    <Dialog className="popup-user" open={open}>
      <div
        style={{
          display: 'flex',
          flexDirection: "column"
        }}
      >

        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <DialogTitle>Firmante del endoso</DialogTitle>
          <AddEndorser />
        </div>

        {
          //! Se deshabilita firmante adicional por tema de cancelacion en flujos de endoso
          // <div
          //   style={{
          //     display: "flex",
          //     width: "100%",
          //     alignItems: "center",
          //     justifyContent: "flex-end"
          //   }}
          // >
          //   <AddEndorsee />
          // </div>
        }
      </div>

      {error && (
        <DialogContent>
          <Alert severity="error">
            Hubo un problema al cargar lo usuarios inténtalo mas tarde
          </Alert>
        </DialogContent>
      )}

      {load ? (
        <DialogContent className="text-center">
          <CircularProgress />
        </DialogContent>
      ) : (
        <>
          {!error && (
            <>
              <DialogContent className="card-zone">
                <Grid
                  container
                  spacing={1}
                  direction="column"
                >
                  <DraggableList
                    items={usersSelected}
                    setItems={handleDragg}
                  >

                    {
                      usersSelected.map((item, index) => (
                        <CardUserFn
                          order={order}
                          key={item.userId}
                          labelType={labelType}
                          onlyShow={false}
                          id={item.userId}
                          name={item.name}
                          index={index}
                          email={item.email}
                          color={item.color}
                          type={item.type}
                          rfc={item.rfc}
                          useRFC={item.temporal ? !!item.rfc : item.hasRFC}
                          stroke={item.stroke ?? "stroke"}
                          onChange={el => onChangeUser(el)}
                          role={item.endorser ? 'Endosante' : 'Endosatario'}
                          disableStroke={item.endorser}
                        />

                      )
                      )
                    }

                  </DraggableList>
                </Grid>

                {endorserSelected.length === 0 && (
                  <div className="text-center mt-2">
                    No hay un endosante seleccionado
                  </div>
                )}
              </DialogContent>
            </>
          )}
        </>
      )}
      <div className={`bottom-options${usersSelected.length > 1 ? ' bottom-options-between' : ''}`}>
        {(usersSelected.length < 2) ? null : (
          <div>
            <FormControlLabel
              className="ml-3 mr-3 mb-1"
              control={
                <Checkbox
                  checked={order}
                  onChange={(evt) => setOrder(evt.target.checked)}
                  disabled={!showOrder}
                />
              }
              label={`Notificar en orden a los firmantes`}
            />
            <p className={`info-text ${!showOrder && `disabled`}`}>como se muestra en la lista</p>
          </div>
        )}


        <DialogActions style={{ padding: "15px" }}>
          <Button
            className="primary-text"
            color="primary"
            variant="outlined"
            onClick={() => handleCancel()}
          >
            Cancelar
          </Button>

          <Button
            color="primary"
            variant="contained"
            disabled={endorserSelected.length === 0}
            onClick={() => handleSubmit()}
          >
            Aceptar
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
