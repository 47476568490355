import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Creatable from 'react-select/creatable';
import PropTypes from 'prop-types';

export const MultiSelect = ({
  className,
  classNamePrefix,
  options,
  onChange,
  placeholder,
  noOptionsMessage,
  defaultValue,
  notMulti,
  isSearchable,
  value,
  isDisabled,
  isClearable,
  onBlur,
  findByLabel,
  closeMenu
}) => {  
  return (
    <React.Fragment>
      {isClearable ? (
        <Creatable
          classNamePrefix={classNamePrefix}
          className={className}
          components={makeAnimated()}
          defaultValue={defaultValue}
          options={options}
          onChange={(selectedData) => onChange(selectedData)}
          noOptionsMessage={() =>
            noOptionsMessage ? noOptionsMessage : 'No hay más opciones'
          }
          placeholder={placeholder}
          isSearchable={isSearchable}
          value={value}
          getOptionLabel={(option) => option.label}
          formatCreateLabel={(inputValue) => `Crear: ${inputValue}`}
          filterOption={({ data }, searchText) => {
            if (data.search) {
              return data.search
                .toLowerCase()
                .includes(searchText.toLowerCase());
            }

            if (typeof data.label === 'string') {
              return data.label
                .toLowerCase()
                .includes(searchText.toLowerCase());
            }

            return true;
          }}
          isDisabled={isDisabled}
          isOptionSelected={
            findByLabel
              ? (option, options) => {
                  const isInArray = !!options.find(
                    ({ label }) => label == option.label
                  );
                  if (isInArray) {
                    return true;
                  }
                  return false;
                }
              : undefined
          }
          isClearable={isClearable}
          isMulti={!notMulti}
          onBlur={(evt) => onBlur && onBlur(evt)}
        />
      ) : (
        <Select
          onMenuClose={closeMenu}
          styles={{
            valueContainer:(provided) => ({
              ...provided,
              display:'flex !important'
            })
          }}
          classNamePrefix={classNamePrefix}
          className={className}
          components={makeAnimated()}
          defaultValue={defaultValue}
          options={options}
          onChange={(selectedData) => onChange(selectedData)}
          noOptionsMessage={() =>
            noOptionsMessage ? noOptionsMessage : 'No hay más opciones'
          }
          placeholder={placeholder}
          isSearchable={isSearchable}
          value={value}
          getOptionLabel={(option) => (
            <span
              style={{
                padding: '5px',
                borderRadius: '10px',
                backgroundColor: `${option.color ? option.color : ''}`,
                color: `${option.color ? 'white' : 'black'}`,
              }}
            >
              {option.label}
            </span>
          )}
          filterOption={({ data }, searchText) => {
            if (data.search) {
              return data.search
                .toLowerCase()
                .includes(searchText.toLowerCase());
            }

            if (typeof data.label === 'string') {
              return data.label
                .toLowerCase()
                .includes(searchText.toLowerCase());
            }

            return true;
          }}
          isDisabled={isDisabled}
          isOptionSelected={
            findByLabel
              ? (option, options) => {
                  const isInArray = !!options.find(
                    ({ label }) => label == option.label
                  );
                  if (isInArray) {
                    return true;
                  }
                  return false;
                }
              : undefined
          }
          isClearable={isClearable}
          isMulti={!notMulti}
          onBlur={(evt) => onBlur && onBlur(evt)}
        />
      )}
    </React.Fragment>
  );
};

MultiSelect.propTypes = {
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  options: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  defaultValue: PropTypes.any,
  isSearchable: PropTypes.bool,
  value: PropTypes.any,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  notMulti: PropTypes.bool,
  onBlur: PropTypes.func,
};
