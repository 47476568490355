import React, { useEffect, useState } from 'react';
import { Avatar, Button, Card, CardActions, CardHeader, makeStyles, Typography, useTheme } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '25px',
    border: `1px solid ${theme.palette.primary.main} `,
    padding: 8,
    marginTop: 8,
    marginBottom: 8,
    overflow: 'initial'
  },
  cardHeader: {
    padding: 0,
    width: '100%'
  },
  cardAvatar: {
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main)
  },
  action: {
    display: 'inline-block',
    width: '80%',
    padding: 4
  }
}));


const SignerCardEndorsement = ({
  name,
  userId,
  userCertificate,
  onGenerateTag,
  check,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const onClick = () => {
    onGenerateTag(name, userId, userCertificate)
  }

  return (
    <Card className={classes.cardRoot}>
      <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", width: "100%" }}>
        <CardHeader
          className={classes.cardHeader}
          disableTypography
          avatar={<Avatar className={classes.cardAvatar}>{name.charAt(0)}</Avatar>}
          title={
            <Typography variant="subtitle1" color={theme.palette.type === 'dark' ? 'initial' : 'primary'}>
              {name.length > 18 ? name.split('').slice(0, 15).join('') + '...' : name}
            </Typography>
          }
        />
        {check && <CheckCircleOutlineIcon fontSize='large' style={{ color: "#25e125" }} />}
      </div>
      <CardActions className={classes.action}>

        <Button
          fullWidth
          variant="contained"
          onClick={onClick}
          color="primary"
          disabled={check}
        >
          Generar Etiqueta
        </Button>

      </CardActions>
    </Card>

  );
};




export default SignerCardEndorsement;
