import React, { Component } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { uuid } from 'axeleratum-sgc-frontend-library';

export class SelectNative extends Component {

  render() {
    const { label, value, items, message, onChange, disabled } = this.props;

    return (
      <FormControl
        disabled={disabled}
        fullWidth={ true }
        variant="outlined"
        margin="dense"
        error={ message }
      >
        <InputLabel
          style={{ background: 'white' }}
        >
          { label }
        </InputLabel>
        
        <Select
          value={ value }
          onChange={ (e) => onChange(e.target.value) }
        >
          {  
            items && items.map((item) => (
              <MenuItem key={ uuid() } value={ item.value }>{ item.label }</MenuItem>
            ))
          }
        </Select>

        <FormHelperText>{ message }</FormHelperText>
      </FormControl>
    );
  }

}