import React, { Component } from 'react';
import moment from 'moment';
import { Button } from '@material-ui/core';
import { NotificationHttp } from '../../../core/http/notification.http';
import 'moment/locale/es';
import { tokenExpired } from '../../../core/utils';
import { authActions } from '../../../core/actions';
import { connect } from 'react-redux';
moment.locale('es');

export class NotificationItem extends Component {
  
  norificationHttp = new NotificationHttp();

  invokeActionType() {
    const { onOpenFile, onOpenActivity, item } = this.props;

    if (item.documentId && !item.activityId) {
      onOpenFile(item.id, item.documentId);
    } else {
      onOpenActivity(item.id, item.activityId);
    }

    this.viewNotification();
  }

  viewNotification() {
    const { onView, item } = this.props;
    const {isLoggedIn, userLoggedOut} = this.props;  

    if(isLoggedIn && tokenExpired()){
         userLoggedOut()
         return
       }  
   
    this.norificationHttp.viewNotification(item.id)
      .then(() => {
        onView();
      })
      .catch(() => {});
  }

  render() {
    const { item, load } = this.props;

    return (
      <div className="row">
        <div className="col-md-12 notification-card">
          <h4>{ moment(item.notificationDate).format('MMMM DD') }</h4>
        </div>
        
        <div className="col-md-2">
          <div 
            className="user-icon" 
            style={{ background: item.sender ? item.sender.color : 'gray' }}
            title={ item.sender ? item.sender.completeName : 'Notificacion del Sistema' }
          >
            { item.sender ? item.sender.completeName.substring(0, 1) : 'N' }
          </div>
        </div>

        <div className="col-md-10">
          { item.description }
        </div>
        
        {
          !item.documentId && item.activityId &&
            <React.Fragment>
              <div className="col-md-2"></div>
              <div className="col-md-10 document-text">
                <em className="fas fa-calendar-day fa-lg mr-2 mt-3 mb-3"></em>
                <b>{ item.activityName }</b>
              </div>
            </React.Fragment>
        }

        {
          item.documentId && !item.activityId &&
            <React.Fragment>
              <div className="col-md-2"></div>
              <div className="col-md-10 document-text">
                <em className="fas fa-file-alt fa-lg mr-2 mt-3 mb-3"></em>
                <b>{ item.documentName }</b>
              </div>
            </React.Fragment>
        }

        {
          item.documentId && item.activityId &&
            <React.Fragment>
              <div className="col-md-2"></div>
              <div className="col-md-10 document-text">
                <em className="fas fa-file-alt fa-lg mr-2 mt-3 mb-3"></em>
                <b>{ item.documentName }</b>
              </div>
            </React.Fragment>
        }

        <div className="col-md-2"></div>
        <div className="col-md-10 mb-3 text-right">
          <Button
            className="primary-text"
            variant="outlined"
            onClick={ () => this.invokeActionType() }
            disabled={ load }
          >
            { load && <em className="fa fa-spinner fa-spin mr-1"></em> }
            Ver
          </Button>
        </div>

        <div className="col-md-12">
          <hr />
        </div>
      </div> 
    )    
  }    

}


const mapStateToProps = state => {
  return {
    isLoggedIn: state.authReducer.loggedIn,
  }
}

const mapDispatchToProps = {
  userLoggedOut: authActions.userLoggedOut,
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationItem);
