import jwt from 'jwt-decode'


export function decodeToken(token) {
  const tokenDecoded = jwt(token)
  return tokenDecoded
}

export const isExpired = (token) => {
	// if (!!token) {
	// 	const exp = decodeToken(token).exp;
	// 	let date_r = new Date(exp * 1000);

	// 	if (Date.now() > date_r) {
	// 		return true;
	// 	}
	// 	return false;
	// }
  return false;
};


export const tokenExpired = () => {
		const exp = decodeToken(sessionStorage.getItem("access_token")).exp;
		let date_r = new Date(exp * 1000);

		if (Date.now() > date_r) {
			return true;
		}
		return false;
	

}