import { authHeader, vaultInstance } from "../../../configs/axios.config"



export const getVaultsUser = async () => {
  const { data } = await vaultInstance({
    headers: authHeader()
  })
  return data
}

export const getVaultsUserDashboard = async () => {
  const { data } = await vaultInstance({
    url: '/dashboard',
    headers: authHeader()
  })
  return data
}

export const getFileSystem = async (vaultId, path) => {
  const { data } = await vaultInstance({
    url: `/${vaultId}`,
    headers: authHeader(),
    params: {
      p: path
    }
  })
  return data
}

export const getVaultById = async (vaultId) => {
  const { data } = await vaultInstance({
    url: `/${vaultId}/details`,
    headers: authHeader(),
  })
  return data
}

export const getVaultUsers = async (vaultId) => {
  const { data } = await vaultInstance({
    url: `/${vaultId}/users`,
    headers: authHeader(),
  })
  return data
}

export const getVaultsByUser = async () => {
  const { data } = await vaultInstance({
    url: `/byUser`,
    headers: authHeader(),
  })
  return data
}

export const allowStoreVaults = async () => {
  const { data } = await vaultInstance({
    url: `/allowStoreVaults`,
    headers: authHeader(),
  })
  return data
}

export const editVault = async (vaultId, body) => {
  const { data } = await vaultInstance({
    method: "PUT",
    url: `/${vaultId}/edit`,
    headers: authHeader(),
    data: body
  })
  return data
}

export const assignUsersVault = async (vaultId, body) => {
  const { data } = await vaultInstance({
    method: "PUT",
    url: `/${vaultId}/assignUsers`,
    headers: authHeader(),
    data: body
  })
  return data
}

export const enabledVault = async (vaultId, body) => {
  const { data } = await vaultInstance({
    method: "PUT",
    url: `/${vaultId}/enabled`,
    headers: authHeader(),
    data: body
  })
  return data
}

export const createVault = async (body) => {
  const { data } = await vaultInstance({
    method: "POST",
    url: `/create`,
    headers: authHeader(),
    data: body
  })
  return data
}

export const getElementsSearchedBar = async (query) => {
  const { data } = await vaultInstance({
    url: '/search',
    headers: authHeader(),
    params: query
  })
  return data
}


