import React, { useEffect, useState } from 'react';
import { MultiSelect } from '../multi-select/MultiSelect';
import PropTypes from 'prop-types';

export const RenderMultiSelect = (props) => {

  const {
    input,
    label,
    className,
    onKeyUp,
    noOptionsMessage,
    defaultValue,
    controlledValue,
    controlled,
    options,
    isSearchable,
    notMulti,
    isClearable,
    required,
    disabled,
    findByLabel,
    meta,
    onCloseSelect,
  } = props;
  const { error, warning, touched, initial } = meta;
  const errorClass = error && touched ? 'select-error' : '';
  
  const [fieldValues, setFieldValues] = useState(initial);

  useEffect(() => {
    if(initial){
      setFieldValues(initial)
    }
  }, [initial])
  
  useEffect(() => {
    if(disabled){
      setFieldValues([]);
    }
  }, [disabled])
  

  return (
    <div className="MuiFormControl-root MuiFormControl-marginDense MuiFormControl-fullWidth">
      {(input.value || controlledValue) && (
        <label
          className={`
              MuiFormLabel-root 
              MuiInputLabel-root 
              MuiInputLabel-formControl 
              MuiInputLabel-animated 
              MuiInputLabel-shrink 
              MuiInputLabel-marginDense 
              MuiInputLabel-outlined 
              MuiFormLabel-filled
              select-material-label
              select-material-label-no-show
            `}
        >
          {required ? `${label}*` : label}
        </label>
      )}

      <MultiSelect
        closeMenu={onCloseSelect}
        className={`${className} ${errorClass}`}
        classNamePrefix={`${className} ${errorClass} select`}
        {...input}
        notMulti={notMulti}
        options={options}
        onChange={(value) => {
          input.onChange(value);
          setFieldValues(value);
        }}
        onKeyUp={onKeyUp}
        isDisabled={disabled}
        onBlur={(event) => event.preventDefault()}
        placeholder={label}
        findByLabel={findByLabel}
        noOptionsMessage={noOptionsMessage}
        defaultValue={controlled ? undefined : input.value || fieldValues}
        value={controlled ? controlledValue : fieldValues}
        isSearchable={isSearchable}
        isClearable={isClearable}
      />
      {
        (((error && touched) && (
          <p
            className="
                  MuiFormHelperText-root 
                  MuiFormHelperText-contained 
                  Mui-error 
                  MuiFormHelperText-marginDense
                "
          >
            {error}
          </p>
        )) ||
          (warning && <div>{warning}</div>))}
    </div>
  );
}


RenderMultiSelect.propTypes = {
  input: PropTypes.any,
  label: PropTypes.string,
  className: PropTypes.string,
  onKeyUp: PropTypes.func,
  noOptionsMessage: PropTypes.string,
  defaultValue: PropTypes.any,
  options: PropTypes.any,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  required: PropTypes.bool,
  meta: PropTypes.shape({
    error: PropTypes.string,
    warning: PropTypes.string,
    touched: PropTypes.bool,
  }),
  controlled: PropTypes.bool,
  controlledValue: PropTypes.any,
};
