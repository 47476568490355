import { Button, Dialog, Grid, Typography } from '@material-ui/core';
import { isMobile, RenderDateField, RenderMultiSelect, RenderTextField, uuid } from 'axeleratum-sgc-frontend-library';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { CollaboratorsHttp } from '../../core/http/collaborators.http';
import { FoldersHttp } from '../../core/http/folders.http';
import { UserHttp } from '../../core/http/user.http';
import { sortData, tokenExpired } from '../../core/utils';
import FolderSelector from './folder-selector';
import { connect } from 'react-redux';
import { authActions } from '../../core/actions';
import { compose } from 'redux';

class FormSaveContractPreview extends Component {

  foldersHttp = new FoldersHttp();
  collaborators = new CollaboratorsHttp();
  userHttp = new UserHttp();

  constructor(props) {
    super(props);
    this.state = {
      usersSelect: [],
      folders: [],
      openFolderSelector: false,
      folderSelected: null,
    };
  }

  componentDidMount() {
    this.getContractFolder();
    this.getUsersSelect();

    this.props.initialize({
      endDate: new Date().toISOString().substring(0, 10),
    });
  }

  getUsersSelect = () => {
    /* this.collaborators.getUsersCollaboratorsByCompany(this.props.company.id).then(({ data }) => {
      const users = [];
      data.forEach(user => {
        users.push({label: user.completeName, value: user.id, email: user.email});
        });
        
        this.setState({usersSelect: users});
        }).catch(error => {}); */
        
        const {isLoggedIn, userLoggedOut} = this.props;  

 if(isLoggedIn && tokenExpired()){
      userLoggedOut()
      return
    } 
    this.userHttp.findAll((resp) => {
      const users = [];
      resp.forEach(user => {
        if (!user.deleted && user.enabled && user && (!user.externalProvider)) {
          const isMember = user.companies.some(item => item.id === this.props.company.id) || user.roles.some(rol => rol.allCompanies);

          if (!isMember) {
            users.push({
              label: user.completeName,
              value: user.id,
              email: user.email,
            });
          }
        }
      });

      this.setState({ usersSelect: users });
    }, (error) => { }
    )
  }

  getContractFolder() {
    const {isLoggedIn, userLoggedOut} = this.props;  

 if(isLoggedIn && tokenExpired()){
      userLoggedOut()
      return
    } 
    this.foldersHttp.getContractFolderByCompanyId(this.props.company.id, 'Contratos', (resp) => {
      const contractsFolder = [{
        id: resp.id,
        name: resp.name,
        type: 'folder',
        isFolder: true,
        path: resp.name,
        pathLabel: `${this.props.company.name}/${resp.name}`,
        children: [],
        childrenLoaded: false,
        loading: true,
      }];

      const companyFolders = [{
        id: this.props.company.id,
        name: this.props.company.name,
        path: '',
        pathLabel: this.props.company.name,
        children: [...contractsFolder],
        childrenLoaded: true,
        notSelectable: true,
      }];

      this.setState({
        folders: companyFolders,
      });
    }, () => {
    });
  };

  loadSubfolders(folder) {
    const { folders } = this.state;
    const {isLoggedIn, userLoggedOut} = this.props;  

 if(isLoggedIn && tokenExpired()){
      userLoggedOut()
      return
    } 

    this.foldersHttp.getFolderInfo(folder.id, this.props.company.id, (resp) => {
      const subfolders = sortData(
        resp.folders.map((item) => {
          return {
            id: item.folderId ? item.folderId : uuid(),
            name: item.name,
            type: 'folder',
            isFolder: true,
            onlyExistInMongo: item.onlyExistInMongo,
            onlyExistInKaleido: item.onlyExistInKaleido,
            path: `${item.path}/${item.name}`,
            pathLabel: `${this.props.company.name}/${item.path}/${item.name}`,
            children: [],
            childrenLoaded: false,
            loading: false,
          };
        })
      );

      folder.children = [...subfolders];
      folder.childrenLoaded = true;

      this.setState({ folders });
    }, () => { });
  }

  render() {
    const { openDialog, submitActions, onCancel, tagsSelect, handleSubmit, } = this.props;
    const { usersSelect, openFolderSelector, folderSelected } = this.state;

    return (
      <React.Fragment>
        <Dialog className={isMobile ? '' : 'w-50-dialog'} open={openDialog}>
          <form className="p-5" onSubmit={handleSubmit((values) => { submitActions(values, folderSelected) })}>
            <Grid container>
              <Grid item xs={12}>
                <h3>Subir Documento</h3>
                <Field
                  label="Nombre del documento"
                  name="name"
                  component={RenderTextField}
                />

                <Field
                  label="Descripción"
                  name="description"
                  component={RenderTextField}
                />

                <Field
                  label='Fecha de vencimiento'
                  name='endDate'
                  component={RenderDateField}
                />

                <Field
                  label="Seleccionar etiquetas"
                  name="tags"
                  options={tagsSelect}
                  component={RenderMultiSelect}
                />
              </Grid>

              <Grid item xs={12}>
                <h3>Invitar Usuarios</h3>

                <Field
                  label="Seleccionar usuarios"
                  name="users"
                  options={usersSelect}
                  component={RenderMultiSelect}
                />
              </Grid>

              <Grid item xs={12}>
                <p>Ruta del documento  </p>
                <Grid container item direction="row" alignItems="center" xs={12}>
                  <Button
                    className="mr-3"
                    variant="contained"
                    color="primary"
                    onClick={() => { this.setState({ openFolderSelector: true }) }}
                  >
                    Seleccionar...
                  </Button>
                  {folderSelected &&
                    <Typography>{folderSelected.pathLabel}</Typography>
                  }
                </Grid>
              </Grid>

              <Grid className="mt-3" container justifyContent="flex-end">
                {
                  isMobile ?
                    <React.Fragment>
                      <Grid item >
                        <Button
                          type="button"
                          variant="outlined"
                          color="secondary"
                          onClick={() => onCancel()}
                        >
                          Cancelar
                        </Button>
                      </Grid>

                      <Grid item >
                        <Button
                          className="ml-2"
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Crear
                        </Button>
                      </Grid>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <Button
                        type="button"
                        variant="outlined"
                        color="secondary"
                        onClick={() => onCancel()}
                      >
                        Cancelar
                      </Button>

                      <Button
                        className="ml-2"
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Guardar
                      </Button>
                    </React.Fragment>
                }
              </Grid>
            </Grid>
          </form>
        </Dialog>

        {openFolderSelector &&
          <FolderSelector
            open={openFolderSelector}
            folders={this.state.folders}
            onClose={() => { this.setState({ openFolderSelector: false }) }}
            onSelected={(value) => {
              this.setState({
                folderSelected: {
                  id: value.id,
                  name: value.name,
                  path: value.path,
                  pathLabel: value.pathLabel,
                  onlyExistInKaleido: value.onlyExistInKaleido,
                  onlyExistInMongo: value.onlyExistInMongo
                },
                openFolderSelector: false
              });
            }}
            onClick={(value) => this.loadSubfolders(value)}
          />
        }
      </React.Fragment>
    );
  }
}

const validate = (values) => {
  const errors = {
    name: ''
  }

  if (!values.name) {
    errors.name = 'El nombre del documento es requerido';
  }

  if (values.name) {
    if (values.name > 100) {
      errors.name = 'El nombre del documento no puede ser mayor a 100 caracteres';
    }
  }

  if (!values.endDate) {
    errors.endDate = 'La fecha de vencimiento es requerida';
  }

  return errors;
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.authReducer.loggedIn,
  }
}

const mapDispatchToProps = {
  userLoggedOut: authActions.userLoggedOut,
}


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
reduxForm({
  form: 'DialogFormContract',
  validate
})
)(FormSaveContractPreview);





