import React, { useState } from 'react';
import { Dialog, Grid, Button } from '@material-ui/core';
import { isMobile, isMobileOnly } from 'axeleratum-sgc-frontend-library';
import { reduxForm } from 'redux-form';
import { TextFieldNative } from '../control/calendar/forms/fields/text-field-native';
import { MultiSelectNative } from '../control/calendar/forms/fields/multi-select-native';
import { SelectNative } from '../control/calendar/forms/fields/select-native';
import Alert from '@material-ui/lab/Alert';

const DialogFormFilter = (props) => {
  const {
    openDialog,
    submitActions,
    onCancel,
    handleSubmit,
    authors = [],
    notTags,
    tags
  } = props;

  const [name, setName] = useState('');
  const [author, setAuthor] = useState('');
  const [date, setDate] = useState(null);
  const [tagList, setTags] = useState([]);
  const [alert, setAlert] = useState(false);

  const handleCancel = () => {
    setName('');
    setAuthor('');
    setDate(null);
    setTags([]);
    setAlert(false);
    onCancel();
  };

  const submit = () => {
    if (name !== '' || author !== '' || date !== null || tagList.length > 0) {
      setAlert(false);
      submitActions({ name, author, date, tagList });
    } else {
      setAlert(true);
    }
  };

  return (
    <Dialog className={isMobile ? '' : 'w-50-dialog'} open={openDialog}>
      <form className={isMobileOnly ? 'p-3' : 'p-5'} onSubmit={handleSubmit(submit)}>
        <Grid container>
          <Grid item md={12}>
            <h3>Filtros</h3>
            {alert &&
              <Alert severity="warning">No has seleccionado ningún filtro.</Alert>
            }
            <TextFieldNative
              label='Nombre'
              name='name'
              value={name}
              onChange={(value) => { setName(value); setAlert(false); }}
            />
            <SelectNative
              label='Autor'
              name='author'
              items={authors}
              value={author}
              onChange={(value) => { setAuthor(value); setAlert(false); }}
            />
            <TextFieldNative
              label='Fecha última modificación'
              name='date'
              type='date'
              value={date}
              onChange={(value) => { setDate(value); setAlert(false); }}
            />
            {!notTags && <MultiSelectNative
              label='Etiquetas'
              name='tags'
              options={tags}
              value={tagList}
              onChange={(value) => { setTags(value); setAlert(false); }}
            />}
          </Grid>

          <Grid className='mt-3' container justifyContent='flex-end'>
            {isMobile ? (
              <React.Fragment>
                <Grid item>
                  <Button
                    type='button'
                    variant='outlined'
                    color='secondary'
                    onClick={handleCancel}
                  >
                    Reestablecer
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    className='ml-2'
                    type='submit'
                    variant='contained'
                    color='primary'
                  >
                    Filtrar
                  </Button>
                </Grid>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button
                  type='button'
                  variant='outlined'
                  color='secondary'
                  onClick={handleCancel}
                >
                  Reestablecer
                </Button>

                <Button
                  className='ml-2'
                  type='submit'
                  variant='contained'
                  color='primary'
                >
                  Filtrar
                </Button>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default reduxForm({
  form: 'DialogFormFilter',
})(DialogFormFilter);
