import { permissionsConstants } from "../constants/permissions.constants";
import initialState from '../../configs/intialState.config';

export const permissionsReducer = (state=initialState.permissions, action) => {
    switch (action.type) {
        case permissionsConstants.ADD_PERMISSION:
            return {permission: action.payload};
            break;
        case permissionsConstants.DELETE_PERMISSION:
            return {permission: action.payload};
            break;
        case permissionsConstants.FETCH_PERMISSIONS:
            return {permission: action.payload};
            break;
        case permissionsConstants.RESET_PERMISSIONS:
            return {permission: action.payload};
            break;    
        default:
            return state;
            break;
    }
}