import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AlertConfirm } from '../../from-library/alerts/AlertConfirm';
import cancelationActions from '../../../core/actions/cancelation.actions';
import { TemporalUsersToCancel } from './TemporalUsersToCancel';


export const AddMain = () => {
	const dispatch = useDispatch();

	const usersToSign = useSelector(state => state.cancelationReducer.usersToSign);
	const availableUsers = useSelector(state => state.cancelationReducer.allUsers);

	const [checked, setChecked] = useState(usersToSign.filter(el => el.endorser));
	const [openExternalForm, setOpenExternalForm] = useState(false);
	const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
	const [temporalSelected, setTemporalSelected] = useState(null);
	const [userReference, setUserReference] = useState(null);
	const [openList, setOpenList] = useState(false);


	const handleChange = (user) => {
		const currentIndex = checked.map(e => e.userId).indexOf(user.userId);
		const newChecked = [];
		const endorser = { ...user, endorser: true }

		if (!!user?.temporal) {
			setTemporalSelected(user.userId);
			setOpenConfirmAlert(true);
			return
		}

		if (!!checked[0]?.temporal) {
			setTemporalSelected(checked[0].userId);
			setOpenConfirmAlert(true);
			setUserReference(user)
			return
		}

		if (currentIndex === -1) {
			newChecked.push(endorser);
		}
		setChecked(newChecked);
	}

	const handleAddEndorser = () => {
		const endorser = { ...checked[0], endorser: true }
		dispatch(cancelationActions.saveEndorser(endorser));
		setOpenList(false);
	};

	const handleCancel = () => {
		setOpenList(false);
		setChecked(usersToSign.filter(el => el.endorser));
	}

	const handleSubmitExternalUser = (user) => {
		const endorser = { ...user, endorser: true }
		setChecked([endorser]);
		setOpenExternalForm(false);
	}

	return (
		<>
			<div style={{ padding: "15px" }}>
				<Button
					color="primary"
					variant="contained"
					onClick={() => setOpenList(true)}>
					Persona que Cancela
				</Button>
			</div>

			{openList && (
				<Dialog open={openList}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-around",
						}}>
						<DialogTitle>Seleccionar Persona que Cancela</DialogTitle>

						<div style={{ padding: "20px" }}>
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									setOpenExternalForm(true);
								}}>
								Añadir Externo
							</Button>
						</div>
					</div>
					<DialogContent style={{ paddingTop: "0px", marginTop: "-16px" }}>
						<List
							style={{ paddingTop: "0px" }}
						>
							{
								[
									...availableUsers.filter(av => av.id !== (usersToSign.find(el => !el.endorser))?.id),
									...(checked.filter(el => el.temporal))
								].map((item, index) => (
									<ListItem
										key={`${item.userId}-${index}`}
										onClick={() => handleChange(item)}
										style={{ paddingTop: "0px", paddingBottom: "0px" }}
									>
										<Checkbox
											id={`check${item.userId}`}
											checked={checked.some(
												(some) => some.userId === item.userId
											)}
										/>
										<ListItemText

											primary={
												!!item.temporal
													? `${item?.name} (externo 1 vez)`
													: item?.name
											}
										/>
									</ListItem>
								))}
						</List>

					</DialogContent>
					<DialogActions

					>
						<Button
							className="primary-text"
							color="primary"
							variant="outlined"
							onClick={handleCancel}>
							Cancelar
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={handleAddEndorser}
							disabled={checked.length === 0}
						>
							Aceptar
						</Button>
					</DialogActions>
				</Dialog>
			)}

			{openExternalForm && (
				<TemporalUsersToCancel
					open={openExternalForm}
					onCancel={() => setOpenExternalForm(false)}
					submitActions={handleSubmitExternalUser}
				/>
			)}

			{openConfirmAlert && (
				<AlertConfirm
					open={openConfirmAlert}
					onCancel={() => setOpenConfirmAlert(false)}
					onConfirm={() => {
						if (!!userReference) {
							setChecked([userReference])
						} else {
							setChecked(curr => curr.filter(el => el.userId !== temporalSelected))
						}
						setUserReference(null);
						setTemporalSelected(null);
						setOpenConfirmAlert(false);
					}}
					textContent="El usuario externo se eliminará de la lista. ¿Deseas continuar?"
				/>
			)}
		</>
	);

}
