import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { Component } from "react";

export class SelectTempo extends Component {
  render() {
    const { month, year, onSelectMonth, onSelectYear } = this.props;

    return (
      <Grid
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <FormControl fullWidth={true}>
            <InputLabel id="demo-simple-select-label">Mes</InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={month}
              onChange={(e, value) => {
                this.setState({ initialMonth: value.props.value });
                onSelectMonth(value.props.value);
              }}
            >
              <MenuItem value={"01"}>Enero</MenuItem>
              <MenuItem value={"02"}>Febrero</MenuItem>
              <MenuItem value={"03"}>Marzo</MenuItem>
              <MenuItem value={"04"}>Abril</MenuItem>
              <MenuItem value={"05"}>Mayo</MenuItem>
              <MenuItem value={"06"}>Junio</MenuItem>
              <MenuItem value={"07"}>Julio</MenuItem>
              <MenuItem value={"08"}>Agosto</MenuItem>
              <MenuItem value={"09"}>Septiembre</MenuItem>
              <MenuItem value={10}>Octubre</MenuItem>
              <MenuItem value={11}>Noviembre</MenuItem>
              <MenuItem value={12}>Diciembre</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth={true}>
            <InputLabel id="demo-simple-select-label">Año</InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={year}
              onChange={(e, value) => {
                this.setState({ initialYear: value.props.value });
                onSelectYear(value.props.value);
              }}
            >
              <MenuItem value={2020}>2020</MenuItem>
              <MenuItem value={2021}>2021</MenuItem>
              <MenuItem value={2022}>2022</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}
