import { axiosBusinessRequest, axiosRequestBlockChain, authHeader } from "../../configs/axios.config"

export class FoldersHttp {
  createFolder(id_company, body, success, error) {
    axiosRequestBlockChain.post(`/folder/${id_company}`, body, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp))
  }

  // createSubFolder(companyId, path, name, success, error) {
  //   axiosBusinessRequest.post(`/folders/create-subfolder?companyId=${companyId}`, {path: path, name: name}, {headers: authHeader()})
  //   .then(({ data }) => success(data))
  //   .catch(errorResp => error(errorResp))
  // }

  updateFolder(body, success, error) {
    axiosRequestBlockChain.put(`/folder`, {...body}, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp))
  }

  deleteFolder(folderId, companyId, success, error) {
    axiosRequestBlockChain.delete(`/folder/${folderId}`, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp))
  }

  foldersCompanies(idCompany,success, error) {
    axiosRequestBlockChain.get(`/folder/${idCompany}`, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp))
  }

  foldersCompaniesByUser(userId, success, error) {
    axiosBusinessRequest.get(`/folders/folders-companies/by-user/${userId}`, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp))
  }

  getFolderInfo(id_folder, companyId, success, error) {
    axiosRequestBlockChain.get(`/folder/details/${id_folder}`, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp))
  }

  getFoldersDetails(success, error) {
    axiosBusinessRequest.get(`/companies/all-companies-by-user`, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp))
  }

  getContractFolderByCompanyId(companyId, path,success, error) {
    axiosRequestBlockChain.get(`/folder/details/${companyId}?name=${path}`, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp))
  }


  checkKaleidoConnection(success, error) {
    axiosRequestBlockChain.get(`/folder/kaleido`, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp))
  }

  getFoldersCompaniesWithTrash(companyId,success, error) {
    axiosBusinessRequest.get(`/folders/get-folders-companies/${companyId}`, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp))
  }
}
