import React, { useEffect, useRef, useState } from "react";
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { makeStyles } from '@material-ui/core';
import swal from 'sweetalert';
import './PDFViewer.css';
import DrawerHelper from "./DrawerHelper"
import NavBarPDF from "./NavBarPDF";
import SideMenuEndorsement from "./SideMenuEndorsement";
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const mainScale = 1.5;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    textAlign: 'center'
  },
  canvasContainer: {
    height: '91vh',
    overflow: 'auto',
    padding: "10px"
  },
  canvas: {
    position: 'static',
    cursor: 'pointer',
    boxShadow: '0 0 10px rgb(121, 131, 125)'
  }
}));


export default function PDFViewerEndorsement(props) {
  const {
    url,
    users,
    onClose,
    onSaveSigns,
    documentName,
    signatures = [],
    strokeAndText = false,
    currentSignatures = [],
  } = props;

  const classes = useStyles();
  const canvasRef = useRef(null);
  const contanierRef = useRef(null);

  const [pdfRef, setPdfRef] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [zoom, setZoom] = useState(1);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const [offsetZoomX, setOffsetZoomX] = useState(0)
  const [offsetZoomY, setOffsetZoomY] = useState(0)
  const [totalPages, setTotalPages] = useState(0);
  const [signers, setSigners] = useState(users ?? []);
  const [dragSignatures, setDragSignatures] = useState(signatures ?? []);
  const [selectedText, setSelectedText] = useState(null);
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [scale, setScale] = useState(null);
  const [changeSize, setChangeSize] = useState(false);
  const [pageRendering, setPageRendering] = useState(false)
  const [pageNumPending, setPageNumPending] = useState(null)
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [inputValue, setInputValue] = useState(1);
  const [firstOpen, setFirstOpen] = useState(true);
  const [generatedTag, setGeneratedTag] = useState(false);
  const [prevSignatures, setPrevSignatures] = useState([])

  const minLimits = { square: { w: 25, h: 25 }, titleBackgroundH: 14 };

  const filterItem = (item) => dragSignatures.filter((e) => e.userId === item.userId).length !== 0;
  const validateStickersGenerated = (userId, stickers) => (stickers.filter((item) => item.userId === userId).length > 0);

  const renderPage = async (pdf, pageNum, stickers, showDelete, deleteX, deleteY, occupied) => {
    if (pdf) {
      setPageRendering(true)
      const page = await pdf.getPage(pageNum);
      const viewport = page.getViewport({ scale: mainScale });
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      const actualScale = ((window.innerHeight - 64) / viewport.height) * 0.95;

      canvas.width = Math.floor(viewport.width);
      canvas.height = Math.floor(viewport.height);
      canvas.style.width = Math.floor(viewport.width) * actualScale * zoom + "px";
      canvas.style.height = Math.floor(viewport.height) * actualScale * zoom + "px";

      const renderContext = {
        canvasContext: context,
        viewport: viewport
      };
      const renderPromise = page.render(renderContext);

      const canvasOffset = canvas?.getBoundingClientRect();
      setTotalPages(pdf.numPages);
      setOffsetX(Math.floor(canvasOffset.x));
      setOffsetY(74);// It should be canvasOffset.top, but it is making some trouble.
      setCanvasWidth(canvas.width);
      setCanvasHeight(canvas.height);
      setScale(actualScale);

      renderPromise.promise.then(() => {
        setPageRendering(false)
        if (pageNumPending !== null) {
          setPageNumPending(null)
        }

        occupied.reduce((acc, text) => {
          if (text.page === currentPage) {
            text.scale = actualScale
            text.zoom = zoom;
            DrawerHelper.drawOccupiedSquare(context, text)
          }
          return acc
        }, [])

        stickers.reduce((acc, text) => {
          if (text.page === currentPage) {
            text.scale = actualScale
            text.zoom = zoom;
            DrawerHelper.drawSquare(context, text)
          }
          return acc
        }, [])

        if (showDelete) {
          const obj = { x: deleteX, y: deleteY, scale: actualScale * zoom, zoom, }
          DrawerHelper.drawDeleteMessage(context, obj)
        } else {
          setShowDeleteMessage(false);
        }
      })

    }
  };

  const updateRender = (stickers, showDeleteMessage, x, y) => {
    const canvas = canvasRef.current;
    const ctxs = canvas.getContext('2d');
    ctxs.clearRect(0, 0, canvas.width, canvas.height);
    if (pageRendering) {
      setPageNumPending(currentPage)
    } else {
      renderPage(pdfRef, currentPage, stickers, showDeleteMessage, x, y, prevSignatures)
    }
  }

  const handleMouseDown = (e) => {
    const x = e.clientX;
    const y = e.clientY;
    const tempStartX = Math.floor(x - offsetX + offsetZoomX);
    const tempStartY = Math.floor(y - offsetY + offsetZoomY);

    dragSignatures.forEach((text) => {
      text.zoom = zoom;
      if (text.page === currentPage) {
        if (DrawerHelper.checkInDeleteSticker(tempStartX, tempStartY, text)) {
          const updateStickers = dragSignatures.filter(el => el.id !== text.id)
          setDragSignatures(updateStickers);
          updateRender(updateStickers, false, 0, 0)
        } else if (DrawerHelper.textHit(tempStartX, tempStartY, text)) {
          setSelectedText(text);
        }
        if (DrawerHelper.checkInRightBorder(tempStartX, tempStartY, text)) {
          setChangeSize(true);
        }
      }
    });

  }

  const handleMouseMove = (e) => {
    if (dragSignatures.length === 0) return
    e.preventDefault();
    const x = e.clientX;
    const y = e.clientY;
    const canvasD = canvasRef.current;
    const tempStartX = Math.floor(x - offsetX + offsetZoomX);
    const tempStartY = Math.floor(y - offsetY + offsetZoomY);

    const inStickerReducer = (acc, text) => {
      // text.zoom = zoom
      if (text.page === currentPage) {
        return acc || DrawerHelper.textHit(tempStartX, tempStartY, text)
      }
      return acc
    }

    const inDeleteStickerReducer = (acc, text) => {
      // text.zoom = zoom
      if (text.page === currentPage) {
        return acc || DrawerHelper.checkInDeleteSticker(tempStartX, tempStartY, text)
      }
      return acc
    }

    const inRightBorderReducer = (acc, text) => {
      // text.zoom = zoom
      if (text.page === currentPage) {
        return acc || DrawerHelper.checkInRightBorder(tempStartX, tempStartY, text)
      }
      return acc
    }

    const isCursorInSticker = dragSignatures.reduce(inStickerReducer, false);
    const isCursorInDeleteSticker = dragSignatures.reduce(inDeleteStickerReducer, false);
    const isCursorInRightBorder = dragSignatures.reduce(inRightBorderReducer, false);

    if (!isCursorInDeleteSticker && showDeleteMessage) {
      updateRender(dragSignatures, false, 0, 0)
    }

    if (isCursorInRightBorder) {
      canvasD.style.cursor = 'ne-resize';
    } else if (isCursorInDeleteSticker && !selectedText) {
      canvasD.style.cursor = 'pointer';
      setShowDeleteMessage(true);
      updateRender(dragSignatures, true, tempStartX, tempStartY)
    } else if (isCursorInSticker) {
      canvasD.style.cursor = 'grab';
    } else {
      canvasD.style.cursor = 'pointer'
    };

    if (!selectedText) return;
    if (x < offsetX) return;

    const dx = e.movementX
    const dy = e.movementY

    const updateStickers = dragSignatures.map(text => {
      const { scale, zoom } = text
      if (changeSize && text.id === selectedText.id) {
        const newWidth = text.squareW + (dx / (scale * zoom));
        const newHeight = text.squareH - (dy / (scale * zoom));

        if (newWidth > (minLimits.square.w / (scale * zoom))) {
          text.squareW = newWidth;
        }
        if (newHeight > (minLimits.square.h / (scale * zoom))) {
          text.squareH = newHeight;
          text.y += (dy / (scale * zoom));
        }
      } else if (text.id === selectedText.id && !changeSize) {

        const newPositionX = text.x + (dx / (scale * zoom));
        const newPositionY = text.y + (dy / (scale * zoom));

        if (newPositionX >= 0 && newPositionX + text.squareW <= text.maxEjeX) {
          text.x = newPositionX;
        }
        if (newPositionY >= 0 && newPositionY + text.squareH <= text.maxEjeY) {
          text.y = newPositionY;
        }
      }

      return text
    });
    setDragSignatures(updateStickers)
    updateRender(updateStickers, false, 0, 0)
  }

  const handleMouseUp = (e) => {
    setSelectedText(null);
    setChangeSize(false);
  }

  const handleMouseOut = (e) => {
    setSelectedText(null);
    setChangeSize(false);
  }

  const handleScroll = (e) => {
    const top = Math.floor(e.target.scrollTop)
    const left = Math.floor(e.target.scrollLeft)
    setOffsetZoomY(top)
    setOffsetZoomX(left)

  }

  const handleClose = () => {
    if (dragSignatures.length !== signatures.length) {

      swal({
        title: '¿Deseas salir sin guardar?',
        icon: 'warning',
        buttons: ['Cancelar', 'Aceptar'],
        dangerMode: true
      }).then(async (response) => {
        if (response) {
          onClose();
        }
      });

      return
    }
    onClose()

  }

  const handleNextPage = () => {
    if (currentPage >= pdfRef.numPages) {
      return;
    }
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage <= 1) {
      return;
    }
    setCurrentPage(currentPage - 1);
  };

  const onChangeHandler = (event) => {
    setInputValue(parseInt(event.target.value));
  };

  const pageByInputRequest = () => {
    if (inputValue < 1 || inputValue > pdfRef.numPages) {
      swal('Aviso', 'El numéro no es una opción válida de página', 'warning');
    } else {
      const pages = inputValue;
      setCurrentPage(pages);
    }
  };

  const handleGenerateTag = (name, userId) => {
    const qtyStickers = (dragSignatures.filter(text => text.page === currentPage)).length;
    const y = (qtyStickers % 13 + 1) * 30 + 55;
    const stickerName = '[' + name.split('').slice(0, 22).join('') + '...' + ']';

    const user = signers.find(el => el.id === userId);

    const sticker = { H: 0, W: 0 }
    if (user.endorser) {
      if (strokeAndText) {
        sticker.H = 110;
        sticker.W = 110;
      } else {
        sticker.H = 150;
        sticker.W = 380;
      }
    } else {
      sticker.H = 50;
      sticker.W = 160;
    }

    const text = {
      id: Date.now(),
      name: name,
      page: currentPage,
      userId: userId,
      text: name.length > 23 ? stickerName : name,
      x: Math.floor(40 / scale),
      y: Math.floor(y / scale),
      maxEjeX: canvasWidth,
      maxEjeY: canvasHeight,
      coordConvert: [],
      scale: scale,
      zoom: zoom,
      width: 0,
      height: 24,
      squareH: Math.floor(sticker.H / scale),
      squareW: Math.floor(sticker.W / scale),
      titleH: Math.floor(minLimits.titleBackgroundH / scale),
    }
    const arrayStickers = [...dragSignatures, text]
    setDragSignatures(arrayStickers);
    updateRender(arrayStickers, false, 0, 0)

    setGeneratedTag(true)


  }

  const sendDocumentFirm = async () => {
    const allSigned = signers.every(filterItem);

    const signatures = dragSignatures.reduce((acc, text) => {
      const { x, y, maxEjeY, squareH, squareW, scale } = text;
      const coordX = Math.ceil(x * scale) - 2;
      const coordY = Math.ceil((maxEjeY - y - squareH) * scale);

      text.squareHOriginal = squareH
      text.squareWOriginal = squareW
      text.squareH = Math.ceil(squareH * scale)
      text.squareW = Math.ceil(squareW * scale)
      text.coordConvert = [coordX, coordY];
      text.scale = text.scale * mainScale
      return [...acc, text];
    }, []);


    //* validamos si hay firmantes sin firma
    if (!allSigned) {
      swal('Aviso', 'Hay firmantes con etiquetas pendientes', 'warning');
      return;
    }

    swal({
      title: '¿Estás seguro?',
      text: `Terminar posicionamiento de etiquetas`,
      icon: 'warning',
      buttons: ['Cancelar', 'Aceptar'],
      dangerMode: true
    }).then(async (finish) => {
      if (finish) {
        onSaveSigns(signatures);
        onClose();
      }
    });
  };

  const loadFilePDF = async () => {
    try {
      const signedStickers = currentSignatures.reduce((acc, curr) => {
        const text = { ...curr }
        text.scale = text.scale / mainScale
        text.zoom = zoom;
        if (text.squareHOriginal) {
          text.squareH = text.squareHOriginal
          text.squareHOriginal = null
        }
        if (text.squareWOriginal) {
          text.squareW = text.squareWOriginal
          text.squareHOriginal = null
        }
        return [...acc, text]
      }, [])

      setPrevSignatures(signedStickers);
      const loadingTask = pdfjsLib.getDocument(url);
      const loadedPdf = await loadingTask.promise;

      setPdfRef(loadedPdf);
      const stickers = signatures.reduce((acc, curr) => {
        const text = { ...curr }
        text.scale = text.scale / mainScale
        text.zoom = zoom;
        if (text.squareHOriginal) {
          text.squareH = text.squareHOriginal
          text.squareHOriginal = null
        }
        if (text.squareWOriginal) {
          text.squareW = text.squareWOriginal
          text.squareHOriginal = null
        }
        return [...acc, text]
      }, [])
      setDragSignatures(stickers)
      renderPage(loadedPdf, currentPage, stickers, false, 0, 0, signedStickers);
      setFirstOpen(false)

    } catch (error) {
      console.log(error);

    }
  }

  useEffect(() => {
    setSigners(oldSigners => oldSigners.map(el => ({ ...el, check: validateStickersGenerated(el.userId, dragSignatures) })))
    // eslint-disable-next-line
  }, [dragSignatures])

  useEffect(() => {
    if (!firstOpen) {
      updateRender(dragSignatures, false, 0, 0)
    }
    // eslint-disable-next-line
  }, [zoom, currentPage])

  useEffect(() => {
    loadFilePDF()
    // eslint-disable-next-line
  }, [])



  return (
    <div>
      <NavBarPDF
        onClose={handleClose}
        documentName={documentName}
        handlePrevPage={handlePrevPage}
        currentPage={currentPage}
        totalPages={totalPages}
        handleNextPage={handleNextPage}
        onChangeHandler={onChangeHandler}
        inputValue={inputValue}
        pageByInputRequest={pageByInputRequest}
        zoom={zoom}
        setZoom={setZoom}
      />
      <div className={classes.root} >
        <SideMenuEndorsement
          signers={signers}
          handleGenerateTag={handleGenerateTag}
          sendDocumentFirm={sendDocumentFirm}
          filterItem={filterItem}
          generatedTag={generatedTag}

        />
        <main className={classes.content}>
          <div
            id="canvas-container"
            className={classes.canvasContainer}
            ref={contanierRef}
            onScrollCapture={handleScroll}
          >
            <canvas
              id="canvas"
              className={classes.canvas}
              ref={canvasRef}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseOut={handleMouseOut}
            />
          </div>
        </main>
      </div>
    </div>
  );
}



