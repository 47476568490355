import React from 'react';
import { Grid, Typography, IconButton, makeStyles } from '@material-ui/core';
import { FolderFileSystemIcon } from '../../icons/FolderFileSystemIcon';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const useStyles = makeStyles((theme) => ({
  iconFolderRoot: {
    fontSize: '2rem',
  },
  buttonFolder: {
    marginLeft: '-12px'
  }
}));

export const DescriptionBasic = ({ items, folder }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClickFolder = () => {
    const { companyId, name, path } = folder;
    const pathFolder = !!path ? `${path}/${name}` : name;
    history.push({
      pathname: `/dashboard/vault/${companyId}`,
      search: `p=${encodeURIComponent(pathFolder)}`
    })
  }

  return (
    <Grid container spacing={1}>
      {
        items.map(item => (
          <Grid container item key={item.label}>
            <Grid item xs sm={4} md={3} lg={2} alignContent='center'>
              <Typography color="primary">
                {item.label}
              </Typography>
            </Grid>
            <Grid item xs sm={8} md={9} lg={10}>
              {item.value}
            </Grid>
          </Grid>
        ))
      }
      <Grid container item >
        <Grid item xs sm={4} md={3} lg={2} alignContent='center'>
          <Typography color="primary">
            Archivos
          </Typography>
        </Grid>
        <Grid item xs sm={8} md={9} lg={10}>
          <IconButton
            onClick={handleClickFolder}
            className={classes.buttonFolder}
          >
            <FolderFileSystemIcon
              className={classes.iconFolderRoot}
            />
          </IconButton>
        </Grid>

      </Grid>
    </Grid>
  )
}
