import { axiosBusinessRequest, authHeader, axiosCollaboratorRequest, axiosCollaboratorUsersRequest } from '../../configs/axios.config';

export class CollaboratorsHttp {
  getCollaborators(documentId, success, error) {
    axiosBusinessRequest.get(`/collaborators/${documentId}`, {headers: authHeader()})
    .then(({data}) => success(data))
    .catch(err => error(err))
  }

  getAllUsersCollaborators() {
    return axiosCollaboratorUsersRequest.get(`/users/collaborators/${window.location.href.split('vault-file/')[1]}`, {headers: authHeader()});
  }

  getUsersCollaboratorsByCompany(companyId) {
    return axiosCollaboratorUsersRequest.get(`/users/collaborators/${companyId}`, {headers: authHeader()});
  }

  saveCollaborators(documentId, users, emails, success, error) {
    const url = `/collaborators/${documentId}?collaborators=${users}&emails=${emails}&companyId=${window.location.href.split('vault-file/')[1]}`
    axiosBusinessRequest.post(url, {}, {headers: authHeader()})
    .then(({data}) => success(data))
    .catch(err => error(err))
  }

  updateCollaborators(documentId, users, emails, success, error) {
    const url = `/collaborators/${documentId}?collaborators=${users}&emails=${emails}&companyId=${window.location.href.split('vault-file/')[1]}`
    axiosBusinessRequest.put(url, {}, {headers: authHeader()})
    .then(({data}) => success(data))
    .catch(err => error(err))
  }

  sharedDocuments() {
    return axiosCollaboratorRequest.get('/collaborators', {headers: authHeader()});
  }

  setAttended(id) {
    return axiosCollaboratorRequest.put(`/collaborators/update-status/${id}?status=true`,{}, {headers: authHeader()});
  }

  getContractsExpire(id) {
    return axiosCollaboratorRequest.get(`/contracts/${id}`, {headers: authHeader()});
  }

  getPendingDocumentsByCompany(companyId) {
    return axiosBusinessRequest.get(`/contracts/pending-signature/${companyId}`, {headers: authHeader()});
  }
}
