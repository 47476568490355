import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './vertical-center-div.scss';

export class VerticalCenterDiv extends Component {
  render() {
    const { children, className, style } = this.props;

    return (
      <span className="VerticalCenterDiv">
        <div className={ `${ className ? className : '' } outer` } style={ style }>
          <div className="middle">
            <div className="inner">
              { children }
            </div>
          </div>
        </div>
      </span>
    );
  }
}

VerticalCenterDiv.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.any
}
