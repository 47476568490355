

import { GET_FOLDERS } from "../constants"

const initialState = {
    allFolders: []
}

export const foldersSearchReducer = (state = initialState, action)=>{
    switch(action.type){
        case GET_FOLDERS:
            return{
                ...state, 
                allFolders: action.payload
            }


        default:
        return{...state}
    }
}