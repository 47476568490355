import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { userConstants } from "../core/constants";
import { foldersCompany, getAllDocumentsFromFolder, getFolderInfo, moveDocument, moveFolder } from "../core/http/functionRequests";



export const useFolderSelector = ({
  onSuccess,
  onFail,
  companySelected,
  itemSelected,
  origin,
  fromCompany
}) => {

  const [folderSelected, setFolderSelected] = useState(null);
  const [folders, setFolders] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [showCompanies, setShowCompanies] = useState(false);
  const [company, setCompany] = useState(companySelected);
  const [totalDocuments, setTotalDocuments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [documentWithProcess, setDocumentWithProcess] = useState([]);
  const [titleError, setTitleError] = useState('');
  const [openAlert, setOpenAlert] = useState(false);

  const dispatch = useDispatch();

  const getFoldersFromCompany = async () => {
    try {
      let folders = await foldersCompany(company.id, company.name);

      if (!itemSelected.extension) {
        folders = folders.filter(el => el.id !== itemSelected.id);
      }

      const companyFolders = [{
        id: company.id,
        name: company.name,
        path: '',
        pathLabel: company.name,
        children: [...folders],
        childrenLoaded: true,
      }];

      setFolders(companyFolders);
      setFolderSelected(null);

    } catch (error) {
      onFail(error)
      setLoading(false);
      console.error(error);
    }
  }

  const getSubfolders = async (folder) => {
    try {
      let subfolders = await getFolderInfo(folder.id, company.name);

      if (!itemSelected.extension) {
        subfolders = subfolders.filter(el => el.id !== itemSelected.id);
      }

      return subfolders;
    } catch (error) {
      onFail(error)
      setLoading(false);
      console.error(error);
    }
  }

  const moveElement = async () => {
    try {
      setOpenConfirm(false);
      setLoading(true);
      let msg = "";

      if (!!itemSelected.extension) {
        msg = "Documento movido exitosamente"

        const request = {
          from: origin,
          to: folderSelected.id,
          fromCompany,
          toCompany: folderSelected.path === "" ? true : false,
          path: folderSelected.path,
          name: itemSelected.name.split('.')[0]
        }
        await moveDocument(itemSelected.id, request);
      } else {
        msg = "Folder movido exitosamente";
        const request = {
          from: origin,
          to: folderSelected.id,
          fromCompany,
          toCompany: folderSelected.path === "" ? true : false,
          path: folderSelected.path,
          name: itemSelected.name
        }
        await moveFolder(itemSelected.id, request);
      }

      setLoading(false);
      onSuccess(msg);
    } catch (error) {
      if (error?.documents) {
        setDocumentWithProcess(error.documents);
        setTitleError(error.msg);
        setOpenAlert(true);
      } else {
        onFail(error);
      }
      setLoading(false);
    }
  }

  const getDocumentList = async () => {
    try {
      const documents = await getAllDocumentsFromFolder(itemSelected.id);
      setTotalDocuments(documents);
    } catch (error) {
      onFail(error)
      setLoading(false);
      console.error(error);
    }
  }

  const clearCompanies = () => {
    dispatch({
      type: userConstants.SET_AVAILABLE_COMPANIES,
      payload: null
    });
  }

  useEffect(() => {
    getFoldersFromCompany();
    if (!totalDocuments && !itemSelected.extension) {
      getDocumentList();
    }

    // eslint-disable-next-line
  }, [company]);


  return {
    getSubfolders,
    clearCompanies,
    setShowCompanies,
    setFolderSelected,
    setFolders,
    setOpenConfirm,
    setCompany,
    setOpenAlert,
    setOpenList,
    setDocumentWithProcess,
    setTitleError,
    moveElement,
    folderSelected,
    openConfirm,
    folders,
    showCompanies,
    company,
    totalDocuments,
    companyName: company.name,
    loading,
    openList,
    documentWithProcess,
    titleError,
    openAlert
  }

}
