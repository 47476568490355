import React, { useState } from 'react';
import {
  Dialog,
  Grid,
  Button,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  DialogActions,
  IconButton,
  ListItemSecondaryAction,
  DialogTitle,
  Backdrop,
  TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { downloadScannedCopy, sendScannedCopy } from '../../../core/http/functionRequests';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "5px 15px 5px 15px",
  },
  containerList: {
    padding: "10px 15px 10px 15px"
  },
  list: {
    overflow: "auto",
    maxHeight: "300px"
  },
  footer: {
    padding: "15px"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  title: {
    padding: "15px 15px 15px 15px"
  },
  dialogTitle: {
    padding: "16px 24px 16px 0px"
  },
  gridList: {
    padding: '0px'
  },
  listText: {
    paddingLeft: "0px"
  },
  sendTo: {
    paddingRight: "0px !important"
  }
}));

export const DialogScannedCopy = (props) => {
  const classes = useStyles();
  const { open, documentId, onCancel, handleSubmit, handleError, documentName } = props;

  const [emails, setEmails] = useState([]);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [email, setEmail] = useState("");

  const downloadDocument = async () => {
    try {
      setLoad(true);
      const doc = await downloadScannedCopy({ documentId });
      const url = URL.createObjectURL(
        new Blob([doc], {
          type: "application/pdf",
        }));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        documentName,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
      handleSubmit("Documento descargado");
    } catch (error) {
      console.error(error);
      handleError("Error descargando documento")
    } finally {
      setLoad(false);
    }
  }

  const sendDocument = async () => {
    try {
      setLoad(true);
      await sendScannedCopy({
        documentId,
        emails
      });
      handleSubmit("Documento enviado");
      setEmails([]);
    } catch (error) {
      console.error(error);
      handleError("Error enviando el documento")
    } finally {
      setLoad(false);
    }
  }

  const handleDownload = () => {
    downloadDocument();
  }

  const handleAddEmail = () => {

    const emailReg = new RegExp(/\S+@\S+\.\S+/);
    if (!emailReg.test(email)) {
      setErrorMsg("Email inválido");
      setError(true)
      return
    }
    if (emails.includes(email)) {
      setErrorMsg("El correo ya fue agregado");
      setError(true)
      return
    }

    if (errorMsg) {
      setErrorMsg("");
    }
    if (error) {
      setError(false);
    }

    setEmails(prev => [...prev, email]);
    setEmail("");
  }

  const handleDeleteEmail = (el) => {
    setEmails(prev => prev.filter(email => email !== el))
  }

  const handleSendCopy = () => {
    sendDocument()
  }

  const handleChange = (e) => {
    const text = e.target.value;
    setEmail(text);
  }

  return (
    <>
      <Dialog
        open={open}
      >
        <Grid container
          spacing={3}
          className={classes.title}
          wrap='nowrap'
          alignItems='center'
          justifyContent='space-between'
        >
          <Grid item>
            <DialogTitle
              className={classes.dialogTitle}
            >
              Copia escaneada
            </DialogTitle>
          </Grid>
          <Grid item>

            <Button
              variant="contained"
              color="primary"
              onClick={handleDownload}
            >
              Descargar
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          className={classes.container}
          justifyContent='flex-end'
        >
          <Grid item>

          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          className={classes.container}
          wrap='nowrap'
          justifyContent='flex-end'
          alignItems='center'
        >
          <Grid
            item
            xs={2}
            className={classes.sendTo}
          >
            <span>Enviar a</span>
          </Grid>
          <Grid item xs>
            <TextField
              error={error}
              variant="outlined"
              value={email}
              onChange={handleChange}
              helperText={errorMsg}
              size='small'
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddEmail}
              disabled={email.length === 0}
            >
              Agregar
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          className={classes.containerList}
          wrap='nowrap'
        >
          <Grid item xs={2}>
          </Grid>
          <Grid item xs className={classes.list}>
            <List dense>
              {
                emails.map(email => (
                  <ListItem
                    className={classes.listText}
                    key={Date.now() + email}
                  >
                    <ListItemText
                      title={email}
                      primary={email.length > 30 ? `${email.substring(0, 30)}...` : email}
                    />
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => handleDeleteEmail(email)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>

                  </ListItem>
                ))
              }
            </List>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          className={classes.containerList}
          wrap='nowrap'
          justifyContent='flex-end'
        >
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendCopy}
              disabled={emails.length === 0}
            >
              Enviar
            </Button>
          </Grid>
        </Grid>
        <DialogActions className={classes.footer}>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => onCancel()}
          >
            Cancelar
          </Button>
        </DialogActions>
        <Backdrop className={classes.backdrop} open={load}>
          <CircularProgress />
        </Backdrop>
      </Dialog >
    </>
  )
}
