import { Button, IconButton, Hidden } from "@material-ui/core";
import Filter2Icon from '@material-ui/icons/Filter2';
import { Calendar } from "./calendar/calendar";
import { FormActivityNative } from "./calendar/forms/form-activity-native";
import { SignaturesAvailableIcon } from "../../icons";
import { BasicModal } from "../modal/BasicModal";
import { useAvailableSignatures } from "../../hooks/useAvailableSignatures";
import { Alert } from "axeleratum-sgc-frontend-library";
import formatDate from "../utils/formatDate";
import { NotificationFn } from "./notification/NotificationFn";

const Control = () => {

  const {
    totalPendingSigns,
    openCalendar,
    openActivity,
    loadActivity,
    openAvailableSignatures,
    documentsAvailable,
    openAlert,
    setOpenCalendar,
    setOpenActivity,
    setLoadActivity,
    setOpenAlert,
    handleOpenAvailableSignatures,
    handleCloseModal,
  } = useAvailableSignatures();

  return (
    <div className="margin-top">

      {
        totalPendingSigns > 1 && (
          <Button
            className="mr-2 button-border"
            variant="outlined"
            onClick={() => window.open("https://firmamex.com/options#!/repositorytodo")}
          >
            <Hidden smUp>
              <Filter2Icon color="primary" />
            </Hidden>
            <Hidden xsDown>FIRMA EN BLOQUE</Hidden>
          </Button>
        )
      }

      <NotificationFn />

      <IconButton
        className="button-border ml-2"
        variant="outlined"
        onClick={() => handleOpenAvailableSignatures()}
        id="availableSignatures"
      >
        <SignaturesAvailableIcon
          color="#5C7BF0"
          width="22px"
        />
      </IconButton>

      <BasicModal
        open={openAvailableSignatures}
        onClose={handleCloseModal}
        title={"DOCUMENTOS DISPONIBLES PARA FIRMA"}
      >
        {
          !documentsAvailable.remaining && !documentsAvailable.remainingNom151
            ? <p>Cargando informacion...</p>
            :
            <>
              {documentsAvailable?.remainingNom151 >= 0 ? <p className="content-modal-documents"><strong>{documentsAvailable.remainingNom151}</strong> Documentos disponibles Con NOM-151</p> : null}
              {documentsAvailable?.remaining >= 0 ? <p className="content-modal-documents"><strong>{documentsAvailable.remaining}</strong> Documentos disponibles Sin NOM-151</p> : null}
              <p className="content-modal-date">Datos a {formatDate(documentsAvailable.date)}</p>
            </>
        }
      </BasicModal>


      {openActivity && (
        <FormActivityNative
          open={openActivity}
          load={loadActivity}
          initialValues={{}}
          onSubmit={(activity) => {
            // debugger;
            setLoadActivity(true);
            setOpenActivity(false);
          }}
          onCancel={() => setOpenActivity(false)}
        />
      )}

      <Alert
        open={openAlert}
        title={"Error obteniendo información del servidor"}
        onConfirm={() => setOpenAlert(false)}
        type={'error'}
      />
    </div>
  )
}

export default Control;
