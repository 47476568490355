import { axiosCalendarRequest, authHeader } from "../../../configs/axios.config"
import { decodeToken } from "../../utils/token";


export const getPendingSigns = async () => {
  try {

    const { data } = await axiosCalendarRequest.get(`/signer-reviewers/get-pending-activities/`, { headers: authHeader() });
    const { massiveSignatures, userId } = decodeToken(sessionStorage.getItem('access_token'));

    const filteredData = data.filter(el => el.type === "firm" && el.show);

    const { rfc, email, token } = filteredData.reduce((prev, curr) => {
      const signer = curr.documents[0].signerReviewer.find(item => item.userId === userId)
      if (signer.signType === "email")
        return { ...prev, email: prev.email + 1 }

      if (signer.signType === "rfc")
        return { ...prev, rfc: prev.rfc + 1 }

      if (signer.signType === "token")
        return { ...prev, token: prev.token + 1 }

      return prev
    }, { rfc: 0, email: 0, token: 0 })


    if (massiveSignatures && (rfc > 1 || email > 1 || token > 0)) {
      return filteredData.length;
    }
    return 0;
  } catch (error) {
    console.error(error)
    throw error
  }
}