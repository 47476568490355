import React from 'react';
import { DescriptionBasic } from '../../components/description-basic/DescriptionBasic';

const GeneralData = ({ business }) => {
  return (
    <DescriptionBasic
      items={business.fields}
      folder={business.folderId}
    />
  )
}

export default GeneralData