import crypto from 'crypto';

const algorithmFiles = 'aes-256-ctr';

const readFile = (file) => new Promise((resolve, reject) => {
  const hash = crypto.createHash('sha256');
  const reader = new FileReader();
  reader.onerror = () => reject("error guardando")
  reader.onload = (ev) => {
    const result = ev.target.result;
    hash.update(result)
    resolve({
      hash,
      fileBuffer: reader.result
    })
  }
  reader.readAsArrayBuffer(file)
})


// async function encryptFileData(fileBuffer, encodedSecretKey, iv) {
//   const algorithm = { name: 'AES-GCM', iv: iv };
//   const cryptoKey = await window.crypto.subtle.importKey(
//     'raw',
//     encodedSecretKey,
//     { name: 'AES-GCM' },
//     false,
//     ['encrypt']
//   );

//   const encryptedData = await window.crypto.subtle.encrypt(
//     algorithm,
//     cryptoKey,
//     fileBuffer
//   );

//   return new Uint8Array(encryptedData);
// }

async function decryptFile(encryptedFile, key) {
  // Separate IV from the encrypted file data
  const iv = new Uint8Array(encryptedFile.slice(0, 12));
  const encryptedData = encryptedFile.slice(12);

  // Convert key and iv to CryptoKey objects
  const cryptoKey = await window.crypto.subtle.importKey(
    "raw",
    key,
    { name: "AES-GCM" },
    false,
    ["encrypt", "decrypt"]
  );

  // Decrypt using AES-GCM
  const decryptedData = await window.crypto.subtle.decrypt(
    {
      name: "AES-GCM",
      iv: iv,
    },
    cryptoKey,
    encryptedData
  );

  return decryptedData;
}


function arrayBufferToBase64(buffer) {
  const bytes = new Uint8Array(buffer);
  return btoa(String.fromCharCode(...bytes));
}

function base64ToArrayBuffer(base64) {
  const binaryString = atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

// async function decryptFile(encryptedFile, base64Key, base64IV) {
//   // Step 1: Import the key from base64
//   const key = await window.crypto.subtle.importKey(
//     'raw',
//     base64ToArrayBuffer(base64Key),
//     { name: 'AES-GCM' },
//     false,
//     ['decrypt']
//   );

//   // Step 2: Convert IV from base64 to array buffer
//   const iv = base64ToArrayBuffer(base64IV);

//   // Step 3: Decrypt the encrypted file data
//   const decryptedData = await window.crypto.subtle.decrypt(
//     {
//       name: 'AES-GCM',
//       iv: iv,
//     },
//     key,
//     encryptedFile
//   );

//   // Return the decrypted file buffer
//   return decryptedData;
// }

function appendBuffer(buffer1, buffer2) {
  const tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
  tmp.set(new Uint8Array(buffer1), 0);
  tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
  return tmp.buffer;
}

export const getHashWithEncrypt = async (file) => {
  try {
    // const secretKey = process.env.REACT_APP_SECRET_KEY
    const { hash, fileBuffer } = await readFile(file);

    // const key = await window.crypto.subtle.generateKey(
    //   { name: 'AES-GCM', length: 256 },
    //   true,
    //   ['encrypt', 'decrypt']
    // );
    // const exportedKey = await window.crypto.subtle.exportKey('raw', key);
    // const base64Key = arrayBufferToBase64(exportedKey);
    // const iv = window.crypto.getRandomValues(new Uint8Array(12));
    // const base64IV = arrayBufferToBase64(iv)
    // console.log(base64Key);
    // console.log(base64IV);

    const key = window.crypto.getRandomValues(new Uint8Array(32));
    const iv = window.crypto.getRandomValues(new Uint8Array(12));

    // const combinedBuffer = new Uint8Array(iv.byteLength + fileBuffer.byteLength);
    // combinedBuffer.set(new Uint8Array(iv), 0);
    // combinedBuffer.set(new Uint8Array(fileBuffer), iv.byteLength);

    const cryptoKey = await window.crypto.subtle.importKey(
      "raw",
      key,
      { name: "AES-GCM" },
      false,
      ["encrypt", "decrypt"]
    );

    const algorithm = { name: 'AES-GCM', iv };

    const encryptedData = await window.crypto.subtle.encrypt(
      algorithm,
      cryptoKey,
      fileBuffer
    );

    const encryptedFile = appendBuffer(iv.buffer, encryptedData);

    // const decryptedFile = await decryptFile(new Uint8Array(encryptedData), base64Key, base64IV)
    const decryptedFile = await decryptFile(encryptedFile, key);

    return {
      hash,
      encryptedFile: new Uint8Array(decryptedFile),
    }
  } catch (error) {
    console.log(error);
    throw error
  }
}

export const getHash = (file) => new Promise((resolve, reject) => {
  const hash = crypto.createHash('sha256');
  const reader = new FileReader();
  reader.readAsArrayBuffer(file)
  reader.onerror = () => reject("error guardando")
  reader.onload = (ev) => {
    const result = ev.target.result;
    hash.update(result)
    resolve({ hash: hash.digest('hex'), buffer: result })
  }

})

// encriptacion