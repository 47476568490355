export const vaultConstants = {
  SAVE_ITEMS: "SAVE_ITEMS_VAULT_CONSTANTS",// ok
  SEARCH_ITEMS: "SEARCH_ITEMS_VAULT_CONSTANTS",
  FILTER_ITEMS: "FILTER_ITEMS_VAULT_CONSTANTS",
  LOADING_ITEMS: "LOADING_ITEMS_VAULT_CONSTANTS",//ok
  SET_DEEP_SEARCH: 'SET_DEEP_SEARCH_VAULT_CONSTANTS',
  SEARCH_ITEMS_LOCAL: "SEARCH_ITEMS_LOCAL_VAULT_CONSTANTS", //ok
  LOADING_ITEMS_SEARCHBAR: "LOADING_ITEMS_SEARCHBAR_VAULT_CONSTANTS",
  SET_INCLUDE_DESCRIPTION: "SET_INCLUDE_DESCRIPTION_VAULT_CONSTANTS",
  UPDATE_CURRENT_LOCATION: "UPDATE_CURRENT_LOCATION_VAULT_CONSTANTS",
}
