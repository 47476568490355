import React from 'react';
import './App.css';
import { ThemeProvider } from '@material-ui/styles';
import * as Theme from './styles/common';
import Routing from './core/routes/routing.routes';


function App() {
  return (
    <ThemeProvider theme={Theme.normal}>
      <Routing />
    </ThemeProvider>
  );
}

export default App;
