import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem } from '@material-ui/core';


export const AddMultipleUsers = ({ initialValues, values, setItems }) => {

  const [selectedOptions, setSelectedOptions] = useState(initialValues ?? []);
  const [checked, setChecked] = useState(initialValues ?? []);
  const [openList, setOpenList] = useState(false);

  const handleChange = (user) => {
    const currentIndex = checked.map(e => e.userId).indexOf(user.userId);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(user);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  }

  const handleAddUsers = () => {
    setItems(checked);
    setSelectedOptions(checked);
    setOpenList(false);
  }

  const handleCancel = () => {
    setOpenList(false);
    setChecked(selectedOptions);
  }

  return (
    <>
      <div>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpenList(true)}
        >
          Agregar
        </Button>
      </div>

      {
        openList && (
          <Dialog open={openList}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
              <DialogTitle>Seleccionar Usuarios</DialogTitle>
            </div>
            <DialogContent style={{ paddingTop: "0px", marginTop: "-16px" }}>
              <List style={{ paddingTop: "0px" }}>
                {
                  values.map((item, index) => (
                    <ListItem
                      key={`${item.userId}-${index}`}
                      onClick={() => handleChange(item)}
                      style={{ paddingTop: '0px', paddingBottom: "0px" }}
                    >
                      <Checkbox
                        id={`check${item.userId}`}
                        checked={checked.some(some => some.userId === item.userId)}
                      />
                      <ListItemText primary={item.name} />
                    </ListItem>
                  ))
                }
              </List>
            </DialogContent>
            <DialogActions style={{ justifyContent: "flex-end" }} >
              <Button
                className="primary-text"
                color="primary"
                variant="outlined"
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddUsers}
              >
                Aceptar
              </Button>

            </DialogActions>
          </Dialog>
        )
      }
    </>
  );
}
